import React from "react";

import "../styles/styles.css";
class App extends React.Component {
  constructor(props) {
    super(props);
    console.log(props.x + " props.x");
    console.log(props.y + " props.y");

    this.mouseMove = this.mouseMove.bind(this);
    this.mouseDown = this.mouseDown.bind(this);
    this.drawSvg = this.drawSvg.bind(this);
    this.mouseUp = this.mouseUp.bind(this);
  }
  mouseDown(e) {
    console.log("================================================");
    console.log(e.pageX);
    console.log(e.pageY);
    console.log("================================================");
    document.addEventListener("mousemove", this.mouseMove);
    document.addEventListener("mouseup", this.mouseUp);
  }
  mouseMove(e) {
    console.log(e.pageX);
    console.log(e.pageY);
    let sideBar1 = document.getElementsByClassName("UiFlow-item2-13")[0]
      .offsetWidth;
    let sideBar2 = document.getElementsByClassName("UiFlow-item5-17")[0]
      .offsetWidth;
    let totalsideBar = sideBar1 + sideBar2;
    let topBar1 = document.getElementsByClassName("MuiToolbar-dense")[1]
      .offsetHeight;
    let topBar2 = document.getElementsByClassName("MuiToolbar-dense")[0]
      .offsetHeight;

    let totalTop = topBar1 + topBar2;
    this.drawSvg(20, 20, e.pageX - totalsideBar, e.pageY - totalTop);
  }
  drawSvg(x1, y1, x2, y2) {
    // M x1 y1 C x2 y1 x3 y4 x4 y4
    let xaxis2 = x2 / 4;
    let xaxis3 = x2 / 2;
    document
      .getElementById("path")
      .setAttribute(
        "d",
        `M ${x1} ${y1} C ${xaxis2} ${y1 / 2} ${xaxis3} ${y2} ${x2} ${y2}`
      );
    document.getElementById("circle2").setAttribute("cx", `${x2}`);
    document.getElementById("circle2").setAttribute("cy", `${y2}`);
  }
  mouseUp(e) {
    console.log("__________________________________________");
    console.log("__________________ mouse up ________________");
    console.log("__________________________________________");
    document.removeEventListener("mousemove", this.mouseMove);
    document.removeEventListener("mouseup", this.mouseUp);
  }

  render() {
    return (
      <>
        <button
          onClick={() => {
            alert("test");
          }}
        >
          test
        </button>
        <svg
          ref={(ref) => {
            this.canvasRef = ref;
          }}
          onMouseDown={(e) => this.mouseDown(e)}
          id="svg"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path id="path" />
          <circle
            class="handle"
            id="circle1"
            cx="20"
            cy="20"
            r="6"
            style={{ fill: "red" }}
          />
          <circle
            class="handle"
            id="circle2"
            cx="20"
            cy="20"
            r="6"
            style={{ fill: "orange" }}
          />
        </svg>
      </>
    );
  }
}

export default App;
