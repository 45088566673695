import React from 'react';
import {
    Collapse,
    Paper,
    ListItem,
    ListItemText,
    TextField,
    Button,
    Modal,
    makeStyles,
    Grid,
    IconButton
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

let activeScreen = null;

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        border: '2px solid #000',
        boxShadow: 24,
        height: 450
    },
    root: {
        height: '100%',
        overflow: 'scroll',
        padding: 25
    },
    closeIcon: {
        position: "absolute",
        right: "-25px",
        top: "-25px"
    }
}));


export default function HideShow(props) {
    const [collapse, setCollapse] = React.useState(false)
    const [state, setState] = React.useState([])
    const [eventsList, setEventsList] = React.useState([])
    const [AllScreenList, setAllScreenList] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const classes = useStyles()

    const addNewCondition = () => {
        let setData = state ?? [];
        setData.push({
            ...activeScreen,
            default: true
        })
        setState([...setData])
    }

    React.useEffect(() => {
        let AllScreenList = props.AllScreenList;
        let eventsList = AllScreenList[props.index]?.events ?? [];
        eventsList = eventsList?.filter(item => item?.CName)
        setEventsList(eventsList)

        let parseScreens = []
        AllScreenList.map(item => {
            if (!item.ignore) {
                parseScreens.push({
                    screenName: item.name,
                    screenId: item.id,
                    screenIndex: item.index - 1
                })
            }
            if (item.index - 1 === props.index) {
                activeScreen = {
                    screenName: item.name,
                    screenId: item.id,
                    screenIndex: item.index - 1
                }
            }
        });

        ///set inital state
        if (props?.state && typeof props?.state === "object" && !Array.isArray(props?.state)) {
            setState([{
                ...props.state,
                ...activeScreen
            }])
        }
        else setState(props?.state ?? [])
        setAllScreenList(parseScreens)
    }, [props?.componentId])

    const handleListChange = (data, index, keyName) => {
        let setData = [...state];
        if (keyName === "screens") {
            setData[index] = {
                ...setData[index],
                screenName: data?.screenName,
                screenId: data?.screenId,
                screenIndex: data?.screenIndex
            }
            if (data?.screenIndex === props.index) {
                setData[index]["default"] = true;
            } else setData[index]["default"] = false;
        }
        if (keyName === "events") {
            setData[index] = {
                ...setData[index],
                componentId: data.fromId,
                componentName: data.component,
                CName: data.CName
            }
        } else {
            setData[index][keyName] = data;
        }
        setState([...setData])
    }

    const deleteStateKey = (index) => {
        let setData = state;
        setData.splice(index, 1)
        setState([...state])
    }

    const handleModalClose = () => {
        setModal(false)
    }

    const saveData = () => {
        props.handleChange(state)
    }

    return <div>
        <Button variant="contained" color="primary" style={{ marginTop: 20 }} onClick={() => setModal(true)}>
            Handle Hide Show
        </Button>
        <Modal
            open={modal}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper className={classes.paper}>
                <div className={classes.closeIcon}>
                    <IconButton style={{ backgroundColor: '#fff', padding: 0 }} onClick={() => setModal(false)}>
                        <HighlightOffIcon size="small" />
                    </IconButton>
                </div>
                <div className={classes.root}>
                    <div>
                        {
                            state && state.map((item, index) => {
                                return <React.Fragment>
                                    <Paper elevation={4} style={{ padding: 15, marginBottom: 10 }}>
                                        <div>
                                            <HighlightOffIcon
                                                onClick={() => deleteStateKey(index)}
                                                style={{ float: "right", cursor: "pointer", marginBottom: 10 }}
                                            />
                                        </div>
                                        <Autocomplete
                                            size={"small"}
                                            value={item?.screenName ? { screenName: item?.screenName } : null}
                                            id="filter-demo"
                                            options={AllScreenList ?? []}
                                            onChange={(event, newValue) => handleListChange(newValue, index, "screens")}
                                            getOptionLabel={(option) => option?.screenName}
                                            renderInput={(params) => (
                                                <TextField {...params} label="select screen" variant="outlined" />
                                            )}
                                        />
                                        <Autocomplete
                                            size={"small"}
                                            value={item?.CName ? { CName: item?.CName } : null}
                                            id="filter-demo"
                                            style={{ marginTop: 15 }}
                                            options={props.AllScreenList[item?.screenIndex]?.events?.filter(item => item?.CName) ?? []}
                                            onChange={(event, newValue) => handleListChange(newValue, index, "events")}
                                            getOptionLabel={(option) => option?.CName}
                                            renderInput={(params) => (
                                                <TextField {...params} label="select event" variant="outlined" />
                                            )}
                                        />
                                        {item?.componentName === "Autocomplete" && <TextField
                                            id="outlined-helperText"
                                            label={"set key"}
                                            style={{ marginTop: 15 }}
                                            size={"small"}
                                            variant="outlined"
                                            value={item?.defaultKey}
                                            onChange={(event) => handleListChange(event?.target?.value, index, "defaultKey")}
                                            fullWidth
                                        />}
                                        {
                                            item?.componentName && <TextField
                                                id="outlined-helperText"
                                                label={"set value"}
                                                style={{ marginTop: 15 }}
                                                size={"small"}
                                                variant="outlined"
                                                value={item?.value}
                                                onChange={(event) => handleListChange(event?.target?.value, index, "value")}
                                                fullWidth
                                            />
                                        }
                                    </Paper>
                                </React.Fragment>
                            })
                        }
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <Button variant="contained" color="primary" onClick={addNewCondition}>
                            ADD NEW
                        </Button>
                        {state.length > 0 && <Button variant="contained" color="primary" onClick={saveData} style={{ marginLeft: 15 }}>
                            Save
                        </Button>}
                    </div>
                </div>
            </Paper>
        </Modal>
    </div>
}