import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CloseIcon from "@material-ui/icons/Close";
import { Entitymapping } from "./Entitymapping";
// import { DisplayLogic } from "./DisplayLogic";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tapcss: {
    minWidth: "fit-content",
    padding: "0px 10px",
    fontSize: 13,
    textTransform: "none",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  scroller: {
    backgroundColor: "#ebebeb",
    "& .MuiTabs-scroller": {
      overflowX: "auto !important",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "white",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "white",
    },
  },
  close: {
    position: "absolute",
    right: 0,
    zIndex: 9,
  },
}));

export function UIFlowentityMapping(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <IconButton
        color="primary"
        component="span"
        className={classes.close}
        onClick={() => props.onClose("right")}
      >
        <CloseIcon />
      </IconButton>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        className={`${classes.scroller} scroll-bar`}
      >
        <Tab
          label="Entity Mapping"
          {...a11yProps(0)}
          className={classes.tapcss}
        />
        {/* <Tab
          label="Display Logics"
          {...a11yProps(2)}
          className={classes.tapcss}
        /> */}
      </Tabs>

      <TabPanel value={value} index={0}>
        <Entitymapping {...props} />
      </TabPanel>
      {/* <TabPanel value={value} index={1}>
        <DisplayLogic {...props} />
      </TabPanel> */}
    </div>
  );
}
