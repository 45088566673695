import React from "react";
import { fade, makeStyles, Grid, Typography, Avatar, IconButton, ListItem, ListItemText, Menu, MenuItem, InputBase, Divider} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SearchIcon from '@material-ui/icons/Search';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import FlipToFrontIcon from '@material-ui/icons/FlipToFront';

const options = [
  'Delete',
  'Edit',
  // 'Tree View'
];


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    marginTop: 20,
    marginBottom: 16,
    borderRadius: 4,
    borderBottom: "1.3px solid rgba(0, 0, 0, 0.12)",
    border: "1.3px solid rgba(0, 0, 0, 0.12)",    
    backgroundColor: fade("#fff", 0.15),
    '&:hover': {
      backgroundColor: fade("#fff", 0.25),
    },
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  searchIcon: {
    padding: "0px 6px",
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    height: "0.9em",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%'
  },
  addButton: {
    border: "1px solid " + theme.palette.text.hint,
    borderRadius: "4px",
  },
  switchButton:{
    margin: 0,
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 0px"
  },
  MenuItem: {
    color: "black",
    cursor: "pointer",
    borderRadius: "4px",
    textTransform: "capitalize",
    padding: "4px 2px 4px 4px",
  },
  selected: { /* Increase the specificity */
    // color: "#3f51b5",
    backgroundColor: "#F6F6F8 !important",
  },
  dragIcon: {
    fontSize: "14px",
    marginRight: "4px"
  },
  previewImage: {
    width: "26px",
    marginRight: "6px",
    fontSize: "12px",
    height: "32px"
  }
}));

const initialState = {
  position: null,
  data: null
}
export function EventsList(props) {
  const classes = useStyles();


  const [anchorEl, setAnchorEl] = React.useState(initialState);
  const open = Boolean(anchorEl.position);

  const handleClick = (event, id) => {
    setAnchorEl({
      ...anchorEl,
      position: event.currentTarget,
      data: id
    });
  };

  const handleClose = () => {
    setAnchorEl(initialState);
  };

  const moreOptions = (e, type) => {
    switch (type) {
      case "Delete":
        props.deleteOldScreen(anchorEl.data);
        handleClose();
        break;
      case "Edit":
        props.openModal(anchorEl.position, "edit", anchorEl.data);
        handleClose();
        break;
      case "Tree View":
        props.treeView(2);
        handleClose();
        break;
      default:
        handleClose();
        break;
    }
  }


  return (
    <div className={classes.root}>
      {/* events list */}
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        style={{padding: "16px 16px 0px"}}
      >
        <Grid>
            <Typography style={{fontWeight:600}}>Events</Typography>
        </Grid>
      </Grid>

      {/* events screen list */}
      <Grid style={{padding: "0px 14px"}}>
          <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon fontSize={"small"} color={"action"} />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div>
        </Grid>
        {/* events screen list END */}
      <Grid container item>
            <ListItem>
            <ListItemText
                primary={<Typography variant="caption" style={{fontWeight:500}}>Decision Box</Typography>}
            />
                <IconButton
                size={"small"}
                aria-label="  overlay"
                >
                <FlipToFrontIcon fontSize={"small"} color={"action"} />
                </IconButton>
            </ListItem>
            <ListItem style={{paddingTop:0}}>
                <ListItemText>
                    <Divider/>
                </ListItemText>    
            </ListItem>
      </Grid>
      {/* events List items */}
      <Grid container item style={{justifyContent: "center",marginTop:4}} 
         id={"EventsList"}
      >
          {props?.menusList?.map((list, index) => {
            return(
                <ListItem style={{width: "240px"}}
                  id={list.id}
                  key={index} 
                  dense 
                  selected={false} 
                  classes={{ root: classes.MenuItem, selected: classes.selected }}
                >
                  <DragIndicatorIcon className={classes.dragIcon} />
                  <Avatar alt={list.name} className={classes.previewImage} variant="rounded" src={list?.image??"/static/images/avatar/1.jpg"} />
                  <ListItemText primary={list.name}/>
                  {false && 
                  <>
                    <IconButton
                      size={"small"}
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(e)=>handleClick(e, list.id)}
                    >
                      <MoreVertIcon fontSize={"small"} color={"action"} />
                    </IconButton>
                  </>
                  }
                </ListItem>
            )
          })}
      </Grid>
      {/* Events List items END */}
      {/* Events list end */}
      
      {/* More option menu list based on( Screen ) */}
      <Menu
        id="long-menu"
        anchorEl={anchorEl.position}
        keepMounted
        open={open}
        onClose={()=>handleClose()}
        // anchorOrigin={{
        //   vertical: 'bottom',
        //   horizontal: 'right',
        // }}
        transformOrigin={{
          vertical: "top",
          horizontal: 'left',
        }}
        
      >
        {options.map((option) => (
          <MenuItem dense key={option} selected={option === 'Pyxis'} onClick={(e)=>moreOptions(e, option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
      {/* More option menu list END */}
    </div>
  );
}
