import React, { Component } from "react";
import Axios from "axios";
// import ReactDOM from "react-dom";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Modal from "@material-ui/core/Modal";
import { Grid, withStyles, Typography } from "@material-ui/core";
import AppNotify from "../../App.notification_autosave";
import { DenseAppBar, MainAppBar, LoadingStepper } from "../../components";
import { UiFlowMapping } from "./components";
import withAlertAndDialog from "../../HOC/withAlertAndDialog";
// common <function></function>
import {
  makeid,
  decisionHandlerArrowLinkRemover,
} from "../../components/commonFunction";
import * as Icons from "@material-ui/icons";
import "./styles/styles.css";

// JSON Data
import {
  // AllScreenList,
  mainMenuList,
} from "./components/jsonDatas";
import { networkCall } from "../../networkcall";
import Config from "./../../config";
import { AlertContext } from "../../contexts";
import { alertProps } from "../../utils";
import { SidePallette, BubbleSetup } from "./components";
import { SelectElementsForm } from "./components/bubblesetup/forms";
import { getEventObj } from "./components/bubblesetup/utils";
import { build } from "../../apis";

const styles = (theme) => ({
  root: {
    backgroundColor: "#ebebeb",
  },
  gridArea: {
    display: "grid",
    gridGap: "1px",
    height: "calc(100vh - 92px)",
  },
  gridContainerFull: {
    gridTemplateAreas: `'mainArea properties'`,
    gridTemplateColumns: "1fr 220px",
    // padding: "10px",
  },
  gridContainerleft: {
    gridTemplateAreas: `'mainArea'`,
    gridTemplateColumns: "1fr",
  },
  gridContainerRight: {
    gridTemplateAreas: `'mainArea properties'`,
    gridTemplateColumns: "1fr 220px",
  },
  gridContainer: {
    gridTemplateAreas: `'mainArea'`,
    gridTemplateColumns: "1fr",
  },
  contentArea: {
    // padding: theme.spacing(2),
  },
  paperLeft: {
    // padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "calc(100vh - 49px)",
    // height: "100vh",
    backgroundColor: "#fff",
  },
  paperRight: {
    padding: theme.spacing(1),
    // margin: theme.spacing(1),
    border: "2px solid transparent",
    borderStyle: "dashed",
    overflow: "auto",
    height: "calc(100vh - 66px)",
  },
  menuButton: {
    padding: "20px 8px",
    fontSize: "10px",
    width: "100%",
    height: "68px",
    wordBreak: "break-word",
    textTransform: "capitalize",
    // backgroundColor: "#eeeeee",
    borderRadius: 0,
  },
  item1: {
    gridArea: "header",
    backgroundColor: "red",
  },
  item2: {
    gridArea: "menu",
    backgroundColor: "#fff",
  },
  item3: {
    gridArea: "mainArea",
    overflow: "auto",
    display: "flex",
  },
  item4: {
    gridArea: "properties",
    overflow: "auto",
    backgroundColor: "#fff",
    borderLeft: "1px solid #dadada",
  },
  item4Hiden: {
    display: "none",
  },
  item5: {
    gridArea: "menulist",
    backgroundColor: "#fff",
  },
  item5Hiden: {
    display: "none",
  },
  backcrop: {
    padding: "2.5rem",
    backgroundImage:
      "linear-gradient(to right, rgb(217, 226, 233) 1px, transparent 1px), linear-gradient(rgb(217, 226, 233) 1px, transparent 1px)",
    backgroundSize: "20px 20px",
    backgroundColor: "rgba(255, 255, 255, 0.36)",
    height: "100%",
    minWidth: "10rem",
    width: "100%",
    display: "block",
    alignItems: "center",
    overflow: "auto",
    position: "relative",
    flexDirection: "column",
  },
  menusList: {
    width: "100%",
    textAlign: "center",
    padding: "14px 6px",
    whiteSpace: "break-spaces",
    cursor: "pointer",
    color: theme.palette.text.disabled,
    borderLeft: "4px solid transparent",
    "&:hover": {
      backgroundColor: "rgba(0, 122, 255, 0.04)",
    },
  },
  activeMenusList: {
    width: "100%",
    textAlign: "center",
    padding: "14px 6px",
    whiteSpace: "break-spaces",
    cursor: "pointer",
    borderLeft: "4px solid " + theme.palette.primary.main,
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  menuText: {
    fontSize: "10px",
    wordBreak: "break-word",
  },
});

class UiFlow extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      projectName: "Tanya Care",
      allScreenList: [],
      screens: null,
      selectedScreen: null,
      screenElements: [],
      mainMenu: 0,
      rightMenu: false,
      DataMapping: false,
      leftMenu: false,
      selectedAllScreen: false,
      anchorEl: null,
      editScreen: "",
      uiType: "flow",
      screenId: "null",
      componentId: null,
      selectedComponent: null,
      screenIndex: null,
      screenName: null,
      rightMenuType: "",
      selectedAction: {},
      buildModal: false,
      dataParsingDetails: null,
    };
  }

  componentDidMount() {
    this.setState({ selectedScreen: this?.props?.auth.user.AllScreenList[0] });
  }

  changeProjectName = async (val) => {
    this.setState({
      ...this.state,
      loading: true,
      projectName: val,
    });
    let data = await networkCall(Config.api_url + `/get`, "POST", {
      name: val,
    });
    let AllScreenList = data?.data?.screens;
    if (this.state.selectedAllScreen) {
      let selecte_id = AllScreenList[0]?.id;
      let screen = AllScreenList.filter((val) => val.id === selecte_id);
      this.setState({
        ...this.state,
        loading: false,
        screens: screen,
        allScreenList: AllScreenList,
        selectedScreen: selecte_id,
      });
    } else {
      this.setState({
        ...this.state,
        loading: false,
        screens: AllScreenList,
        allScreenList: AllScreenList,
        selectedScreen: null,
      });
    }
  };

  // Add New Screen Function
  openModal = (event, val) => {
    if (val === "close") {
      this.setState({ ...this.state, anchorEl: null, editScreen: "" });
    } else if (val === "edit") {
      this.setState({ ...this.state, anchorEl: event, editScreen: val });
    } else {
      this.setState({ ...this.state, anchorEl: event.currentTarget });
    }
  };

  submitAddScreen = (e, newScreenObject, type) => {
    if (type === "edit") {
      this.state.allScreenList.filter((val) => {
        if (val.id === this.state.screens[0]?.id) {
          return (
            (val.name = newScreenObject.name),
            (val.description = newScreenObject.description),
            (val.isHome = newScreenObject.isHome),
            (val.isIdmEnabled = newScreenObject.isIdmEnabled),
            (val.isPrivate = newScreenObject.isPrivate),
            (val.path = newScreenObject.path)
          );
        }
        return false;
      });

      this.setState({
        ...this.state,
        anchorEl: null,
        allScreenList: this.state.allScreenList,
      });
    } else {
      let newScreen = {
        id: makeid(),
        pathId: makeid(),
        arrowLink: [],
        ...newScreenObject,
        render: {
          component: "div",
          child: [],
        },
      };

      this.state.allScreenList.push(newScreen);

      this.setState({
        ...this.state,
        anchorEl: null,
        allScreenList: this.state.allScreenList,
      });
    }
  };

  //   delect screen
  deleteOldScreen = (id) => {
    const handleOnOkModal = () => {
      const pathId = this.props.auth.user.AllScreenList.filter(
        (screen) => screen.id === id
      )[0]?.pathId;

      this.props.auth.user.AllScreenList.forEach((screen, index, arr) => {
        // deleting screen related ArrowLinks In Decision Handlers
        if (screen.type === "decisionHandler") {
          screen.arrowLink.forEach((link, index, arrowLinkArray) => {
            if (link.arrowTarget === pathId) {
              const arrowSourceId = link.id;
              const filteredArray = arrowLinkArray.filter(
                (link) => link.id === arrowSourceId
              );
              if (filteredArray.length > 2) {
                arrowLinkArray.splice(index, 1);
              } else {
                const newFilteredArray = arrowLinkArray.filter(
                  (link) => link.id !== arrowSourceId
                );
                screen.arrowLink = newFilteredArray;
                //removing the child component
                screen.render.child.forEach(function iter(val, index, array) {
                  if (val.component === "DecisionBox") {
                    if (val.id === arrowSourceId) {
                      array.splice(index, 1);
                    }
                  } else {
                    if (val?.child?.length > 0) {
                      val.child.forEach(iter);
                    }
                  }
                });
              }
            }
          });
          //deleting components related arrowlinks of the matched screen to delete
          screen.render?.child?.length > 0 &&
            screen.render.child.forEach(function iter(cmpnt) {
              decisionHandlerArrowLinkRemover(screen, cmpnt.id);
              cmpnt?.child?.length > 0 && cmpnt.child.forEach(iter);
            });
        }

        //delete the screen and the decision handler if it has
        if (screen.id === id) {
          const decisionBoxOrNot = arr[index + 1];
          if (decisionBoxOrNot?.type === "decisionHandler") {
            arr.splice(index, 2);
          } else {
            arr.splice(index, 1);
          }
        }
      });

      // deleting decision handler if it arrowlink is empty
      this.props.auth.user.AllScreenList.forEach((screen, index, array) => {
        if (
          screen.type === "decisionHandler" &&
          screen.arrowLink.length === 0
        ) {
          array.splice(index, 1);
        }
      });
      let deleted = this.props.auth.user.AllScreenList;
      let selecte_id = deleted[0]?.id ?? "";
      let screen =
        deleted.length > 0 && deleted.filter((val) => val.id === selecte_id);
      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: false,
        tone: false,
      });

      this.props.auth.setAuth({
        ...this.props.auth.user,
        // screens: screen ? screen : [],
        // selectedScreen: selecte_id,
        isEdited: true,
      });

      console.log(this.props.auth);
      // this.setState({
      //   ...this.state,
      //   allScreenList: deleted,
      //   screens: screen,
      //   selectedScreen: selecte_id,
      //   isEdited: true,
      // });
    };

    const handClose = () => {
      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: false,
        tone: false,
        title: "Delete Alert",
        body: "Do you need to delete this screen?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: () => {},
        onCancel: () => {},
      });
    };

    this.props.dialog.setDialog({
      ...this.props.dialog,
      open: true,
      tone: true,
      title: "Delete Alert",
      body: "Do you need to delete this screen?",
      positiveBtn: "Yes",
      negativeBtn: "No",
      onOk: handleOnOkModal,
      onCancel: handClose,
    });
  };

  getLayoutClass = (rightMenu, leftMenu) => {
    if (rightMenu && leftMenu) {
      return "gridContainerFull-uiflow";
    } else if (!rightMenu && !leftMenu) {
      return "gridContainer-uiflow";
    } else if (rightMenu && !leftMenu) {
      return "gridContainerRight-uiflow";
    } else if (!rightMenu && leftMenu) {
      return "gridContainerleft-uiflow";
    } else {
      return "gridContainer-uiflow";
    }
  };

  onClose = (type) => {
    if (type === "left") {
      this.setState({ ...this.state, leftMenu: !this.state.leftMenu });
    } else if (type === "right") {
      // remove selected component active css class
      if (document.querySelector(".selectedScreen")) {
        document
          .querySelector(".selectedScreen")
          .classList.remove("selectedScreen");
      }
      if (document.querySelector(".selectedComponent")) {
        document
          .querySelector(".selectedComponent")
          .classList.remove("selectedComponent");
      }
      this.setState({
        ...this.state,
        rightMenu: !this.state.rightMenu,
        DataMapping: false,
        rightMenuType: null,
      });
    }
  };
  updateState = (object) => {
    this.setState({ ...this.state, ...object });
  };

  treeView = (val) => {
    this.setState({ ...this.state, mainMenu: val });
  };

  updateNewChildComponent = (object, child_id, screen_id) => {
    this.state.allScreenList.filter((screen) => {
      if (screen.id === screen_id) {
        this.updateJson(screen.render.child, child_id, object);
        return true;
      } else {
        return false;
      }
    });
    this.setState({ ...this.state });
  };

  updateJson = (screen, child_id, object) => {
    if (screen) {
      this.updateChildJson(screen, child_id, object);
    } else {
      console.log("fasle parent");
      return false;
    }
  };

  updateChildJson = (screen, child_id, object) => {
    if (screen) {
      screen.filter((child, index) => {
        if (child.id === child_id) {
          if (child.child) {
            // console.log("child.child.push",JSON.stringify(child))
            child.child.push(object);
          } else {
            child["child"] = [object];
            // console.log('child["child"]',screen)
          }

          return true;
        } else {
          this.updateJson(child.child, child_id, object);
          return true;
        }
      });
      // return true
    } else {
      return false;
    }
  };

  getComponentId = (
    index,
    component,
    screenId,
    pathId,
    screenindexVal,
    screenName,
    eventId
  ) => {
    let componentId = component.id;
    this.setState({
      ...this.state,
      screenIndex: screenindexVal,
      componentId: componentId,
      componentDecisionId: component.componentDecisionId,
      screenId: pathId,
      screenName: screenName,
      componentAttr: component,
    });
    this.props.auth.setAuth({
      ...this.props.auth.user,
      componentId: componentId,
      componentDecisionId: component.componentDecisionId,
      componentAttr: component,
      eventId: eventId,
      isEdited: false,
      screenIndex: screenindexVal,
    });
  };

  updatePropsData = (obj) => {
    if (obj) {
      this.setState({
        ...this.state,
        ...obj,
      });
    } else {
      this.setState({
        ...this.state,
      });
    }
  };

  rightmenuUpdate = (type, data, screenData) => {
    if (type === "dataMapping") {
      this.setState({
        ...this.state,
        rightMenu: true,
        DataMapping: true,
        dataParsingDetails: { arrowData: data, screenData: screenData },
        rightMenuType: "dataMapping",
      });
    }
  };

  saveForm = async () => {
    this.props.auth
      .save(true)
      .then((response) => {
        console.log(response);
        this.props.alert.setSnack({
          ...this.props.alert,
          open: true,
          severity: alertProps.severity.success,
          msg: "UI saved successfully",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.center,
          tone: true,
        });
      })
      .catch((err) => {
        this.props.alert.setSnack({
          ...this.props.alert,
          open: true,
          severity: alertProps.severity.error,
          msg: "Network Error",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.center,
        });
      });
  };

  // build = () => {
  //   this.setState({
  //     buildModal: true,
  //   });
  // };

  handleClose = (val) => {
    this.setState({
      [val]: false,
    });
  };

  setRightMenu = (status, menuType, data) => {
    debugger;
    var activeCard = document.querySelector(".display-card.active");
    if (activeCard) {
      activeCard.classList.remove("active");
    }
    this.setState({
      rightMenu: status,
      rightMenuType: menuType,
      selectedAction: data ? data : null,
    });
  };

  getFormResult = (res, resType) => {
    if (resType === "selectElements") {
      let { element, event, screen, eventIndex, screenIndex } = res;
      let { totalEventsItems } = this.props.auth.user;
      let allScreenList = this.props.auth.user.AllScreenList;
      let currentScreen = [];

      if (screenIndex > -1) {
        currentScreen = allScreenList[screenIndex];
      } else {
        currentScreen = allScreenList.filter((item) => {
          return item.id === screen.id;
        })?.[0];
      }
      if (eventIndex > -1) {
        let currEvent = null;
        currEvent = currentScreen.events.filter(
          (item) => item.eventIndex === eventIndex
        )?.[0];
        currEvent.name = event.label;
      } else if (currentScreen?.events) {
        currentScreen.events.push(
          getEventObj(event, element, totalEventsItems)
        );
      } else {
        currentScreen["events"] = [];
        currentScreen.events.push(
          getEventObj(event, element, totalEventsItems)
        );
      }
      this.props.auth.setAuth({
        AllScreenList: allScreenList,
      });
      this.onClose("right", false);
    }
  };

  render() {
    // const open = Boolean(this.state.anchorEl);
    // const id = open ? "simple-popover" : undefined;
    const { classes } = this.props;
    const { rightMenu, leftMenu, dataParsingDetails } = this.state;
    return (
      this.props.auth.user?.AllScreenList &&
      Array.isArray(this.props.auth.user?.AllScreenList) && (
        <div className={classes.root}>
          <Modal
            closeAfterTransition
            open={this.state.buildModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            disableBackdropClick
          >
            {
              <LoadingStepper
                handleClose={() => this.handleClose("buildModal")}
              />
            }
          </Modal>
          {/* Top App Bar */}
          {/* <DenseAppBar title={"QDM"} onClose={this.onClose} {...this.state} /> */}
          {/* Title Heaser bar */}
          <AppNotify>
            <MainAppBar
              build={build}
              saveForm={this.saveForm}
              title={localStorage.projectName}
              changeProjectName={this.changeProjectName}
              onClose={this.onClose}
              screens={this.state.screens}
              {...this.state}
              rightMenu={false}
            />
          </AppNotify>
          {/* Top App Bar end */}

          {/* Main layout area */}
          <div
            className={`${classes.gridArea} ${this.getLayoutClass(
              rightMenu,
              leftMenu
            )}`}
          >
            {/* Menu Component Left menu list  */}
            <div
              className={
                this.state.leftMenu ? classes.item5 : classes.item5Hiden
              }
            >
              {this.state.leftMenu &&
                this.getMenuListComponents(this.state.mainMenu)}
            </div>
            {/* Menu Component Left menu list END */}

            {/* UI Builder Area */}
            <div className={`${classes.item3} scroll-bar`}>
              {this.props?.auth?.user?.AllScreenList.length > 0 && (
                <BubbleSetup
                  setRightMenu={this.setRightMenu}
                  getComponentId={this.getComponentId}
                />
              )}
            </div>
            {/* UI Builder Area END */}

            {/* Right Side Menu ( Properties, Relationship, Entities ) */}
            <div
              className={
                this.state.rightMenu ? classes.item4 : classes.item4Hiden
              }
            >
              {this.state.rightMenu &&
                this.state.rightMenuType === "selectElements" && (
                  <SidePallette title="Select Elements">
                    <SelectElementsForm
                      selectedScreen={this.state.selectedScreen}
                      screenElements={this.state?.screenElements}
                      getFormResult={(res) =>
                        this.getFormResult(res, "selectElements")
                      }
                      auth={this.props.auth}
                      selectedAction={this.state.selectedAction}
                    />
                  </SidePallette>
                )}
              {this.state.rightMenu &&
                this.state.rightMenuType === "componentMapping" && (
                  <SidePallette title="">
                    {this?.props?.auth?.user?.AllScreenList?.length > 0 && (
                      <UiFlowMapping
                        index={this.state.screenIndex ?? 0}
                        id={this.state.screenId}
                        screenName={this.state.screenName}
                        onClose={this.onClose}
                        data={this.state}
                      />
                    )}
                  </SidePallette>
                )}
            </div>
            {/* Right Side Menu END */}
          </div>
          {/* Main layout area END */}
        </div>
      )
    );
  }
}
UiFlow.contextType = AlertContext;

export default withAlertAndDialog(withStyles(styles)(UiFlow));
