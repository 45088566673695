import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, Typography, Button, Grid } from "@material-ui/core";
import { getScreensAndComponents } from "./commonFunction";
import Config from "../config";
import { AuthContext } from "../contexts";

import CachedIcon from "@material-ui/icons/Cached";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import PauseIcon from "@material-ui/icons/Pause";
import clsx from "clsx";
import Axios from "axios";
// import Config from "../config";

import "../index.css";
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    padding: 24,
    borderRadius: "8px",
  },
  center: {
    display: "flex",
    alignItems: "center",
    padding: 14,
    fontWeight: 500,
  },
  icon: {
    fontSize: 18,
  },
  marginBottom: {
    marginBottom: "2%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export function LoadingStepper(props) {
  const classes = useStyles();
  const [loading1, setloadeing1] = useState(false);
  const [loading2, setloadeing2] = useState(false);
  const [loading3, setloadeing3] = useState(false);
  const [loading4, setloadeing4] = useState(false);
  // const [loading5, setloadeing5] = useState(false);
  const auth = useContext(AuthContext);

  const fun1 = () => {
    setTimeout(() => setloadeing1(true), 30000);
    setTimeout(() => setloadeing2("yes"), 30000);

    setTimeout(() => setloadeing2(true), 60000);
    setTimeout(() => setloadeing3("yes"), 60000);

    setTimeout(() => setloadeing3(true), 120000);
    setTimeout(() => setloadeing4("yes"), 120000);

    setTimeout(() => {
      window.open(
        "http://164.52.212.67:3000/",
        "_blank",
        "noopener,noreferrer"
      );
      setloadeing4(true);
      // window.location.href = "http://164.52.212.67:3000/";
    }, 160000);
  };

  const buildProject = async () => {
    let dataList = {
      metadataId: localStorage.getItem("metaDataId"),
      UidesignerModelerid: localStorage.getItem("UidesignerModelerid"),
      projectname: localStorage.getItem("projectName"),
    };
    let data = JSON.stringify(dataList);
    var config = {
      method: "post",
      // url: "http://164.52.212.67:7066/clone",
      // url: "http://localhost:8080/clone",
      url: `${Config.node_backend}/clone`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    await Axios;
    Axios(config)
      .then((response) => {
        console.log("build", response);
        // setloadeing5(true);
      })
      .catch((err) => {
        console.log("build", err);
        // setloadeing5(true);
      });
  };

  const sendProjectScreensAndComponents = () => {
    const dataList = getScreensAndComponents(auth.user.AllScreenList);
    let data = JSON.stringify([dataList]);
    var config = {
      method: "post",
      url: Config.api_url + "/upsert_document",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    Axios(config)
      .then((response) => {
        console.log("Auto Save Response" + response);
        return response;
      })
      .catch((err) => {
        console.log("Auto Save Error" + err);
        return err;
      });
  };

  useEffect(() => {
    buildProject();
    sendProjectScreensAndComponents();
    fun1();
  }, []);

  return (
    <div className={classes.paper}>
      <Card elevation={2} className={classes.marginBottom}>
        <Typography
          variant="subtitle1"
          component="subtitle1"
          className={classes.center}
          style={
            loading1
              ? { color: "green", borderLeft: "4px solid green" }
              : { color: "gray", borderLeft: "4px solid gray" }
          }
        >
          {loading1 ? (
            <CheckCircleOutlineIcon className={classes.icon} />
          ) : (
            <CachedIcon className={clsx(classes.icon, "loading")} />
          )}{" "}
          &nbsp;Generating code
        </Typography>
      </Card>
      <Card elevation={2} className={classes.marginBottom}>
        <Typography
          variant="subtitle1"
          component="subtitle1"
          className={classes.center}
          style={
            loading2 === true
              ? { color: "green", borderLeft: "4px solid green" }
              : { color: "gray", borderLeft: "4px solid gray" }
          }
        >
          {loading2 === true ? (
            <CheckCircleOutlineIcon className={classes.icon} />
          ) : loading2 === "yes" ? (
            <CachedIcon className={clsx(classes.icon, "loading")} />
          ) : (
            <PauseIcon className={clsx(classes.icon)} />
          )}{" "}
          &nbsp;Building
        </Typography>
      </Card>
      <Card elevation={2} className={classes.marginBottom}>
        <Typography
          variant="subtitle1"
          component="subtitle1"
          className={classes.center}
          style={
            loading3 === true
              ? { color: "green", borderLeft: "4px solid green" }
              : { color: "gray", borderLeft: "4px solid gray" }
          }
        >
          {loading3 === true ? (
            <CheckCircleOutlineIcon className={classes.icon} />
          ) : loading3 === "yes" ? (
            <CachedIcon className={clsx(classes.icon, "loading")} />
          ) : (
            <PauseIcon className={clsx(classes.icon)} />
          )}{" "}
          &nbsp;Committing code
        </Typography>
      </Card>
      <Card elevation={2}>
        <Typography
          variant="subtitle1"
          component="subtitle1"
          className={classes.center}
          style={
            loading4 === true
              ? { color: "green", borderLeft: "4px solid green" }
              : { color: "gray", borderLeft: "4px solid gray" }
          }
        >
          {loading4 === true ? (
            <CheckCircleOutlineIcon className={classes.icon} />
          ) : loading4 === "yes" ? (
            <CachedIcon className={clsx(classes.icon, "loading")} />
          ) : (
            <PauseIcon className={clsx(classes.icon)} />
          )}{" "}
          &nbsp;Deploying
        </Typography>
      </Card>
      <Grid container item justify={"flex-end"}>
        <Button
          size={"small"}
          style={{ marginTop: "20px" }}
          variant={"outlined"}
          color={"secondary"}
          onClick={() => props.handleClose()}
        >
          Cancel
        </Button>
      </Grid>
    </div>
  );
}
