import {
  Button,
  Divider,
  Drawer,
  Grid,
  Input,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { ReactTransliterate } from "react-transliterate";
import { v4 as uuidv4 } from "uuid";
import { useArangoQuery, arangoQueryFn } from "../../../apis/hooks";
import config from "../../../config";
import { AlertContext, AuthContext } from "../../../contexts";
import { useDebounce } from "../../../hooks";
import { QueryClient } from "@tanstack/react-query";
import withAlertAndDialog from "../../../HOC/withAlertAndDialog";
import { useRef } from "react";
import { createDocument, updateDocument } from "../../../apis/crud";

const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: 450,
    padding: "20px 8px",
    overflowY: "inherit",
  },
  dialogContent: {
    display: "Grid",
    gridGap: "16px",
    overflowY: "inherit",
    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
  },
  dialogActions: {
    marginTop: "15px",
  },
  TitleInput: {
    margin: "12px 24px",
  },
  drawerContainer: {
    padding: "18px",
    width: "560px",
  },
  drawerContent: {
    paddingTop: "16px",
  },
  mctitle: {
    "& .MuiFormControl-marginNormal": {
      margin: "0px",
    },
  },
  buttonforcheck: {
    "& .MuiButton-outlinedSizeSmall": {
      fontSize: "0.5rem",
    },
    "& .MuiButton-root": {
      width: "40px",
    },
  },
  autocompelete: {
    "& label": {
      fontSize: 12,
      lineHeight: "normal",
      fontWeight: 300,
    },
    "& input": {
      fontSize: 9,
      fontWeight: 600,
    },
    "& fieldset": {
      border: "1px solid #ebebeb",
    },
    "& .ui-builder-MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'][class*='MuiOutlinedInput-marginDense']":
      {
        padding: "6px 6px 9px 6px",
      },
  },
}));

const MC = (props) => {
  const classes = useStyles(props);
  const { value, data, label, onChange } = props;
  const [messageCatalogs, setMessageCatalogs] = useState([]);
  const [messageCatalogOptions, setMessageCatalogOptions] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const [messageCatalogDialog, setMessageCatalogDialog] = useState(false);
  const [messageCatalog, setMessageCatalog] = useState({});
  const alert = useContext(AlertContext);

  const [titleSearch, setTitleSearch] = useState(null);
  const [titleSearchLoading, setTitleSearchLoading] = useState(false);
  const autofillMCRef = useRef(null);
  const lazyTitleSearch = useDebounce(titleSearch, 500);
  const queryClient = new QueryClient();

  const {
    loading: languagesLoading,
    error: languagesError,
    data: languages,
  } = useArangoQuery(
    ["languages"],
    {
      entity: "projectvstools",
      filter: `projectvstools.metadataid=='${localStorage.getItem(
        "metaDataId"
      )}'`,
      return_fields:
        "merge(keep(projectvstools,'projectid','€€€','clientid'),{projectlang:document(document(projectvstools.projectid).projectlang)})",
    },
    {
      staleTime: Infinity,
    }
  );

  const {
    loading: messageCatalogsLoading,
    error: messageCatalogsError,
    data: messageCatalogsFromDb,
  } = useArangoQuery(["messageCatalogs"], {
    entity: `message_catalog`,
    filter: `message_catalog.projectid any == '${localStorage.projectId}' AND message_catalog.activestatus == true`,
    return_fields: "message_catalog",
  });

  const filter = createFilterOptions();
  const auth = useContext(AuthContext);

  const handleClose = () => {
    setMessageCatalog({});
    setMessageCatalogDialog(false);
    setTitleSearch(null);
    autofillMCRef.current = null;
  };

  const messageCatalogFiller = (languageData) => {
    let result;
    if (Object.keys(messageCatalog).length === 0) {
      result =
        allLanguages.length > 0 &&
        allLanguages.reduce((obj, ln) => {
          const data = {
            ...obj,
            title: "",
            [ln.language]: "",
          };
          return data;
        }, {});
    }
    let messageCatalogCopy = JSON.parse(
      JSON.stringify(
        Object.keys(messageCatalog).length > 0 ? messageCatalog : result
      )
    );
    Object.keys(languageData).map((lngKey) => {
      if (
        Object.keys(messageCatalogCopy).some(
          (key) => key === lngKey || lngKey === "title"
        )
      ) {
        messageCatalogCopy[lngKey] = languageData[lngKey];
      }
    });
    setMessageCatalog({
      ...messageCatalogCopy,
    });
  };

  const getMessageCatalogOptions = async (searchText) => {
    setTitleSearchLoading(true);
    const data = await queryClient.fetchQuery(
      [searchText],
      () =>
        arangoQueryFn({
          entity: "message_catalog",
          filter: `LIKE(message_catalog.title,'%${searchText}%',true) AND message_catalog.private == false AND message_catalog.activestatus == true`,
          return_fields: "message_catalog",
        }),
      {
        staleTime: Infinity,
      }
    );
    setMessageCatalogOptions(data?.data?.result);
    setTitleSearchLoading(false);
    return data;
  };

  const handleClickToAddMC = async (value) => {
    try {
      // Check for similar title in db if it has add it to the messagecatalog
      const data = await getMessageCatalogOptions(value.value);
      let mc = data?.data?.result[0];
      if (
        mc &&
        mc.title.trim().toLowerCase() === value.value.trim().toLowerCase()
      ) {
        messageCatalogFiller({
          ...mc.language,
          title: value.value,
        });
        autofillMCRef.current = mc;
      } else {
        // if not add the title and english
        messageCatalogFiller({
          title: value.value,
          English: value.value,
        });
      }
      setMessageCatalogDialog(true);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMessageCatalogSubmit = async () => {
    if (Object.values(messageCatalog).some((value) => !value)) {
      return props.alert.setSnack({
        open: true,
        msg: "Please Fill all the data",
        severity: "warning",
      });
    }
    let { title, ...rest } = messageCatalog;
    if (
      messageCatalogs.some(
        (mc) => mc.title.toLowerCase() === title.toLowerCase()
      )
    ) {
      return props.alert.setSnack({
        open: true,
        msg: `${title} is already added, please check`,
        severity: "info",
      });
    }
    let attribute = {};
    if (autofillMCRef?.current?._id) {
      attribute = {
        ...autofillMCRef.current,
        projectid: [...autofillMCRef.current.projectid, localStorage.projectId],
        language: {
          ...autofillMCRef.current.language,
          ...rest,
        },
      };
      try {
        // Check for similar title in db if it has add it to the messagecatalog
        await updateDocument([
          {
            entity: "message_catalog",
            body: attribute,
            filter: {
              id: attribute.id,
            },
          },
        ]);
        let messageDetails = [...messageCatalogs, attribute];
        setMessageCatalogs(messageDetails);
        onChange(title);
        handleClose();
        props.alert.setSnack({
          open: true,
          msg: "Message catalog added",
          severity: "success",
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      attribute = {
        projectid: [localStorage.projectId],
        id: uuidv4(),
        language: {
          ...rest,
        },
        private: false,
        title: title,
      };
      try {
        // Check for similar title in db if it has add it to the messagecatalog
        await createDocument([
          {
            entity: "message_catalog",
            body: attribute,
          },
        ]);
        let messageDetails = [...messageCatalogs, attribute];
        setMessageCatalogs(messageDetails);
        onChange(title);
        handleClose();
        props.alert.setSnack({
          open: true,
          msg: "Message catalog added",
          severity: "success",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleapiforlanguage = async (v, text) => {
    const params = new URLSearchParams();
    params.append("q", text);
    params.append("source", "en");
    params.append("target", v.code.trim() === "" ? "en" : v.code);
    params.append("api_key", "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx");
    let result = await axios
      .post("https://libretranslate.de/translate", params, {
        headers: {
          accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => {
        debugger;
        return res.data.translatedText;
      })
      .catch(() => {
        return "not supported";
      });
    return result;
  };

  const handlecheck = async (language) => {
    props.backDrop.setBackDrop({
      open: true,
      message: "loading",
    });
    var text = messageCatalog.title;
    let result = await handleapiforlanguage(language, text);
    if (result === "not supported") {
      props.alert.setSnack({
        open: true,
        msg: "Auto translation is not supported for this language, please add manually",
        severity: "warning",
      });
      setTimeout(() => {
        props.alert.onclose();
      }, 5000);
    } else {
      setMessageCatalog({
        ...messageCatalog,
        [language.language]: result,
      });
    }
    props.backDrop.setBackDrop({
      open: false,
      message: "loading",
    });
  };

  useEffect(() => {
    if (!languagesLoading && languages) {
      let languageList = languages[languages.length - 1]?.projectlang;
      if (languageList) {
        let result = languageList.reduce((lngs, lng) => {
          let data = {
            language: lng.description,
            code: lng?.code,
          };
          return [...lngs, data];
        }, []);
        setAllLanguages(result);
      }
    }
  }, [languagesLoading, languages]);

  useEffect(() => {
    if (!messageCatalogsLoading && messageCatalogsFromDb) {
      setMessageCatalogs(messageCatalogsFromDb);
    }
  }, [messageCatalogsLoading, messageCatalogsFromDb]);

  useEffect(() => {
    if (lazyTitleSearch && lazyTitleSearch.length > 1) {
      getMessageCatalogOptions(lazyTitleSearch);
    } else {
      setMessageCatalogOptions([]);
    }
  }, [lazyTitleSearch]);

  useEffect(() => {
    if (messageCatalog?.title === null && messageCatalogOptions.length > 0) {
      setMessageCatalogOptions([]);
    }
  }, [messageCatalog]);

  console.log(messageCatalog, "messageCatalog");
  return (
    <>
      <Autocomplete
        value={{ title: value ?? "" }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        id="combo-box-demo"
        options={messageCatalogs}
        className={classes.autocompelete}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(option) => {
          if (option?.id === "message catalog") {
            return (
              <Typography
                style={{
                  fontSize: "16px",
                }}
              >
                {option.title}
              </Typography>
            );
          } else {
            return (
              <React.Fragment>
                <Grid item>
                  <Typography
                    style={{
                      fontSize: "16px",
                    }}
                    variant={"body2"}
                    color={"textPrimary"}
                  >
                    {option.title}
                  </Typography>
                  {Object.keys(option.language).map((key) => {
                    if (key === "Title" || key === "id") {
                      return false;
                    } else {
                      return (
                        <Typography
                          variant={"subtitle2"}
                          color={"textSecondary"}
                        >
                          {key + " : " + option.language[key]}
                        </Typography>
                      );
                    }
                  })}
                </Grid>
              </React.Fragment>
            );
          }
        }}
        renderInput={(params) => (
          <TextField
            size={"small"}
            margin={"dense"}
            {...params}
            label={label}
            variant="outlined"
          />
        )}
        onChange={(e, v) => {
          if (v?.id === "message catalog" && allLanguages.length > 0) {
            handleClickToAddMC(v);
          } else {
            onChange(v?.title ?? v);
          }
        }}
        filterOptions={(options, params) => {
          let updatedParams = { ...params };
          let inputValue = data["properties"][label] ?? params.inputValue;
          updatedParams = {
            ...updatedParams,
            inputValue,
          };
          const filtered = filter(options, updatedParams);
          if (
            updatedParams.inputValue !== "" &&
            allLanguages?.length > 0 &&
            !options.some(
              (option) =>
                option?.title.toLowerCase() ===
                (params.inputValue !== ""
                  ? params.inputValue.toLowerCase()
                  : data["properties"][label]?.toLowerCase() ?? "")
            )
          ) {
            filtered.push({
              id: "message catalog",
              value:
                params.inputValue !== ""
                  ? params.inputValue
                  : data["properties"][label] ?? "",
              title: `Click to add ${
                params.inputValue !== ""
                  ? params.inputValue
                  : data["properties"][label] ?? ""
              }`,
            });
          }
          return filtered;
        }}
      />
      <Drawer open={messageCatalogDialog} onClose={handleClose} anchor="right">
        <div className={classes.drawerContainer}>
          <Typography
            style={{ fontSize: "18px", fontWeight: "500", padding: "10px 0px" }}
          >
            Add Message Catalog
          </Typography>
          <Divider></Divider>
          {Object.keys(messageCatalog).map((item, i) => (
            <div className={classes.drawerContent}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={9}>
                  {item === "title" ? (
                    <Autocomplete
                      size="small"
                      loading={titleSearchLoading}
                      value={messageCatalog?.title}
                      onChange={(event, newValue) => {
                        if (!newValue) {
                          if (messageCatalogOptions.length > 0) {
                            setMessageCatalogOptions([]);
                          }
                          setMessageCatalog({
                            ...messageCatalog,
                            [item]: newValue,
                          });
                          autofillMCRef.current = null;
                          return;
                        }
                        if (typeof newValue === "string") {
                          setMessageCatalog({
                            ...messageCatalog,
                            [item]: newValue,
                          });
                          setTitleSearch(null);
                          autofillMCRef.current = null;
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          setMessageCatalog({
                            ...messageCatalog,
                            [item]: newValue.inputValue,
                            English: newValue.inputValue,
                          });
                          setTitleSearch(null);
                          autofillMCRef.current = null;
                        } else {
                          if (newValue?._id) {
                            messageCatalogFiller({
                              ...newValue.language,
                              title: newValue.title,
                            });
                            setTitleSearch(null);
                            autofillMCRef.current = newValue;
                          } else {
                            setMessageCatalog({
                              ...messageCatalog,
                              [item]: newValue,
                            });
                            autofillMCRef.current = null;
                          }
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);
                        // Suggest the creation of a new value
                        if (!titleSearchLoading && params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            title: `Add ${params.inputValue}`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={messageCatalogOptions}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.title;
                      }}
                      renderOption={(option) => option.title}
                      // style={{ width: 300 }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => {
                            setTitleSearch(e.target.value);
                          }}
                        />
                      )}
                    />
                  ) : (
                    <ReactTransliterate
                      value={messageCatalog[item] ?? ""}
                      onChangeText={(text) => {
                        setMessageCatalog({
                          ...messageCatalog,
                          [item]: text,
                        });
                      }}
                      lang={
                        allLanguages.find((lng) => lng.language === item)
                          ?.code ?? "en"
                      }
                      renderComponent={(props) => {
                        const inputRef = props.ref;
                        delete props["ref"];
                        return (
                          <Input
                            {...props}
                            fullWidth={true}
                            inputRef={inputRef}
                            placeholder={"Add your message"}
                            endAdornment={
                              item !== "English" && (
                                <Button
                                  color="primary"
                                  variant="outlined"
                                  size="small"
                                  style={{
                                    fontSize: "10px",
                                    margin: "10px 0px",
                                  }}
                                  onClick={() => {
                                    if (!messageCatalog.title) return;
                                    let language = allLanguages.find(
                                      (lng) => lng.language === item
                                    );
                                    handlecheck(language);
                                  }}
                                >
                                  Translate
                                </Button>
                              )
                            }
                          />
                        );
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
          ))}
          <div
            style={{
              width: "100%",
              marginTop: "32px",
              textAlign: "end",
            }}
          >
            <Button
              variant="contained"
              onClick={handleClose}
              size="small"
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleMessageCatalogSubmit}
            >
              Add
            </Button>
          </div>
        </div>
      </Drawer>
    </>
  );
};

export const MessageCatalog = withAlertAndDialog(MC);

MessageCatalog.defaultProps = {
  value: "",
  label: "",
  onChange: () => {},
  data: {}, //Component
};
