import React from "react";
import AppBar from "@material-ui/core/AppBar";
import {
  Toolbar,
  Button,
  Switch,
  Typography,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Tabs,
  Tab,
  Divider,
  Grid,
} from "@material-ui/core";
import { alertProps } from "../utils";
import { makeStyles } from "@material-ui/core/styles";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { AuthContext, NotificationStatus, AlertContext } from "../contexts";
import routes from "./../router/routes";
import { useHistory } from "react-router-dom";
import { Avatar, Popover, MenuList, MenuItem } from "@material-ui/core";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";
import { build, createDocument, updateDocument } from "../apis";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import RedoRoundedIcon from "@material-ui/icons/RedoRounded";
import { getDefaultProfileImg, localStorageKeys } from "../utils";
import config from "../config";
import TopNavBar from "./header";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& .MuiToolbar-dense": {
      minHeight: "10px",
    },
    "& .MuiTab-root": {
      minWidth: "30px",
      minHeight: "18px",
    },
    "& .MuiTabs-scroller": {
      overFlow: "scroll",
    },
    "& .MuiTabs-root": {
      minHeight: "8px",
      padding: "0px",
    },
    "& .MuiAppBar-root": {
      padding: "7px",
      borderBottom: "2px solid #ebebeb",
    },
  },
  userMenu: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
  },
  profilePic: {
    width: "18px",
    height: "18px",
    borderRadius: "8px",
  },
  profileName: {
    margin: "auto",
    fontSize: "10px",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0px 0px 0px 8px",
    fontWeight: "bold ",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
    paddingLeft: "550px",
  },
  layoutLeftIcon: {
    width: "18px",
    height: "14px",
    border: "2px solid",
    borderRadius: "2px",
    borderLeftWidth: "6px",
  },
  layoutRightIcon: {
    width: "18px",
    height: "14px",
    border: "2px solid",
    borderRadius: "2px",
    borderRightWidth: "6px",
  },
  sizeRiplle: {
    padding: "3px",
    borderRadius: 4,
    border: "1px solid " + theme.palette.text.disabled,
    color: theme.palette.text.disabled,
    display: "flex",
    cursor: "pointer",
  },
  sizeRiplleActive: {
    padding: "5px",
    borderRadius: 4,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    display: "flex",
    cursor: "pointer",
  },
  active: {
    backgroundColor: "#3f51b542",
  },
  menu: {
    width: "56px",
    display: "flex",
    borderRight: "1px solid " + theme.palette.text.disabled,
    "& svg": {
      cursor: "pointer",
    },
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: "#fff !important",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  selecteTab: {
    padding: "3px 12px",
    border: "0px",
    borderRadius: "50px !important",
    minWidth: "144px",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  Tabs: {
    "& .PrivateTabIndicator-colorPrimary-43": {
      backgroundColor: "#808080",
      width: "50px",
      "& .PrivateTabIndicator-colorPrimary-42 .MuiTabs-indicator": {
        //left: "50%",
        width: "50px",
      },
    },
    "& .MuiTabs-flexContainer": {
      height: "38px",
    },
    //"& .PrivateTabIndicator-root-40
  },
  tabButton: {
    border: "1px solid #ebebeb",
    borderRadius: "25px",
    padding: "4px",
  },
  titleName: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important",
    },
  },
  margin: {
    marginRight: theme.spacing(1),
  },
  Tabs: {
    "& .MuiTab-root": {
      minWidth: "30px",
      minHeight: "18px",
    },
    "& .MuiTabs-root": {
      minHeight: "8px",
    },
  },
}));

const getPercentage = {
  0.1: "10%",
  0.2: "20%",
  0.3: "30%",
  0.4: "40%",
  0.5: "50%",
  0.6: "60%",
  0.7: "70%",
  0.8: "80%",
  0.9: "90%",
  1: "100%",
  1.1: "110%",
  1.2: "120%",
};

export function MainAppBar(props) {
  const auth = React.useContext(AuthContext);
  const [state, setState] = React.useState({
    anchorEl: null,
    layout: 1,
    fullScreen: false,
    codePanel: {},
  });
  const classes = useStyles();
  const history = useHistory();
  const autoSave = React.useContext(NotificationStatus);
  const alert = React.useContext(AlertContext);
  const open = Boolean(state.anchorEl);
  const id = open ? "simple-popover" : undefined;

  React.useEffect(() => {
    setState({ layout: props.layoutType });
  }, []);

  const logOut = () => {
    localStorage.clear();
    window.location.replace(config.qdm_admin_url);
  };

  const handleClickAnchorEl = (event) => {
    setState({
      ...state,
      anchorEl: event.currentTarget,
    });
  };

  const handleClose = () => {
    setState({
      ...state,
      anchorEl: null,
    });
  };

  const fullScreenMode = (type) => {
    var elem = document.getElementById("fullViewMode");
    var mainLayout = document.getElementById("mainLayer");
    function openFullscreen() {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
    // close fullScreen
    function closeFullscreen() {
      if (document && document?.exitFullscreen) {
        document.exitFullscreen();
      } else if (document && document?.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document && document?.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
    if (type === "exit") {
      closeFullscreen();
      mainLayout.style.height = "calc(100vh - 92px)";
    } else {
      openFullscreen();
      mainLayout.style.height = "calc(100vh - 49px)";
    }
    setState({
      ...state,
      fullScreen: !state.fullScreen,
    });
  };

  const onChangeSwitch = async (e, val) => {
    debugger;
    function getScreen() {
      let result = auth.user.AllScreenList.find(
        (screen) => screen.type === "Screen"
      );
      return result ? [result] : [];
    }

    function getScreenId() {
      let result = auth.user.AllScreenList.find(
        (screen) => screen.type === "Screen"
      );
      return result ? result.id : null;
    }

    function getCurrentScreen() {
      const screen = auth.user.AllScreenList.filter(
        (screen) => screen.id === auth.user.selectedScreen
      );
      let result =
        auth.user.AllScreenList.length === 0
          ? []
          : screen.length === 1
          ? screen
          : getScreen();
      return result;
    }

    function getCurrentScreenId() {
      const screen = auth.user.AllScreenList.filter(
        (screen) => screen.id === auth.user.selectedScreen
      );
      let result =
        auth.user.AllScreenList.length === 0
          ? null
          : screen.length === 1
          ? screen[0]?.id
          : getScreenId();
      return result;
    }

    if (val === "builder") {
      localStorage.setItem("currentScreen", "builder");
      history.goBack();
      console.log(auth.user.AllScreenList, "ui builder");
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        screens: getCurrentScreen(),
        selectedScreen: getCurrentScreenId(),
      });
    } else {
      localStorage.setItem("currentScreen", "uiflow");
      if (auth.user?.isSaved === false) {
        await auth.save();
        auth.setAuth({
          ...auth.user,
          isSaved: true,
        });
      }
      history.goBack();
    }
  };

  const handleChangeArrow = (type, val) => {
    auth.setAuth({
      ...auth.user,
      [type]: val,
    });
  };

  const handleSaveScreen = async (type, e) => {
    if ((e.keyCode === 83 && (e.ctrlKey || e.metaKey)) || type === "button") {
      if (auth.user?.user?.name !== auth.user?.screens[0]?.isLockedBy) {
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.warning,
          msg: "The screen is currently locked",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
      } else {
        debugger;
        // console.log(auth.user, "user", selectedScreen, "selectedScreen");
        console.log("called this func", auth.user);
        let currentScreen = auth.user?.screens[0];
        let screenSchema = {
          ...currentScreen,
          version: 1,
        };

        let screenQuery = [
          {
            entity: "screens",
            body: screenSchema,
          },
        ];
        console.log(screenQuery, auth.user.AllScreenList[0].render.child);
        if (auth.user.screens[0].render.child.length === 0) {
          debugger;
          // console.log("called for upsert");
          // let upsert1 = await createDocument(screenQuery);
          // await Promise.all([upsert1])
          //   .then(function (values) {
          //     //update the AllScreenList & AllScreenListRef
          //     let screen = values[0][0]?.properties?.doc;
          //     console.log(values, screen);

          //     let allScreenList = JSON.parse(
          //       JSON.stringify(this.props.auth.user?.AllScreenList)
          //     );
          //     // allScreenList.forEach((eachScreen, index, array) => {
          //     //   if (screen.id === eachScreen?.id) {
          //     //     array[index] = screen;
          //     //   }
          //     // });
          //     this.props.auth.setAuth({
          //       ...this.props.auth.user,
          //       AllScreenList: [...allScreenList],
          //       AllScreenListRef: [...allScreenList],
          //     });
          //     this.props.alert.setSnack({
          //       ...this.props.alert,
          //       open: true,
          //       severity: alertProps.severity.success,
          //       msg: "Screen updated successfully",
          //       vertical: alertProps.vertical.top,
          //       horizontal: alertProps.horizontal.right,
          //       tone: true,
          //     });
          //   })
          //   .catch((err) => {
          //     console.log(err);
          //   });
          alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.warning,
            msg: "Please add some components",
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        } else {
          debugger;
          //read mode
          console.log("called for user", auth.user?.user?.name);
          let screenSchema = {
            ...auth.user?.screens[0],
            readMode: true,
            isLockedBy: auth.user?.user?.name,
            lock: true,
          };
          let screenQuery = [
            {
              entity: "screens",
              body: screenSchema,
              filter: {
                id: screenSchema?.id,
                version: screenSchema?.version,
              },
            },
          ];
          await updateDocument(screenQuery)
            .then(() => {
              let allScreenList = JSON.parse(
                JSON.stringify(auth.user?.AllScreenList)
              );
              allScreenList.forEach((eachScreen, index, array) => {
                if (currentScreen.id === eachScreen?.id) {
                  array[index] = screenSchema;
                }
              });
              auth.setAuth({
                ...auth.user,
                screens: allScreenList.filter(
                  (val) => val.id === currentScreen.id
                ),
                AllScreenList: [...allScreenList],
                AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
              });
              alert.setSnack({
                ...alert,
                open: true,
                severity: alertProps.severity.success,
                msg: "Screen updated successfully",
                vertical: alertProps.vertical.top,
                horizontal: alertProps.horizontal.right,
                tone: true,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
        localStorage.setItem(
          "previewScreen",
          JSON.stringify(auth.user.screens)
        );
      }
    }
  };

  const undoRedo = (type) => {
    debugger;

    let undoData = null;
    let currentScreen = null;
    if (type === "redo") {
      undoData = JSON.parse(
        JSON.stringify(auth.user.screensHistory[auth.user.selectedScreen])
      );
      console.log(undoData);
      currentScreen =
        undoData[auth.user.screensActiveIndex[auth.user.selectedScreen] + 1];
    } else {
      undoData = JSON.parse(
        JSON.stringify(auth.user.screensHistory[auth.user.selectedScreen])
      );
      currentScreen =
        undoData[auth.user.screensActiveIndex[auth.user.selectedScreen] - 1];
      console.log(currentScreen);
    }
    // let data2 = null;
    // if (Object.keys(auth.user.componentAttr).length > 0) {
    //   data2 = getPropertiesItems(
    //     auth.user.componentAttr,
    //     auth.user.selectedScreen,
    //     eleAtrribute
    //   );
    // }

    let allScreenListCopy = JSON.parse(JSON.stringify(auth.user.AllScreenList));

    allScreenListCopy.forEach((screen, index, array) => {
      if (screen.id === auth.user.selectedScreen) {
        array[index] = {
          ...currentScreen,
          readMode: false,
          isLockedBy: auth.user?.user?.name,
        };
      }
    });

    auth.setAuth({
      ...auth.user,
      AllScreenList: allScreenListCopy,
      screensActiveIndex: {
        ...auth.user.screensActiveIndex,
        [auth.user.selectedScreen]:
          type === "undo"
            ? auth.user.screensActiveIndex[auth.user.selectedScreen] - 1
            : auth.user.screensActiveIndex[auth.user.selectedScreen] + 1,
      },
      screens: [
        {
          ...currentScreen,
          readMode: false,
          isLockedBy: auth.user?.user?.name,
        },
      ],
      // componentAttr: data2 ? data2 : {},
      selectedScreen: currentScreen.id,
    });
    props.onClose("right", false);
  };

  const handleKeyboardSave = (e) => {
    let key = e.keyCode;
    // if (key === 83 && (e.ctrlKey || e.metaKey)) {
    //   e.preventDefault();
    //   console.log("Ctrl + S Pressed !");
    //   handleSaveScreen();
    // }
    //  else
    if (key === 90 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      console.log("Ctrl + Z Pressed !");
      undoRedo("undo");
    } else if (key === 89 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      console.log("Ctrl + Z Pressed !");
      undoRedo("redo");
    }
  };
  React.useEffect(() => {
    // window.addEventListener("keydown", handleSaveScreen());
    // return () => {
    //   window.removeEventListener("keydown", handleSaveScreen);
    // };
  }, [auth]);

  const handleClick = (event) => {
    setState({ ...state, anchorEl: event.currentTarget });
  };

  const handlezoomout = (e) => {
    props.zoomfunc(e, "zoomIn");
  };
  return (
    <>
      <TopNavBar />
      <div className={classes.root}>
        {localStorage.getItem("currentScreen") === "uiflow" &&
          window.location.pathname === "/uiflow" && (
            <Grid
              container
              justifyContent="flex-end"
              alignItems="center"
              style={{
                backgroundColor: "#fff",
                height: "35px",
                borderBottom: "2px solid #ebebeb",
              }}
            >
              <Grid container item xs={8} justifyContent="center">
                <Tabs
                  value={"uiflow"}
                  onChange={(e, val) => onChangeSwitch(e, val)}
                  indicatorColor="primary"
                  // TabIndicatorProps={{ style: { background: '#808080' } }}
                  //textColor="red"
                  // aria-label="simple tabs example"
                  className={classes.Tabs}
                  style={{
                    fontFamily: "Roboto Helvetica Arial sans-serif",
                  }}
                  centered
                >
                  <Tab
                    value={"builder"}
                    label={
                      <h2
                        style={{
                          fontSize: "9px",
                          textTransform: "capitalize !important",
                          color: "#3093ff",
                          margin: "0",
                        }}
                      >
                        UI Designer
                      </h2>
                    }
                  ></Tab>
                  <Tab
                    value={"uiflow"}
                    label={
                      <h2
                        style={{
                          fontSize: "9px",
                          textTransform: "capitalize !important",
                          color: "#3093ff",
                          margin: "0",
                        }}
                      >
                        UI Flow
                      </h2>
                    }
                  ></Tab>
                </Tabs>
              </Grid>
              <Grid item xs={1}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={(e) => handleSaveScreen("button", e)}
                  style={{ fontSize: "8px" }}
                >
                  save all
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  style={{ fontSize: "8px" }}
                  onClick={build}
                >
                  Build
                </Button>
              </Grid>
            </Grid>
          )}
        <Popover
          id={id}
          open={open}
          anchorEl={state.anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuList>
            {/* <MenuItem>Profile</MenuItem>
          <MenuItem>My account</MenuItem> */}
            <MenuItem
              style={{ fontSize: "10px", height: "10px" }}
              onClick={() => logOut()}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Popover>
      </div>
    </>
  );
}

MainAppBar.defaultProps = {
  saveForm: () => {},
  uiType: "builder",
  title: "HyperEngage App",
  onClose: () => {},
  changeProjectName: () => {},
  build: () => {},
};
