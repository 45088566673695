import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { IconButton, TextField } from '@material-ui/core';
import Checkbox from './styledCheckbox';
import Autocomplete from '@material-ui/lab/Autocomplete';


const useStyles = makeStyles((theme) => ({
    root: {
        height: 216,
        flexGrow: 1,
        maxWidth: 400,
    },
    block: {
        paddingLeft: 20,
    },
    checkbox: {
        padding: 0,
        marginRight: 6
    },
    textInput: {
        border: 'none',
        padding: 0,
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.38)',
        outline: 'none',
        marginLeft: 10,
        color: theme.palette.error.main,
        '&:focus': {
            borderBottom: `solid 1px ${theme.palette.primary.main}`
        }
    },
    autocomplete: {
        marginLeft: 10,
        width: 150,
        "&.MuiInputBase-input": {
            textAlign: 'right'
        }
    }
}));

export default function ControlledTreeView(props) {
    const classes = useStyles();
    const subChildRenderer = (type, data) => {
        switch (type) {
            case 'fieldOptions':
                return <div className={classes.block}>
                    <CheckBoxInput
                        label={"distinctFrom"}
                        active={data?.distinctFrom !== undefined}
                        value={data?.distinctFrom}
                        keyCode={"distinctFrom"}
                        nodeId={data?.$id}
                        handleInputChange={props.handleInputChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        inputDisabled={false}
                        inputType={'text'}
                    />
                    <CheckBoxInput
                        label={"equalto"}
                        active={data?.equalto !== undefined}
                        value={data?.equalto}
                        keyCode={"equalto"}
                        nodeId={data?.$id}
                        handleInputChange={props.handleInputChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        inputDisabled={false}
                        inputType={'text'}
                    />
                </div>
            case 'filter':
                return <div className={classes.block}>
                    {
                        props.columnFields?.map((item) => {
                            return !item?.column ? <CheckBoxSelectInput
                                label={item?.name}
                                keyCode={item?.name}
                                active={data?.[item?.name] !== undefined}
                                value={data?.[item?.name] ?? null}
                                nodeId={data?.$id}
                                data={data?.[item?.name]}
                                inputDisabled={false}
                                inputType={"number"}
                                columnFields={props.columnFields}
                                handleCheckboxChange={props.handleCheckboxChange}
                                handleInputChange={props.handleInputChange}
                                selectedElements={props.selectedElements}
                            /> : null
                        })
                    }
                    {/* <ControlledTreeView
                        title={'and'}
                        type={'filter'}
                        keyCode="and"
                        value={{}}
                        active={data?.and}
                        nodeId={data?.$id}
                        data={data?.and}
                        columnFields={props.columnFields}
                        handleIndicatorChange={props.handleIndicatorChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        handleInputChange={props.handleInputChange}

                    />
                    <ControlledTreeView
                        title={'or'}
                        type={'filter'}
                        keyCode="or"
                        value={{}}
                        active={data?.or}
                        nodeId={data?.$id}
                        data={data?.or}
                        columnFields={props.columnFields}
                        handleIndicatorChange={props.handleIndicatorChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        handleInputChange={props.handleInputChange}
                    />
                    <ControlledTreeView
                        title={'not'}
                        type={'filter'}
                        keyCode="not"
                        value={{}}
                        active={data?.not}
                        nodeId={data?.$id}
                        data={data?.not}
                        columnFields={props.columnFields}
                        handleIndicatorChange={props.handleIndicatorChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        handleInputChange={props.handleInputChange}
                    /> */}
                </div>
            case 'node':
                return <div className={classes.block}>
                    {
                        props.columnFields?.map((item) => {
                            return item?.column ? <ControlledTreeView
                                title={item.name}
                                type={'node'}
                                keyCode={`node${item.name}`}
                                nodeId={data?.$id}
                                active={data?.[`node${item.name}`]}
                                value={true}
                                handleIndicatorChange={props.handleIndicatorChange}
                                handleCheckboxChange={props.handleCheckboxChange}
                                columnFields={item.column}
                                data={data?.[`node${item.name}`]}
                            />
                                :
                                <CheckBoxInput
                                    label={item.name}
                                    active={data?.[item?.name]}
                                    value={true}
                                    keyCode={item.name}
                                    nodeId={data?.$id}
                                    handleCheckboxChange={props.handleCheckboxChange}
                                    inputDisabled={true}
                                />
                        })
                    }
                </div>
                break;
            case 'queryOptions':
                return <div className={classes.block}>
                    {/* <CheckBoxInput
                        label={"first"}
                        active={data?.first !== undefined}
                        value={data?.first}
                        keyCode={"first"}
                        nodeId={data?.$id}
                        handleInputChange={props.handleInputChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        inputDisabled={false}
                        inputType={'number'}
                    />
                    <CheckBoxInput
                        label={"last"}
                        active={data?.last !== undefined}
                        value={data?.last}
                        keyCode={"last"}
                        nodeId={data?.$id}
                        handleInputChange={props.handleInputChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        inputDisabled={false}
                        inputType={'number'}
                    />
                    <CheckBoxInput
                        label={"after"}
                        active={data?.after !== undefined}
                        value={data?.after}
                        keyCode={"after"}
                        nodeId={data?.$id}
                        handleInputChange={props.handleInputChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        inputDisabled={false}
                        inputType={'text'}
                    />
                    <CheckBoxInput
                        label={"before"}
                        active={data?.before !== undefined}
                        value={data?.before}
                        keyCode={"before"}
                        nodeId={data?.$id}
                        handleInputChange={props.handleInputChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        inputDisabled={false}
                        inputType={'text'}
                    /> */}
                    <ControlledTreeView
                        title={'node'}
                        type={'node'}
                        keyCode="node"
                        nodeId={data?.$id}
                        active={data?.node}
                        value={true}
                        handleIndicatorChange={props.handleIndicatorChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        columnFields={props.columnFields}
                        data={data?.node}
                    />
                    <ControlledTreeView
                        title={'filter'}
                        type={'filter'}
                        keyCode="filter"
                        nodeId={data?.$id}
                        active={data?.filter}
                        value={{}}
                        handleIndicatorChange={props.handleIndicatorChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        handleInputChange={props.handleInputChange}
                        columnFields={props.columnFields}
                        selectedElements={props.selectedElements}
                        data={data?.filter}
                    />
                </div>
                break
            case 'query':
                return <div className={classes.block}>
                    <ControlledTreeView
                        title={`${props?.title}`}
                        type={"queryOptions"}
                        keyCode={`${props?.title}`}
                        nodeId={data?.$id}
                        active={data?.[`${props?.title}`]}
                        value={{}}
                        handleIndicatorChange={props.handleIndicatorChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        handleInputChange={props.handleInputChange}
                        columnFields={props.columnFields}
                        selectedElements={props.selectedElements}
                        data={data?.[`${props?.title}`]}
                    />
                    <ControlledTreeView
                        title={`${props?.title}ById`}
                        type={"queryOptions"}
                        keyCode={`${props?.title}ById`}
                        nodeId={data?.$id}
                        active={data?.[`${props?.title}ById`]}
                        value={{}}
                        handleIndicatorChange={props.handleIndicatorChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        handleInputChange={props.handleInputChange}
                        columnFields={props.columnFields}
                        selectedElements={props.selectedElements}
                        data={data?.[`${props?.title}ById`]}
                    />
                    {
                        props.columnFields.map(item => {
                            var columnFields = props.entityDetails.filter(entity => entity.entity === item?.mappedEntity)
                            var keycode = `${item?.mappedEntity}By${props?.title?.charAt(0).toUpperCase() + props?.title?.slice(1)}Id`;
                            if(item.mappedEntity && columnFields.length){
                                return <ControlledTreeView
                                    title={keycode}
                                    type={"queryOptions"}
                                    keyCode={keycode}
                                    nodeId={data?.$id}
                                    active={data?.[keycode]}
                                    value={{}}
                                    handleIndicatorChange={props.handleIndicatorChange}
                                    handleCheckboxChange={props.handleCheckboxChange}
                                    handleInputChange={props.handleInputChange}
                                    columnFields={columnFields[0].column}
                                    selectedElements={props.selectedElements}
                                    data={data?.[keycode]}
                                />
                            }
                        })
                    }
                </div>
                break;
            case 'inputview':
                return <div className={classes.block}>
                    {
                        props.columnFields?.map((item) => {
                            return <CheckBoxSelectInput
                                label={item.name}
                                active={data?.[item?.name] !== undefined}
                                value={data?.[item?.name] ?? null}
                                keyCode={item.name}
                                nodeId={data?.$id}
                                handleCheckboxChange={props.handleCheckboxChange}
                                handleInputChange={props.handleInputChange}
                                inputDisabled={false}
                                inputType={"number"}
                            />
                        })
                    }
                </div>
                break;
            case 'input':
                return <div className={classes.block}>
                    <ControlledTreeView
                        title={'input'}
                        type={'inputview'}
                        keyCode="input"
                        value={{}}
                        active={data?.input}
                        nodeId={data?.$id}
                        data={data?.input}
                        columnFields={props.columnFields}
                        handleIndicatorChange={props.handleIndicatorChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        handleInputChange={props.handleInputChange}

                    />
                    <ControlledTreeView
                        title={'query'}
                        type={'query'}
                        keyCode="query"
                        value={{}}
                        active={data?.query}
                        nodeId={data?.$id}
                        data={data?.query}
                        columnFields={props.columnFields}
                        handleIndicatorChange={props.handleIndicatorChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        handleInputChange={props.handleInputChange}
                        entityDetails={props.entityDetails}
                    />
                </div>
                break;
            case 'mutation':
                return <div className={classes.block}>
                    <ControlledTreeView
                        title={'create'}
                        type={'input'}
                        keyCode="create"
                        value={{}}
                        active={data?.create}
                        nodeId={data?.$id}
                        data={data?.create}
                        columnFields={props.columnFields}
                        handleIndicatorChange={props.handleIndicatorChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        handleInputChange={props.handleInputChange}

                    />
                    <ControlledTreeView
                        title={'update'}
                        type={'input'}
                        keyCode="update"
                        value={{}}
                        active={data?.update}
                        nodeId={data?.$id}
                        data={data?.update}
                        columnFields={props.columnFields}
                        handleIndicatorChange={props.handleIndicatorChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        handleInputChange={props.handleInputChange}

                    />
                    <ControlledTreeView
                        title={'delete'}
                        type={'input'}
                        keyCode="delete"
                        value={{}}
                        active={data?.delete}
                        nodeId={data?.$id}
                        data={data?.delete}
                        columnFields={props.columnFields}
                        handleIndicatorChange={props.handleIndicatorChange}
                        handleCheckboxChange={props.handleCheckboxChange}
                        handleInputChange={props.handleInputChange}

                    />
                </div>
                break;
        }
    }
    return (
        <TreeGroup {...props}>
            {subChildRenderer(props.type, props.data)}
        </TreeGroup>
    );
}

const TreeGroup = (props) => {
    return <div>
        <div>
            {
                props.active ?
                    <IconButton size="small" style={{ padding: 0, width: 20, height: 20 }}>
                        <ArrowDropDownIcon onClick={(e) => props.handleIndicatorChange(null, props.keyCode, props.nodeId)} />
                    </IconButton>
                    :
                    <IconButton size="small" style={{ padding: 0, width: 20, height: 20 }}>
                        <ArrowRightIcon onClick={(e) => props.handleIndicatorChange(props.value, props.keyCode, props.nodeId)} />
                    </IconButton>
            }
            <span>{props.title}</span>
        </div>
        {
            props.active ? props.children : null
        }
    </div>
}
const TreeItem = ({ children }) => {
    return <div>{children}</div>
}
const CheckBoxInput = ({ label, active, value, nodeId, keyCode, inputDisabled, inputType, handleInputChange, handleCheckboxChange }) => {
    const classes = useStyles();
    return <TreeItem>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
                <Checkbox
                    checked={active}
                    className={classes.checkbox}
                    onClick={(e) => handleCheckboxChange(!active, keyCode, nodeId, inputDisabled)}
                />
            </div>
            <div>{label}</div>
            <div>
                {
                    // auto adjusting text input
                    active && !inputDisabled ? <input
                        type={inputType}
                        value={value}
                        className={classes.textInput}
                        style={{ width: `${value?.length ? value?.length : 2}ch` }}
                        onChange={(e) => {
                            handleInputChange(e.target.value, keyCode, nodeId);
                        }}
                    /> : null
                }
            </div>
        </div>
    </TreeItem>
}

const CheckBoxSelectInput = ({ label, active, value, selectedElements, nodeId, keyCode, inputDisabled, inputType, handleInputChange, handleCheckboxChange }) => {
    const classes = useStyles();
    return <TreeItem>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
                <Checkbox
                    checked={active}
                    className={classes.checkbox}
                    onClick={(e) => handleCheckboxChange(!active, keyCode, nodeId, inputDisabled)}
                />
            </div>
            <div>{label}</div>
            <div>
                {
                    // auto adjusting text input
                    // active && !inputDisabled ? <input
                    //     type={inputType}
                    //     value={value}
                    //     className={classes.textInput}
                    //     style={{ width: `${value?.length ? value?.length : 2}ch` }}
                    //     onChange={(e) => {
                    //         handleInputChange(e.target.value, keyCode, nodeId);
                    //     }}
                    // /> : null
                    active && !inputDisabled ? <div
                        className={classes.autocomplete}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={selectedElements ?? []}
                            getOptionLabel={(option) => option.CName}
                            size={"small"}
                            fullWidth
                            value={value ?? null}
                            onChange={(e, value) => handleInputChange(value, keyCode, nodeId)}
                            renderInput={(params) => <TextField
                                {...params}
                                label=""
                                variant="standard"
                                placeholder={"select entities"}
                                value={active.key}
                            />}
                        />
                    </div> : null
                }
            </div>
        </div>
    </TreeItem>
}