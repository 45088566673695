export const ComponentsLists = [
  {
    catagerie: "layouts",
    list: [
      {
        name: "Button",
      },
      {
        name: "TextInput",
      },
      {
        name: "Text",
      },
      {
        name: "H1",
      },
      {
        name: "H2",
      },
      {
        name: "H6",
      },
      {
        name: "Container",
      },
      {
        name: "Col",
      },
      {
        name: "Hidden",
      },
      {
        name: "Checkbox",
      },
      {
        name: "Alert",
      },
      {
        name: "Divider",
      },
      {
        name: "Card",
      },
      {
        name: "Row",
      },
      {
        name: "Badge",
      },
      { name: "CardActions" },
      { name: "CardContent" },
      { name: "CardHeader" },
      { name: "CardMedia" },
      { name: "Snackbar" },
      { name: "SideMenu" },
      { name: "Link" },
      { name: "Switch" },
      { name: "Radio" },
      { name: "CircularProgress" },
      { name: "Paper" },
      { name: "Modal" },
      { name: "DialogTitle" },
      { name: "DialogActions" },
      { name: "DialogContent" },
      { name: "Drawer" },
      { name: "Select" },
      { name: "Avatar" },
      { name: "TopHeader" },
      { name: "MultiSelect" },
      { name: "RangePicker" },
      { name: "Chip" },
      { name: "List" },
      { name: "ListItem" },
      { name: "Upload" },
      { name: "Carousel" },
      { name: "TopSelect" },
      { name: "BorderLinearProgress" },
      { name: "Image" },
      { name: "Dropdown" },
      { name: "Table" },
      { name: "Collapse" },
    ],
  },
  {
    catagerie: "Navigation",
    list: [
      {
        name: "Button",
      },
      {
        name: "Link",
      },
    ],
  },
];

export const AllScreenList = [
  {
    id: "login",
    pathId: "loginScreen",
    isHome: true,
    isPrivate: false,
    path: "/login",
    name: "Login",
    arrowLink: [
      {
        id: "arrowSource",
        activePath: true,
        arrowSource: "arrowSource",
        arrowTarget: "screenpathId2",
      },
      // {
      //   id: "1212",
      //   arrowSource: "1212",
      //   arrowTarget: "screenpathId2",
      // },
    ],
    render: {
      component: "form",
      attr: [
        {
          key: "id",
          value: "login_form",
        },
        {
          key: "name",
          value: "login_form",
        },
      ],
      child: [
        {
          type: "component",
          component: "Col",
          name: "Col",
          id: "1212",
          attr: [
            {
              key: "id",
              value: "email_id",
            },
            {
              key: "name",
              value: "email_id",
            },
            {
              key: "placeholder",
              value: "Email",
            },
            {
              key: "type",
              value: "text",
            },
          ],
          child: [
            // {
            //   type: "Col",
            //   component: "Col",
            //   name: "Col",
            //   id: "1222",
            //   child: [
            //     {
            //       type: "component",
            //       component: "Text",
            //       name: "User Name",
            //       id: "1211-username",
            //       properties: {
            //         label: "User Name",
            //       },
            //     },
            //     {
            //       type: "component",
            //       component: "Radio",
            //       name: "password",
            //       id: "1212-pws",
            //       properties: {
            //         label: "Password",
            //       },
            //     },
            //   ],
            // },
            {
              type: "component",
              component: "Col",
              name: "Col",
              id: "1214",
              child: [
                {
                  type: "component",
                  component: "Button",
                  name: "login",
                  id: "arrowSource",
                  properties: {
                    name: "Login",
                  },
                },
              ],
              properties: {
                justify: "center",
                alignItems: "center",
              },
            },
          ],
        },
      ],
    },
  },
  {
    id: "screen2",
    pathId: "screenpathId1",
    path: "/screen2",
    name: "Screen 2",
    arrowLink: [],
    render: {
      component: "div",
      child: [
        {
          type: "component",
          component: "Button",
          name: "User Name",
          id: "arrowSource2",
          properties: {
            label: "User Name",
          },
        },
      ],
    },
  },
  {
    id: "screen3",
    pathId: "screenpathId2",
    path: "/screen3",
    name: "Screen 3",
    arrowLink: [],
    render: {
      component: "div",
      child: [
        {
          type: "component",
          component: "Button",
          name: "User Name",
          id: "arrowSource3",
          properties: {
            label: "User Name",
          },
        },
      ],
    },
  },
];

export const mainMenuList = [
  // {
  //   name: "Flows",
  //   id: 0,
  //   icon: "AccountTree",
  // },
  {
    name: "Screens",
    id: 1,
    icon: "FlipToFront",
  },
  // {
  //   name: "Events",
  //   id: 2,
  //   icon: "Settings",
  // },
];
export const ActionsList = [
  { label: "onChange", val: "onChange" },
  { label: "onSubmit", val: "onSubmit" },
  { label: "onClick", val: "onClick" },
  { label: "onfocus", val: "onfocus" },
  { label: "ondblclick", val: "ondblclick" },
  { label: "onmousedown", val: "onmousedown" },
  { label: "onmousemove", val: "onmousemove" },
  { label: "onLoad", val: "onLoad" },
  { label: 'stateCreation', val: "stateCreation" }
];