import React from "react";
import isEqual from "react-fast-compare";
import {
  createDocument,
  getAllUIComponents,
  readDocuments,
  readProjectTemplates,
  readProjectVsTools,
  readUIBuilderDoc,
  updateDocument,
} from "./apis";
import config from "./config";
import { AuthContext } from "./contexts";
import { localStorageKeys } from "./utils";
class AppAuth extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      loading: true,
      isRefreshed: false,
      UIcomponents: [],
      screens: [],
      selectedScreen: null,
      componentLink: true,
      screenLink: true,
      componentId: null,
      componentDecisionId: null,
      componentAttr: {},
      projectTemplates: [],
      customComponents: [],
      templateDialog: {},
      flowGroup: [],
      // isEdited: false,
      undoRedoData: [],
      activeIndex: 0,
      activeGroup: null,
      undoRedo: false,
      AllScreenList: [],
      AllScreenListRef: [],
      storageManagement: {
        sessionStorage: [],
        localStorageKeys: [],
        cookies: [],
      },
      version: 1,
      permissionData: {},
      screensHistory: {},
      projectTemplatesCreated: false,
      customComponentsCreated: false,
      flowGroupCreated: false,
      storageManagementCreated: false,
      screensActiveIndex: {},
    };
  }
  componentDidMount() {
    this.refreshAPI();
    console.log(AuthContext, this.state);
  }
  _setAuthState = (props) => {
    debugger;
    let screensHistory = this.state.screensHistory;
    let screensActiveIndex =
      props?.screensActiveIndex ?? this.state.screensActiveIndex;
    let activeIndex;

    if (props?.screenEdited) {
      activeIndex = props.screensActiveIndex[props?.screenEdited];
      let screens = Object.keys(props.screensHistory ?? {});
      console.log(screens);
      let currentScreen = JSON.parse(
        JSON.stringify(screensHistory[props?.screenEdited] ?? [])
      );
      let [currentScreenData] = props.AllScreenList.filter(
        (eachScreen) => eachScreen.id === props?.screenEdited
      );
      console.log(currentScreenData);
      currentScreen.push(currentScreenData);
      console.log(currentScreen, "currentScreen");
      screensHistory = {
        ...screensHistory,
        [props?.screenEdited]: JSON.parse(JSON.stringify(currentScreen)),
      };
      screensActiveIndex = {
        ...screensActiveIndex,
        [props?.screenEdited]: activeIndex + 1,
      };
    }
    console.log(props, "props", screensActiveIndex, screensHistory);
    this.setState({
      ...props,
      screensHistory: screensHistory,
      screensActiveIndex: screensActiveIndex,
      screenEdited: null,
    });
  };
  refreshAPI = async () => {
    debugger;
    let urlParams = new URLSearchParams(window.location.search);
    let metadataIdFromURL = urlParams?.get("metadata_id");
    let metaDataId = localStorage.getItem(localStorageKeys.metaDataId);
    //to get token from url
    let tokenFromUrl = urlParams.get("token");
    let token = localStorage.getItem(localStorageKeys.auth_token);
    if (window.location.pathname === "/uiflow") {
      localStorage.setItem("currentScreen", "uiflow");
    } else {
      localStorage.setItem("currentScreen", "builder");
    }
    if (metadataIdFromURL && tokenFromUrl) {
      localStorage.setItem(localStorageKeys.metaDataId, metadataIdFromURL);
      localStorage.setItem(localStorageKeys.auth_token, tokenFromUrl);
    }
    let metadataid = !metadataIdFromURL ? metaDataId : metadataIdFromURL;
    let parsedToken;
    let userInfo = {};
    if (tokenFromUrl) {
      parsedToken = tokenFromUrl?.split(".")[1];
      console.log(parsedToken, "parsedToken");
      userInfo = JSON.parse(window.atob(parsedToken));
      console.log("urltoken", tokenFromUrl);
      console.log("urllocal", token);
    } else if (token) {
      parsedToken = token?.split(".")[1];
      userInfo = JSON.parse(window.atob(parsedToken));
      console.log("url", token);
    }
    debugger;
    if (metadataid) {
      debugger;
      // Read UI Builder data
      const uibuilderData = await readUIBuilderDoc(metadataid);

      //If no document is created for this UIBuilder create one
      let AllScreenList = [],
        customComponents = [],
        projectTemplates = [],
        flowGroup = [],
        storageManagement = {
          sessionStorage: [],
          localStorageKeys: [],
          cookies: [],
        };
      localStorage.setItem("projectId", uibuilderData.projectId);
      //set project name
      localStorage.setItem(
        localStorageKeys.projectName,
        uibuilderData.metadataid
      );
      console.log("lo", uibuilderData);
      if (Object.keys(uibuilderData)?.length === 0) {
        //get client & project details from projectvstools
        const projectVSToolsData = await readProjectVsTools(metadataid);

        let uibuilderSchema = {
          metadataid: projectVSToolsData?.metadataid,
          projectName: projectVSToolsData?.metadataname?.split("_")[0] ?? "",
          version: 1.0,
          projectId: projectVSToolsData.projectid,
          clientId: projectVSToolsData.clientid,
          toolId: projectVSToolsData.toolid,
        };

        //set project name
        localStorage.setItem(
          localStorageKeys.projectName,
          projectVSToolsData?.metadataname?.split("_")[0] ?? ""
        );
        //set project id
        localStorage.setItem("projectId", projectVSToolsData.projectid ?? "");

        await createDocument([
          {
            entity: "uibuilder",
            body: uibuilderSchema,
          },
        ]);
      } else {
        //set project name
        localStorage.setItem(
          localStorageKeys.projectName,
          uibuilderData.projectName
        );
        //get  screens data
        let screenQuery = [
          {
            entity: "screens",
            filter: {
              metadataid: metadataid,
              // version: uibuilderData?.version ?? 1,
              activestatus: true,
            },
          },
        ];
        const allScreens = await readDocuments(screenQuery, { limit: 100 });
        AllScreenList = [...allScreens];
        //get project templates
        const projectTemplatesResult = await readProjectTemplates(
          metadataid,
          uibuilderData?.version ?? 0
        );
        if (Object.keys(projectTemplatesResult).length > 0) {
          projectTemplates = projectTemplatesResult?.templateGroups;
          this.state.projectTemplatesCreated = true;
        }
        //get customcomponents
        // const customComponentsResult = await readCustomComponents(
        //   metadataid,
        //   uibuilderData?.version ?? 0
        // );
        // if (Object.keys(customComponentsResult).length > 0) {
        //   customComponents = customComponentsResult?.customComponents ?? [];
        //   this.state.customComponentsCreated = true;
        // }
        // //get flow groups
        // const flowGroupResult = await readFlowGroups(
        //   metadataid,
        //   uibuilderData?.version ?? 0
        // );
        // if (Object.keys(flowGroupResult).length > 0) {
        //   flowGroup = flowGroupResult?.flowGroups;
        //   this.state.flowGroupCreated = true;
        // }
        // //get storageManagement data
        // const storagemanagementResult = await readStorageManagement(
        //   metadataid,
        //   uibuilderData?.version ?? 0
        // );
        // if (Object.keys(storagemanagementResult).length > 0) {
        //   storageManagement = storagemanagementResult?.storageManagement;
        //   this.state.storageManagementCreated = true;
        // }
      }

      // let qpasQueries = await getAllQpasQueries(
      //   "35d4aeca-cb7a-459f-9478-afc4b4a8f0a9"
      // );

      let UIcomponents = await getAllUIComponents();
      console.log(
        AllScreenList.sort(function (a, b) {
          return a?.index ?? 0 - b?.index ?? 0;
        })
      );

      function getInitialScreen() {
        let result = AllScreenList.find((screen) => screen.type === "Screen");
        console.log(result);
        return result ? [result] : [];
      }

      //update the state
      this.setState({
        user: {
          ...userInfo,
          name: userInfo?.given_name,
        },
        isRefreshed: true,
        screensMetaData: uibuilderData?.screens ?? [],
        loading: false,
        // qpasQueries: qpasQueries,
        UIcomponents: UIcomponents.sort((a, b) =>
          a.componentName.localeCompare(b.componentName)
        ),
        version: uibuilderData?.version ?? 0,
        undoRedoData: [JSON.parse(JSON.stringify(AllScreenList))],
        activeIndex: 0,
        AllScreenList: AllScreenList,
        AllScreenListRef: JSON.parse(
          JSON.stringify(
            AllScreenList.sort(function (a, b) {
              return a.index - b.index;
            })
          )
        ),
        screens: getInitialScreen(),
        selectedScreen:
          getInitialScreen().length > 0 ? getInitialScreen()[0].id : null,
        projectTemplates: projectTemplates,
        projectTemplatesRef: JSON.parse(JSON.stringify(projectTemplates)),
        customComponents: customComponents,
        customComponentsRef: JSON.parse(JSON.stringify(customComponents)),
        flowGroup: flowGroup ?? [],
        flowGroupRef: JSON.parse(JSON.stringify(flowGroup)),
        storageManagement: storageManagement,
        storageManagementRef: JSON.parse(JSON.stringify(storageManagement)),
        projectId: uibuilderData?.projectId ?? null,
        clientId: uibuilderData?.clientId ?? null,
        toolId: uibuilderData?.toolId ?? null,
        screensHistory: JSON.parse(JSON.stringify(AllScreenList)).reduce(
          (allScreens, currScreen) => {
            return {
              ...allScreens,
              [currScreen.id]: [{ ...currScreen }],
            };
          },
          {}
        ),
        screensActiveIndex: JSON.parse(JSON.stringify(AllScreenList)).reduce(
          (allScreens, currScreen) => {
            return {
              ...allScreens,
              [currScreen.id]: 0,
            };
          },
          {}
        ),
      });
      localStorage.setItem("previewScreen", JSON.stringify(getInitialScreen()));
    } else {
      window.location.replace(config.qdm_admin_url);
    }
  };

  // auto save metaJSON Data's
  _update = async (uiflow) => {
    let metaDataId = localStorage.metaDataId;
    //find what have changed
    let changes = [
      {
        type: "screens",
        ops: [
          {
            type: "updated",
            payload: [],
          },
          {
            type: "deleted",
            payload: [],
          },
        ],
      },
      {
        type: "projectTemplates",
        ops: [
          {
            type: "created",
            payload: false,
          },
          {
            type: "updated",
            payload: false,
          },
        ],
      },
      {
        type: "customComponents",
        ops: [
          {
            type: "created",
            payload: false,
          },
          {
            type: "updated",
            payload: false,
          },
        ],
      },
      {
        type: "flowGroup",
        ops: [
          {
            type: "created",
            payload: false,
          },
          {
            type: "updated",
            payload: false,
          },
        ],
      },
      {
        type: "storageManagement",
        ops: [
          {
            type: "created",
            payload: false,
          },
          {
            type: "updated",
            payload: false,
          },
        ],
      },
    ];

    //screen - deleted, updated
    let allScreenListRef = this.state.AllScreenListRef;
    //deleted screens
    allScreenListRef.forEach(({ id: id1 }) => {
      if (!this.state.AllScreenList.some(({ id: id2 }) => id2 === id1)) {
        changes.forEach((eachType) => {
          if (eachType.type === "screens") {
            eachType.ops.forEach((op) => {
              if (op.type === "deleted") {
                op.payload.push(id1);
              }
            });
          }
        });
      }
    });
    this.state.AllScreenList.forEach((screen) => {
      //created & updated screens
      let foundScreen = allScreenListRef.find(
        (eachScreen) => eachScreen.id === screen.id
      );
      let isEqualOrNot = isEqual(foundScreen, screen);
      if (!isEqualOrNot) {
        changes.forEach((eachType) => {
          if (eachType.type === "screens") {
            eachType.ops.forEach((op) => {
              if (op.type === "updated") {
                op.payload.push(screen);
              }
            });
          }
        });
      }
    });
    console.log(changes);

    console.log(changes, "changes");
    //projectTemplates is not equal- create or update it
    let projectTemplatesRef = this.state.projectTemplatesRef;
    let pTStatus = isEqual(
      this.state.projectTemplates ?? [],
      projectTemplatesRef ?? []
    );

    if (!pTStatus) {
      if (this.state.projectTemplatesCreated) {
        changes.forEach((eachType) => {
          if (eachType.type === "projectTemplates") {
            eachType.ops.forEach((op) => {
              if (op.type === "updated") {
                op.payload = true;
              }
            });
          }
        });
      } else {
        changes.forEach((eachType) => {
          if (eachType.type === "projectTemplates") {
            eachType.ops.forEach((op) => {
              if (op.type === "created") {
                op.payload = true;
              }
            });
          }
        });
      }
    }

    // the network call
    let allPromises = [];
    changes.forEach((eachType) => {
      switch (eachType.type) {
        // case "screens":
        //   eachType.ops.forEach(async (op) => {
        //     if (op.type === "created" && op.payload.length > 0) {
        //       let screenCreatePayload = op.payload.reduce(
        //         (payload, eachPayload) => {
        //           let result = {
        //             entity: "screens",
        //             body: eachPayload,
        //           };
        //           return [...payload, result];
        //         },
        //         []
        //       );
        //       let screensCreateRequest = createDocument(screenCreatePayload);
        //       allPromises.push(screensCreateRequest);
        //     } else if (op.type === "updated" && op.payload.length > 0) {
        //       let screenUpdatePayload = op.payload.reduce(
        //         (payload, eachPayload) => {
        //           let result = {
        //             entity: "screens",
        //             body: eachPayload,
        //             filter: {
        //               id: eachPayload.id,
        //               metadataid: localStorage.metaDataId,
        //               version: this.state.version?.version ?? 1.0,
        //             },
        //           };
        //           return [...payload, result];
        //         },
        //         []
        //       );
        //       let screensUpdateRequest = updateDocument(screenUpdatePayload);
        //       allPromises.push(screensUpdateRequest);
        //     } else if (op.type === "deleted" && op.payload.length > 0) {
        //       op.payload.forEach(async (payload, index) => {
        //         let result = {
        //           entity: "screens",
        //           filter: {
        //             id: payload,
        //           },
        //         };
        //         let screensDeleteRequest = deleteDocument([result]);
        //         allPromises.push(screensDeleteRequest);
        //       });
        //     }
        //   });
        //   break;
        case "projectTemplates":
          eachType.ops.forEach(async (op) => {
            if (op.type === "created" && op.payload) {
              let projectTemplatesSchema = {
                metadataid: localStorage.metaDataId,
                projectId: this.state.projectId,
                templateGroups: this.state.projectTemplates,
                version: this.state.version,
              };

              let pTCreateRequest = createDocument([
                {
                  entity: "project_templates",
                  body: projectTemplatesSchema,
                },
              ]);
              this.setState({
                projectTemplatesCreated: true,
              });
              allPromises.push(pTCreateRequest);
            } else if (op.type === "updated" && op.payload) {
              let projectTemplatesSchema = {
                metadataid: localStorage.metaDataId,
                projectId: this.state.projectId,
                templateGroups: this.state.projectTemplates,
                version: this.state.version,
              };
              let pTUpdateRequest = updateDocument([
                {
                  entity: "project_templates",
                  body: projectTemplatesSchema,
                  filter: {
                    metadataId: metaDataId,
                    version: this.state.version,
                  },
                },
              ]);
              allPromises.push(pTUpdateRequest);
            }
          });
          break;
        // case "customComponents":
        //   eachType.ops.forEach(async (op) => {
        //     if (op.type === "created" && op.payload) {
        //       let customComponentsSchema = {
        //         metadataid: localStorage.metaDataId,
        //         projectId: this.state.projectId,
        //         customComponents: this.state.customComponents,
        //         version: this.state.version,
        //       };

        //       let ccCreateRequest = createDocument([
        //         {
        //           entity: "custom_components",
        //           body: customComponentsSchema,
        //         },
        //       ]);
        //       this.setState({
        //         customComponentsCreated: true,
        //       });
        //       allPromises.push(ccCreateRequest);
        //     } else if (op.type === "updated" && op.payload) {
        //       let customComponentsSchema = {
        //         metadataid: localStorage.metaDataId,
        //         projectId: this.state.projectId,
        //         customComponents: this.state.customComponents,
        //         version: this.state.version,
        //       };
        //       let ccUpdateRequest = updateDocument([
        //         {
        //           entity: "custom_components",
        //           body: customComponentsSchema,
        //           filter: {
        //             metadataId: metaDataId,
        //             version: this.state.version,
        //           },
        //         },
        //       ]);
        //       allPromises.push(ccUpdateRequest);
        //     }
        //   });
        //   break;
        // case "flowGroup":
        //   eachType.ops.forEach(async (op) => {
        //     if (op.type === "created" && op.payload) {
        //       let flowGroupSchema = {
        //         metadataid: localStorage.metaDataId,
        //         projectId: this.state.projectId,
        //         flowGroups: this.state.flowGroup,
        //         version: this.state.version,
        //       };

        //       let fgCreateRequest = createDocument([
        //         {
        //           entity: "flowgroup",
        //           body: flowGroupSchema,
        //         },
        //       ]);
        //       this.setState({
        //         flowGroupCreated: true,
        //       });
        //       allPromises.push(fgCreateRequest);
        //     } else if (op.type === "updated" && op.payload) {
        //       let flowGroupSchema = {
        //         metadataid: localStorage.metaDataId,
        //         projectId: this.state.projectId,
        //         flowGroups: this.state.flowGroup,
        //         version: this.state.version,
        //       };
        //       let fgUpdateRequest = updateDocument([
        //         {
        //           entity: "flowgroup",
        //           body: flowGroupSchema,
        //           filter: {
        //             metadataId: metaDataId,
        //             version: this.state.version,
        //           },
        //         },
        //       ]);
        //       allPromises.push(fgUpdateRequest);
        //     }
        //   });

        //   break;
        // case "storagemanagement":
        //   eachType.ops.forEach(async (op) => {
        //     if (op.type === "created" && op.payload) {
        //       let storageManagementSchema = {
        //         metadataid: localStorage.metaDataId,
        //         projectId: this.state.projectId,
        //         storageManagement: this.state.flowGroup,
        //         version: this.state.version,
        //       };

        //       let smCreateRequest = createDocument([
        //         {
        //           entity: "storagemanagement",
        //           body: storageManagementSchema,
        //         },
        //       ]);
        //       this.setState({
        //         storageManagementCreated: true,
        //       });
        //       allPromises.push(smCreateRequest);
        //     } else if (op.type === "updated" && op.payload) {
        //       let storageManagementSchema = {
        //         metadataid: localStorage.metaDataId,
        //         projectId: this.state.projectId,
        //         storageManagement: this.state.flowGroup,
        //         version: this.state.version,
        //       };
        //       let smUpdateRequest = updateDocument([
        //         {
        //           entity: "storagemanagement",
        //           body: storageManagementSchema,
        //           filter: {
        //             metadataId: metaDataId,
        //             version: this.state.version,
        //           },
        //         },
        //       ]);
        //       allPromises.push(smUpdateRequest);
        //     }
        //   });
        //   break;
        default:
          break;
      }
    });

    Promise.all(allPromises)
      .then(async (result) => {
        console.log("result:\n" + JSON.stringify(result));
        //increase version & update refvalues
        this.setState({
          AllScreenListRef: JSON.parse(
            JSON.stringify(this.state.AllScreenList)
          ),
          projectTemplatesRef: JSON.parse(
            JSON.stringify(this.state.projectTemplates)
          ),
          // customComponentsRef: JSON.parse(
          //   JSON.stringify(this.state.customComponents)
          // ),
        });
      })
      .catch((err) => {
        console.error("Failed to do the operations");
        console.error(err);
      });
  };

  render() {
    return (
      <>
        {
          <AuthContext.Provider
            value={{
              user: this.state,
              setAuth: this._setAuthState,
              save: this._update,
              refresh: this.refreshAPI,
            }}
          >
            {this.props.children}
          </AuthContext.Provider>
        }
      </>
    );
  }
}

export default AppAuth;
