import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { AuthContext } from '../../../../contexts';
import { InitiateButton, EventCard, ActionController } from './components';
import { getElementData } from './utils';

const useStyles = makeStyles((theme) => ({
    root: {
        overflow: "auto",
        backgroundSize: "20px 20px",
        padding: "30px",
        width: '100%'
    },
    cardHolder: {
        display: 'flex',
        justifyContent: 'center',
        // backgroundColor:'orange'
    }
}))
const BubbleSetup = (props) => {
    debugger
    const classes = useStyles();
    const auth = React.useContext(AuthContext);
    const [allAppEvents, setAllAppEvents] = React.useState([])
    const [selectedCard, setSelectedCard] = React.useState(null)
    const [selectedArrow, setSelectedArrow] = React.useState(null)

    React.useEffect(() => {
        let allScreenList = auth.user?.AllScreenList ?? [];
        let eventsList = []
        allScreenList.forEach((item, index) => {
            if (item && item?.events) {
                var events = item?.events?.map(event => ({ ...event, screenIndex: index }))
                eventsList.push(...events)
            }
        })
        eventsList.sort((a, b) => parseFloat(a.eventIndex) - parseFloat(b.eventIndex));
        auth.user.totalEventsItems = eventsList?.length ?? 0
        setAllAppEvents(eventsList);
    }, [auth])

    React.useEffect(() => {
        let currentScreen = auth.user?.AllScreenList[auth.user.screenIndex];
        let selectedEvent = currentScreen?.events.filter(item => item.eventId === auth.user.eventId)[0]
        if (selectedEvent) {
            setSelectedCard({
                event: selectedEvent,
                screenIndex: selectedEvent?.screenIndex,
            })
            let selectedArrow = currentScreen?.arrowLink.filter(arrowData => arrowData?.eventId === auth.user.eventId);
            setSelectedArrow(selectedArrow)
        }
    }, [auth])

    const cardAddAction = () => {
        setSelectedCard(null)
        props.setRightMenu(true, "selectElements")
    }

    const handleCardSelection = (item, index) => {
        debugger
        setSelectedCard({
            event: item,
            screenIndex: index,
        })
        let elementData = [];
        let currentScreen = auth.user?.AllScreenList[index];
        let selectedArrow = currentScreen?.arrowLink.filter(arrowData => arrowData?.eventId === item.eventId);
        setSelectedArrow(selectedArrow ?? []);
        getElementData(currentScreen?.render?.child, item?.fromId, elementData)
        props.getComponentId(index, elementData?.[0], currentScreen.id, currentScreen.pathId, index, currentScreen.name, item?.eventId);
        props.setRightMenu(false)
    }

    const cardEditAction = (e, item, index) => {
        props.setRightMenu(true, "selectElements", item)
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }


    const cardDeleteAction = (e, item, screenIndex) => {
        let allScreenList = auth.user?.AllScreenList;
        let eventIndex = allScreenList[screenIndex].events.findIndex((event) => {
            return item.eventIndex === event.eventIndex
        })
        if (eventIndex > -1) {
            allScreenList[screenIndex].events.splice(eventIndex, 1);
            auth.setAuth({
                AllScreenList: allScreenList
            })
        }
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
    }

    return <div className={classes.root}>
        <Grid container spacing={2} >
            {
                allAppEvents?.map((item, index) => {
                    return <Grid item sm={4} xs={12} md={3} lg={2} xl={2}   className={classes.cardHolder}>
                        <EventCard
                            cardAction={() => handleCardSelection(item, item.screenIndex)}
                            onEdit={(e) => cardEditAction(e, item, index)}
                            onDelete={(e) => cardDeleteAction(e, item, item.screenIndex)}
                            event={item}
                            selected={item.eventId === selectedCard?.event?.eventId}
                        />
                    </Grid>
                })
            }
            <Grid item sm={4} xs={12} md={3} lg={2} xl={2} className={classes.cardHolder}>
                <InitiateButton
                    cardAction={cardAddAction}
                    allScreenList={props?.allScreenList} />
            </Grid>
        </Grid>
        <Grid container>
            {
                selectedCard && <ActionController
                    setRightMenu={props?.setRightMenu}
                    selectedArrow={selectedArrow}
                    selectedCard={selectedCard}
                    allScreenList={auth.user?.AllScreenList}
                />
            }
        </Grid>
    </div>
}

export default BubbleSetup;