import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import routes from "./routes";
import PrivateRoute from "./private_router";
import { UiBuilder } from "./../screens";
import { Renderer } from "../screens/uiBuilder/components/renderer";
import UiFlow from "./../screens/uiFlow/index";


const RouterApp = (props) => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to={routes.uibuilder} />
        </Route>
        <Route exact path={routes.uibuilder} component={UiBuilder} />
        <PrivateRoute exact path={routes.uiflow} component={UiFlow} />
        <Route exact path={routes.preview} component={Renderer} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
