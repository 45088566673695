import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import useStyles from "../../../screens/uiBuilder/components/propertyspanel/styles";

// const useStyles = makeStyles((theme) => ({}));

export const MuiSelect = (props) => {
  const classes = useStyles(props);
  const { label, value, onChange, options } = props;
  return (
    <FormControl
      variant="outlined"
      fullWidth
      margin={"dense"}
      size="small"
      className={classes.muiselect}
    >
      <InputLabel id="select" className={classes.label}>
        {label}
      </InputLabel>
      <Select
        labelId="select"
        id="select_comp"
        value={value}
        onChange={onChange}
        fullWidth
        label={label}
        className={classes.muiselect}

        // labelWidth={val?.labelWidth ? val?.labelWidth : 0}
      >
        {options.map((item) => {
          return <MenuItem value={item}>{`${item}`}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};

MuiSelect.defaultProps = {
  onChange: () => {},
  label: "Select",
  value: "",
  options: ["option1", "option2"],
};
