import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography } from '@material-ui/core';
import { snakeToPascal } from '../../commonFunction';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { iconsList } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  textInput: {
    '& input': {
      fontSize: (prop) => prop?.fontSize
    },
    '& label': {
      fontSize: (prop) => prop?.fontSize
    }
  }
}));

export const IconSelect = (props) => {
  const classes = useStyles(props);
  const { label, value, onChange } = props;
  const filterOptions = createFilterOptions({
    stringify: (option) => option.split('_').join(' ')
  });

  return (
    <Autocomplete
      id="icon-select"
      freeSolo
      filterOptions={filterOptions}
      options={iconsList}
      value={value ?? null}
      onChange={(e, v) => {
        if (v?.length > 0) {
          let value = {
            type: 'icon',
            iconName: snakeToPascal(v),
            style: {}
          };
          onChange(value);
        } else {
          onChange({});
        }
      }}
      getOptionLabel={(option) => option && snakeToPascal(option)}
      style={{ width: '100%' }}
      className={classes.textInput}
      renderInput={(params) => (
        <TextField
          margin={'dense'}
          {...params}
          label={label}
          variant="outlined"
        />
      )}
    />
  );
};

IconSelect.defaultProps = {
  onChange: () => {},
  label: 'Text Input',
  fontSize: '14px',
  value: ''
};
