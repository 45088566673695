import React, { useEffect } from "react";
import {
  makeStyles,
  Checkbox,
  TextField,
  FormControlLabel,
  Button,
  Paper,
  Divider,
  Grid,
  Typography,
  Switch,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { AuthContext, AlertContext } from "../../../contexts";
// import ConditonRenderer from "./conditonRenderer";
import { GraphQlSupportDisplay } from "../components/graphqlSupport";
import { v4 as uuidv4 } from "uuid";
import { alertProps } from "../../../utils";
import { queryByTestId } from "@testing-library/dom";
import { nestedHandler } from "../../../components/commonFunction";
import {
  SessionManagement,
  DataSource,
  commonDataSources,
  sessionDataSources,
  CustomState
} from "../components";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  panelHead: {
    backgroundColor: "#ebebeb",
    "& .text": {
      backgroundColor: "#fff",
      display: "inline-block",
      padding: 10,
      borderTopRightRadius: 6,
    },
  },
  body: {
    padding: 20,
    paddingTop: 0,
  },
  close: {
    padding: 8,
    position: "absolute",
    right: 0,
  },
  marginTop: {
    marginTop: "6%",
  },
}));

let currentUUID = null;
let ACTIVE_SCREEN_INDEX = null;
let ACTIVE_EVENT_ID = null;
let EXTERNAL_COMPONENT = null;
export function Actions(props) {
  const auth = React.useContext(AuthContext);
  const Alert = React.useContext(AlertContext);

  const SelectedFun = (val, selectVal, type, index, child_index) => {
    let Screen = auth.user.AllScreenList.filter(
      ({ pathId }) => pathId === props.id
    );
    if (type === "checkbox") {
      var is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (is_id_true.length > 0) {
        if (WAction[0]) {
          let data = is_id_true.filter(({ name }) => name === WAction[0].label);
          setIsApiCall(true);
          data[0]["isApiCall"] = true;
          data[0]["isMapped"] = isMapped ? true : false;
          forceUpdate();
        } else {
          let data = is_id_true.filter(({ name }) => name === "");
          setIsApiCall(false);
          if (data.length) {
            data[0]["isApiCall"] = false;
            data[0]["isMapped"] = false;
          } else setIsApiCall(true);
          forceUpdate();
        }
      } else {
        is_id_true[0].isApiCall = true;
      }
    } else if (type === "refreshEnabled") {
      var is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (is_id_true.length > 0) {
        if (WAction[0]) {
          let data = is_id_true.filter(({ name }) => name === WAction[0].label);
          setIsRefreshEnabled(true);
          data[0]["refreshEnabled"] = true;
          forceUpdate();
        } else {
          let data = is_id_true.filter(({ name }) => name === "");
          setIsRefreshEnabled(false);
          if (data.length) data[0]["refreshEnabled"] = false;
          else setIsRefreshEnabled(true);
          forceUpdate();
        }
      } else {
        is_id_true[0].refreshEnabled = true;
      }
    } else if (type === "loggingEnabled") {
      var is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (is_id_true.length > 0) {
        if (actionval.label) {
          let data = is_id_true.filter(({ name }) => name === actionval.label);
          setisLogger({
            status: selectVal,
            severity: null,
            message: null,
          });
          forceUpdate();
          data[0]["isLogger"] = {
            status: selectVal,
            severity: null,
            message: null,
          };
        } else {
          let data = is_id_true.filter(({ name }) => name === "");
          setisLogger({
            status: selectVal,
            severity: null,
            message: null,
          });
          forceUpdate();
          if (data.length)
            data[0]["isLogger"] = {
              status: selectVal,
              severity: null,
              message: null,
            };
          else
            setisLogger({
              status: selectVal,
              severity: null,
              message: null,
            });
        }
      } else {
        is_id_true[0].isLogger = {
          status: false,
          severity: null,
          message: null,
        };
      }
    } else if (type === "loggerSeverity") {
      var is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (is_id_true.length > 0) {
        if (actionval.label) {
          let data = is_id_true.filter(({ name }) => name === actionval.label);
          setisLogger({
            ...isLogger,
            severity: selectVal,
          });
          forceUpdate();
          data[0]["isLogger"] = {
            ...isLogger,
            severity: selectVal,
          };
        } else {
          let data = is_id_true.filter(({ name }) => name === "");
          setisLogger({
            ...isLogger,
            severity: selectVal,
          });
          forceUpdate();
          if (data.length)
            data[0]["isLogger"] = {
              ...isLogger,
              severity: selectVal,
            };
          else
            setisLogger({
              ...isLogger,
              severity: selectVal,
            });
        }
      } else {
        is_id_true[0].isLogger = {
          status: false,
          severity: null,
          message: null,
        };
      }
    } else if (type === "loggerMessage") {
      var is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (is_id_true.length > 0) {
        if (actionval.label) {
          let data = is_id_true.filter(({ name }) => name === actionval.label);
          setisLogger({
            ...isLogger,
            message: selectVal,
          });
          forceUpdate();
          data[0]["isLogger"] = {
            ...isLogger,
            message: selectVal,
          };
        } else {
          let data = is_id_true.filter(({ name }) => name === "");
          setisLogger({
            ...isLogger,
            message: selectVal,
          });
          forceUpdate();
          if (data.length)
            data[0]["isLogger"] = {
              ...isLogger,
              message: selectVal,
            };
          else
            setisLogger({
              ...isLogger,
              message: selectVal,
            });
        }
      } else {
        is_id_true[0].isLogger = {
          status: false,
          severity: null,
          message: null,
        };
      }
    } else if (type === "table") {
      let is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      is_id_true = is_id_true.filter(
        ({ name }) => name === (WAction ? WAction[0]?.label : "")
      );
      if (is_id_true.length) {
        is_id_true.map((val, i) => {
          state[index]["EntityDetails"] = state[index]["EntityDetails"]
            ? state[index]["EntityDetails"]
            : {};
          state[index]["EntityDetails"]["entity"] = selectVal
            ? selectVal.entity
            : null;
          is_id_true[i].apiCalls = state;
        });
      } else {
        state[index]["EntityDetails"] = state[index]["EntityDetails"]
          ? state[index]["EntityDetails"]
          : {};
        state[index]["EntityDetails"]["entity"] = selectVal
          ? selectVal.entity
          : null;
      }
      // is_id_true[0].EntityDetails.entity = selectVal ? selectVal.title : null;
      var getData = Screen[0].events.filter(({ name }) => name === "onChange");
      setState(state);
      forceUpdate();
      seteventData(getData);
    } else if (type === "nifiurl") {
      let is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (typeof WAction !== "undefined" && WAction.length > 0) {
        var getdata = is_id_true.filter(
          ({ name }) => name === (WAction ? WAction[0].label : "")
        );
        getdata.map((val, i) => {
          state[index].NifiUrl = selectVal ? selectVal.label : "";
          getdata[i].apiCalls = state;
        });
        setState(state);
        forceUpdate();
        // getdata[0].NifiUrl = selectVal ? selectVal.label : "";
      } else {
        state[index].NifiUrl = selectVal ? selectVal.label : "";
        setState(state);
        forceUpdate();
      }
      // Screen[0].nifiurl = selectVal ? selectVal.label : "";
    } else if (type == "apiSelection") {
      let is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (
        typeof WAction !== "undefined" &&
        WAction.length > 0 &&
        selectVal?.value
      ) {
        var getdata = is_id_true.filter(
          ({ name }) => name === (WAction ? WAction[0].label : "")
        );
        getdata.map((val, i) => {
          state[index] = {};
          state[index].type = selectVal ? selectVal.value : "";
          if (selectVal.value === "graphQl") {
            state[index].title = "";
            state[index].query = [];
            state[index].mutation = [];
          }
          if (selectVal.value === "api") {
            state[index].column = [];
          }
          getdata[i].apiCalls = state;
        });
        setState(state);
        forceUpdate();
      } else {
        state[index].column = [];
        state[index].type = selectVal ? selectVal.value : "";
        setState(state);
        forceUpdate();
      }
    } else if (type == "qpasId") {
      let is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (
        typeof WAction !== "undefined" &&
        WAction.length > 0 &&
        selectVal?.label
      ) {
        var getdata = is_id_true.filter(
          ({ name }) => name === (WAction ? WAction[0].label : "")
        );
        getdata.map((val, i) => {
          let selected = auth.user.qpasQueries[selectVal.id];
          let type = state[index].type;
          let processed = [];
          try {
            let payload = selected?.requestschema;
            processed = nestedHandler(
              payload?.items
                ? payload.items?.[0]?.properties?.doc ?? payload.items
                : payload
            );
          } catch { }
          state[index] = {
            type: type,
            method: selected?.httpmethod,
            queryId: selected?._id,
            name: selected?.apiname,
            url: selected?.url,
            endPoint: selected?.endpointpath,
            requestType: selected?.httpmethod,
            query: selected?.request,
            column: processed,
          };
        });
        setState(state);
        forceUpdate();
      } else {
        state[index] = { type: state[index].type };
        setState(state);
        forceUpdate();
      }
    } else if (type === "endPoint") {
      let is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (typeof WAction !== "undefined" && WAction.length > 0) {
        var getdata = is_id_true.filter(
          ({ name }) => name === (WAction ? WAction[0].label : "")
        );
        getdata.map((val, i) => {
          state[index].endPoint = selectVal;
          getdata[i].apiCalls = state;
        });
        setState(state);
        forceUpdate();
      } else {
        state[index].type = selectVal;
        setState(state);
        forceUpdate();
      }
    } else if (type === "apiName") {
      let is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (typeof WAction !== "undefined" && WAction.length > 0) {
        var getdata = is_id_true.filter(
          ({ name }) => name === (WAction ? WAction[0].label : "")
        );
        getdata.map((val, i) => {
          state[index].name = selectVal;
          getdata[i].apiCalls = state;
        });
        setState(state);
        forceUpdate();
      }
    } else if (type === "apiTargetEntity") {
      let is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (typeof WAction !== "undefined" && WAction.length > 0) {
        var getdata = is_id_true.filter(
          ({ name }) => name === (WAction ? WAction[0].label : "")
        );
        getdata.map((val, i) => {
          state[index].targetEntity = selectVal;
          getdata[i].apiCalls = state;
        });
        setState(state);
        forceUpdate();
      }
    } else if (type === "apiColumnFieldAdd") {
      let is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (typeof WAction !== "undefined" && WAction.length > 0) {
        var getdata = is_id_true.filter(
          ({ name }) => name === (WAction ? WAction[0].label : "")
        );
        getdata.map((val, i) => {
          state[index].column.push({});
          getdata[i].apiCalls = state;
        });
        setState(state);
        forceUpdate();
      } else {
        state[index].column.push({});
        setState(state);
        forceUpdate();
      }
    } else if (type === "endpointField") {
      let is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (typeof WAction !== "undefined" && WAction.length > 0) {
        var getdata = is_id_true.filter(
          ({ name }) => name === (WAction ? WAction[0].label : "")
        );
        getdata.map((val, i) => {
          const currColumn = state[index].column[child_index];
          const value = currColumn[Object.keys(currColumn)[0]];
          state[index].column[child_index] = {};
          state[index].column[child_index][selectVal] = value ?? "";
          getdata[i].apiCalls = state;
        });
        setState(state);
        forceUpdate();
      } else {
        state[index].column[child_index][selectVal] = "";
        setState(state);
        forceUpdate();
      }
    } else if (type === "endpointValue") {
      if (selectVal) {
        let is_id_true = Screen[0].events.filter(
          ({ fromId }) => fromId === val.id
        );
        if (typeof WAction !== "undefined" && WAction.length > 0) {
          var getdata = is_id_true.filter(
            ({ name }) => name === (WAction ? WAction[0].label : "")
          );
          getdata.map((val, i) => {
            const currColumn = state[index].column[child_index];
            state[index].column[child_index][Object.keys(currColumn)[0]] =
              selectVal.fromId;
            getdata[i].apiCalls = state;
          });
          setState(state);
          forceUpdate();
        }
      } else {
        const currColumn = state[index].column[child_index];
        state[index].column[child_index][Object.keys(currColumn)[0]] = {};
        setState(state);
        forceUpdate();
      }
    } else if (type === "custom-state") {
      var is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (is_id_true.length > 0) {
        let data = is_id_true.filter(({ name }) => name === WAction[0].label);
        data[0]["customState"] = selectVal
      }
    } else if (type === "isaddnew") {
      var is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (is_id_true.length > 0) {
        let data = is_id_true.filter(({ name }) => name === WAction[0].label);
        if (!data[0]["isaddnew"]) {
          setIsAddNew(true);
          data[0]["isaddnew"] = true;
          forceUpdate();
        } else {
          setIsAddNew(false);
          data[0]["isaddnew"] = false;
          data[0]["stateType"] = "";
          forceUpdate();
        }
      } else {
        is_id_true[0].isaddnew = false;
      }
      return val
    } else if (type === "isexternal") {
      var is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (is_id_true.length > 0) {
        let data = is_id_true.filter(({ name }) => name === WAction[0].label);
        if (!data[0]["isexternal"]) {
          setisexternal(true);
          data[0]["isexternal"] = true;
          forceUpdate();
        } else {
          // let data = is_id_true.filter(({ name }) => name === "");
          setisexternal(false);
          data[0]["isexternal"] = false;
          data[0]["stateType"] = "";
          // if (data.length)
          // else setisexternal(true);
          forceUpdate();
        }
      } else {
        is_id_true[0].isexternal = true;
      }
      return val
    } else if (type === "isDelete") {
      var is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (is_id_true.length > 0) {
        let data = is_id_true.filter(({ name }) => name === WAction[0].label);
        if (!data[0]["isDelete"]) {
          setIsDelete(true);
          data[0]["isDelete"] = true;
          forceUpdate();
        } else {
          setIsDelete(false);
          data[0]["isDelete"] = false;
          forceUpdate();
        }
      } else {
        is_id_true[0].isDelete = true;
      }
      return val
    } else if (type === "isInsertNew") {
      var is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (is_id_true.length > 0) {
        let data = is_id_true.filter(({ name }) => name === WAction[0].label);
        if (!data[0]["isInsertNew"]) {
          setisInsertNew(true);
          data[0]["isInsertNew"] = true;
          forceUpdate();
        } else {
          setisInsertNew(false);
          data[0]["isInsertNew"] = false;
          forceUpdate();
        }
      } else {
        is_id_true[0].isDelete = true;
      }
      return val
    }
    else if (type === "isexternalObj") {
      var is_id_true = Screen[0].events.filter(
        ({ fromId }) => fromId === val.id
      );
      if (is_id_true.length > 0) {
        let data = is_id_true.filter(({ name }) => name === WAction[0].label);
        setisexternalobj(selectVal)
        data[0]["stateType"] = selectVal[0].val;
        forceUpdate();
      } else {
        // is_id_true[0].isexternal = true;
      }
      return val
    }

    else {
      if (
        typeof Screen[0].events !== "undefined" &&
        Screen[0].events.length > 0
      ) {
        let is_id_true = Screen[0].events.filter(
          ({ fromId }) => fromId === val.id
        );
        if (is_id_true.length) {
          let eventobj = {
            name: selectVal ? selectVal[selectVal.length - 1]?.label : "",
            fromId: val.id,
            CName: val.properties["aliasName"]
              ? val.properties["aliasName"]
              : val.properties["aliasName"],
            value: {
              type: val.name === "TapSelect" ? 1 : 2,
            },
            EntityDetails: {},
            action: [],
            component: val?.component, //set componentType
            eventId: uuidv4(),
          };
          if (val.name === 'Autocomplete') {
            eventobj["stateData"] = val?.properties?.src;
          }
          let eventIndex = Screen[0].events.findIndex(
            (item) => item.fromId === val.id
          );
          if (selectVal[0]?.label) Screen[0].events[eventIndex] = eventobj;
          else Screen[0].events.splice(eventIndex, 1);
        } else {
          let eventobj = {
            name: selectVal ? selectVal[selectVal.length - 1]?.label : "",
            fromId: val.id,
            CName: val.properties["aliasName"]
              ? val.properties["aliasName"]
              : val.properties["aliasName"],
            value: {
              type: val.name === "TapSelect" ? 1 : 2,
            },
            EntityDetails: {},
            action: [],
            component: val?.component, ///set componentType
            eventId: uuidv4(),
          };
          if (val.name === 'Autocomplete') {
            eventobj["stateData"] = val.properties.src;
          }
          Screen[0].events.push(eventobj);
        }
      } else {
        Screen[0].events = [];
        let eventobj = {
          name: selectVal ? selectVal[selectVal.length - 1]?.label : "",
          fromId: val.id,
          CName: val?.properties?.["aliasName"]
            ? val?.properties?.["aliasName"]
            : val?.properties?.["aliasName"],
          value: {
            type: val.name === "TapSelect" ? 1 : 2,
          },
          EntityDetails: {},
          action: [],
          component: val?.component, ///set componentType
          eventId: uuidv4(),
        };
        if (val.name === 'Autocomplete') {
          eventobj["stateData"] = val.properties.src;
        }
        Screen[0].events.push(eventobj);
      }
    }
    return val;
  };

  const findArr = (
    arr,
    key,
    listKey,
    nextLoop,
    selectVal,
    type,
    index,
    child_index
  ) => {
    arr.map((val, i) => {
      if (val?.[listKey] === key) {
        arr[i] = SelectedFun(val, selectVal, type, index, child_index);
        return false;
      }
      if (nextLoop && val?.[nextLoop]) {
        findArr(
          val[nextLoop],
          key,
          listKey,
          nextLoop,
          selectVal,
          type,
          index,
          child_index
        );
      }
      return false;
    });
    return arr;
  };

  const Actions = [
    { label: "onChange", val: "onChange" },
    { label: "onSubmit", val: "onSubmit" },
    { label: "onClick", val: "onClick" },
    { label: "onfocus", val: "onfocus" },
    { label: "ondblclick", val: "ondblclick" },
    { label: "onmousedown", val: "onmousedown" },
    { label: "onmousemove", val: "onmousemove" },
    { label: "onLoad", val: "onLoad" },
    { label: 'stateCreation', val: "stateCreation" }
  ];
  const Nifi = [
    { label: "upsertVacine", val: "upsertVacine" },
    { label: "readVacine", val: "readVacine" },
  ];
  const Severity = [
    { label: "trace", val: "trace" },
    { label: "debug", val: "debug" },
    { label: "info", val: "info" },
    { label: "warn", val: "warn" },
    { label: "error", val: "error" },
  ];
  const isExternalObj = [
    { label: "array", val: "array" },
    { label: "object", val: "object" },
  ]
  const [conditionEnabler, setconditionEnabler] = React.useState(false);
  const [NifiUrl] = React.useState(Nifi);
  const [options] = React.useState(Actions);
  const [state, setState] = React.useState([]);
  const [qpasQueriesResponse, setQpasQueriesResponse] = React.useState([]);
  const [customState, setCustomState] = React.useState([])
  const [isMapped, setIsmapped] = React.useState(false);
  const [isApiCall, setIsApiCall] = React.useState(false);
  const [isLogger, setisLogger] = React.useState({
    status: false,
    severity: null,
    message: null
  });
  const [isRefreshEnabled, setIsRefreshEnabled] = React.useState(null);
  const [isAddNew, setIsAddNew] = React.useState(null);
  const [isexternal, setisexternal] = React.useState(null);
  const [isInsertNew, setisInsertNew] = React.useState(null);
  const [isDelete, setIsDelete] = React.useState(null)
  const [isexternalobj, setisexternalobj] = React.useState([]);
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  // const [flowCount, setFlowCount] = React.useState(1);
  const [actionval, setActionval] = React.useState([]);
  const [componentval, setcomponentval] = React.useState([]);
  const [componentinputdis, setcomponentinputdis] = React.useState(true);
  const [graphQl, setGraphQl] = React.useState([]);
  ///all seperate events
  const [listernerEvents, setAllListenerEvents] = React.useState([]);
  const [historyData, setAllHistoryData] = React.useState([]);
  const [selfEvents, setAllSelfEvents] = React.useState([]);

  // const [isapicall, setisapicall] = React.useState(true);
  // const [ischeckbox, setischeckbox] = React.useState(false);
  const [entities, setentities] = React.useState([]);
  const [entitiescol, setentitiescol] = React.useState([]);
  const [eventData, seteventData] = React.useState([]);
  const [WAction, setWAction] = React.useState([]);
  const indicesRef = React.useRef(null);

  const ComponentlistVar = auth.user.AllScreenList.filter(
    ({ type }) => type === "Component"
  );
  const classes = useStyles();

  const deleteNifiApi = (i) => {
    let Screen = auth.user.AllScreenList.filter(
      ({ pathId }) => pathId === props.id
    );
    if (typeof WAction !== "undefined" && WAction.length > 0) {
      var allData = Screen[0].events.filter(
        ({ name }) => name === WAction[0].label
      );
      let getData = allData.filter(
        ({ fromId }) => fromId === auth.user.componentId
      );
      state.splice(i, 1);
      getData.map((val, i) => {
        getData[i].apiCalls = state;
        ////remove is apicalls if all api's condition removed
        if (!state.length) {
          delete getData[i].isApiCall;
        }
      });
      setState(state);
      forceUpdate();
    } else {
      state.splice(0);
      setState(state);
      forceUpdate();
    }
  };


  const updateQpasPayload = (v, indices, index) => {
    debugger
    var updateorder = indices
      .split(".")
      .map((str) => Number(str))
      .slice(1);
    var data = state[index].column;

    updateorder.forEach((item, index) => {
      if (data?.column) {
        data = data.column[item];
      } else data = data[item];
    });
    data.value = v;
    data.fromId = v.fromId;
    //for dropdown label,value
    data.defaultKey = v?.defaultKey ?? null;
    forceUpdate();
  };

  const updateQpasExternalPayload = (v, indices, index, keyName) => {
    var updateorder = indices
      .split(".")
      .map((str) => Number(str))
      .slice(1);
    var data = state[index].column;

    updateorder.forEach((item, index) => {
      if (data?.column) {
        data = data.column[item];
      } else data = data[item];
    });
    data[keyName] = v
    forceUpdate();
  };

  React.useEffect(() => {
    ///store active screen index
    let screenIndex = auth.user.AllScreenList?.findIndex(
      ({ pathId }) => pathId === props.id
    );
    if (screenIndex !== 1) ACTIVE_SCREEN_INDEX = screenIndex;
  }, []);

  const updateSession = (v, indices) => {
    auth.setAuth({
      ...auth.user,
      storageManagement: v,
    });
  };

  const getAllQueryPayload = (payload, queryName, queryIndex, parentIndex) => {
    parentIndex = parentIndex ? parentIndex : "0";
    return (
      payload &&
      payload.map((item, index) => {
        let currentIndex = parentIndex + "." + index;
        if (item.type === "array") {
          if (item?.selectedComponent?.screenName) EXTERNAL_COMPONENT = item?.selectedComponent;
          return (
            <>
              <div style={{ fontWeight: 500 }}>
                {item.name.slice(0, 1).toUpperCase() + item.name.slice(1)}
                <div style={{ marginLeft: 15 }}>
                  <div>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item?.isExternal}
                          onChange={(e) => {
                            item["isExternal"] = !item?.isExternal;
                            forceUpdate()
                            // updateQpasExternalPayload(!item?.isExternal, currentIndex, queryIndex, "isExternal")
                          }}
                          name="isExternal"
                        />
                      }
                      label="Is External"
                    />
                  </div>
                  <Autocomplete
                    value={item?.selectedComponent ? { name: item?.selectedComponent?.screenName } : null}
                    onChange={(event, newValue) => {
                      item["selectedComponent"] = { screenName: newValue.name, screenId: newValue.id };
                      forceUpdate()
                      // updateQpasExternalPayload({ screenName: newValue.name, screenId: newValue.id }, currentIndex, queryIndex, "selectedComponent")
                    }}
                    size={"small"}
                    options={auth.user.AllScreenList.filter(item => item.type !== "Screen" && !item.ignore) ?? []}
                    filterSelectedOptions
                    disableClearable
                    getOptionLabel={(options) => options?.name}
                    style={{ width: "80%" }}
                    renderInput={(params) => (
                      <TextField {...params} label="Severity" variant="outlined" />
                    )}
                  />
                </div>
              </div>
              <div style={{ marginLeft: 15 }}>
                {getAllQueryPayload(
                  item.column,
                  queryName,
                  queryIndex,
                  currentIndex
                )}
              </div>
            </>
          );
        } else {
          let externalComponentData = []
          if (EXTERNAL_COMPONENT) {
            let externalComponent = auth.user.AllScreenList.filter(item => item.name === EXTERNAL_COMPONENT?.screenName);
            if (externalComponent?.length) {
              externalComponentData = getAllListenerEvents(externalComponent[0])
            }
          }
          return (
            <DataSource
              label={"selected key"}
              keyName={item?.name}
              isKeyEditable={false}
              value={item?.value}
              index={currentIndex}
              onChange={(value, indices) => {
                item.value = value;
                item.fromId = value?.fromId;
                item.defaultKey = value?.defaultKey ?? null;
                forceUpdate()
                // updateQpasPayload(value, indices, queryIndex)              
              }}
              listernerEvents={listernerEvents}
              qpasQueriesResponse={{
                screenLevel:
                  qpasQueriesResponse?.screenLevel?.filter(
                    (item) => item.label !== queryName
                  ) ?? [],
                eventLevel:
                  qpasQueriesResponse?.eventLevel?.filter(
                    (item) => item.label !== queryName
                  ) ?? [],
              }}
              dataSources={commonDataSources}
              externalDataSource={externalComponentData}
              isEndDecoration={true}
              selfEvents={selfEvents}
              historyData={historyData}
              sessionList={auth.user?.storageManagement?.sessionStorage ?? []}
              localList={auth.user?.storageManagement?.localStorage ?? []}
              cookiesList={auth.user?.storageManagement?.cookies ?? []}
            />
          );
        }
      })
    );
  };

  const handleCustomState = (state) => {
    let Screen = auth.user.AllScreenList.filter(
      ({ pathId }) => pathId === props.id
    );
    findArr(
      Screen[0].render.child,
      auth.user.componentId,
      "id",
      "child",
      state,
      "custom-state"
    );
  }

  const findChildParentIndices = (data, componentId) => {
    if (data.mappedIndices) {
      indicesRef.current = {
        mappedIndices: data.mappedIndices,
        mappedEntity: data.mappedEntity,
      };
    }
    if (data?.id === componentId) {
      if (indicesRef.current) {
        // mappRequiredEntity(indicesRef.current);
        setIsmapped(true);
        mappRequiredIndices(indicesRef.current);
      }
      return;
    } else if (data?.child) {
      data.child.map((item) => {
        findChildParentIndices(item, componentId);
      });
    }
  };

  const mappRequiredIndices = (selectedComponent) => {
    let qpasQueries = auth.user.qpasQueries;
    let qpasEntities = [];
    qpasQueries.forEach((item) => {
      let payload = [];
      try {
        payload = item.responseschema;
      } catch {
        return null;
      }
      qpasEntities.push({
        label: item.apiname,
        id: item._id,
        column: nestedHandler(payload?.items ? payload.items : payload),
      });
    });
    var selectedValue = qpasEntities.filter(
      (item) => item.label === selectedComponent.mappedEntity
    )[0];

    let indices = selectedComponent?.mappedIndices?.split(".");
    let currentKey = indices[0];
    if (currentKey) {
      let currentColumn = selectedValue?.column?.filter(
        (currentColumn, i) =>
          i === parseInt(currentKey) || currentColumn.name === currentKey
      );
      if (currentColumn?.length) {
        currentColumn = currentColumn[0];
        var pushArray = [];
        let a = currentColumn?.column?.map((val, i) => {
          var datajson = {
            fromId: "self",
            CName: `data.${val.name}`,
          };
          pushArray.push(datajson);
        });
        setAllSelfEvents(pushArray);
      }
    }
  };

  const handleGraphQl = (data) => {
    setGraphQl(data);
    forceUpdate();
  };

  const addNewFlow = () => {
    state.push({});
    setState(state);
    setIsApiCall(true);
    // setischeckbox(newValue);
    let Screen = auth.user.AllScreenList.filter(
      ({ pathId }) => pathId === props.id
    );
    findArr(
      Screen[0].render.child,
      auth.user.componentId,
      "id",
      "child",
      true,
      "checkbox"
    );
    forceUpdate();
  };

  useEffect(() => {
    let activeScreen = auth.user.AllScreenList?.filter(
      ({ pathId }) => pathId === props.id
    )[0];
    // // check events for loggger
    let events = activeScreen?.events?.filter(
      (val) => val.fromId === auth.user.componentId
    );
    if (events?.[0]) {
      setWAction([{ ...events[0], label: events[0].name }]);
    }
    if (events?.[0] && events[0]?.isLogger) {
      setisLogger({
        status: events[0].isLogger.status,
        severity: events[0].isLogger.severity,
        message: events[0].isLogger.message,
      });
    } else {
      setisLogger({
        status: false,
        severity: null,
        message: null,
      });
    }

    setCustomState(events?.[0]?.customState ?? [])
    setIsAddNew(events?.[0]?.isaddnew ? true : false);
    setIsDelete(events?.[0]?.isDelete ? true : false);
    setisInsertNew(events?.[0]?.isInsertNew ? true : false)
    setisexternal(events?.[0]?.isexternal);
    if (events?.[0]?.stateType) {
      setisexternalobj([{ label: events[0].stateType, val: events[0].stateType }]);
    } else setisexternal(null)
    // // check events and set for apicall
    events = activeScreen?.events?.filter(
      (val) => val.fromId === auth.user.componentId && val.isApiCall
    );
    if (events?.[0]?.refreshEnabled) setIsRefreshEnabled(true);
    if (events?.[0]?.apiCalls) {
      setWAction([{ ...events[0], label: events[0].name }]);
      setState(events[0]?.apiCalls);
      let getData = activeScreen.events.filter(
        ({ name }) => name === "onChange"
      );
      seteventData(getData);
      setIsApiCall(true);
    } else {
      ///to remove actions on change
      setState([]);
      seteventData([]);
      setIsApiCall(false);
    }

    // let historyVariables = [];
    // activeScreen?.history &&
    //   activeScreen.history.filter((item) => {
    //     if (item.component === "Dropdown") {
    //       historyVariables.push(

    //       );
    //     } else if (item.name === "onChange") {
    //       listernerEvents.push({ fromId: item.fromId, CName: item.CName });
    //     }
    //   });

    // get and set all event data's
    let listernerEvents = getAllListenerEvents(activeScreen)
    setAllListenerEvents(listernerEvents);

    // //get history datas
    let historyData = [];
    let histories = activeScreen?.history;
    histories &&
      Object.keys(histories).map((screenName) => {
        histories[screenName].map((item) => {
          historyData.push({
            fromId: `history`,
            CName: `${screenName.replace(" ", "_")}_${item.name}`,
            newVariable: item?.newVariable ?? null
          });
        });
      });
    // // set all history data's
    setAllHistoryData(historyData);

    let apicallDetails = {
      screenLevel: [],
      eventLevel: [],
    };

    // //differentiate state(screen level) and reponse events(event level)
    activeScreen?.events &&
      activeScreen?.events.length &&
      activeScreen.events.filter((event) => {
        if (event?.apiCalls && event?.apiCalls?.length) {
          event.apiCalls.map((item) => {
            if (event.fromId === WAction[0]?.fromId)
              apicallDetails.eventLevel.push(item.name);
            else apicallDetails.screenLevel.push(item.name);
          });
        }
      });

    let qpasQueriesResponse = {
      screenLevel: [],
      eventLevel: [],
    };

    // //generating qpas query list based on above generated differentiator
    auth?.user?.qpasQueries?.length &&
      auth.user.qpasQueries.forEach((item) => {
        if (item?.responseschema) {
          try {
            let payload = item?.responseschema;
            let qpasQueries = {
              label: item.apiname,
              id: item._id,
              payload: nestedHandler(
                payload?.items
                  ? payload.items?.[0]?.properties?.doc ?? payload.items
                  : payload
              ),
            };
            if (apicallDetails.eventLevel.indexOf(qpasQueries.label) > -1) {
              qpasQueriesResponse.eventLevel.push(qpasQueries);
            }
            if (apicallDetails.screenLevel.indexOf(qpasQueries.label) > -1) {
              qpasQueriesResponse.screenLevel.push(qpasQueries);
            }
          } catch (e) {
            console.log(e)
          }
        }
      });
    // set all qpasqeries after screen and event level differentiation
    setQpasQueriesResponse(qpasQueriesResponse);

    findChildParentIndices(activeScreen?.render, auth.user.componentId);
    console.log(selfEvents)
  }, [actionval, auth.user.componentId]);

  const getAllListenerEvents = (activeScreen) => {
    let listernerEvents = [];
    activeScreen?.events &&
      activeScreen.events.filter((item) => {
        if (item.component === "Dropdown") {
          listernerEvents.push(
            { fromId: item.fromId, CName: item.CName },
            {
              fromId: item.fromId,
              CName: `${item?.CName ?? " - "}.label`,
              defaultKey: "label",
            },
            {
              fromId: item.fromId,
              CName: `${item?.CName ?? " - "}.value`,
              defaultKey: "value",
            }
          );
        } else if (item.component === "Autocomplete") {
          debugger
          listernerEvents.push({ fromId: item.fromId, CName: item?.CName ?? " - " })
          let stateData = []
          if (item?.stateData) {
            stateData = auth.user.qpasQueries.filter(item1 => item1.apiname === item.stateData.label)
            let state = stateData[0]?.responseschema?.properties?.result?.items[0]?.required
            if (state?.length) state.map(key => {
              listernerEvents.push({
                fromId: item.fromId,
                CName: `${item?.CName ?? " - "}.${key}`,
                defaultKey: `${key}`,
              })
            })
          } else {
            listernerEvents.push(
              {
                fromId: item.fromId,
                CName: `${item?.CName ?? " - "}.label`,
                defaultKey: "label",
              },
              {
                fromId: item.fromId,
                CName: `${item?.CName ?? " - "}.value`,
                defaultKey: "value",
              }
            );
          }
        }
        else if (item.name === "onChange" || item.name === "onClick" || item.name === "stateCreation") {
          listernerEvents.push({ fromId: item.fromId, CName: item?.CName ?? " - " });
        }
      });
    return listernerEvents;
  }

  // // condition switch handler
  const handleConditonChange = () => {
    ///adding decsion box
    if (actionval?.label && isApiCall) {
      if (isApiCall) {
        if (!conditionEnabler) {
          onAddCondition();
          setconditionEnabler(!conditionEnabler);
        } else {
          viewChange();
          return;
        }
      } else {
        props.context.setSnack({
          ...props.context,
          open: true,
          severity: alertProps.severity.error,
          msg: "api calls not selected for action",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.center,
        });
      }
    } else {
      props.context.setSnack({
        ...props.context,
        open: true,
        severity: alertProps.severity.error,
        msg: "select actions to add decision box",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.center,
      });
    }
  };

  const viewChange = () => {
    if (conditionEnabler) props.setdecisionPanel(true);
  };

  const onAddCondition = () => {
    debugger
    var extendData = auth.user.AllScreenList;
    ////create decision handler screen if not already enabled
    if (auth.user.AllScreenList[props.index + 1]?.type !== "decisionHandler") {
      auth.user.AllScreenList.forEach((screen, index) => {
        if (props.index < index) {
          screen.index += 1;
        }
      })
      extendData.splice(props.index + 1, 0, {
        id: uuidv4(),
        type: "decisionHandler",
        name: "decisionHandler",
        name: "Feed",
        metadataid: localStorage.metaDataId,
        arrowLink: [],
        index: auth.user.AllScreenList[props.index].index + 1,
        ignore: true,
        render: {
          child: [
            {
              type: "component",
              component: "Container",
              component_type: "qdm",
              frameWork: "qdm",
              name: "Container",
              id: "LaJPf",
              properties: {
                name: "container",
                fluid: false,
              },
              inlineStyles: {
                width: "300px",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              },
              child: [],
            },
          ],
        },
        version: auth.user.version
      });
    }
    var Data = extendData[props.index + 1];
    /// create decision box
    currentUUID = auth.user.componentDecisionId
      ? auth.user.componentDecisionId
      : uuidv4();
    Data.render.child[0].child.push({
      type: "component",
      component: "DecisionBox",
      component_type: "qdm",
      frameWork: "qdm",
      name: "DecisionBox",
      id: currentUUID,
      attr: null,
      properties: { name: "Decision Box" },
      inlineStyles: {
        marginTop: 100,
        backgroundColor: "white",
        marginLeft: Data.render.child[0].child.length % 2 === 1 ? 80 : 15,
        width: 120,
        height: 120,
        textAlign: "center",
        fontSize: 20,
        fontWeight: 500,
        border: "solid 4px rgb(0, 122, 255)",
      },
    });
    /// create descision circuit
    var UpdateJson = {
      id: currentUUID,
      arrowSource: auth.user.componentId,
      arrowTarget: currentUUID,
      pathId: "/",
      label: "/",
      conditionType: null,
    };
    Data.arrowLink.push(UpdateJson);
    props.updateNewChildComponent(
      currentUUID,
      auth.user.componentId,
      extendData[props.index].id
    );
    auth.setAuth({
      ...auth.user,
      AllScreenList: auth.user.AllScreenList,
      componentDecisionId: currentUUID,
      isEdited: true,
    });
    props.setdecisionPanel(true);
  };

  useEffect(() => {
    if (
      auth.user.componentDecisionId &&
      auth.user.AllScreenList[props.index + 1]?.type === "decisionHandler"
    ) {
      setconditionEnabler(true);
    } else {
      setconditionEnabler(false);
    }
  }, [auth.user]);

  useEffect(() => {
    var Screenval = auth.user.AllScreenList.filter(
      ({ pathId }) => pathId === props.id
    );
    if (
      typeof Screenval[0].events !== "undefined" &&
      Screenval[0].events.length > 0
    ) {
      const getSelectedEvents = (evt, id) => {
        let arr = [];
        // eslint-disable-next-line
        evt.map((val) => {
          if (val.fromId === id) {
            arr.push({ label: val.name, value: val.name });
          }
        });
        if (arr.length) {
          return arr[0];
        }
        return arr;
      };
      if (Screenval[0].events) {
        setcomponentinputdis(false);
      }
      setActionval(
        getSelectedEvents(Screenval[0].events, auth.user.componentId)
      );
    }
    if (
      typeof Screenval[0].models !== "undefined" &&
      Screenval[0].models.length > 0
    ) {
      setentities(
        Array.isArray(Screenval[0].models[0].EntityDetails)
          ? Screenval[0].models[0].EntityDetails
          : [Screenval[0].models[0].EntityDetails]
      );
    }

    // eslint-disable-next-line
  }, [auth.user.componentId, actionval?.label]);

  const dropDownChange = (event, newValue, type) => {
    if (type === "Actions") {
      setActionval(newValue[0]);
      let Screen = auth.user.AllScreenList.filter(
        ({ pathId }) => pathId === props.id
      );
      findArr(
        Screen[0].render.child,
        auth.user.componentId,
        "id",
        "child",
        newValue,
        type
      );
      if (newValue) {
        setcomponentinputdis(false);
        // if (actionval.length === 1) {

        // }
        if (newValue.length === 1) {
          setWAction(newValue);
        }
      } else {
        setcomponentinputdis(true);
        setcomponentval([]);
      }
      ///remove on change event and current event present
      if ((auth.user.componentDecisionId && actionval?.label) || actionval) {
        // setIsApiCall(false)
        ///automate and remove all decisions of the component
        var Data = auth.user.AllScreenList[props.index + 1];
        var arrowLinks = auth.user.AllScreenList[props.index + 1]?.arrowLink;
        arrowLinks = arrowLinks?.filter(
          (item) => item.id !== auth.user.componentDecisionId
        );
        if (arrowLinks) {
          Data.arrowLink.splice(0);
          Data["arrowLink"].push(...arrowLinks);
        }

        ///delete child
        let body = Data?.render?.child[0]?.child;
        var base_child_index = body?.findIndex(
          (item) => item.id === auth.user.componentDecisionId
        );
        ///safe trip if component decision id failed to become null
        if (body) {
          if (
            body.length &&
            auth.user.AllScreenList[props.index + 1] &&
            auth.user.AllScreenList[props.index + 1]?.type === "decisionHandler"
          ) {
            ///if last element delete child
            auth.user.AllScreenList.splice(props.index + 1, 1);
          } else {
            //else delete only found child
            // body.splice(base_child_index, 1);
          }
        }
        auth.setAuth({
          ...auth.user,
          AllScreenList: auth.user.AllScreenList,
          componentDecisionId: null,
          isEdited: true,
        });
        sessionStorage.setItem(
          "arrayData",
          JSON.stringify(auth.user.AllScreenList)
        );
      }
    } else if (type === "components") {
      setcomponentval(newValue);
    } else if (type === "checkbox") {
      if (!isApiCall) {
        setIsApiCall(true);
        // setischeckbox(newValue);
        let Screen = auth.user.AllScreenList.filter(
          ({ pathId }) => pathId === props.id
        );
        findArr(
          Screen[0].render.child,
          auth.user.componentId,
          "id",
          "child",
          newValue,
          type
        );
      } else {
        setState([{}]);
        seteventData([]);
        setIsApiCall(false);
      }
    } else if (type === "WActions") {
      setWAction(newValue);
    } else if (
      type === "loggingEnabled" ||
      type === "loggerSeverity" ||
      type === "loggerMessage" ||
      type === "isaddnew" ||
      type === "isexternal" ||
      type === "isexternalObj" ||
      type === "isDelete" ||
      type === "isInsertNew"
    ) {
      let Screen = auth.user.AllScreenList.filter(
        ({ pathId }) => pathId === props.id
      );
      findArr(
        Screen[0].render.child,
        auth.user.componentId,
        "id",
        "child",
        newValue,
        type
      );
    } else if (type === "refreshEnabled") {
      let Screen = auth.user.AllScreenList.filter(
        ({ pathId }) => pathId === props.id
      );
      findArr(
        Screen[0].render.child,
        auth.user.componentId,
        "id",
        "child",
        newValue,
        type
      );
      setIsRefreshEnabled(newValue);
    }
  };

  const dropDownEntities = (event, newValue, type, entitiecolName, index) => {
    let Screen = auth.user.AllScreenList.filter(
      ({ pathId }) => pathId === props.id
    );
    if (type === "col") {
      if (typeof WAction !== "undefined" && WAction.length > 0) {
        var allData = Screen[0].events.filter(
          ({ name }) => name === WAction[0].label
        );
        let getData = allData.filter(
          ({ fromId }) => fromId === auth.user.componentId
        );
        state[index]["EntityDetails"] = state[index]["EntityDetails"]
          ? state[index]["EntityDetails"]
          : {};
        state[index]["EntityDetails"]["column"] = state[index].EntityDetails
          .column
          ? state[index].EntityDetails.column
          : [];
        if (newValue) {
          state[index]["EntityDetails"]["column"].push({
            [entitiecolName]: newValue.fromId,
          });
        } else {
          state[index]["EntityDetails"]["column"] = state[index][
            "EntityDetails"
          ]["column"].filter((val) => Object.keys(val)[0] != entitiecolName);
        }
        getData.map((val, i) => {
          getData[i].apiCalls = state;
        });
        setState(state);
        forceUpdate();
        // if (getData[0].EntityDetails.column) {
        //   getData[0].EntityDetails.column.push({
        //     [entitiecolName]: newValue.fromId,
        //   });
        // } else {
        //   getData[0].EntityDetails.column = [];
        //   getData[0].EntityDetails.column.push({
        //     [entitiecolName]: newValue.fromId,
        //   });
        // }
      } else {
        let getData = Screen[0].events.filter(({ name }) => name === "onClick");
        if (getData[0].EntityDetails.column) {
          getData[0].EntityDetails.column.push({
            [entitiecolName]: newValue.fromId,
          });
        } else {
          getData[0].EntityDetails.column = [];
          getData[0].EntityDetails.column.push({
            [entitiecolName]: newValue.fromId,
          });
        }
      }
    } else if (
      type === "nifiurl" ||
      type === "apiSelection" ||
      type === "endPoint" ||
      type === "apiColumnFieldAdd" ||
      type === "endpointField" ||
      type === "endpointValue" ||
      type === "apiName" ||
      type === "apiTargetEntity" ||
      type === "qpasId"
    ) {
      findArr(
        Screen[0].render.child,
        auth.user.componentId,
        "id",
        "child",
        newValue,
        type,
        index,
        entitiecolName
      );
    } else {
      findArr(
        Screen[0].render.child,
        auth.user.componentId,
        "id",
        "child",
        newValue,
        type,
        index
      );
      var removeArr = [
        "id",
        "createdby",
        "createddate",
        "updatedby",
        "updateddate",
        "activestatus",
      ];
      let filteredArray = newValue?.column.filter(function (itm) {
        return removeArr.indexOf(itm.name) === -1;
      });
      state[index].columnsList = filteredArray;
      setState(state);
      if (filteredArray) {
        setentitiescol(filteredArray);
      } else {
        setentitiescol([]);
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Autocomplete
          className={classes.marginTop}
          value={actionval ? actionval : null}
          onChange={(event, newValue) => {
            let value = [newValue];
            dropDownChange(event, value, "Actions");
          }}
          size={"small"}
          options={options}
          filterSelectedOptions
          getOptionLabel={(options) => options.label}
          renderInput={(params) => (
            <TextField {...params} label="Select Actions" variant="outlined" />
          )}
        />
        {/* is refresh End*/}
        <FormControlLabel
          control={
            <Checkbox
              checked={isRefreshEnabled}
              onChange={(e, val) =>
                actionval?.label
                  ? dropDownChange(e, val, "refreshEnabled")
                  : Alert.setSnack({
                    ...Alert,
                    open: true,
                    severity: alertProps.severity.error,
                    msg: "select actions to set refresh",
                    vertical: alertProps.vertical.top,
                    horizontal: alertProps.horizontal.center,
                  })
              }
              name="isRefresh"
            />
          }
          label="Is Refresh"
        />
        {/* is refrese END */}
        {/* logger block start */}
        <FormControlLabel
          control={
            <Checkbox
              checked={isLogger.status}
              onChange={(e, val) =>
                actionval?.label
                  ? dropDownChange(e, val, "loggingEnabled")
                  : Alert.setSnack({
                    ...Alert,
                    open: true,
                    severity: alertProps.severity.error,
                    msg: "select actions to set logger",
                    vertical: alertProps.vertical.top,
                    horizontal: alertProps.horizontal.center,
                  })
              }
              name="isLogger"
            />
          }
          label="Is Logger"
        />
        {/* Add New block */}
        <FormControlLabel
          control={
            <Checkbox
              checked={isAddNew}
              onChange={(e, val) =>
                dropDownChange(e, val, "isaddnew")
              }
              name="isaddnew"
            />
          }
          label="is Add New"
        />
        {/* Add New block */}
        <FormControlLabel
          control={
            <Checkbox
              checked={isexternal}
              onChange={(e, val) =>
                dropDownChange(e, val, "isexternal")
              }
              name="isexternal"
            />
          }
          label="is External"
        />
        {/* add is delete  */}
        {
          isexternal && <FormControlLabel
            control={
              <Checkbox
                checked={isDelete}
                onChange={(e, val) =>
                  dropDownChange(e, val, "isDelete")
                }
                name="isDelete"
              />
            }
            label="is Delete"
          />
        }
        {
          <FormControlLabel
            control={
              <Checkbox
                checked={isInsertNew}
                onChange={(e, val) =>
                  dropDownChange(e, val, "isInsertNew")
                }
                name="isInsertNew"
              />
            }
            label="is Insert New"
          />
        }
        {isexternal || isAddNew ? <div style={{ display: "flex" }}>
          <Autocomplete
            className={classes.marginTop}
            value={isexternalobj ? isexternalobj[0] : null}
            onChange={(event, newValue) => {
              let value = [newValue];
              dropDownChange(event, value, "isexternalObj");
            }}
            size={"small"}
            options={isExternalObj}
            filterSelectedOptions
            disableClearable
            getOptionLabel={(options) => options.label}
            style={{ width: "100%", marginBottom: "3%" }}
            renderInput={(params) => (
              <TextField {...params} label="Is External" variant="outlined" />
            )}
          />
        </div> : null}
        {isLogger.status ? (
          <div style={{ display: "flex" }}>
            <Autocomplete
              className={classes.marginTop}
              value={isLogger.severity ? isLogger.severity[0] : null}
              onChange={(event, newValue) => {
                let value = [newValue];
                dropDownChange(event, value, "loggerSeverity");
              }}
              size={"small"}
              options={Severity}
              filterSelectedOptions
              disableClearable
              getOptionLabel={(options) => options.label}
              style={{ width: "35%" }}
              renderInput={(params) => (
                <TextField {...params} label="Severity" variant="outlined" />
              )}
            />
            <TextField
              id="outlined-helperText"
              label="message"
              className={classes.marginTop}
              size={"small"}
              style={{ width: "60%", marginLeft: "5%" }}
              value={isLogger.message}
              onChange={(event) =>
                dropDownChange(event, event.target.value, "loggerMessage")
              }
              variant="outlined"
              fullWidth
            />
          </div>
        ) : null}
        {/* logger block end */}
        {actionval?.length > 1 ? (
          <Autocomplete
            multiple
            className={classes.marginTop}
            // value={actionval ? actionval : null}
            onChange={(event, newValue) =>
              dropDownChange(event, newValue, "WActions")
            }
            size={"small"}
            options={actionval}
            filterSelectedOptions
            getOptionLabel={(WAction) => WAction.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Actions"
                variant="outlined"
              />
            )}
          />
        ) : null}
        {/* <FormControlLabel
          control={
            <Checkbox
              name="checkedB"
              color="primary"
              style={{ padding: 12 }}
              checked={isApiCall}
              onChange={(e, val) => dropDownChange(e, val, "checkbox")}
            />
          }
          label="Is Api Call"
        /> */}

        {/* {isApiCall ? ( */}
        <div style={{ width: "100%" }}>
          {/* custom set state */}
          <div>
            <CustomState
              state={customState}
              listernerEvents={listernerEvents}
              handleChange={handleCustomState}
            />
          </div>
          {/* api listing */}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end', marginTop: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => addNewFlow()}
            >
              ADD API +
            </Button>
          </Grid>
          <div>
            {state &&
              state.map((dataList, i) => (
                <div>
                  <Paper style={{ marginBottom: 10, padding: "10px" }}>
                    <div style={{ height: 25 }}>
                      <HighlightOffIcon
                        onClick={() => deleteNifiApi(i)}
                        style={{ float: "right", cursor: "pointer" }}
                      />
                    </div>
                    <Autocomplete
                      className={classes.marginTop}
                      size={"small"}
                      options={[
                        // { label: "nifi url", value: "nifiUrl" },
                        // { label: "api url", value: "api" },
                        { label: "graphql", value: "graphQl" },
                        { label: "Service Registry", value: "service-registry" },
                      ]}
                      id="controllable-states-demo12"
                      onChange={(event, newValue) =>
                        dropDownEntities(event, newValue, "apiSelection", null, i)
                      }
                      value={dataList?.type ? { label: dataList?.type } : null}
                      getOptionLabel={(option) =>
                        option.label ? option.label : ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="select api type"
                          variant="outlined"
                        />
                      )}
                    />
                    {dataList?.type === "nifiUrl" ? (
                      <>
                        <Autocomplete
                          className={classes.marginTop}
                          size={"small"}
                          options={NifiUrl}
                          id="controllable-states-demo12"
                          onChange={(event, newValue) =>
                            dropDownEntities(event, newValue, "nifiurl", null, i)
                          }
                          value={
                            dataList?.NifiUrl
                              ? { label: dataList?.NifiUrl }
                              : null
                          }
                          getOptionLabel={(option) =>
                            option.label ? option.label : ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select Nifi Url"
                              variant="outlined"
                            />
                          )}
                        />
                        <Autocomplete
                          className={classes.marginTop}
                          size={"small"}
                          onChange={(event, newValue) =>
                            dropDownEntities(event, newValue, "table", null, i)
                          }
                          id="controllable-states-demo2"
                          options={entities}
                          getOptionLabel={(option) =>
                            option.entity ? option.entity : ""
                          }
                          value={
                            dataList?.EntityDetails?.entity
                              ? { entity: dataList?.EntityDetails?.entity }
                              : null
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select entities"
                              variant="outlined"
                            />
                          )}
                        />
                        <div>
                          {dataList?.columnsList?.map((val) => {
                            let value = null;
                            if (dataList?.EntityDetails?.column) {
                              let Cvalue = dataList?.EntityDetails?.column.filter(
                                (cVal) => Object.keys(cVal)[0] === val.name
                              )[0];
                              if (Cvalue) {
                                value = listernerEvents.filter(
                                  (cVal) =>
                                    cVal.fromId === Cvalue[val.name] ||
                                    cVal.formId === "history"
                                )[0];
                              }
                            }
                            return (
                              <div>
                                <p style={{ fontWeight: "bold", fontSize: 14 }}>
                                  {val.name}
                                </p>
                                <Autocomplete
                                  style={{ marginBottom: "2%" }}
                                  size={"small"}
                                  onChange={(event, newValue) =>
                                    dropDownEntities(
                                      event,
                                      newValue,
                                      "col",
                                      val.name,
                                      i
                                    )
                                  }
                                  id="controllable-states-demo2"
                                  options={listernerEvents}
                                  value={value}
                                  getOptionLabel={(option) =>
                                    option.CName ? option.CName : ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select entities"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </>
                    ) : null}
                    {dataList?.type === "api" ? (
                      <>
                        <TextField
                          id="outlined-helperText"
                          label="name"
                          className={classes.marginTop}
                          size={"small"}
                          value={dataList?.name}
                          onChange={(event) =>
                            dropDownEntities(
                              event,
                              event.target.value,
                              "apiName",
                              null,
                              i
                            )
                          }
                          variant="outlined"
                          fullWidth
                        />
                        <TextField
                          id="outlined-helperText"
                          label="endpoint"
                          className={classes.marginTop}
                          size={"small"}
                          value={dataList?.endPoint}
                          onChange={(event) =>
                            dropDownEntities(
                              event,
                              event.target.value,
                              "endPoint",
                              null,
                              i
                            )
                          }
                          variant="outlined"
                          fullWidth
                        />
                        {/* <Autocomplete
                        size={"small"}
                        options={entities}
                        id="controllable-states-demo12"
                        className={classes.marginTop}
                        onChange={(event, newValue) =>
                          dropDownEntities(
                            event,
                            newValue,
                            "apiTargetEntity",
                            null,
                            i
                          )
                        }
                        value={dataList?.apiTargetEntity && null}
                        getOptionLabel={(option) =>
                          option.entity ? option.entity : ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Choose Entity"
                            variant="outlined"
                          />
                        )}
                      /> */}
                        {dataList?.column?.map((mapper, child_id) => {
                          let value = eventData.filter(
                            (item) =>
                              item.fromId === mapper[Object.keys(mapper)[0]]
                          );
                          return (
                            <Grid
                              container
                              spacing={2}
                              className={classes.marginTop}
                            >
                              <Grid item xs={6}>
                                <TextField
                                  id="outlined-helperText"
                                  label="Enter keyname"
                                  size={"small"}
                                  value={Object.keys(mapper)[0]}
                                  onChange={(event) =>
                                    dropDownEntities(
                                      event,
                                      event.target.value,
                                      "endpointField",
                                      child_id,
                                      i
                                    )
                                  }
                                  variant="outlined"
                                  fullWidth
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <Autocomplete
                                  size={"small"}
                                  options={listernerEvents}
                                  id="controllable-states-demo12"
                                  onChange={(event, newValue) =>
                                    dropDownEntities(
                                      event,
                                      newValue,
                                      "endpointValue",
                                      child_id,
                                      i
                                    )
                                  }
                                  value={value[0]}
                                  getOptionLabel={(option) =>
                                    option.CName ? option.CName : ""
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="select value"
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </Grid>
                            </Grid>
                          );
                        })}
                        <div style={{ textAlign: "right" }}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.marginTop}
                            onClick={(event, newValue) =>
                              dropDownEntities(
                                event,
                                newValue,
                                "apiColumnFieldAdd",
                                null,
                                i
                              )
                            }
                          >
                            Add Fields
                          </Button>
                        </div>
                      </>
                    ) : null}
                    {dataList?.type === "graphQl" && isApiCall ? (
                      <GraphQlSupportDisplay
                        data={graphQl}
                        setData={handleGraphQl}
                        component={props.data}
                        activeIndex={props.index}
                      />
                    ) : null}
                    {dataList?.type === "service-registry" && (
                      <>
                        <Autocomplete
                          className={classes.marginTop}
                          size={"small"}
                          options={
                            auth.user.qpasQueries
                              ? auth.user.qpasQueries.map((query, index) => {
                                return {
                                  label: query.apiname,
                                  value: query._id,
                                  id: index,
                                };
                              })
                              : []
                          }
                          id="controllable-states-demo12"
                          onChange={(event, newValue) =>
                            dropDownEntities(event, newValue, "qpasId", null, i)
                          }
                          value={{ label: dataList.name }}
                          getOptionLabel={(option) =>
                            option.label ? option.label : ""
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select query id"
                              variant="outlined"
                            />
                          )}
                        />
                        {dataList?.column && (
                          <>
                            <div style={{ fontWeight: 400, marginTop: 15 }}>
                              Payload
                            </div>
                            {getAllQueryPayload(
                              dataList.column,
                              dataList.name,
                              i
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Paper>
                </div>
              ))}
          </div>
          {/* session management */}
          <div>
            {WAction[0] && (
              <SessionManagement
                label={"enter key"}
                isKeyEditable={true}
                onChange={updateSession}
                listernerEvents={listernerEvents}
                qpasQueriesResponse={qpasQueriesResponse}
                dataSources={sessionDataSources}
                storageData={auth.user.storageManagement}
                isEndDecoration={true}
                selfEvents={selfEvents}
                historyData={historyData}
                eventId={WAction[0]?.eventId}
              />
            )}
          </div>
        </div>
      </div>
      <div>
        {/* decision controller box */}
        <Grid
          container
          justify={"space-between"}
          style={{ marginTop: 60, marginBottom: 10 }}
        >
          <Typography
            variant="subtitle1"
            onClick={viewChange}
            style={{ textTransform: "underline", cursor: "pointer" }}
          >
            Decision Box
          </Typography>
          {conditionEnabler ? (
            <Button
              variant="text"
              size="small"
              color="primary"
              onClick={viewChange}
            >
              Add +
            </Button>
          ) : (
            <Switch
              checked={conditionEnabler}
              onChange={handleConditonChange}
              color="primary"
              name="condition-enabler"
              inputProps={{ "aria-label": "condition-enabler-checkbox" }}
            />
          )}
        </Grid>
        {/* decision controller box */}
      </div>
    </div>
  );
}
