import React from "react";

import { AuthContext, AlertContext, DialogContext,BackdropContext,DrawerContext } from "../contexts";

const withAlertAndDialog = (Component) => (props) => {
  const alert = React.useContext(AlertContext);
  const dialog = React.useContext(DialogContext);
   const backDrop = React.useContext(BackdropContext);
   const drawer = React.useContext(DrawerContext);
  // const queryParams = new URLSearchParams(useLocation().search);
  const auth = React.useContext(AuthContext);
  console.log("dialog func", props);
  return (
    <Component
      {...props}
      // screenData={screenData}
      auth={auth}
      alert={alert}
      dialog={dialog}
      backDrop={backDrop}
      drawer={drawer}
      // queryParams={queryParams}
    >
      {props.children}
    </Component>
  );
};

export default withAlertAndDialog;
