import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import AppNotify from "../../App.notification_autosave";
import { MainAppBar, LoadingStepper } from "../../components";
import ReactLoading from "react-loading";
import {
  Grid,
  withStyles,
  Typography,
  Popover,
  Paper,
  Tabs,
  Tab,
  Switch,
  Divider,
  Tooltip,
  IconButton,
  Button,
  Card,
  CardActions,
} from "@material-ui/core";

// JSON Data
import { mainMenuList, templates } from "./components/jsonData";
import * as Icons from "@material-ui/icons";
import "./styles/styles.css";
//mport  from '@material-ui/icons/AccountTreeOutlined';
import AddScreenStepper from "./components/addScreenStepper";
import {
  createDocument,
  updateDocument,
  deleteDocument,
  readDocument,
} from "../../apis/crud";
import {
  ScreenList,
  ComponentsList,
  BuilderArea,
  ComponentTree,
  UiBuilderPanel,
} from "./components";
// import { getScreenIndex, build } from "../../apis/helperFunction";
import { getScreenIndex } from "../../apis/helperFunction";
import { alertProps } from "../../utils";
import RedoRoundedIcon from "@material-ui/icons/RedoRounded";
import UndoRoundedIcon from "@material-ui/icons/UndoRounded";
// import MonitorIcon from '@material-ui/icons/Monitor'
import ComputerIcon from "@material-ui/icons/Computer";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExit from "@material-ui/icons/FullscreenExit";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import routes from "../../router/routes";
import CircularProgress from "@material-ui/core/CircularProgress";
// common function
import {
  makeid,
  screenArrowLinkAndEventRemover,
  decisionHandlerArrowLinkRemover,
} from "../../components/commonFunction";

import { withAlertAndDialog } from "../../HOC";
import { Renderer } from "./components/renderer";

import isEqual from "react-fast-compare";
import { Axios } from "axios";
import config from "../../config";
import { networkCall } from "../../networkcall";
import "./styles/styles.css";
const styles = (theme) => ({
  root: {
    backgroundColor: "#ebebeb",
    // "& .MuiTab-root": {
    //   minWidth: "30px",
    //   minHeight:"18px",

    // },
    "& .MuiTabs-root": {
      minHeight: "8px",
    },
  },
  // tabfont: {
  //   "&.MuiTab-root": {
  //     textTransform: "capitalize",
  //     padding: 0,
  //     margin: 0,
  //     right: 0,
  //     width: 0,

  //   },
  //   "& .PrivateTabIndicator-vertical-45" :{
  //     right: 0,
  //     width: 0,
  // },
  // },
  gridArea: {
    display: "grid",
    gridGap: "1px",

    minHeight: "92.5vh",
    // height: "92vh",
    gridColumnSstart: 1,
    gridColumnEnd: 4,
  },
  gridContainerFull: {
    gridTemplateAreas: `'header header header
                         menu mainArea properties'`,
    // gridTemplateColumns: "80px 220px 1fr 220px",
    gridTemplateColumns: "220px 1fr 200px",
    // padding: "10px",
  },
  gridContainerleft: {
    gridTemplateAreas: `'menu  mainArea properties'`,
    gridTemplateColumns: "220px  1fr 220px",
  },
  gridContainerRight: {
    gridTemplateAreas: `'menu mainArea properties'`,
    gridTemplateColumns: "220px 1fr 220px",
  },
  gridContainer: {
    gridTemplateAreas: `'menu mainArea'`,
    gridTemplateColumns: "220px 1fr",
  },
  contentArea: {
    // padding: theme.spacing(2),
  },
  paperLeft: {
    // padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "calc(100vh - 49px)",
    // height: "100vh",
    backgroundColor: "#fff",
  },
  paperRight: {
    padding: theme.spacing(1),
    // margin: theme.spacing(1),
    border: "2px solid transparent",
    borderStyle: "dashed",
    overflow: "auto",
    height: "calc(100vh - 66px)",
  },
  menuButton: {
    padding: "20px 8px",
    fontSize: "10px",
    width: "100%",
    height: "68px",
    wordBreak: "break-word",
    textTransform: "capitalize",
    // backgroundColor: "#eeeeee",
    borderRadius: 0,
  },
  item1: {
    gridArea: "header",
    "& .MuiAppBar-root": {
      // height:"40px",
    },
    //height:"40px"
    //backgroundColor: "red",
  },
  item2: {
    gridArea: "menu",
    backgroundColor: "#fff",
    width: "200px",
  },
  item3: {
    gridArea: "mainArea",
    overflow: "auto",
    // display: "flex",
    minHeight: "85.5vh",
    // border: "1px solid darkblue",
    backgroundImage:
      "linear-gradient(to right, rgb(217, 226, 233) 1px, transparent 1px), linear-gradient(rgb(217, 226, 233) 1px, transparent 1px)",
  },
  item4: {
    overflow: "auto",
    gridArea: "properties",
    backgroundColor: "#fff",
    zIndex: 4,
  },
  item4Hiden: {
    display: "none",
  },
  item5: {
    gridArea: "menulist",
    backgroundColor: "#e11010",
  },
  item5Hiden: {
    display: "none",
  },
  backcrop: {
    padding: "2.5rem",
    backgroundImage:
      "linear-gradient(to right, rgb(217, 226, 233) 1px, transparent 1px), linear-gradient(rgb(217, 226, 233) 1px, transparent 1px)",
    backgroundSize: "20px 20px",
    backgroundColor: "rgba(255, 255, 255, 0.36)",
    height: "100%",
    minWidth: "10rem",
    width: "100%",
    display: "block",
    alignItems: "center",
    overflow: "auto",
    position: "relative",
    flexDirection: "column",
  },
  menusList: {
    width: "100%",
    textAlign: "center",
    padding: "14px 6px",
    whiteSpace: "break-spaces",
    cursor: "pointer",
    color: theme.palette.text.disabled,
    borderLeft: "4px solid transparent",
    "&:hover": {
      backgroundColor: "rgba(0, 122, 255, 0.04)",
    },
  },
  activeMenusList: {
    width: "100%",
    textAlign: "center",
    padding: "14px 6px",
    whiteSpace: "break-spaces",
    cursor: "pointer",
    borderBottom: "4px" + theme.palette.primary.main,
    // backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
  menuText: {
    fontSize: "10px !important",
    wordBreak: "break-word",
  },
  tabnames: {
    minWidth: "30px",
    fontSize: "8px",
  },
  readEditBtn: {
    color: "#1492E6",
    fontSize: "8px",
    // width:"10px",
    height: "20px",
  },
  unDoAndReDo: {
    paddingTop: "6px",
    fontSize: "20px",
    "& .MuiSvgIcon-root": {
      fontSize: "0.98em",
    },
  },
  Tabs: {
    "& .MuiTab-root": {
      minWidth: "30px",
      minHeight: "18px",
    },
    "& .MuiTabs-root": {
      minHeight: "8px",
    },
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    padding: 14,
    borderRadius: "8px",
  },
});

class UiBuilder extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      name: "",
      loading: true,
      AllScreenList: [],
      screens: null,
      selectedScreen: 1,
      mainMenu: 0,
      rightMenu: false,
      leftMenu: true,
      selectedAllScreen: false,
      anchorEl: null,
      editScreen: "",
      uiType: "builder",
      componentAttr: null,
      previewScreen: false,
      buildModal: false,
      isEdited: false,
      newScreenType: "",
      layoutType: 1,
      tabValue: 0,
      zoom: 0.6,
      fullScreen: false,
      editMode: false,
      isLockedBy: null,
    };
  }

  getPercentage = {
    0.1: "10%",
    0.2: "20%",
    0.3: "30%",
    0.4: "40%",
    0.5: "50%",
    0.6: "60%",
    0.7: "70%",
    0.8: "80%",
    0.9: "90%",
    1: "100%",
    1.1: "110%",
    1.2: "120%",
  };

  // build = async () => {
  //   debugger;
  //   let dataList = {
  //     project_id: localStorage.getItem("projectId"),
  //     metadata_id: localStorage.getItem("metaDataId"),
  //   };

  // let data = dataList;
  // console.log("lo", config.compiler_url + "clone");
  // var confige = {
  //   method: "post",
  //   url: `${config.compiler_url}clone`,
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   data: data,
  // };

  // if (!this.state.isEdited) {
  //   this.setState({
  //     buildModal: true,
  //   });
  //   return false;
  // }

  // await Axios(confige)
  //   .then((response) => {
  //     console.log(response);
  //     debugger;
  //     this.setState({
  //       buildModal: true,
  //     });
  //   })
  //   .catch((err) => {
  //     this.props.alert.setSnack({
  //       ...this.props.alert,
  //       open: true,
  //       severity: alertProps.severity.error,
  //       msg: "Network Error",
  //       vertical: alertProps.vertical.top,
  //       horizontal: alertProps.horizontal.center,
  //       tone: true,
  //     });
  //   });

  // Axios.post("https://apicompiler.dev.ainqaplatform.in/clone", {
  //   project_id: localStorage.getItem("projectId"),
  //   metadata_id: localStorage.getItem("metaDataId"),
  // }).then((res) => {
  //   console.log(res);
  // });

  // let data = JSON.stringify(dataList);
  // let response = await networkCall(
  //   `${config.compiler_url}clone`,
  //   "POST",
  //   data,
  //   {
  //     "Content-Type": "application/json",
  //   }
  // );

  // if (response?.data?.Code !== 201) {
  //   console.log(
  //     response?.data?.error ??
  //       response?.data?.Errormsg ??
  //       "Something went wrong when creating documents"
  //   );
  // resolve(
  //   response?.data?.error ??
  //     response?.data?.Errormsg ??
  //     "Something went wrong when creating documents"
  // );
  //   } else {
  //     let result = response?.data?.Result ?? response?.data?.result;
  //     console.log(result);
  //     // resolve(result);
  //   }
  // };
  handleSwitchChange = async () => {
    debugger;
    alert.setSnack({
      ...alert,
      open: true,
      severity: alertProps.severity.info,
      msg: `Switching mode...`,
      vertical: alertProps.vertical.top,
      horizontal: alertProps.horizontal.right,
      tone: false,
    });

    let mode = !this.state.editMode;
    //check the newly created screen is saved before updating
    let currentScreen = this.props.auth.user?.screens[0];
    let allScreenListRef = JSON.parse(
      JSON.stringify(this.props.auth.user?.AllScreenListRef)
    );
    // let isCurrentScreenSaved = allScreenListRef.filter(
    //   (screen) => screen.id === currentScreen.id
    // );
    // if (isCurrentScreenSaved.length === 0) {
    //   return alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: alertProps.severity.warning,
    //     msg: "Save the newly created screen",
    //     vertical: alertProps.vertical.top,
    //     horizontal: alertProps.horizontal.right,
    //     tone: true,
    //   });
    // }

    //edit mode
    if (mode === true) {
      let screenQuery = [
        {
          entity: "screens",
          filter: {
            id: this.props.auth.user?.selectedScreen,
            version: this.props.auth.user?.version ?? 1.0,
          },
        },
      ];
      let result = await readDocument(screenQuery);
      if (result?.screens?.readMode === false) {
        //someone is already editing it
        this.setState({
          ...this.state,
          isLockedBy: result?.screens?.isLockedBy,
        });
        console.log(
          `${result?.screens?.isLockedBy} currently editing this screen`
        );
        alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.warning,
          msg: `${result?.screens?.isLockedBy} currently editing this screen`,
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
        this.setState({
          ...this.state,
          editmode: false,
        });
      } else {
        //Set edit mode
        let screenSchema = {
          ...this.auth.user?.screens[0],
          readMode: false,
          isLockedBy: this.auth?.user?.user?.name,
        };
        let screenQuery = [
          {
            entity: "screens",
            body: screenSchema,
            filter: {
              id: screenSchema?.id,
              metadataid: localStorage.metaDataId,
              version: screenSchema?.version,
            },
          },
        ];
        await updateDocument(screenQuery)
          .then(() => {
            let allScreenList = JSON.parse(
              JSON.stringify(this.props.auth.user?.AllScreenList)
            );
            allScreenList.forEach((eachScreen, index, array) => {
              if (currentScreen.id === eachScreen?.id) {
                array[index] = screenSchema;
              }
            });
            this.props.auth.setAuth({
              ...this.props.auth.user,
              screens: allScreenList.filter(
                (val) => val.id === currentScreen.id
              ),
              AllScreenList: [...allScreenList],
              AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
            });
            //setEditMode(true);
            this.setState({
              ...this.state,
              editmode: true,
            });
            //setIsLockedBy("You");
            this.setState({
              ...this.state,
              isLockedBy: "You",
            });
            alert.setSnack({
              ...alert,
              open: true,
              severity: alertProps.severity.success,
              msg: `Edit Mode enabled`,
              vertical: alertProps.vertical.top,
              horizontal: alertProps.horizontal.right,
              tone: true,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      let screenRef = allScreenListRef.find(
        (screen) => screen.id === currentScreen.id
      );
      let isItEqual = isEqual(screenRef, currentScreen);
      if (!isItEqual) {
        return alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.warning,
          msg: "Please save the changes",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
      }
      //read mode
      let screenSchema = {
        ...this.props.auth.user?.screens[0],
        readMode: true,
        isLockedBy: "",
      };
      let screenQuery = [
        {
          entity: "screens",
          body: screenSchema,
          filter: {
            id: screenSchema?.id,
            version: screenSchema?.version,
          },
        },
      ];
      await updateDocument(screenQuery)
        .then(() => {
          let allScreenList = JSON.parse(
            JSON.stringify(this.props.auth.user?.AllScreenList)
          );
          allScreenList.forEach((eachScreen, index, array) => {
            if (currentScreen.id === eachScreen?.id) {
              array[index] = screenSchema;
            }
          });
          this.props.auth.setAuth({
            ...this.props.auth.user,
            screens: allScreenList.filter((val) => val.id === currentScreen.id),
            AllScreenList: [...allScreenList],
            AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
          });
          //setEditMode(false);
          this.setState({
            ...this.state,
            editmode: false,
          });
          //setIsLockedBy(null);
          this.setState({
            ...this.state,
            isLockedBy: null,
          });

          alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.success,
            msg: `Read Mode enabled`,
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  handlezoomout = (e) => {
    this.zoomInOut(e, "zoomIn");
  };

  onClickMenu = (selected, index) => {
    console.log("index", index);
    this.setState({
      ...this.state,
      mainMenu: selected.id,
      leftMenu: true,
      tabValue: index,
    });
  };

  getLayoutClass = (rightMenu, leftMenu) => {
    if (rightMenu && leftMenu) {
      return "gridContainerFull";
    } else if (!rightMenu && !leftMenu) {
      return "gridContainer";
    } else if (rightMenu && !leftMenu) {
      return "gridContainerRight";
    } else if (!rightMenu && leftMenu) {
      return "gridContainerleft";
    } else {
      return "gridContainer";
    }
  };

  selectSteppterScreenType = (e) => {
    this.setState({ ...this.state, stepperType: e.currentTarget });
    console.log("whilee");
  };
  // Add New Screen Function
  openModal = (event, val) => {
    debugger;
    if (
      this.props.auth.user?.screens[0]?.isLockedBy === "" ||
      this.props.auth.user?.user?.name ===
      this.props.auth.user?.screens[0]?.isLockedBy ||
      val === "close"
    ) {
      if (val === "close") {
        this.setState({
          ...this.state,
          anchorEl: null,
          editScreen: "",
          stepperType: false,
          newScreenType: "",
        });
      } else if (val === "edit") {
        this.setState({ ...this.state, anchorEl: event, editScreen: val });
      } else {
        this.setState({ ...this.state, anchorEl: event.currentTarget });
      }
    } else {
      return this.props.alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${this.props.auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
  };

  //   delete screen
  deleteOldScreen = (id) => {
    debugger;
    if (
      this.props.auth.user?.screens[0]?.isLockedBy === "" ||
      this.props.auth.user?.user?.name ===
      this.props.auth.user?.screens[0]?.isLockedBy
    ) {
      console.log("Deleting da..", this.props.auth.user, id);
      debugger;
      const handleOnOkModal = () => {
        this.props.auth.user.AllScreenList.forEach(
          async (screen, index, arr) => {
            console.log(
              "show handleonokmodal",
              screen,
              index,
              arr,
              screen.id,
              id
            );
            if (screen.id === id) {
              arr.splice(index, 1);

              let currentScreen = this.props.auth.user.screens[0];

              let allScreenListRef = JSON.parse(
                JSON.stringify(this.props.auth.user?.AllScreenList)
              );
              console.log(allScreenListRef, this.props.auth.user.AllScreenList);

              //delete from db
              let payload = [
                {
                  entity: "screens",
                  filter: {
                    id: screen.id,
                    version: screen?.version,
                  },
                },
              ];

              await deleteDocument(payload);
            }
          }
        );
        // deleting decision handler if it arrowlink is empty

        let updatedScreens = this.props.auth.user.AllScreenList;
        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          tone: false,
        });

        this.props.auth.setAuth({
          ...this.props.auth.user,
          AllScreenListRef: JSON.parse(JSON.stringify(updatedScreens)),
          screens: updatedScreens.length > 0 ? [updatedScreens[0]] : [],
          selectedScreen: updatedScreens.length > 0 ? updatedScreens[0].id : "",
        });
      };

      const handClose = () => {
        this.props.dialog.setDialog({
          ...this.props.dialog,
          open: false,
          tone: false,
          title: "Delete Alert",
          body: "Do you need to delete this screen?",
          positiveBtn: "Yes",
          negativeBtn: "No",
          onOk: () => { },
          onCancel: () => { },
        });
      };

      this.props.dialog.setDialog({
        ...this.props.dialog,
        open: true,
        tone: true,
        title: "Delete Alert",
        body: "Do you need to delete this screen?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: handleOnOkModal,
        onCancel: handClose,
      });
    } else {
      return this.props.alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${this.props.auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
  };

  onClose = (type, val) => {
    if (type === "left") {
      this.setState({ ...this.state, leftMenu: val });
    } else if (type === "right") {
      if (document.querySelector(".selectedComponent") && !val) {
        document
          .querySelector(".selectedComponent")
          .classList.remove("selectedComponent");
      }
      this.setState({ ...this.state, rightMenu: val });
    }
  };

  goBack = () => {
    this.setState({ ...this.state, mainMenu: 0 });
  };

  treeView = (val) => {
    this.setState({ ...this.state, mainMenu: val });
  };

  getMenuListComponents = (val) => {
    switch (val) {
      case 0:
        return (
          <ScreenList
            onSwitchPreview={this.onSwitchPreview}
            selectSteppterScreenType={this.selectSteppterScreenType}
            previewScreen={this.state.previewScreen}
            openModal={this.openModal}
            deleteOldScreen={this.deleteOldScreen}
            onClose={this.onClose}
          />
        );
      case 1:
        return (
          <ComponentsList
            deleteOldScreen={this.deleteOldScreen}
            onClose={this.onClose}
            openModal={this.openModal}
          />
        );
      case 2:
        return <ComponentTree onClose={this.onClose} goBack={this.goBack} />;
      default:
        break;
    }
  };
  submitAddScreen = async (e, newScreenObject, type, scenes) => {
    let currentScreen;
    console.log("Type ", type);

    if (type === "edit") {
      this.props.auth.user.AllScreenList.filter((val) => {
        if (val.id === this.props.auth.user.screens[0]?.id) {
          return (
            (val.name = newScreenObject.name),
            (val.description = newScreenObject.description),
            (val.isHome = newScreenObject.isHome),
            (val.isIdmEnabled = newScreenObject.isIdmEnabled),
            (val.isPrivate = newScreenObject.isPrivate),
            (val.isLayoutScreen = newScreenObject?.isLayoutScreen ?? false),
            (val.path = newScreenObject.path),
            (val["type"] = newScreenObject.type),
            (val["scenes"] = scenes ? scenes : [])
          );
        }
        return false;
      });
      let permissionData = this.props.auth.user.permissionData;
      // if(newScreenObject?.isPrivate){
      //   permissionData = repositoryOperation.addScreen(permissionData,this.props.auth.user.screens[0]);
      // }
      // if (newScreenObject?.isIdmEnabled) {
      //   permissionData = repositoryOperation.addScreen(
      //     permissionData,
      //     this.props.auth.user.screens[0]
      //   );
      // } else {
      //   permissionData = repositoryOperation.removeScreen(
      //     permissionData,
      //     this.props.auth.user.screens[0]
      //   );
      // }
      this.props.auth.setAuth({
        ...this.props.auth.user,
        AllScreenList: this.props.auth.user.AllScreenList,
        AllScreenListRef: JSON.parse(
          JSON.stringify(this.props.auth.user.AllScreenList)
        ),
        permissionData: permissionData,
      });

      this.setState({
        ...this.state,
        anchorEl: null,
        allScreenList: this.state.allScreenList,
        isEdited: true,
        stepperType: false,
        newScreenType: "",
      });

      let currentScreen = this.props.auth.user?.screens[0];
      let screenSchema = {
        ...currentScreen,
        readMode: currentScreen?.editMode,
        lock: true,
        isLockedBy:
          // currentScreen?.editMode === false?
          this.props.auth?.user?.user?.name,
        //: null,
      };
      let screenQuery = [
        {
          entity: "screens",
          body: screenSchema,
          filter: {
            id: screenSchema?.id,
            version: 1,
          },
        },
      ];
      await updateDocument(screenQuery);
    } else {
      console.log("version",this.props.auth.user.version)
      const indexFromDb = await getScreenIndex(
        "6b2ffb61-a2c5-4cb0-b089-1968ff9855e6",
        this.props.auth.user.version
      );

      let setTemplate = templates?.filter(
        (val) => val.name === newScreenObject.layoutId
      )?.[0]?.json;
      currentScreen = {
        id: makeid(),
        pathId: makeid(),
        arrowLink: [],
        ...newScreenObject,
        scenes: scenes ? scenes : [],
        readMode: false,
        lock: true,
        isLockedBy: this.props.auth?.user?.user?.name,
        index: indexFromDb,
        render: {
          component: "div",
          component_type: "qdm",
          child: setTemplate ? [setTemplate] : [],
        },
        version: 1,
      };

      this.props.auth.user.AllScreenList.push(currentScreen);
      this.props.auth.user.selectedScreen = currentScreen.id;
      this.props.auth.user.screens = [currentScreen];
      let permissionData = this.props.auth.user.permissionData;

      this.props.auth.setAuth({
        ...this.props.auth.user,
        AllScreenList: this.props.auth.user.AllScreenList,
        AllScreenListRef: JSON.parse(
          JSON.stringify(this.props.auth.user.AllScreenList)
        ),
        permissionData: permissionData,
        isEdited: true,
        selectedScreen: currentScreen.id,
        screens: [currentScreen],
      });

      this.setState({
        ...this.state,
        anchorEl: null,
        // allScreenList: this.state.allScreenList,
        // isEdited: true,
        stepperType: false,
        newScreenType: "",
      });

      let screenSchema = {
        ...currentScreen,
        version: this.props.auth.user.version,
        metadataid: localStorage.getItem("metaDataId"),
      };
      let screenQuery = [
        {
          entity: "screens",
          body: screenSchema,
        },
      ];
      await createDocument(screenQuery);
    }
  };

  openStepper = (e, val) => {
    this.setState({
      ...this.state,
      anchorEl: e.currentTarget,
      newScreenType: val,
    });
  };
  //   switch to view preview mode
  onSwitchPreview = () => {
    this.setState({ ...this.state, previewScreen: !this.state.previewScreen });
    // debugger;
    // return (
    //   <Renderer auths={this.state} onSwitchPreview={this.onSwitchPreview} />
    // );
  };
  handleChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };
  //undo redo
  undoRedo = (type) => {
    debugger;

    let undoData = null;
    let currentScreen = null;
    if (type === "redo") {
      undoData = JSON.parse(
        JSON.stringify(
          this.props.auth.user.screensHistory[
          this.props.auth.user.selectedScreen
          ]
        )
      );
      console.log(undoData);
      currentScreen =
        undoData[
        this.props.auth.user.screensActiveIndex[
        this.props.auth.user.selectedScreen
        ] + 1
        ];
    } else {
      undoData = JSON.parse(
        JSON.stringify(
          this.props.auth.user.screensHistory[
          this.props.auth.user.selectedScreen
          ]
        )
      );
      currentScreen =
        undoData[
        this.props.auth.user.screensActiveIndex[
        this.props.auth.user.selectedScreen
        ] - 1
        ];
      console.log(currentScreen);
    }
    // let data2 = null;
    // if (Object.keys(auth.user.componentAttr).length > 0) {
    //   data2 = getPropertiesItems(
    //     auth.user.componentAttr,
    //     auth.user.selectedScreen,
    //     eleAtrribute
    //   );
    // }

    let allScreenListCopy = JSON.parse(
      JSON.stringify(this.props.auth.user.AllScreenList)
    );

    allScreenListCopy.forEach((screen, index, array) => {
      if (screen.id === this.props.auth.user.selectedScreen) {
        array[index] = {
          ...currentScreen,
          readMode: false,
          isLockedBy: this.props.auth.user?.user?.name,
        };
      }
    });

    this.props.auth.setAuth({
      ...this.props.auth.user,
      AllScreenList: allScreenListCopy,
      screensActiveIndex: {
        ...this.props.auth.user.screensActiveIndex,
        [this.props.auth.user.selectedScreen]:
          type === "undo"
            ? this.props.auth.user.screensActiveIndex[
            this.props.auth.user.selectedScreen
            ] - 1
            : this.props.auth.user.screensActiveIndex[
            this.props.auth.user.selectedScreen
            ] + 1,
      },
      screens: [
        {
          ...currentScreen,
          readMode: false,
          isLockedBy: this.props.auth.user?.user?.name,
        },
      ],
      // componentAttr: data2 ? data2 : {},
      selectedScreen: currentScreen.id,
    });
    this.props.onClose("right", false);
  };

  zoomInOut = (e, type) => {
    debugger;
    if (type === "zoomIn") {
      let n = this.state.zoom + 0.1;
      let number = Math.round(n * 10) / 10;
      if (number === 1.2) return false;
      this.setState({ zoom: number });
    } else {
      let n = this.state.zoom - 0.1;
      let number = Math.round(n * 10) / 10;
      if (number === 0.1) return false;
      this.setState({ zoom: number });
    }
    console.log(this.state.zoom);
  };

  fullScreenMode = (type) => {
    var elem = document.getElementById("fullViewMode");
    var mainLayout = document.getElementById("mainLayer");
    function openFullscreen() {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
    // close fullScreen
    function closeFullscreen() {
      if (document && document?.exitFullscreen) {
        document.exitFullscreen();
      } else if (document && document?.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document && document?.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
    if (type === "exit") {
      closeFullscreen();
      mainLayout.style.height = "100vh"; //"calc(100vh - 92px)";
    } else {
      openFullscreen();
      mainLayout.style.height = "calc(100vh - 49px)";
    }
    this.setState({
      ...this.state,
      fullScreen: !this.state.fullScreen,
    });
  };

  onChangeSwitch = async (e, val) => {
    function getScreen() {
      let result = this.props.auth.user.AllScreenList.find(
        (screen) => screen.type === "Screen"
      );
      return result ? [result] : [];
    }

    function getScreenId() {
      let result = this.props.auth.user.AllScreenList.find(
        (screen) => screen.type === "Screen"
      );
      return result ? result.id : null;
    }

    function getCurrentScreen() {
      const screen = this.props.auth.user.AllScreenList.filter(
        (screen) => screen.id === this.props.auth.user.selectedScreen
      );
      let result =
        this.props.auth.user.AllScreenList.length === 0
          ? []
          : screen.length === 1
            ? screen
            : getScreen();
      return result;
    }

    function getCurrentScreenId() {
      const screen = this.props.auth.user.AllScreenList.filter(
        (screen) => screen.id === this.props.auth.user.selectedScreen
      );
      let result =
        this.props.auth.user.AllScreenList.length === 0
          ? null
          : screen.length === 1
            ? screen[0]?.id
            : getScreenId();
      return result;
    }

    if (val === "builder") {
      localStorage.setItem("currentScreen", "builder");
      this.props.history.push(routes.uibuilder);
      console.log(this.props.auth.user.AllScreenList, "ui builder");
      this.props.auth.setAuth({
        ...this.props.auth.user,
        AllScreenList: this.props.auth.user.AllScreenList,
        screens: getCurrentScreen(),
        selectedScreen: getCurrentScreenId(),
      });
    } else {
      localStorage.setItem("currentScreen", "uiflow");
      if (this.props.auth.user?.isSaved === false) {
        await this.props.auth.save();
        this.props.auth.setAuth({
          ...this.props.auth.user,
          isSaved: true,
        });
      }
      this.props.history.push(routes.uiflow);
    }
  };

  handleSaveScreen = async () => {
    if (
      this.props.auth.user?.screens[0]?.isLockedBy === "" ||
      this.props.auth.user?.user?.name ===
      this.props.auth.user?.screens[0]?.isLockedBy
    ) {
      debugger;
      // console.log(auth.user, "user", selectedScreen, "selectedScreen");
      console.log("called this func", this.props.auth.user);
      let currentScreen = this.props.auth.user?.screens[0];
      let screenSchema = {
        ...currentScreen,
        version: 1,
      };

      let screenQuery = [
        {
          entity: "screens",
          body: screenSchema,
        },
      ];
      console.log(
        screenQuery,
        this.props.auth.user.AllScreenList[0].render.child
      );
      if (this.props.auth.user.screens[0].render.child.length === 0) {
        debugger;
        // console.log("called for upsert");
        // let upsert1 = await createDocument(screenQuery);
        // await Promise.all([upsert1])
        //   .then(function (values) {
        //     //update the AllScreenList & AllScreenListRef
        //     let screen = values[0][0]?.properties?.doc;
        //     console.log(values, screen);

        //     let allScreenList = JSON.parse(
        //       JSON.stringify(this.props.auth.user?.AllScreenList)
        //     );
        //     // allScreenList.forEach((eachScreen, index, array) => {
        //     //   if (screen.id === eachScreen?.id) {
        //     //     array[index] = screen;
        //     //   }
        //     // });
        //     this.props.auth.setAuth({
        //       ...this.props.auth.user,
        //       AllScreenList: [...allScreenList],
        //       AllScreenListRef: [...allScreenList],
        //     });
        //     this.props.alert.setSnack({
        //       ...this.props.alert,
        //       open: true,
        //       severity: alertProps.severity.success,
        //       msg: "Screen updated successfully",
        //       vertical: alertProps.vertical.top,
        //       horizontal: alertProps.horizontal.right,
        //       tone: true,
        //     });
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
        this.props.alert.setSnack({
          ...this.props.alert,
          open: true,
          severity: alertProps.severity.warning,
          msg: "Please add some components",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
      } else {
        debugger;
        //read mode
        console.log("called for update");
        let screenSchema = {
          ...this.props.auth.user?.screens[0],
          readMode: true,
          isLockedBy: this.props.auth.user?.user?.name,
          lock: true,
        };
        let screenQuery = [
          {
            entity: "screens",
            body: screenSchema,
            filter: {
              id: screenSchema?.id,
              version: screenSchema?.version,
            },
          },
        ];
        await updateDocument(screenQuery)
          .then(() => {
            let allScreenList = JSON.parse(
              JSON.stringify(this.props.auth.user?.AllScreenList)
            );
            allScreenList.forEach((eachScreen, index, array) => {
              if (currentScreen.id === eachScreen?.id) {
                array[index] = screenSchema;
              }
            });
            this.props.auth.setAuth({
              ...this.props.auth.user,
              screens: allScreenList.filter(
                (val) => val.id === currentScreen.id
              ),
              AllScreenList: [...allScreenList],
              AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
            });
            this.props.auth
              .save()
              .then((response) => {
                console.log(response);
                // this.props.save().then(
                this.props.alert.setSnack({
                  ...this.props.alert,
                  open: true,
                  severity: alertProps.severity.success,
                  msg: "Screen updated successfully",
                  vertical: alertProps.vertical.top,
                  horizontal: alertProps.horizontal.right,
                  tone: true,
                })
                // .catch((err) => {
                //   this.props.alert.setSnack({
                //     ...this.props.alert,
                //     open: true,
                //     severity: alertProps.severity.error,
                //     msg: "Network Error",
                //     vertical: alertProps.vertical.top,
                //     horizontal: alertProps.horizontal.center,
                //   });
                // });
              })
              .catch((err) => {
                console.log(err);
              });

          })

        //   // this.props.save().then(
        //   this.props.alert.setSnack({
        //     ...this.props.alert,
        //     open: true,
        //     severity: alertProps.severity.success,
        //     msg: "Screen updated successfully",
        //     vertical: alertProps.vertical.top,
        //     horizontal: alertProps.horizontal.right,
        //     tone: true,
        //   })
        //   // .catch((err) => {
        //   //   this.props.alert.setSnack({
        //   //     ...this.props.alert,
        //   //     open: true,
        //   //     severity: alertProps.severity.error,
        //   //     msg: "Network Error",
        //   //     vertical: alertProps.vertical.top,
        //   //     horizontal: alertProps.horizontal.center,
        //   //   });
        //   // });
        // })
        // .catch((err) => {
        //   console.log(err);
        // });
      }
      localStorage.setItem(
        "previewScreen",
        JSON.stringify(this.props.auth.user.screens)
      );
    } else {
      this.props.alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "The screen is currently locked",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
  };
  build = async () => {
    this.setState({
      buildModal: true,
    });
    let dataList = {
      projectName: localStorage.getItem("projectName"),
      metadataId: localStorage.getItem("metaDataId"),
    };

    let data = JSON.stringify(dataList);
    let response = await networkCall(
      `${config.compiler_url}/clone`,
      "POST",
      data,
      {
        "Content-Type": "application/json",
      }
    );
    console.log("response", response);
    if (response?.data?.status !== 200) {
      console.log(
        response?.data?.error ??
        response?.data?.Errormsg ??
        "Something went wrong when triggering build..."
      );
      // resolve(
      //   response?.data?.error ??
      //     response?.data?.Errormsg ??
      //     "Something went wrong when creating documents"
      // );
    } else if (response?.data?.status === 200) {
      this.handleClose("buildModal");
      if (this.state.buildModal !== false) {
        this.props.alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.success,
          msg: `Deployed Successfully`,
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
      }
    } else {
      let result = response?.data?.Result ?? response?.data?.result;
      console.log(result);
      // resolve(result);
    }
  };
  handleClose = (val) => {
    this.setState({
      [val]: false,
    });
  };
  render() {
    const openScreenStepper = Boolean(this.state.anchorEl);
    const open = Boolean(this.state.stepperType);
    const id = open ? "simple-popover" : undefined;
    const { classes } = this.props;
    const { rightMenu, leftMenu } = this.state;
    return (
      <div
        className={classes.root}
        id={"fullViewMode"}
      /* style={{
      minHeight: "100vh",
    }} */
      >
        <Modal
          closeAfterTransition
          open={this.state.buildModal}
          // open={true}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          disableBackdropClick
        >
          {
            <Card className={classes.paper}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <CircularProgress color="secondary" disableShrink size={20} /> */}
                <ReactLoading
                  className="react-loading-style"
                  type="bubbles"
                  color="#0000FF"
                  height={100}
                  width={50}
                />
                <Typography>
                  &nbsp;Please Wait While Code the is being Deployed{" "}
                </Typography>
              </div>
              <CardActions
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button
                  size="small"
                  onClick={() => this.handleClose("buildModal")}
                >
                  Cancel
                </Button>
              </CardActions>
            </Card>
          }
        </Modal>

        {/** Top App Bar START*/}

        <div className={classes.item1}>
          <AppNotify>
            <MainAppBar
              onSwitchPreview={this.onSwitchPreview}
              previewScreen={this.state.previewScreen}
              onChangeSwitch={this.onChangeSwitch}
              build={this.build}
              title={localStorage.projectName}
              onClose={this.onClose}
              saveForm={this.saveForm}
              layoutType={this.layoutType}
              {...this.state}
            //zoom={this.state.zoom}
            //zoomfunc={this.zoomInOut}
            //screens={this.props.auth.user.screens}
            />
          </AppNotify>
        </div>

        {/** Top App Bar END */}

        {/* Main Layout Area  */}
        <div
          id={"mainLayer"}
          className={`${classes.gridArea} ${this.getLayoutClass(
            // rightMenu,
            leftMenu
          )}`}
        >
          {/* Side Menu List */}
          <div className={classes.item2}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  borderRight: "2px solid #ebebeb",
                  height: "100vh",
                }}
              >
                <Tabs
                  value={this.state.tabValue}
                  //indicatorColor="primary"
                  textColor="primary"
                  orientation="vertical"
                  // aria-label="simple tabs example"
                  style={{
                    fontFamily: "Roboto Helvetica Arial sans-serif",
                  }}
                  centered
                  TabIndicatorProps={{
                    style: {
                      right: 0,
                      width: 0,
                    },
                  }}
                >
                  {mainMenuList?.map((list, index) => {
                    let IconType = Icons[list.icon];
                    return (
                      <Tab
                        className={`roboto-font ${classes.tabfont}`}
                        onClick={() => this.onClickMenu(list, index)}
                        label={
                          <Tooltip title={list.name} placement="right-start">
                            <IconType fontSize={"small"} />
                          </Tooltip>

                          /*<h6
                            style={{
                              fontSize: "10px",
                              textTransform: "capitalize !important",
                              fontWeight:300,
                              margin: "0",
                            }}
                          >
                            {list.name}
                          </h6>
                          */
                        }
                        style={{
                          minWidth: 50,
                          paddingLeft: 5,
                          paddingRight: 5,
                          backgroundColor: "white",
                        }}
                      ></Tab>
                    );
                  })}
                </Tabs>
              </div>

              <div
              // className={
              //   this.state.leftMenu ? classes.item5 : classes.item5Hiden
              // }
              >
                {this.state.leftMenu &&
                  this.getMenuListComponents(this.state.mainMenu)}
              </div>
            </div>

            {/* <Grid container>
              {mainMenuList?.map((list, index) => {
                let IconType = Icons[list.icon];
                return (
                  <>
                    <div
                      onClick={() => this.onClickMenu(list)}
                      className={
                        this.state.mainMenu === list.id
                          ? classes.activeMenusList
                          : classes.menusList
                      }
                    >
                      <Typography>
                        <IconType fontSize={"small"} />
                      </Typography>
                      <Typography variant={"h6"} className={classes.menuText}>
                        {list.name}
                      </Typography>
                    </div>
                  </>
                );
              })}
            </Grid> */}
          </div>

          {/* Side Menu List END */}

          {/* Left Menu List */}

          {/* <div
            className={this.state.leftMenu ? classes.item5 : classes.item5Hiden}
          >
            {this.state.leftMenu &&
              this.getMenuListComponents(this.state.mainMenu)}
          </div> */}
          {/* Left Menu List END */}
          {/* UI Builder Area */}

          <div className={`${classes.item3}`}>
            <Grid
              container
              style={{
                height: "35px",
                borderBottom: "2px solid  #ebebeb",
                backgroundColor: "white",
                position: "relative",
              }}
              alignItems="center"
            >
              <Grid container item xs={12} justifyContent="center">
                <Tabs
                  onChange={(e, val) => this.onChangeSwitch(e, val)}
                  value={"builder"}
                  indicatorColor="primary"
                  // TabIndicatorProps={{style: {background:'#808080'}}}
                  //textColor="red"
                  // aria-label="simple tabs example"
                  className={classes.Tabs}
                  style={{
                    fontFamily: "Roboto Helvetica Arial sans-serif",
                  }}
                  centered
                >
                  <Tab
                    value={"builder"}
                    label={
                      <h2
                        style={{
                          fontSize: "9px",
                          textTransform: "capitalize !important",
                          color: "#3093ff",
                          margin: "0",
                        }}
                      >
                        UI Designer
                      </h2>
                    }
                  ></Tab>
                  <Tab
                    value={"uiflow"}
                    label={
                      <h2
                        style={{
                          fontSize: "9px",
                          textTransform: "capitalize !important",
                          color: "#3093ff",
                          margin: "0",
                        }}
                      >
                        UI Flow
                      </h2>
                    }
                  ></Tab>
                </Tabs>
              </Grid>

              <Grid
                style={{
                  position: "absolute",
                  right: 0,
                }}
              >
                <Button
                  disabled={this.props.auth.user.loading}
                  variant={"outlined"}
                  color={"primary"}
                  size="small"
                  style={{
                    fontSize: "8px",
                    marginLeft: "5px",
                    marginRight: "10px",
                  }}
                  disableElevation
                  onClick={this.handleSaveScreen}
                >
                  Save Screen
                </Button>
                <Button
                  disabled={this.props.auth.user.loading}
                  onClick={() => this.build()}
                  variant={"outlined"}
                  color={"primary"}
                  size={"small"}
                  style={{ fontSize: "8px", marginRight: "5px" }}
                >
                  Build
                </Button>
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                backgroundColor: "#fff",
                width: "auto",
                height: "30px",
                marginLeft: "0px",
                paddingLeft: "10px",
              }}
            >
              {/* <div>
                <Typography component="div">
                  <Grid
                    component="label"
                    container
                    alignItems="center"
                    spacing={1}
                  >
                    <Grid item>
                      <Typography
                        style={{ fontSize: "10px", color: "#9f9f9f" }}
                      >
                        Read Mode
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Switch
                        size="small"
                        checked={this.state.editMode}
                        onChange={this.handleSwitchChange}
                        name="checkedC"
                        color="primary"
                      />
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{ fontSize: "10px", color: "#9f9f9f" }}
                      >
                        Edit Mode
                      </Typography>
                    </Grid>
                  </Grid>
                </Typography>
              </div> */}
              {this.props.auth?.user?.screens?.length === 1 &&
                this.props.auth?.user &&
                Object.keys(this.props.auth.user.screensHistory).length > 0 &&
                Object.keys(this.props.auth.user.screensActiveIndex).length >
                0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      gap: "22px",
                    }}
                  >
                    <IconButton
                      onClick={() => this.undoRedo("undo")}
                      disabled={
                        this.props.auth.user.screensHistory[
                          this.props.auth.user.selectedScreen
                        ]?.length > 1 &&
                          this.props.auth.user.screensActiveIndex[
                          this.props.auth.user.selectedScreen
                          ] !== 0
                          ? false
                          : true
                      }
                      disableFocusRipple={
                        this.props.auth.user.screensHistory[
                          this.props.auth.user.selectedScreen
                        ]?.length > 1 &&
                          this.props.auth.user.screensActiveIndex[
                          this.props.auth.user.selectedScreen
                          ] !== 0
                          ? false
                          : true
                      }
                      disableRipple={
                        this.props.auth.user.screensHistory[
                          this.props.auth.user.selectedScreen
                        ]?.length > 1 &&
                          this.props.auth.user.screensActiveIndex[
                          this.props.auth.user.selectedScreen
                          ] !== 0
                          ? false
                          : true
                      }
                      size="small"
                    // className={classes.unDoAndReDo}
                    >
                      <Tooltip title="Undo">
                        <UndoRoundedIcon
                          fontSize="small"
                          style={{
                            fontSize: "18px",
                          }}
                        />
                      </Tooltip>
                    </IconButton>
                    <IconButton
                      // className={classes.unDoAndReDo}
                      onClick={() => this.undoRedo("redo")}
                      disabled={
                        this.props.auth.user.screensActiveIndex[
                          this.props.auth.user.selectedScreen
                        ] ===
                          this.props.auth.user.screensHistory[
                            this.props.auth.user.selectedScreen
                          ]?.length -
                          1
                          ? true
                          : false
                      }
                      disableFocusRipple={
                        this.props.auth.user.screensActiveIndex[
                          this.props.auth.user.selectedScreen
                        ] ===
                          this.props.auth.user.screensHistory[
                            this.props.auth.user.selectedScreen
                          ]?.length -
                          1
                          ? true
                          : false
                      }
                      disableRipple={
                        this.props.auth.user.screensActiveIndex[
                          this.props.auth.user.selectedScreen
                        ] ===
                          this.props.auth.user.screensHistory[
                            this.props.auth.user.selectedScreen
                          ]?.length -
                          1
                          ? true
                          : false
                      }
                      // className={classes.margin}
                      size="small"
                    >
                      <Tooltip title="Redo">
                        <RedoRoundedIcon
                          fontSize="small"
                          style={{
                            fontSize: "18px",
                          }}
                        />
                      </Tooltip>
                    </IconButton>

                    <IconButton
                      size="small"
                      //onClick={() => history.push("/preview")}
                      // className={classes.unDoAndReDo}
                      onClick={this.onSwitchPreview}
                    >
                      <Tooltip title="Preview">
                        <ComputerIcon
                          fontSize={"small"}
                          style={{ fontSize: "13px" }}
                          color={"#757575"}
                        />
                      </Tooltip>
                    </IconButton>

                    <Tooltip title="Fullscreen">
                      <IconButton
                        size="small"
                        onClick={(e) =>
                          this.fullScreenMode(
                            this.state.fullScreen ? "exit" : "full"
                          )
                        }
                      >
                        {this.state.fullScreen ? (
                          <FullscreenExit
                            fontSize={"small"}
                            color={"#757575"}
                            style={{ fontSize: "13px" }}
                          />
                        ) : (
                          <FullscreenIcon
                            fontSize={"small"}
                            color={"#757575"}
                            style={{ fontSize: "13px" }}
                          />
                        )}
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Preview">
                      <IconButton
                        size="small"
                        // className={classes.unDoAndReDo}
                        onClick={() => this.props.history.push("/preview")}
                      >
                        <PlayArrowIcon
                          fontSize={"small"}
                          color={"#757575"}
                          style={{ fontSize: "13px" }}
                        />
                      </IconButton>
                    </Tooltip>

                    <IconButton
                      disabled={
                        this.getPercentage[this.state.zoom] === "20%"
                          ? true
                          : false
                      }
                      size={"small"}
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      // style={{ margin: "0px 3px 0px 3px" }}
                      //className={classes.unDoAndReDo}
                      onClick={(e) => this.zoomInOut(e, "zoomOut")}
                    >
                      <RemoveIcon
                        fontSize={"small"}
                        color={"#757575"}
                        style={{ fontSize: "13px" }}
                      />
                    </IconButton>
                    <Button
                      disabled
                      color={"default"}
                      style={{ fontSize: "10px" }}
                      disableElevation
                      disableFocusRipple
                      disableRipple
                      component={"span"}
                    >
                      {this.getPercentage[this.state.zoom]}
                    </Button>

                    <IconButton
                      disabled={
                        this.getPercentage[this.state.zoom] === "110%"
                          ? true
                          : false
                      }
                      //className={classes.unDoAndReDo}
                      size={"small"}
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(e) => this.handlezoomout(e, "zoomout")}
                    >
                      <AddIcon
                        fontSize={"small"}
                        color={"#757575"}
                        style={{ fontSize: "13px" }}
                      />
                    </IconButton>
                  </div>
                )}
            </div>
            <div className={`${classes.item3} scroll-bar`}>
              <BuilderArea
                onSwitchPreview={this.onSwitchPreview}
                onClose={this.onClose}
                leftMenu={this.state.leftMenu}
                previewScreen={this.state.previewScreen}
                zoom={this.state.zoom}
              />
            </div>
          </div>
          {/* UI Builder Area END */}
          {/* Right Side Menu ( Properties, Relationship, Entities ) */}
          <div
          // className={rightMenu ? classes.item4 : classes.item4Hiden}
          >
            {
              // rightMenu &&
              <UiBuilderPanel showproperties={rightMenu} />
            }
          </div>
        </div>

        {/* Main Layout Area END */}
        {openScreenStepper && (
          <AddScreenStepper
            type={this.state.newScreenType}
            viewType={this.state.editScreen}
            submitAddScreen={this.submitAddScreen}
            modal={this.openModal}
            open={openScreenStepper}
          />
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={this.state.stepperType}
          onClose={(e) => this.openModal(e, "close")}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Grid spacing={2}>
            <Typography
              variant={"body1"}
              style={{ padding: "4px 10px", cursor: "pointer", fontSize: 12.4 }}
              onClick={(e) => this.openStepper(e, "Screen")}
            >
              {"Screen"}
            </Typography>
            <Typography
              variant={"body1"}
              style={{ padding: "4px 10px", cursor: "pointer", fontSize: 12.4 }}
              onClick={(e) => this.openStepper(e, "Component")}
            >
              {"Component"}
            </Typography>
          </Grid>
        </Popover>
      </div>
    );
  }
}

export default withRouter(withAlertAndDialog(withStyles(styles)(UiBuilder)));
//export default withRouter(withStyles(styles)(UiBuilder));
