import React from 'react';
import { Grid, TextField, makeStyles, Typography } from '@material-ui/core';
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
    searchBox: {
        borderRadius: "6px",
        border: "1px solid #e4e4e4",
        backgroundColor: "#f7f7f7",
    },
    container: {
        marginTop: 10
    }
}))
const ConditionForm = (props) => {
    const classes = useStyles();
    return (
        <Grid container>
            <Grid container item xs={12} className={classes.container}>
                <Grid item xs={4}>
                    <Autocomplete
                        className={classes.searchBox}
                        disableClearable
                        value={props?.value?.actionValue ?? []}
                        onChange={(event, newValue) => props?.handleDropdown(event, newValue, "actionOptions", props.index)}
                        id="controllable-states-demo"
                        options={props?.options?.actionOptions ?? []}
                        getOptionLabel={(options) => options.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={5}>
                    <Autocomplete
                        className={classes.searchBox}
                        style={{ marginLeft: 10, marginRight: 10 }}
                        disableClearable
                        value={props?.value?.conditionValue ?? []}
                        onChange={(event, newValue) => props?.handleDropdown(event, newValue, "conditionOptions", props.index)}
                        id="controllable-states-demo"
                        options={props?.options?.conditionOptions ?? []}
                        getOptionLabel={(options) => options.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Autocomplete
                        className={classes.searchBox}
                        disableClearable
                        value={props?.value?.resValue ?? []}
                        onChange={(event, newValue) => props?.handleDropdown(event, newValue, "resOptions", props.index)}
                        id="controllable-states-demo"
                        options={props?.options?.resOptions ?? []}
                        getOptionLabel={(options) => options.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.container}>
                <Grid item>
                    {/* navigation listing dropdown */}
                    <Autocomplete
                        className={classes.searchBox}
                        disableClearable
                        value={props?.value?.navigationValue ?? []}
                        onChange={(event, newValue) => props?.handleDropdown(event, newValue, "navigationOptions", props.index)}
                        id="controllable-states-demo"
                        options={props?.options?.navigationOptions ?? []}
                        getOptionLabel={(options) => options.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Typography variant="subtitle1" color="error">
                {props?.errorText ?? ''}
            </Typography>
        </Grid>
    )
}

export default ConditionForm;