import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export function useSearchParamsQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), []);
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};
