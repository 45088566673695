import React, { useEffect, useState, useContext } from 'react'
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Tabs,
  Tab,
} from '@material-ui/core'
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab'
import { blue, blueGrey as gray } from '@material-ui/core/colors'
import { WebAppPanel } from './tab'
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded'
import ArrowDropDownRoundedIcon from '@material-ui/icons/ArrowDropDownRounded'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import * as Icons from '../../../components'
import { docorateName } from '../../../components'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { AuthContext } from '../../../contexts'
import ProjectTemplates from './projectTemplates'
const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      width: '150px',
      '& .MuiDivider-root ': {
        height: '0px',
      },
    },
    tabButton: {
      // width: "2px",
      textTransform: 'capitalize',
      fontSize: '8px !important',
      minWidth: '10px !important',

      //border: "1px solid #ebebeb ",
      borderRadius: '0px ',
      backgroundColor: '#F6F6F8 ',
    },
    selectedTab: {
      //  color: "blue",

      borderRadius: '0px !important',
      padding: '0px 0px',
      minWidth: '50px',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    selecteTab: {
      //padding: "4px 12px",
      //border: "0px",
      border: '1px solid #007AFF',
      borderRadius: '5px  !important',
      minWidth: '60px',
      height: '25px',

      color: theme.palette.primary.main,
      //color: "white",
    },
    catagerie: {
      textTransform: 'capitalize',
      padding: '3px 10px',
      marginBottom: '4px',
      borderBottom: '1.2px solid #e7e7e7',
      color: '#5b5b5c',
      // '& .MuiTypography-body2': {
      //   fontSize: 10.2,
      // },
    },
    listItemText: {
      fontSize: "11.4px",
    },
    dragArea: {
      width: '75px',
      cursor: 'grab',
      textAlign: 'center',
      wordBreak: 'break-words',
      padding: '0px 0px 5px 0px',
      minHeight: '80px',
      borderRadius: '8px',
      display: 'grid',
      placeItems: 'center',
      backgroundColor: blue[700],
      boxShadow: `0px 0px 10px ${gray[50]}`,
      '&:hover': {
        backgroundColor: blue[50],
        boxShadow: `0px 0px 20px ${gray[200]}`,
        '& .dragAreaText': {
          color: blue[900],
        },
        '& .iconBg': {
          backgroundColor: blue[700],
        },
        '& .dragAreaIcon': {
          color: 'white',
        },
      },
      '& .iconBg': {
        borderRadius: '6px 6px 0px 0px',
        backgroundColor: blue[50],
        width: '100%',
        height: '100%',
        padding: '14px 0px',
        display: 'grid',
        placeItems: 'center',
      },
      '& .dragAreaText': {
        color: 'white',
        padding: '0px 1px 0px 1px',
      },
      '& .dragAreaIcon': {
        color: blue[700],
        fontSize: '28px',
      },
    },
    collapseView: {
      // maxHeight: 'calc(100vh - 286px)',
      // overflowX: "hidden",
      // overflowY: "auto",
      color: 'gray',
      '& .MuiCollapse-wrapperInner': {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
    inputInput: {
      height: '0.9em',
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(2)}px) !important`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    searchIcon: {
      padding: '0px 6px !important',
      position: 'absolute !important',
      pointerEvents: 'none !important',
      display: 'flex!important',
      alignItems: 'center !important',
      justifyContent: 'center!important',
      marginTop: '10px !important',
    },

    primaryElementText: {
      fontSize: '50px',
    },
    search: {
      borderRadius: '4px !important',
      borderBottom: '1.3px solid rgba(0, 0, 0, 0.12) !important',
      border: '1.3px solid rgba(0, 0, 0, 0.12) !important',
      marginLeft: '12px',
      marginRight: '20px',

      //backgroundColor: fade("#fff", 0.15),
      '&:hover': {
        // backgroundColor: fade("#fff", 0.25),
      },

      [theme.breakpoints.up('sm')]: {
        width: 'auto',
      },
    },
    primaryAndTemplate: {
      fontSize: '8px',
    },
    components: {
      fontSize: '10px',
      color: '#6E6E6E',
    },
  }
})

export function ComponentsList(props) {
  const [tab, setTab] = useState(0)
  const classes = useStyles()
  const [open, setOpen] = useState({})
  const auth = useContext(AuthContext)
  const [groupedComponents, setGroupedComponents] = useState({})
  const [componentList, setComponentList] = useState(
    auth?.user?.UIcomponents ?? [],
  )

  useEffect(() => {
    auth?.user?.UIcomponents && setComponentList([...auth?.user?.UIcomponents])
    if (auth?.user?.UIcomponents) {
      const components = {}
      auth.user.UIcomponents.forEach((component) => {
        if (!components[component?.componentType]) {
          components[component?.componentType] = []
        }
        components[component?.componentType].push(component)
      })
      setGroupedComponents(components)
    }
  }, [auth?.user?.UIcomponents])

  const handleClick = (val) => {
    setOpen({
      [val]: open[val] ? !open[val] : true,
    })
  }
  const tabChange = (val) => {
    setTab(val)
  }
  const onDragStart = (evt, type, obj) => {
    let element = evt.currentTarget
    element.classList.add('vivifySmall')
    element.classList.add('pulsate')
    element.classList.add('dragAreaBg')
    evt.dataTransfer.setData('components', evt.currentTarget.id)
    evt.dataTransfer.setData('componentId', obj.componentId)
    evt.dataTransfer.effectAllowed = 'move'
  }

  const onDragEnd = (evt, type) => {
    let element = evt.currentTarget
    element.classList.remove('dragAreaBg')
    element.classList.remove('vivifySmall')
    element.classList.remove('pulsate')
  }

  const getgrouperComponentsList = (
    list,
    open,
    type,
    group,
    openAll,
    setOpenAll,
  ) => {
    return (
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        style={{ width: '100%', padding: '0px' }}
      >
        {/* Component Category List Mapping */}

        <ListItem
          button
          onClick={() => {
            setOpenAll(false)
            handleClick(group)
          }}
          dense
          className={classes.catagerie}
        >
          <ListItemText classes={{ primary: classes.listItemText }} style={{ fontSize: 6 }} primary={group === 'undefined' ? 'Others' : group} />
          {(openAll || open[group]) ?? false ? (
            <KeyboardArrowUpIcon
              style={{ fontSize: '14px' }}
              fontSize={'default'}
              color={'#00000'}
            />
          ) : (
            <KeyboardArrowDownIcon
              style={{ fontSize: '14px' }}
              color={'#00000'}
              fontSize={'default'}
            />
          )}
        </ListItem>

        {/* Component Category List Mapping End*/}

        {/* Component List */}
        <Collapse
          in={(openAll || open[group]) ?? false}
          timeout="auto"
          unmountOnExit
          className={`${classes.collapseView} scroll-bar`}
        >
          {list.map((component, index) => {
            let Icon = Icons[component.componentName] ?? Icons['Template']
            return (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  draggable={true}
                  key={index}
                  onDragStart={(e) => onDragStart(e, 'components', component)}
                  onDragEnd={(e) => onDragEnd(e, 'components')}
                  id={component.componentId}
                  style={{
                    cursor: 'grab',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    margin: '5px',
                    border: '1.4px dashed #cbd5e1',
                    padding: '1px 4px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    {Icon && (
                      <Icon style={{ color: '#64748b', width: '15px' }} />
                    )}
                    <Typography
                      variant={'body1'}
                      color={'inherit'}
                      style={{
                        fontSize: '10px',
                        color: '#0f172a',
                        width: '100%',
                        paddingTop: '4px',
                        // wordBreak: "break-all",
                        marginLeft: '10px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {docorateName(component?.componentName)}
                    </Typography>
                  </div>
                  <DragIndicatorIcon
                    color={'action'}
                    style={{
                      fontSize: '14px',
                      marginTop: '5px',
                    }}
                  />
                </Grid>
              </Grid>
            )
          })}
        </Collapse>

        {/* Component List End */}
      </List>
    )
  }

  const [value, setValue] = React.useState(0)

  const handleTabChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      {/* Menu Title */}
      {/* <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
        style={{ padding: "8px" }}
      >
        <Grid>
          <Typography style={{ fontWeight: "600" }} variant={"p"}>
            Component's
          </Typography>
        </Grid>
      </Grid> */}
      {/* Menu Title End */}
      {/* Component Type Tab */}

      <div

      /* className={classes.grow}
        style={{ textAlign: "center", marginTop: "10px" }} */
      >
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: '35px', borderBottom: '2px solid #ebebeb' }}
        >
          <Typography className={classes.components}>COMPONENTS</Typography>
        </Grid>

        <Grid style={{ padding: '8px 8px 0px' }}>
          <Button
            value={'builder'}
            aria-label="left"
            size={'small'}
            color={'primary'}
            style={{}}
            classes={{
              root: tab === 0 ? classes.selecteTab : classes.selectedTab,
            }}
            onClick={() => tabChange(0)}
          >
            <Typography
              className={classes.primaryAndTemplate}
              component={'p'}
              variant={'caption'}
            >
              Primary
            </Typography>
          </Button>

          <Button
            value={'flow'}
            aria-label="centered"
            size={'small'}
            color={'primary'}
            style={{
              marginLeft: '10px',
            }}
            classes={{
              root: tab === 1 ? classes.selecteTab : classes.selectedTab,
            }}
            onClick={() => tabChange(1)}
          >
            <Typography
              className={classes.primaryAndTemplate}
              component={'p'}
              variant={'caption'}
            >
              Templates
            </Typography>
          </Button>
        </Grid>
      </div>

      {/* Component Type Tab END */}
      {tab === 0 && (
        <>
          <Grid container item style={{ marginTop: '12px' }}>
            <Grid xs={12}>
              <WebAppPanel
                groupedComponents={groupedComponents}
                getgrouperComponentsList={getgrouperComponentsList}
                open={open}
                handleTabChange={handleTabChange}
                value={value}
              />
            </Grid>
          </Grid>
        </>
      )}
      {tab === 1 && (
        <Grid
          item
          style={{
            marginTop: '12px',
            width: '100%',
            height: 'calc(100vh - 196px)',
            overflow: 'auto',
          }}
        >
          {/* {getComponentsList(ComponentsTemplateLists, open, "template")} */}
          <ProjectTemplates
            deleteOldScreen={props.deleteOldScreen}
            onClose={props.onClose}
            openModal={props.openModal}
          />
        </Grid>
      )}
    </div>
  )
}
