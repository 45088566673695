import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "../../../screens/uiBuilder/components/propertyspanel/styles";
// const useStyles = makeStyles((theme) => ({
//   textInput: {
//     '& input': {
//       fontSize: (prop) => prop?.fontSize
//     },
//     '& label': {
//       fontSize: (prop) => prop?.fontSize
//     }
//   }
// }));

export const Input = (props) => {
  const classes = useStyles(props);
  const { label, value, variant, onChange, style, type } = props;
  return (
    <TextField
      value={type === "number" ? parseInt(value) : value}
      onChange={onChange}
      label={label}
      type={type}
      // className={clsx(classes.textInput)}
      className={classes.textfieldcss}
      margin="dense"
      InputLabelProps={{
        ...props?.inputLabelProps,
      }}
      InputProps={{
        ...props?.inputProps,
      }}
      size="small"
      variant={variant}
      // style={style}
      {...props}
    />
  );
};

Input.defaultProps = {
  onChange: () => {},
  label: "Text Input",
  type: "text",
  inputLabelProps: {},
  variant: "outlined",
  inputProps: {},
  fontSize: "14px",
  value: "",
};
