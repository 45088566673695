import React from "react";
import {
  IconButton,
  Tooltip,
  makeStyles,
  fade,
  Grid,
  FormControlLabel,
  Avatar,
  Switch,
  Typography,
  InputBase,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import Layout from "../../../assets/img/icons8-layout.svg";
import DeviceHubRoundedIcon from "@material-ui/icons/DeviceHubRounded";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";
import { AlertContext } from "../../../contexts";
import { withAlertAndDialog } from "../../../HOC/index";
import cloneDeep from "lodash/cloneDeep";
import { alertProps } from "../../../utils";
import { readDocument, updateDocument } from "../../../apis";
import isEqual from "react-fast-compare";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import LockIcon from "@material-ui/icons/Lock";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "150px",
    "& .MuiDivider-root ": {
      height: "0px",
    },
  },
  search: {
    position: "relative !important",
    marginBottom: "14px !important",
    borderRadius: "4px !important",
    borderBottom: "1.3px solid rgba(0, 0, 0, 0.12) !important",
    border: "1.3px solid rgba(0, 0, 0, 0.12) !important",
    backgroundColor: fade("#fff", 0.15),
    "&:hover": {
      backgroundColor: fade("#fff", 0.25),
    },
    search: {
      position: "relative !important",
      marginBottom: "14px !important",
      borderRadius: "4px !important",
      borderBottom: "1.3px solid rgba(0, 0, 0, 0.12) !important",
      border: "1.3px solid rgba(0, 0, 0, 0.12) !important",
      backgroundColor: fade("#fff", 0.15),
      "&:hover": {
        backgroundColor: fade("#fff", 0.25),
      },
      width: "100% !important",
      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    searchIcon: {
      padding: "0px 6px !important",
      height: "100% !important",
      position: "absolute !important",
      pointerEvents: "none !important",
      display: "flex!important",
      alignItems: "center !important",
      justifyContent: "center!important",
    },
    inputRoot: {
      color: "inherit",
    },

    // addButton: {
    //   border: "1px solid " + theme.palette.text.hint,
    //   borderRadius: "4px",
    // },
    // switchButton: {
    //   margin: 0,
    //   justifyContent: "space-between",
    //   width: "100%",
    //   padding: "10px 0px",
    // },
    smallIcon: {
      fontSize: "12px!important ",
    },
    MenuItem: {
      color: "black",
      cursor: "pointer",
      borderRadius: "4px",
      textTransform: "capitalize",
      padding: "4px 2px 4px 4px",
    },
    dragIcon: {
      fontSize: "14px",
      margin: "0px 4px",
    },
    previewImage: {
      width: "26px",
      marginRight: "6px",
      fontSize: "12px",
      height: "32px",
    },
  },
  searchIcon: {
    padding: "0px 2px !important",
    height: "100% !important",
    position: "absolute !important",
    pointerEvents: "none !important",
    display: "flex!important",
    alignItems: "center !important",
    justifyContent: "center!important",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "11px",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  inputInput: {
    height: "0.4em",
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(2)}px) !important`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  addButton: {
    //border: "1px solid " + theme.palette.text.hint,
    borderRadius: "4px",
  },
  addIcon: {
    fontSize: "1rem",
  },
  switchButton: {
    margin: 0,
    justifyContent: "space-between",
    width: "100%",
    padding: "6px 0px",
    "& .MuiSwitch-sizeSmall .MuiSwitch-thumb": {
      width: "13px",
      height: "13px",
    },
    "& .MuiSwitch-sizeSmall": {
      width: "36px",
      height: "23px",
      padding: "8px",
    },
  },
  MenuItem: {
    cursor: "pointer",
    borderRadius: "4px",

    padding: "4px 2px 4px 4px",
    "&:hover": {
      background: "#f6f6f8",
    },
  },
  selected: {
    /* Increase the specificity */
    // color: "#3f51b5",
    backgroundColor: "#F6F6F8 !important",
  },
  dragIcon: {
    fontSize: "11px",
    margin: "0px 4px",
  },
  smallIcon: {
    fontSize: "12px!important ",
    color: "rgb(97, 95, 95)",
  },
  previewImage: {
    width: "20px",
    marginRight: "6px",
    fontSize: "10px",
    height: "20px",
  },
  addscreen: {
    fontSize: "10px",
    color: "#6E6E6E",
  },
}));

const options = ["Delete", "Edit"];
const initialState = {
  position: null,
  data: null,
};

function ScreenList(props) {
  const classes = useStyles();

  console.log("Allscreenref", props.auth.user.AllScreenList);
  const propee = props.auth.user.AllScreenList?.filter(
    (screen) => screen.type !== "decisionHandler"
  );
  console.log(propee);

  const [anchorEl, setAnchorEl] = React.useState(initialState);
  const [selectedAllScreen, setSelectedAllScreen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const open = Boolean(anchorEl.position);
  const alert = React.useContext(AlertContext);
  const [lock, setLock] = React.useState(true);
  const [isLockedBy, setIsLockedBy] = React.useState(null);
  const handleClick = (event, id) => {
    event.stopPropagation();
    setAnchorEl({
      ...anchorEl,
      position: event.currentTarget,
      data: id,
    });
  };

  const handleClose = () => {
    setAnchorEl(initialState);
  };

  const moreOptions = (e, type) => {
    e.stopPropagation();
    debugger;
    switch (type) {
      case "Delete":
        props.deleteOldScreen(anchorEl.data);
        handleClose();
        break;
      case "Edit":
        props.openModal(anchorEl.position, "edit", anchorEl.data);
        handleClose();
        break;
      case "Tree View":
        props.treeView(2);
        handleClose();
        break;
      default:
        handleClose();
        break;
    }
  };

  const onClickScreenButton = async (e, selected) => {
    debugger;
    let currentScreen = props.auth.user.screens[0];
    console.log("check", props.auth.user);
    let screenRef = props.auth.user.AllScreenListRef.find(
      (screen) => screen.id === currentScreen.id
    );
    let isItEqual = await isEqual(screenRef, currentScreen);
    // if (!isItEqual) {
    //   return alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: alertProps.severity.warning,
    //     msg: "Please save the changes",
    //     vertical: alertProps.vertical.top,
    //     horizontal: alertProps.horizontal.right,
    //     tone: true,
    //   });
    // } else {
    let selecte_id = props.auth.user.AllScreenList[0]?.id;
    props.onClose("right", false);
    if (selected === "checkBox") {
      let screen = props.auth.user.AllScreenList.filter(
        (val) => val.id === selecte_id
      );
      localStorage.setItem(
        "previewScreen",
        JSON.stringify(
          !selectedAllScreen ? props.auth.user.AllScreenList : screen
        )
      );
      sessionStorage.setItem(
        "previewScreen",
        JSON.stringify(
          !selectedAllScreen ? props.auth.user.AllScreenList : screen
        )
      );
      setSelectedAllScreen(!selectedAllScreen);
      props.auth.setAuth({
        ...props.auth.user,
        screens: !selectedAllScreen ? props.auth.user.AllScreenList : screen,
        selectedScreen: !selectedAllScreen ? null : selecte_id,
      });
    } else {
      let currentScreen = props.auth.user.screens[0];
      console.log(selected, currentScreen);
      if (selected.id === currentScreen.id) return null;

      let allScreenListRef = JSON.parse(
        JSON.stringify(props.auth.user?.AllScreenList)
      );
      console.log(allScreenListRef);

      let isCurrentScreenSaved = allScreenListRef.filter(
        (screen) => screen.id === selected.id
      );

      console.log(isCurrentScreenSaved, "isCurrentScreenSaved");

      if (isCurrentScreenSaved.length === 0) {
        return alert.setSnack({
          ...alert,
          open: true,
          severity: alertProps.severity.warning,
          msg: "Save the newly created screen",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.right,
          tone: true,
        });
      }

      // if (
      //   currentScreen?.readMode === false &&
      //   props.auth.user?.user?.name === props.auth.user?.screens[0]?.isLockedBy
      // ) {
      //   return alert.setSnack({
      //     ...alert,
      //     open: true,
      //     severity: alertProps.severity.warning,
      //     msg: "Switch the current screen to read mode before switching screen",
      //     vertical: alertProps.vertical.top,
      //     horizontal: alertProps.horizontal.right,
      //     tone: true,
      //   });
      // }

      let [screen] = props.auth.user.AllScreenList.filter(
        (val) => val.id === selected.id
      );
      let screenQuery = [
        {
          entity: "screens",
          filter: {
            id: selected.id,
            metadataid: localStorage.metaDataId,
            version: props.auth.user?.version ?? 1.0,
          },
        },
      ];
      let result = await readDocument(screenQuery);

      let isScreenUpdated = isEqual(result?.screens, screen);

      let allScreenList = JSON.parse(
        JSON.stringify(props.auth.user?.AllScreenList)
      );

      console.log(isScreenUpdated, result, screen);

      if (
        !isScreenUpdated &&
        Object.keys(result).length > 0 &&
        result?.screens?.readMode === false &&
        props.auth.user?.user?.name !== result?.screens?.isLockedBy
      ) {
        allScreenList.forEach((eachScreen, index, array) => {
          if (result.screens.id === eachScreen.id) {
            array[index] = { ...result.screens };
          }
        });
        console.log(allScreenList);
      }

      setSelectedAllScreen(false);
      localStorage.setItem(
        "previewScreen",
        JSON.stringify(allScreenList.filter((val) => val.id === selected.id))
      );
      sessionStorage.setItem(
        "previewScreen",
        JSON.stringify(allScreenList.filter((val) => val.id === selected.id))
      );
      props.auth.setAuth({
        ...props.auth.user,
        screens: allScreenList.filter((val) => val.id === selected.id),
        AllScreenList: allScreenList,
        AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
        selectedScreen: selected.id,
      });
    }
    // }
  };
  const onDragEnd = (result) => {
    debugger;
    if (!result.destination) {
      return;
    }
    const allScreenListCopy = cloneDeep(props.auth.user.AllScreenList);
    let drag_item_id = result.draggableId;
    let getIndexDragItem = result.source.index;
    let getIndexDropItem = result.destination.index;
    if (allScreenListCopy[getIndexDragItem + 1]?.type === "decisionHandler") {
      let cutOut = allScreenListCopy.splice(getIndexDragItem, 2);
      allScreenListCopy.splice(getIndexDropItem, 0, ...cutOut);
    } else {
      let cutOut = allScreenListCopy.splice(getIndexDragItem, 1)[0];
      allScreenListCopy.splice(getIndexDropItem, 0, cutOut);
    }

    console.log("copy", allScreenListCopy);
    props.auth.setAuth({
      ...props.auth.user,
      AllScreenList: allScreenListCopy,
    });
  };

  const handlelock = async (e, type) => {
    debugger;
    e.stopPropagation();
    let currentScreen = props.auth.user?.screens[0];
    let allScreenListRef = JSON.parse(
      JSON.stringify(props.auth.user?.AllScreenListRef)
    );
    let allScreenList = props.auth.user?.AllScreenList;
    let screenRef = allScreenListRef.find(
      (screen) => screen.id === currentScreen.id
    );
    let isItEqual = await isEqual(screenRef, currentScreen);
    if (!isItEqual) {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: "Please save the changes",
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
    alert.setSnack({
      ...alert,
      open: true,
      severity: "info",
      msg: "Please wait while refreshing the page",
      vertical: alertProps.vertical.top,
      horizontal: alertProps.horizontal.center,
      // tone: true,
    });

    await props.auth.refresh();

    //check the newly created screen is saved before updating

    //edit mode
    //lock
    const interval = setTimeout(async () => {
      if (
        props.auth.user?.user?.name !== props.auth.user?.screens[0]?.isLockedBy
      ) {
        let screenQuery = [
          {
            entity: "screens",
            filter: {
              id: props.auth.user?.selectedScreen,
              version: props.auth.user?.version ?? 1.0,
            },
          },
        ];
        let result = await readDocument(screenQuery);
        if (result?.screens?.lock === true) {
          //someone is already editing it
          setIsLockedBy(result?.screens?.isLockedBy);
          alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.warning,
            msg: `${result?.screens?.isLockedBy} currently editing this screen`,
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
          // props.auth.refresh()
          // setLock(false);
        } else {
          //Set edit mode
          let screenSchema = {
            ...props.auth.user?.screens[0],
            lock: true,
            readmode: false,
            isLockedBy: props.auth?.user?.user?.name,
          };
          let screenQuery = [
            {
              entity: "screens",
              body: screenSchema,
              filter: {
                id: screenSchema?.id,
                metadataid: localStorage.metaDataId,
                version: screenSchema?.version,
              },
            },
          ];
          await updateDocument(screenQuery)
            .then(() => {
              allScreenList.forEach((eachScreen, index, array) => {
                if (currentScreen.id === eachScreen?.id) {
                  array[index] = screenSchema;
                }
              });
              props.auth.setAuth({
                ...props.auth.user,
                screens: allScreenList.filter(
                  (val) => val.id === currentScreen.id
                ),
                AllScreenList: [...allScreenList],
                AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
              });
              setLock(true);
              setIsLockedBy("You");
              alert.setSnack({
                ...alert,
                open: true,
                severity: alertProps.severity.success,
                msg: `Current screen is locked`,
                vertical: alertProps.vertical.top,
                horizontal: alertProps.horizontal.right,
                tone: true,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        // props.auth.refresh()

        let screenSchema = {
          ...props.auth.user?.screens[0],
          lock: false,
          readmode: true,
          isLockedBy: "",
        };
        let screenQuery = [
          {
            entity: "screens",
            body: screenSchema,
            filter: {
              id: screenSchema?.id,
              version: screenSchema?.version,
            },
          },
        ];
        if (props.auth.user?.screens[0]?.lock === true) {
          //read mode
          let screenSchema = {
            ...props.auth.user?.screens[0],
            lock: false,
            readmode: true,
            isLockedBy: "",
          };
          let screenQuery = [
            {
              entity: "screens",
              body: screenSchema,
              filter: {
                id: screenSchema?.id,
                version: screenSchema?.version,
              },
            },
          ];
          await updateDocument(screenQuery)
            .then(() => {
              let allScreenList = JSON.parse(
                JSON.stringify(props.auth.user?.AllScreenList)
              );
              allScreenList.forEach((eachScreen, index, array) => {
                if (currentScreen.id === eachScreen?.id) {
                  array[index] = screenSchema;
                }
              });
              props.auth.setAuth({
                ...props.auth.user,
                screens: allScreenList.filter(
                  (val) => val.id === currentScreen.id
                ),
                AllScreenList: [...allScreenList],
                AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
              });
              setLock(false);
              setIsLockedBy(null);
              alert.setSnack({
                ...alert,
                open: true,
                severity: alertProps.severity.success,
                msg: `The current screen is unlocked`,
                vertical: alertProps.vertical.top,
                horizontal: alertProps.horizontal.right,
                tone: true,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          let screenSchema = {
            ...props.auth.user?.screens[0],
            lock: true,
            readmode: true,
            isLockedBy: "",
          };
          let screenQuery = [
            {
              entity: "screens",
              body: screenSchema,
              filter: {
                id: screenSchema?.id,
                version: screenSchema?.version,
              },
            },
          ];
          await updateDocument(screenQuery)
            .then(() => {
              let allScreenList = JSON.parse(
                JSON.stringify(props.auth.user?.AllScreenList)
              );
              allScreenList.forEach((eachScreen, index, array) => {
                if (currentScreen.id === eachScreen?.id) {
                  array[index] = screenSchema;
                }
              });
              props.auth.setAuth({
                ...props.auth.user,
                screens: allScreenList.filter(
                  (val) => val.id === currentScreen.id
                ),
                AllScreenList: [...allScreenList],
                AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
              });
              setLock(false);
              setIsLockedBy(null);
              alert.setSnack({
                ...alert,
                open: true,
                severity: alertProps.severity.success,
                msg: `The current screen is locked`,
                vertical: alertProps.vertical.top,
                horizontal: alertProps.horizontal.right,
                tone: true,
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    }, 1200);
  };
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
        style={{
          height: "35px",
          borderBottom: "2px solid #ebebeb",
          borderRight: "2px solid #ebebeb",
        }}
      >
        <Grid item>
          <Typography className={classes.addscreen}>SCREENS</Typography>
        </Grid>
        <Grid item style={{ paddingBottom: "3px" }}>
          <IconButton
            disabled={props.auth.user.loading}
            size={"small"}
            aria-label="show 4 new mails"
            color={"default"}
            aria-describedby={props.id}
            className={classes.addButton}
            onClick={(e) => {
              console.log("props:");
              props.selectSteppterScreenType(e);
            }}
          >
            <Tooltip title="Add new screen" placement="right-start">
              <AddIcon className={classes.addIcon} />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>

      {/* This is Show All screen switch  */}
      {/* <Grid
        container
        direction="row"
        justify="space-between"

        alignItems="center"
        style={{ padding: "0px 12px 0px", marginLeft:"5px" }}
        >
        <FormControlLabel
          disabled={props.auth.user.loading}
          className={classes.switchButton}
          control={
            <Switch
              checked={
                props.auth?.user?.screens?.length > 1 ? true : selectedAllScreen
              }
              onChange={() => onClickScreenButton("checkBox")}
              color="primary"
              name="checkedB"
              size={"small"}
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          }
          labelPlacement={"start"}
          label={
            <span style={{ fontSize: "9px" }} className={classes.addscreen}>
              Show All Screens
            </span>
          }
          classes={{ label: classes.smallIcon }}
        />
        
      </Grid> 
      <Divider bold />*/}

      {/* This is Input filed for search*/}
      <Grid style={{ padding: "5px 5px", borderRight: "2px solid #ebebeb" }}>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon fontSize={"small"} color={"action"} />
          </div>
          <InputBase
            disabled={props.auth.user.loading}
            placeholder="search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
        {/* Screens Lists*/}
        {console.log("screen search", search)}
        <DragDropContext onDragEnd={onDragEnd}>
          <Grid
            container
            item
            style={{
              justifyContent: "center",
              maxHeight: "61vh",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            id={"screenList"}
          >
            <Droppable droppableId="droppable" type="screens">
              {(dropProvided, dropSnapshot) => (
                <div
                  {...dropProvided.draggableProps}
                  key="droppable"
                  ref={dropProvided.innerRef}
                  // style={{
                  //   background: `${dropSnapshot.isDraggingOver ? "#F9FAFB" : "white"
                  //     }`,
                  //   borderRadius: `${dropSnapshot.isDraggingOver ? "5px" : "0px"
                  //     }`,
                  //   padding: `${dropSnapshot.isDraggingOver ? "10px 4px" : "0px 0px 0px 0px"
                  //     }`,
                  // }}
                >
                  {props.auth.user.AllScreenList?.filter(
                    (screen) => screen.type !== "decisionHandler"
                  ).map((list, index) => {
                    return (
                      <>
                        {list.name
                          .toLowerCase()
                          .includes(search.toLowerCase()) && (
                          <Draggable
                            key={list.id}
                            draggableId={list.id}
                            index={index}
                          >
                            {(dragProvided, dragSnapshot) => (
                              <div
                                {...dragProvided.draggableProps}
                                ref={dragProvided.innerRef}
                                key={list.id}
                                index={index}
                              >
                                <ListItem
                                  title={list?.isLayout ? "Layout" : "Screen"}
                                  key={list.id}
                                  // draggable={true}
                                  id={list.id}
                                  style={{
                                    width: "140px",

                                    margin: "3px 0px",
                                    paddingLeft: "4px",
                                    paddingRight: "0px",
                                    borderRadius: "13px",
                                    display: `${
                                      list.type === "decisionHandler"
                                        ? "none"
                                        : "flex"
                                    }`,
                                  }}
                                  dense
                                  selected={
                                    props.auth.user.selectedScreen === list.id
                                      ? true
                                      : false
                                  }
                                  classes={{
                                    root: classes.MenuItem,
                                    selected: classes.selected,
                                  }}
                                  onClick={(e) => onClickScreenButton(e, list)}
                                >
                                  <span {...dragProvided.dragHandleProps}>
                                    <DragIndicatorIcon
                                      className={classes.dragIcon}
                                    />
                                  </span>
                                  <Avatar
                                    alt={list.name.toUpperCase()}
                                    // onClick={() => onClickScreenButton(list)}
                                    className={classes.previewImage}
                                    variant="rounded"
                                    src={
                                      list?.image ??
                                      "/static/images/avatar/1.jpg"
                                    }
                                  />
                                  {list?.name?.length > 25 ? (
                                    <Tooltip
                                      title={`ScreenName:${list.name} robo`}
                                    >
                                      <ListItemText
                                        style={{
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                        }}
                                        // onClick={() =>
                                        //   onClickScreenButton(list)
                                        // }
                                      >
                                        <span
                                          style={{
                                            fontSize: "9px",
                                            color: "#4b4b4d",
                                          }}
                                          className="roboto-font"
                                        >
                                          {list.name}
                                        </span>
                                      </ListItemText>
                                    </Tooltip>
                                  ) : (
                                    <ListItemText
                                      style={{
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                      }}
                                      // onClick={() => onClickScreenButton(list)}
                                    >
                                      <span
                                        style={{
                                          fontSize: "9px",
                                          fontWeight: "200",
                                          color: "#4b4b4d",
                                        }}
                                        className="roboto-font"
                                      >
                                        {list.name}
                                      </span>
                                    </ListItemText>
                                  )}
                                  {props.auth.user.selectedScreen ===
                                    list.id && (
                                    <>
                                      {list.lock === true && (
                                        <Tooltip
                                          placement="top"
                                          arrow
                                          title={
                                            props.auth.user?.user?.name ===
                                            props.auth.user?.screens[0]
                                              ?.isLockedBy
                                              ? "is locked by you"
                                              : `is locked by ${props.auth.user?.screens[0]?.isLockedBy}`
                                          }
                                        >
                                          <IconButton
                                            size={"small"}
                                            onClick={(e) =>
                                              handlelock(e, list.lock)
                                            }
                                            // disabled={props.auth.user?.user?.name ===props.auth.user?.screens[0]?.isLockedBy || props.auth.user?.screens[0]?.isLockedBy =="" ?false:true}
                                          >
                                            <LockIcon
                                              fontSize="small"
                                              color={
                                                props.auth.user?.user?.name !==
                                                  props.auth.user?.screens[0]
                                                    ?.isLockedBy &&
                                                props.auth.user?.screens[0]
                                                  ?.isLockedBy !== ""
                                                  ? "error"
                                                  : props.auth.user?.screens[0]
                                                      ?.isLockedBy === ""
                                                  ? "success"
                                                  : "primary"
                                              }
                                              style={{
                                                width: "15px",
                                                height: "15px",
                                              }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      {list.lock === false && (
                                        <IconButton
                                          size={"small"}
                                          onClick={(e) =>
                                            handlelock(e, list.lock)
                                          }
                                          disabled={
                                            props.auth.user?.user?.name ===
                                              props.auth.user?.screens[0]
                                                ?.isLockedBy ||
                                            props.auth.user?.screens[0]
                                              ?.isLockedBy == ""
                                              ? false
                                              : true
                                          }
                                        >
                                          <LockOpenIcon
                                            fontSize="small"
                                            color={
                                              props.auth.user?.user?.name !==
                                                props.auth.user?.screens[0]
                                                  ?.isLockedBy &&
                                              props.auth.user?.screens[0]
                                                ?.isLockedBy !== ""
                                                ? "error"
                                                : props.auth.user?.screens[0]
                                                    ?.isLockedBy === ""
                                                ? "success"
                                                : "primary"
                                            }
                                            style={{
                                              width: "15px",
                                              height: "15px",
                                            }}
                                          />
                                        </IconButton>
                                      )}
                                    </>
                                  )}
                                  {list?.isLayoutScreen && (
                                    <IconButton
                                      size={"small"}
                                      aria-label="more"
                                      aria-controls="long-menu"
                                      aria-haspopup="true"
                                    >
                                      <img
                                        src={Layout}
                                        alt="layout"
                                        width="14px"
                                        height="auto"
                                      />
                                    </IconButton>
                                  )}
                                  {props.auth.user.selectedScreen ===
                                    list.id && (
                                    <>
                                      <IconButton
                                        size={"small"}
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={(e) => handleClick(e, list.id)}
                                      >
                                        <MoreVertIcon
                                          fontSize={"small"}
                                          color={"action"}
                                        />
                                      </IconButton>
                                    </>
                                  )}
                                </ListItem>
                              </div>
                            )}
                          </Draggable>
                        )}
                      </>
                    );
                  })}
                  {dropProvided.placeholder}
                </div>
              )}
            </Droppable>
          </Grid>
        </DragDropContext>

        {/* Screen List end */}
      </Grid>

      <Menu
        id="long-menu"
        anchorEl={anchorEl.position}
        keepMounted
        open={open}
        style={{
          width: "100px",
          height: "160px",
        }}
        onClose={() => handleClose()}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {options.map((option) => (
          <MenuItem
            dense
            key={option}
            selected={option === "Pyxis"}
            onClick={(e) => moreOptions(e, option)}
            style={{
              fontSize: "9px",
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

ScreenList.defaultProps = {
  onSwitchPreview: () => {},
  previewScreen: false,
  treeView: false,
  setState: () => {},
  deleteOldScreen: () => {},
  openModal: () => {},
  menusList: [],
  selected: 1,
  selectedAllScreen: false,
  onClickScreenButton: () => {},
};

export default withAlertAndDialog(ScreenList);
