import { useContext } from "react";
import {
  getProjectComponents,
  IconSelect,
  ImageUpload,
  MessageCatalog,
  MuiInput,
  MuiMultiSelect,
  MuiSelect,
  PageSelect,
  getscreenComponent
} from "../../../../components";
import { ArrayOfObject } from "../../../../components/arrayOfObject";
import { MuiAutocomplete } from "../../../../components/common/inputs/autocomplete";
import { MuiTextArea } from "../../../../components/common/inputs/textArea";
import { AlertContext, AuthContext } from "../../../../contexts";
import { alertProps, getProjectPages } from "../../../../utils";

const propertyProducer = (props, alert, auth) => {
  const {
    data,
    properties,
    propertyKey,
    component,
    propertiesOptions,
    handleChange,
    index,
  } = props;

  switch (propertiesOptions.componentToRender) {
    case "text":
    case "number":
      return (
        <MuiInput
          value={properties[propertyKey] ?? ""}
          onChange={(e) => {
            const value =
              propertiesOptions.componentToRender === "number"
                ? e.target?.value
                  ? parseInt(e.target?.value)
                  : e.target?.value
                : e.target.value;
            let pattern;
            if (component === "Box") {
              pattern = /^[a-z]{1,}$/;
              const result = pattern.test(value ?? "");
              if (!result && value)
                return alert.setSnack({
                  ...alert,
                  open: true,
                  severity: alertProps.severity.warning,
                  msg: "Not a valid input",
                  vertical: alertProps.vertical.top,
                  horizontal: alertProps.horizontal.right,
                  tone: true,
                });
            }
            handleChange(propertyKey, value, index);
          }}
          label={propertyKey}
        />
      );
    case "messageCatalog":
      return (
        <MessageCatalog
          value={properties[propertyKey]}
          label={propertyKey}
          onChange={(value) => {
            handleChange(propertyKey, value, index);
            data["isTranslate"] = true;
          }}
          data={data}
        />
      );
    case "select":
      return (
        <MuiSelect
          value={properties[propertyKey] ?? ""}
          onChange={(e) => {
            handleChange(propertyKey, e.target.value, index);
          }}
          label={propertyKey}
          options={propertiesOptions.options}
        />
      );
    case "icons":
      return (
        <IconSelect
          value={properties[propertyKey]?.iconName ?? ""}
          onChange={(value) => {
            handleChange(propertyKey, value, index);
          }}
          label={propertyKey}
        />
      );
    case "imageUpload":
      return (
        <ImageUpload
          onChange={(url) => {
            handleChange(propertyKey, url);
          }}
          value={properties[propertyKey] ?? ""}
        />
      );
    case "multiSelect":
      return (
        <MuiMultiSelect
          value={properties[propertyKey] ?? ""}
          onChange={(value) => {
            console.log(value, "value");
            handleChange(propertyKey, value, index);
          }}
          creatable={propertiesOptions?.creatable ?? true}
          label={propertyKey}
          options={propertiesOptions?.options ?? []}
        />
      );
    case "component":
      let components = getProjectComponents(auth);
      return (
        <MuiAutocomplete
          value={properties[propertyKey] ?? ""}
          onChange={(e, value) => {
            handleChange(propertyKey, value, index);
          }}
          label={propertyKey}
          options={components ?? []}
          optionViewKey="componentName"
        />
      );
      case "screen":
        let screens = getscreenComponent(auth);
         console.log("screens" , screens)
        return (
          <MuiAutocomplete
            value={properties[propertyKey] ?? ""}
            onChange={(e, value) => {
              handleChange(propertyKey, value, index);
            }}
            label={propertyKey}
            options={screens ?? []}
            optionViewKey="componentpath"
          />
        );
    case "pageSelect":
      let pages = getProjectPages(auth);
      //to get filtered Options
      if (properties["navbarType"] === "list") {
        pages = (pages ?? []).filter((page) => {
          if (
            !properties[propertyKey]?.some((v) => v?.pageId === page?.pageId)
          ) {
            return true;
          } else {
            return false;
          }
        });
      } else {
        pages = (pages ?? []).filter((page) => {
          if (
            properties[propertyKey]?.filter((parentNav) => {
              return (
                parentNav?.navItems?.length > 0 &&
                parentNav?.navItems?.some((nav) => nav?.pageId === page?.pageId)
              );
            }).length > 0
          ) {
            return false;
          } else {
            return true;
          }
        });
      }
      return (
        <PageSelect
          pages={pages}
          navItems={properties[propertyKey] ?? []}
          getNavItems={(navItemsData) => {
            handleChange(propertyKey, navItemsData, index);
          }}
          nested={properties["navbarType"] === "list" ? false : true}
        />
      );
    case "themeGetter":
      return (
        <MuiTextArea
          minRows={12}
          maxRows={32}
          value={properties[propertyKey] ?? ""}
          onChange={(e) => {
            handleChange(propertyKey, e.target.value, index);
          }}
          placeholder={
            "Insert a valid material ui theme JSON - https://bareynol.github.io/mui-theme-creator/"
          }
        />
      );
    case "ArrayOfObject":
      return (
        <ArrayOfObject
          handleChange={handleChange}
          toRender={propertiesOptions.value[0]}
          name={propertyKey}
          values={properties[propertyKey] ?? []}
          data={data}
        />
      );
    default:
      break;
  }
};

export const PropertyRenderer = (props) => {
  const alert = useContext(AlertContext);
  const auth = useContext(AuthContext);
  return <>{propertyProducer(props, alert, auth)}</>;
};
