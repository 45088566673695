import { readDocument, readDocuments } from "./crud";
// import { metadata_id } from "./constants"
import Config from "../config";
import { networkCall } from "../networkcall";
import config from "../config";

export const getAllUIComponents = async () => {
  let { data } = await networkCall(Config.api_url + "/read_documents", "POST", {
    db_name: Config.db_name,
    entity: "UIcomponents",
    filter: "UIcomponents.active==true",
    return_fields: "UIcomponents",
  });
  return data.result ?? [];
};

export async function readUIBuilderDoc(metaDataId) {
  let uibuilderQuery = [
    {
      entity: "uibuilder",
      filter: {
        metadataid: metaDataId,
      },
    },
  ];

  let result = await readDocument(uibuilderQuery, {
    sort: "uibuilder.version desc",
  });
  return result?.uibuilder ?? {};
}
//Read projectvstools
export async function readProjectVsTools(metaDataId) {
  let projectVsToolsQuery = [
    {
      entity: "projectvstools",
      filter: {
        metadataid: metaDataId,
      },
    },
  ];

  let result = await readDocument(projectVsToolsQuery);
  return result?.projectvstools ?? {};
}
export const getScreenIndex = async (metaDataId, version) => {
  debugger;
  let screenQuery = [
    {
      entity: "screens",
      filter: {
        metadataid: metaDataId,
        version: version ?? 1.0,
        activestatus: true,
      },
    },
  ];
  const allScreens = await readDocuments(screenQuery, { limit: 100 });
  console.log(allScreens, "allScreens");
  return allScreens.length + 1;
};

export const build = async () => {
  let dataList = {
    projectName: localStorage.getItem("projectName"),
    metadataId: localStorage.getItem("metaDataId"),
  };

  let data = JSON.stringify(dataList);
  let response = await networkCall(
    `${config.compiler_url}/clone`,
    "POST",
    data,
    {
      "Content-Type": "application/json",
    }
  );
  console.log("response", response);
  if (response?.data?.Code !== 200) {
    console.log(
      response?.data?.error ??
        response?.data?.Errormsg ??
        "Something went wrong when triggering build..."
    );
    // resolve(
    //   response?.data?.error ??
    //     response?.data?.Errormsg ??
    //     "Something went wrong when creating documents"
    // );
  } else {
    let result = response?.data?.Result ?? response?.data?.result;
    console.log(result);
    // resolve(result);
  }
};

//Read project_templates
export async function readProjectTemplates(metaDataId, version) {
  let projectTemplatesQuery = [
    {
      entity: "project_templates",
      filter: {
        metadataid: metaDataId,
        version: version,
      },
    },
  ];
  let result = await readDocument(projectTemplatesQuery);
  return result?.project_templates ?? {};
}
