import React, { useEffect } from "react";
import {
  makeStyles,
  Typography,
  TextField,
  IconButton,
  Select,
  Button,
  FormControl,
  InputLabel,
  Divider,
  MenuItem,
} from "@material-ui/core";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import FilterListIcon from "@material-ui/icons/FilterList";
import AlbumIcon from "@material-ui/icons/Album";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import { AuthContext } from "../../../contexts";
import TreeItem from "@material-ui/lab/TreeItem";
import TreeView from "@material-ui/lab/TreeView";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

import { QpasActions } from "./qpasUi";
import config from "../../../config";

const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.entity,
});

const useStyles = makeStyles((theme) => ({
  body: {
    padding: "20px 16px",
  },
  formControl: {
    minWidth: 140,
    margin: 0,
    marginRight: "2%",
    marginTop: "3%",
  },
  formControlsort: {
    minWidth: 154,
    margin: 0,
    marginRight: "2%",
    marginTop: "2%",
  },
  filterdiv: {},
  icon: {
    fontSize: 16,
    color: "#007aff",
    marginRight: "2%",
  },
  iteam: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "3%",
    fontSize: 16,
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "8%",
  },
  addbtn: {
    padding: 4,
    border: "1px solid",
    borderColor: "#e0e0e0",
    borderRadius: 6,
    color: "gray",
    zIndex: 9,
  },
  treeItemDiv: {
    display: "flex",
    padding: 8,
    justifyContent: "space-between",
    backgroundColor: "white",
    marginLeft: "-4px",
  },
  addfilterBtn: {
    padding: 0,
    marginTop: "4%",
    fontSize: 14,
    textTransform: "none",
  },
  btnDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
}));

const EntitymappingSec = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <div className={classes.title}>
        <Typography variant="h6" component="h5">
          Scene
        </Typography>
      </div>
      <QpasActions {...props} />
      <div className={classes.title}>
        <Typography variant="h6" component="h5">
          Entities
        </Typography>
        <IconButton color="primary" component="span" className={classes.addbtn}>
          <AddIcon style={{ fontSize: 16 }} />
        </IconButton>
      </div>
      {/* Entities */}
      <Autocomplete
        multiple
        size={"small"}
        value={props.entitiesValue}
        style={{ paddingBottom: "6%", height: "6%" }}
        id="filter-demo"
        options={props.EnitiesDropDown}
        filterSelectedOptions
        onChange={(event, newValue) => props.onChangefun(event, newValue)}
        getOptionLabel={(option) => (option?.entity ? option?.entity : "")}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField {...params} label="Search" variant="outlined" />
        )}
      />
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ArrowDropDownIcon style={{ fontSize: 30 }} />}
        defaultExpandIcon={<ArrowRightIcon style={{ fontSize: 30 }} />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        {props.entitiesValue?.map((val, index) => {
          return (
            <>
              <TreeItem
                style={{ marginBottom: "2%" }}
                nodeId={index}
                label={
                  <div className={classes.treeItemDiv}>
                    <Typography variant="subtitle1">{val?.entity}</Typography>
                    <IconButton
                      color="primary"
                      component="span"
                      className={classes.addbtn}
                      onClick={(e) => props.filterClick(e, val?.entity)}
                    >
                      <FilterListIcon style={{ fontSize: 14 }} />
                    </IconButton>
                  </div>
                }
              >
                {val?.column &&
                  val?.column.map((value, index) => {
                    return (
                      <div className={classes.iteam}>
                        <AlbumIcon className={classes.icon} />
                        &nbsp;
                        <p style={{ margin: 0, marginBottom: "1%" }}>
                          {value?.name}
                        </p>{" "}
                        &nbsp;
                        {/* <Link className={classes.icon} />{" "}
                      <Chip
                        size={"small"}
                        label="category_name"
                        onDelete={handleDelete}
                        color="primary"
                        variant="outlined"
                      /> */}
                      </div>
                    );
                  })}
              </TreeItem>
            </>
          );
        })}
      </TreeView>
    </div>
  );
};

const FilterSec = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          color="primary"
          component="span"
          onClick={(e) => props.filterClick(e)}
          style={{ padding: 0 }}
        >
          <ArrowBackIosIcon style={{ fontSize: 14, color: "#afafaf" }} />
        </IconButton>
        &nbsp;&nbsp;
        <Typography
          variant="subtitle1"
          component="h6"
          style={{ fontWeight: 500 }}
        >
          {props.FilterTitle ? props.FilterTitle : "title"}
        </Typography>
      </div>
      <div style={{ marginTop: "6%" }}>
        <Typography
          variant="body1"
          component="h6"
          style={{ color: "#696969", fontWeight: "500" }}
        >
          Filters
        </Typography>
        <div className={classes.filterdiv}>
          {props.filtercout?.map((value, index) => {
            return (
              <div>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size={"small"}
                >
                  <InputLabel id="label1">filter</InputLabel>
                  <Select
                    labelId="demo-1"
                    id="demo-1"
                    label="filter"
                    onChange={(e, val) =>
                      props.filterFun(value.id, val, "columnName")
                    }
                    defaultValue={value.columnName}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {props.entitiesValue[0].column.map((val, i) => {
                      return <MenuItem value={val.name}>{val.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size={"small"}
                >
                  <InputLabel id="label2">filter</InputLabel>
                  <Select
                    labelId="demo-2"
                    id="demo-2"
                    label="filter"
                    onChange={(e, val) =>
                      props.filterFun(value.id, val, "condtion")
                    }
                    defaultValue={value.condtion}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"=="}>equals_to</MenuItem>
                    <MenuItem value={"!="}>not_equals</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size={"small"}
                >
                  <TextField
                    label="filter"
                    onChange={(e, val) => props.filterFun(e, value.id, "value")}
                    defaultValue={value.value}
                    variant="outlined"
                    size="small"
                  />
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  size={"small"}
                >
                  <InputLabel id="label3">filter</InputLabel>
                  <Select
                    labelId="demo-3"
                    id="demo-3"
                    label="filter"
                    onChange={(e, val) =>
                      props.filterFun(value.id, val, "filterType")
                    }
                    defaultValue={value.filterType}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"and"}>and</MenuItem>
                    <MenuItem value={"or"}>or</MenuItem>
                    <MenuItem value={"not"}>not</MenuItem>
                  </Select>
                </FormControl>
                <div className={classes.btnDiv}>
                  <Button
                    color="secondary"
                    onClick={(e, val) =>
                      props.filterFun(e, value.id, "deleteflitter")
                    }
                    className={classes.addfilterBtn}
                  >
                    <DeleteIcon style={{ fontSize: 14 }} /> &nbsp; Remove
                  </Button>
                </div>
              </div>
            );
          })}
          <Button
            color="primary"
            onClick={(e, val) => props.filterFun(e, val, "addflitter")}
            className={classes.addfilterBtn}
          >
            <AddIcon style={{ fontSize: 14 }} /> &nbsp; ADD FILTER
          </Button>
          <Divider style={{ margin: "16px 0px", height: 4 }} />
        </div>
      </div>
      <div style={{ marginTop: "6%" }}>
        <Typography
          variant="body1"
          component="h6"
          style={{ color: "#696969", fontWeight: "500" }}
        >
          Sort
        </Typography>
        <div className={classes.filterdiv}>
          {props.sortcount?.map((value, index) => {
            return (
              <div>
                <FormControl
                  variant="outlined"
                  className={classes.formControlsort}
                  size={"small"}
                >
                  <InputLabel id="label1">Sort</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    label="Sort"
                    onChange={(e, val) =>
                      props.onChangeSort(value.id, val, "columnName")
                    }
                    defaultValue={value.columnName}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"id"}>id</MenuItem>
                    <MenuItem value={"category_name"}>category_name</MenuItem>
                    <MenuItem value={"category_img_url"}>
                      category_img_url
                    </MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  className={classes.formControlsort}
                  size={"small"}
                >
                  <InputLabel id="label1">Sort</InputLabel>
                  <Select
                    labelId="demo-5"
                    id="demo-5"
                    label="Sort"
                    onChange={(e, val) =>
                      props.onChangeSort(value.id, val, "sorttype")
                    }
                    defaultValue={value.condtion}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"ascending"}>Ascending</MenuItem>
                    <MenuItem value={"descending"}>Descending</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  color="secondary"
                  onClick={(e) => props.onChangeSort(e, value.id, "deletesort")}
                  className={classes.addfilterBtn}
                >
                  <DeleteIcon style={{ fontSize: 14 }} /> &nbsp; Remove
                </Button>
                <Divider style={{ margin: "16px 0px" }} />
              </div>
            );
          })}
          <div className={classes.btnDiv}>
            <Button
              color="primary"
              onClick={(e, val) => props.onChangeSort(e, val, "addsort")}
              className={classes.addfilterBtn}
            >
              <AddIcon style={{ fontSize: 14 }} /> &nbsp; ADD SORT
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Entitymapping = (props) => {
  console.log(props);
  const [Filter, setFilter] = React.useState(false);
  const [FilterTitle, setFilterTitle] = React.useState("");

  const [entitiesValue, setentitiesValue] = React.useState([]);
  const [EnitiesDropDown, setEnitiesDropDown] = React.useState([]);

  const [filterData, setfilterData] = React.useState([]);
  const [QpassScene, setQpassScene] = React.useState([]);
  const [screenMapArray, setscreenMapArray] = React.useState([]);
  const auth = React.useContext(AuthContext);
  const EntitiesData = [];
  const FindScene = (arr) => {
    let list = [];
    // eslint-disable-next-line
    arr.map((val) => {
      if (val.scene) {
        let listarr = getscene(val.scene, val);
        list = [...list, ...listarr];
      }
    });
    return list;
  };
  const getscene = (arr, scene) => {
    let list = [];
    // eslint-disable-next-line
    arr.map((val) => {
      if (val?.title?.projectName?.[0]) {
        list.push({
          sceneId: val.id,
          label: Array.isArray(val?.title?.projectName)
            ? val?.title?.projectName?.[0]
            : val?.title?.projectName,
          actName: scene.actName,
          actId: scene.actid,
          playName: scene.playName,
          playId: scene.playid,
          storyId: scene.storyId,
          storyName: scene.storyName,
          entity:
            scene?.entity?.length > 0
              ? scene?.entity?.map((val) => val.entity)
              : [],
        });
      }
    });
    return list;
  };

  // get entity data list based on scenes list
  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var list =
      screenMapArray?.length > 0
        ? screenMapArray
            ?.map((val) => val.entity)
            .join()
            .split(",")
        : [];
    var a = [];
    list.map((val) => {
      if (a.indexOf(val) === -1) {
        a.push(val);
      }
    });
    var EntitiesRaw = JSON.stringify({
      // filter: {
      //   columname: "metadataId",
      //   operator: "equals",
      //   value: metaDataId_get_url ? metaDataId_get_url : "",
      // },
      // "db_name": "Test_User_Entity",
      db_name: localStorage.projectName.replace(/\s/g, "_"),
      entity: a,
    });

    var EntitiesRequestOptions = {
      method: "POST",
      headers: myHeaders,
      body: EntitiesRaw,
      redirect: "follow",
    };
    const fetchDataEntities = async () => {
      // const response = await MyAPI.getData(someId);
      await fetch(
        "http://164.52.210.54:8080/api/getcollectionattributes",
        EntitiesRequestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          res.Result.map((val, i) => {
            Object.keys(val).map((tabelName) => {
              var objectTable = val[tabelName].length > 0 ? val[tabelName] : [];

              // var tableColums = objectTable.columns;

              var FiledsNames = [];

              objectTable.map((name) => {
                FiledsNames.push({ name: name.columnName });
              });

              var getData = {
                id: i,
                entity: tabelName,
                column: FiledsNames,
              };
              EntitiesData.push(getData);
            });
          });
          setEnitiesDropDown(EntitiesData);
        })
        .catch((error) => console.log("error", error));
    };
    if (screenMapArray.length > 0) {
      fetchDataEntities();
    } else {
      setEnitiesDropDown([]);
      setentitiesValue([]);
    }
  }, [screenMapArray, props]);

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // Entities level data api
    // var metaDataId_get_url = localStorage.getItem("metaDataId");

    // Qpas data api
    var UidesignerModelerid = localStorage.getItem("UidesignerModelerid");
    var QpasRaw = JSON.stringify({
      db_name: config.db_name,
      entity: "QPAS",
      filter: `QPAS.qpasid == '${
        UidesignerModelerid ? UidesignerModelerid : ""
      }'`,
      return_fields: "{QPAS}",
    });
    var QpasRequestOptions = {
      method: "POST",
      headers: myHeaders,
      body: QpasRaw,
      redirect: "follow",
    };

    const fetchDataQpas = async () => {
      await fetch(
        "http://164.52.210.54:8080/api/read_documents",
        QpasRequestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          var Data = res.result[res.result.length - 1].QPAS;
          var SceneArray = FindScene(Data.attributes.scene);
          setQpassScene(SceneArray);
        })
        .catch((error) => console.log("error", error));
    };

    const funCall = () => {
      // fetchDataEntities();
      fetchDataQpas();
    };
    funCall();
    /// props  update - not working
    // var getentitiesval = props.Data.filter(({ id }) => id === props.id);
    // if (getentitiesval[0]?.models) {
    //   setentitiesValue(
    //     Array.isArray(getentitiesval[0].models[0].EntityDetails)
    //       ? getentitiesval[0].models[0].EntityDetails
    //       : [getentitiesval[0].models[0].EntityDetails]
    //   );
    //   setfilterData(getentitiesval);
    // }

    ///replaced by auth update
    let screenIndex = auth.user.AllScreenList.findIndex(
      (item) => item.id === props.id
    );
    let getentitiesval = auth.user.AllScreenList[screenIndex];
    if (getentitiesval?.models) {
      setentitiesValue(
        Array.isArray(getentitiesval.models[0].EntityDetails)
          ? getentitiesval.models[0].EntityDetails
          : [getentitiesval.models[0].EntityDetails]
      );
      setfilterData(getentitiesval);
    }

    var getsceneData =
      props?.Data?.filter((val) => val.id === props.id)?.[0]?.scenes ?? null;
    if (getsceneData) {
      setscreenMapArray(getsceneData);
    }
    // if (getentitiesval[0].qpas) {
    //   debugger;
    //   setscreenMapArray(getentitiesval[0].qpas.Screens.QpassScene);
    // }
    // eslint-disable-next-line
    // }, []);
  }, [props.id]);

  const onChangefun = (e, val) => {
    let screenIndex = auth.user.AllScreenList.findIndex(
      (item) => item.id === props.id
    );
    let lastEleIndex = val.length - 1;
    let screen = auth.user.AllScreenList[screenIndex];
    if (val && val.length > 0) {
      setentitiesValue(val);
      if (!screen?.models) {
        screen.models = [
          {
            type: "graphql",
            name: screen.name,
            endPoint: null,
            requestType: "POST",
            EntityDetails: val,
            filter: [],
            sort: [],
          },
        ];
        screen.actions = [
          {
            action: val[lastEleIndex].entity,
            isInitial: true,
            state: `state?.${screen.name.replaceAll(" ", "")}${
              val[lastEleIndex].entity
            }Reducer?.${val.entity}`,
            prop: val[lastEleIndex].entity + "Data",
          },
        ];
      } else {
        screen.models = [
          {
            type: "graphql",
            name: screen.name,
            endPoint: null,
            requestType: "POST",
            EntityDetails: val,
            filter: [],
            sort: [],
          },
        ];
        screen.actions = [];
        val.map((item) => {
          screen.actions.push({
            action: item.entity,
            isInitial: true,
            state: `state?.${screen.name.replaceAll(" ", "")}${
              item.entity
            }Reducer?.${item.entity}`,
            prop: item.entity + "Data",
          });
        });
      }
      setfilterData(screen);
    } else {
      delete screen.models;
      delete screen.actions;
      setentitiesValue([]);
      setfilterData([]);
    }
    auth.setAuth({
      ...auth.user,
      AllScreenList: auth.user.AllScreenList,
      isEdited: false,
    });
  };

  const onChangeScenedropdowns = (newValue) => {
    if (newValue) {
      screenMapArray.push(newValue);
      setscreenMapArray([...screenMapArray]);
      let Array = QpassScene.filter(function (item) {
        return item.sceneId !== newValue.sceneId;
      });
      setQpassScene(Array);

      var matadataval = props.Data.filter(function (item) {
        return item.id === props.id;
      });
      matadataval[0].scenes = screenMapArray;
    }
  };
  const removeSceenFun = (id) => {
    let addOriginalArray = screenMapArray.filter(function (item) {
      return item.sceneId === id;
    });

    QpassScene.push(addOriginalArray[0]);
    setQpassScene([...QpassScene]);
    let Array = screenMapArray.filter(function (item) {
      return item.sceneId !== id;
    });
    setscreenMapArray(Array);

    // var matadataval = props.Data.filter(function (item) {
    //   return item.id == props.id;
    // });
    // matadataval[0].qpas = {
    //   QpassScene,
    // };
  };
  const filterClick = (e, title) => {
    setFilter(!Filter);
    setFilterTitle(title);
  };

  const filterFun = (e, val, type) => {
    if (type === "addflitter") {
      var json = {
        id: btoa(Math.random()).substr(10, 5),
        filterType: null,
        columnName: "",
        condtion: "",
        value: "",
      };

      filterData[0].models[0].filter.push(json);
      setfilterData([...filterData]);
    } else if (type === "deleteflitter") {
      var filtered = filterData[0].models[0].filter.filter(function (item) {
        return item.id !== val;
      });
      filterData[0].models[0].filter = filtered;
      setfilterData([...filterData]);
    } else if (type === "columnName") {
      filterData[0].models[0].filter.find((ele) => ele.id === e).columnName =
        val.props.value;
    } else if (type === "condtion") {
      filterData[0].models[0].filter.find((ele) => ele.id === e).condtion =
        val.props.value;
    } else if (type === "value") {
      filterData[0].models[0].filter.find((ele) => ele.id === val).value =
        e.target.value;
    } else if (type === "filterType") {
      filterData[0].models[0].filter.find((ele) => ele.id === e).filterType =
        val.props.value;
    }
  };

  const onChangeSort = (e, value, type) => {
    if (type === "addsort") {
      var json = {
        id: btoa(Math.random()).substr(10, 5),
        columnName: "",
        condtion: "",
      };
      filterData[0].models[0].sort.push(json);
      setfilterData([...filterData]);
    } else if (type === "deletesort") {
      var filtered = filterData[0].models[0].sort.filter(function (item) {
        return item.id !== value;
      });
      filterData[0].models[0].sort = filtered;
      setfilterData([...filterData]);
    } else if (type === "columnName") {
      filterData[0].models[0].sort.find((ele) => ele.id === e).columnName =
        value.props.value;
    } else if (type === "sorttype") {
      filterData[0].models[0].sort.find((ele) => ele.id === e).condtion =
        value.props.value;
    }
  };

  return Filter ? (
    <FilterSec
      filterClick={(e) => filterClick(e)}
      FilterTitle={FilterTitle}
      filtercout={filterData[0]?.models ? filterData[0].models[0].filter : null}
      sortcount={filterData[0]?.models ? filterData[0].models[0].sort : null}
      filterFun={(e, val, type) => filterFun(e, val, type)}
      entitiesValue={entitiesValue}
      onChangeSort={(e, val, type) => onChangeSort(e, val, type)}
    />
  ) : (
    <EntitymappingSec
      // mata josn
      screenId={props.id}
      Data={props.Data}
      QpassSceneData={QpassScene}
      screenMapArray={screenMapArray}
      // entities Value on change
      entitiesValue={entitiesValue}
      EnitiesDropDown={EnitiesDropDown}
      // on Change Scene dropdowns
      onChangeScenedropdowns={(val) => onChangeScenedropdowns(val)}
      removeSceenFun={(val) => removeSceenFun(val)}
      onChangefun={(e, val) => onChangefun(e, val)}
      filterClick={(e, title) => filterClick(e, title)}
    />
  );
};
