import React, { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";

import CircularProgress from "@material-ui/core/CircularProgress";
import { ScreenLayout } from "../../uiBuilder/components/screenLayout";
import { AuthContext } from "../../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    backgroundImage:
      "linear-gradient(to right, rgb(217, 226, 233) 1px, transparent 1px), linear-gradient(rgb(217, 226, 233) 1px, transparent 1px)",
    backgroundSize: "20px 20px",
    backgroundColor: "rgba(255, 255, 255, 0.36)",
    flexGrow: 1,
    padding: "30px",
    display: "flex",
    flexWrap: "wrap",
    minWidth: "2200px",
    maxWidth: "2200px",
  },
  screenArea: {
    // width: "320px",
    // height: "520px",
    // margin: "1px auto auto auto",
    padding: "20px",
    paddingBottom: "90px",
  },
  webView: {
    // maxWidth: "1920px",
    maxWidth: "100vw",
    minWidth: "100vw",
    // height: "1080px"
    height: "110vh",
  },
  mobileView: {
    maxWidth: "375px",
    minWidth: "375px",
    minHeight: "812px",
  },
}));

export function BuilderFlow(props) {
  const [zoom, setSoom] = useState(0.6);
  const [selectedScreen, setSelectedScreen] = useState(null)
  const [layoutType, setLayoutType] = useState(1);
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const zoomInOut = (e, type) => {
    if (type === "zoomIn") {
      let n = zoom + 0.1;
      let number = Math.round(n * 10) / 10;
      if (number === 1.2) return false;
      setSoom(number);
    } else {
      let n = zoom - 0.1;
      let number = Math.round(n * 10) / 10;
      if (number === 0.1) return false;
      setSoom(number);
    }
  };

  const changeLayout = (type) => {
    setLayoutType(type);
  };
  const currentFlowGroup = auth.user.flowGroup.filter(
    (item) => item.groupName === auth.user.activeGroup
  );
  const allScreenPointers = {};
  auth.user.AllScreenList.forEach((item, index) => {
    allScreenPointers[item.id] = index;
  });


  const ScreenLayoutProducer = () => {

  }

  console.log(auth.user.AllScreenList, "flow");
  return (
    <div className={classes.root}>
      {auth.user.loading && (
        <div
          style={{
            display: "flex",
            width: "100vw",
            height: "100vh",
            zoom: 0.6,
          }}
        >
          <CircularProgress
            disableShrink
            style={{ margin: "auto", width: "60px", height: "60px" }}
          />
        </div>
      )}
      {/* show all screens */}
      {
        !auth.user.loading &&
        auth.user.AllScreenList?.length > 0 &&
        props.selectedScreen && auth.user.AllScreenList.map((screen, index) => {
          if (props.selectedScreen?.id === screen.id) {
            return (
              <ScreenLayout
                deleteOldScreen={props.deleteOldScreen}
                uiType={"uiFlow"}
                screen={screen}
                index={index}
                zoom={zoom}
                layoutType={layoutType}
                draggable={false}
                getComponentId={props.getComponentId}
                rightMenu={props.rightMenuUpdate}
              />
            );
          }
        })}
      {!auth.user.loading &&
        auth.user.AllScreenList?.length > 0 &&
        !auth.user.activeGroup && !props.selectedScreen &&
        auth.user.AllScreenList.map((screen, index) => {
          return (
            <ScreenLayout
              deleteOldScreen={props.deleteOldScreen}
              uiType={"uiFlow"}
              screen={screen}
              index={index}
              zoom={zoom}
              layoutType={layoutType}
              draggable={false}
              getComponentId={props.getComponentId}
              rightMenu={props.rightMenuUpdate}
            />
          );
        })}
      {/* show selected group */}
      {auth.user.activeGroup && !props.selectedScreen &&
        currentFlowGroup[0]?.screensList.map((item, index) => {
          const index1 = allScreenPointers[item.id];
          const screen = auth.user.AllScreenList[index1];
          if (
            index === currentFlowGroup[0]?.screensList.length - 1 &&
            screen?.type === "decisionHandler"
          )
            return <></>;
          else
            return (
              <ScreenLayout
                deleteOldScreen={props.deleteOldScreen}
                uiType={"uiFlow"}
                screen={screen}
                index={index1}
                zoom={zoom}
                layoutType={layoutType}
                draggable={false}
                getComponentId={props.getComponentId}
                rightMenu={props.rightMenuUpdate}
              />
            );
        })}
      {/* : ( */}
      {!auth.user.loading &&
        auth.user.AllScreenList &&
        auth.user.AllScreenList?.length === 0 && (
          <div className={classes.screenArea}>
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              <Typography>{"Create new screen..."}</Typography>
            </div>
          </div>
        )}
      {/* )} */}

      {/* <BottomCard
        zoomInOut={zoomInOut}
        zoom={zoom}
        layoutType={layoutType}
        changeLayout={changeLayout}
      /> */}
    </div>
  );
}
