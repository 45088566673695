import React from 'react';
import {
    Paper,
    TextField,
    Button,
    Modal,
    makeStyles,
    Grid,
    IconButton
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import HighlightOffIcon from "@material-ui/icons/HighlightOff";


let activeScreen = null;

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        border: '2px solid #000',
        boxShadow: 24,
        height: 450
    },
    root: {
        height: '100%',
        overflow: 'scroll',
        padding: 25
    },
    marginTop:{
        marginTop: 15
    },
    closeIcon: {
        position: "absolute",
        right: "-25px",
        top: "-25px"
    }
}));


export default function CustomState(props) {
    const [state, setState] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const classes = useStyles()


    React.useEffect(() => {
        if(props?.state){
            setState(props?.state ?? [])
        }
    }, [props?.state])

    const handleModalClose = () => {
        setModal(false)
    }

    const saveData = () =>{
        props.handleChange(state)
    }

    const deleteStateKey = (index) => {
        let setData = state;
        setData.splice(index, 1)
        setState([...state])
    }

    const addNewCondition = () => {
        let setData = state;
        setData.push({});
        setState([...setData])
    }

    const defaultSetState = (value, index, keyName) => {
        let stateData = state;

        stateData[index][keyName] = value;
        setState([...stateData])
    }

    return <React.Fragment>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end', marginTop: 10 }}>
            <Button
                style={{ marginLeft: "10px" }}
                variant="contained"
                color="secondary"
                onClick={() => setModal(true)}>
                Handle Set State
            </Button>
        </Grid>
        <Modal
            open={modal}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper className={classes.paper}>
            <div className={classes.closeIcon}>
                    <IconButton style={{ backgroundColor: '#fff', padding: 0 }} onClick={() => setModal(false)}>
                        <HighlightOffIcon size="small" />
                    </IconButton>
                </div>
                <div className={classes.root}>
                    {
                        state.map((customSetState, index) => {
                            return <React.Fragment>
                                {
                                    <Paper>
                                        <div>
                                            <HighlightOffIcon
                                                onClick={() => deleteStateKey(index)}
                                                style={{ float: "right", cursor: "pointer", marginBottom: 10 }}
                                            />
                                        </div>
                                        <div>
                                            <Autocomplete
                                                className={classes.marginTop}
                                                size={"small"}
                                                options={props?.listernerEvents ?? []}
                                                id="controllable-states-demo12"
                                                onChange={(event, newValue) => defaultSetState(newValue, index, "event")}
                                                value={customSetState?.event}
                                                getOptionLabel={(option) =>
                                                    option.CName ? option.CName : ""
                                                }
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="select event"
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </div>
                                        {
                                            customSetState?.event && <div>
                                                <Autocomplete
                                                    className={classes.marginTop}
                                                    size={"small"}
                                                    options={["form", "custom"]}
                                                    id="controllable-states-demo12"
                                                    onChange={(event, newValue) => defaultSetState(newValue, index, "type")}
                                                    value={customSetState?.type}
                                                    getOptionLabel={(option) =>
                                                        option
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="select type"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        }
                                        {
                                            customSetState?.type == "custom" && <div>
                                                <TextField
                                                    id="outlined-helperText"
                                                    label="Enter value"
                                                    size={"small"}
                                                    value={customSetState?.setValue}
                                                    onChange={(event) => defaultSetState(event.target.value, index, "setValue")}
                                                    className={classes.marginTop}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            </div>
                                        }
                                        {
                                            customSetState?.type == "form" && <div>
                                                <Autocomplete
                                                    className={classes.marginTop}
                                                    size={"small"}
                                                    options={props?.listernerEvents ?? []}
                                                    id="controllable-states-demo12"
                                                    onChange={(event, newValue) => defaultSetState(newValue, index, "formValue")}
                                                    value={customSetState?.formValue}
                                                    getOptionLabel={(option) =>
                                                        option.CName ? option.CName : ""
                                                    }
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="select event"
                                                            variant="outlined"
                                                        />
                                                    )}
                                                />
                                            </div>
                                        }
                                    </Paper>
                                }
                            </React.Fragment>
                        })
                    }
                    <div style={{ textAlign: 'center', marginTop: 20 }}>
                        <Button variant="contained" color="primary" onClick={addNewCondition}>
                            ADD NEW
                        </Button>
                        {state.length > 0 && <Button variant="contained" color="primary" onClick={saveData} style={{ marginLeft: 15 }}>
                            Save
                        </Button>}
                    </div>
                </div>
            </Paper>
        </Modal>
    </React.Fragment>
}