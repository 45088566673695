import "./App.css";
import RouterApp from "./router";
import AppTheme from "./App.theme";
import AppAuth from "./App.auth";
import AppDialog from "./App.dialog";
import React from "react";
import "./animation.css";
import AppAlert from "./App.alert";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

function App() {
  // React.useEffect(() => {
  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  // const handleBeforeUnload = (e) => {
  //   e.preventDefault();
  //   const message =
  //     "Are you sure you want to leave? All provided data will be lost.";
  //   e.returnValue = message;
  //   return message;
  // };
  return (
    <QueryClientProvider client={queryClient}>
      <AppAuth>
        <AppTheme>
          <AppAlert>
            <AppDialog>
              <RouterApp />
            </AppDialog>
          </AppAlert>
        </AppTheme>
      </AppAuth>
    </QueryClientProvider>
  );
}

export default App;
