import React from "react";
import {
  Grid,
  makeStyles,
  TextField,
  IconButton,
  Checkbox,
  Paper,
  Avatar,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";

const styles = makeStyles((theme) => ({
  root: {
    position: "relative",
    padding: 10,
    marginBottom: 15,
    marginTop: 10,
  },
  input: {
    marginTop: 8,
    marginBottom: 2,
    "& .MuiInputBase-input": {
      fontSize: 14,
    },
    "& .MuiInputLabel-root": {
      fontSize: 15,
      top: "3px !important",
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      fontWeight: 500,
    },
  },
  indexFab: {
    position: "absolute",
    left: 0,
    top: -10,
    "& .MuiAvatar-root": {
      fontSize: 12,
      width: 20,
      height: 20,
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const DataSource = (props) => {
  const [state, setState] = React.useState();
  const [dataSourcesList, setDataSourcesList] = React.useState();
  const dataSource = props?.value?.dataSource;
  const crntValue = props?.value?.value;
  const crntExpanded = props?.value?.expanded;
  const crntKeyName = props?.keyName;
  const classes = styles();

  React.useEffect(() => {
    let dataSourcesList = props?.dataSources;
    let newList = dataSourcesList.filter((item) => {
      if (
        (item.label === "form" && props?.listernerEvents?.length) ||
        (item.label === "self" && props?.selfEvents?.length) ||
        (item.label === "state" &&
          props?.qpasQueriesResponse?.screenLevel?.length) ||
        (item.label === "response" &&
          props?.qpasQueriesResponse?.eventLevel?.length) ||
        (item.label === "history" && props?.historyData?.length) ||
        (item.label === "sessionStorage" && props?.sessionList?.length) ||
        (item.label === "localStorage" && props?.localList?.length) ||
        (item.label === "cookies" && props?.cookiesList?.length) ||
        (item.label === "external" && props?.externalDataSource?.length) ||
        item.label === "custom"
      ) {
        return true;
      } else return false;
      return true;
    });
    setDataSourcesList(newList);
  }, [props]);

  const handleChange = (v, keyName, index) => {
    if (keyName === "dataSource") {
      let value = props?.value;
      if (!v || (v !== dataSource && crntValue)) {
        ///remove value from state on removing/changing data sources
        value.value = null;
      }
      props.onChange(
        {
          ...props?.value,
          dataSource: v,
        },
        props.index
      );
    } else if (
      keyName === "custom" ||
      keyName === "form" ||
      keyName === "self" ||
      keyName === "external"
    ) {
      props.onChange(
        {
          ...props?.value,
          value: v,
        },
        props.index
      );
    } else if (
      keyName === "history"
    ) {
      props.onChange(
        {
          ...props?.value,
          value: v.CName,
          getParentVariable: v.newVariable
        },
        props.index
      );
    } else if (keyName === "state" || keyName === "response") {
      props.onChange(
        {
          ...props?.value,
          queryName: v?.label?.split("?.")[0],
          value: v?.label,
          expanded: [],
          newVariable: v?.newVariable
        },
        props.index
      );
    } else if (keyName === "keyName") {
      props.onChange(
        {
          ...props?.value,
          keyName: v,
        },
        props.index
      );
    } else if (
      keyName === "sessionStorage" ||
      keyName === "localStorage" ||
      keyName === "cookies"
    ) {
      props.onChange(
        {
          ...props?.value,
          queryName: v?.label?.split("?.")[0],
          value: v?.keyName,
          newVariable: v?.newVariable,
          getParentVariable: v.newVariable
        },
        props.index
      );
    } else if (keyName === "expanded" && props?.value?.getParentVariable) {
      let currenntValue = v.v;
      let newVariable = v.newVariable;
      let value = crntValue;
      let expanded = crntExpanded ?? [];
      if (expanded[index] !== currenntValue.title) {
        ///replace respective key level if another key selected on same level
        expanded[index] = currenntValue.title;
        expanded.splice(index + 1, expanded.length);

        let label = value.split("?.");
        label[index + 1] = currenntValue.type === "array" ? `${currenntValue.title}[0]` : currenntValue.title;
        label.splice(index + 2, label.length);
        value = label.join("?.");
      } else {
        ///remove key if selected value exists
        expanded.splice(index, expanded.length);

        let label = value.split("?.");
        label.splice(index + 1, label.length);
        value = label.join("?.");
      }
      props.onChange(
        {
          ...props?.value,
          value: value,
          expanded: expanded,
          newVariable: newVariable.type === "array" || newVariable.type === "object" ?
            JSON.stringify(newVariable) :
            null
        },
        props.index
      );
    } else if (keyName === "expanded" && props?.value?.getParentVariable) {
      let currenntValue = v.v;
      let newVariable = v.newVariable;
      let value = crntValue;
      let expanded = crntExpanded;
      if(!expanded?.length){
        expanded = [];
        expanded.push(value);
        value = `${value}?.${currenntValue.type === "array" ? `${currenntValue.title}[0]` : currenntValue.title}`;
      }
      if (expanded && index < expanded.length) {
        if (expanded[index] !== currenntValue.title) {
          ///replace respective key level if another key selected on same level
          expanded[index] = currenntValue.title;
          expanded.splice(index + 1, expanded.length);

          let label = value.split("?.");
          label[index + 1] = currenntValue.type === "array" ? `${currenntValue.title}[0]` : currenntValue.title;
          label.splice(index + 2, label.length);
          value = label.join("?.");
        } else {
          ///remove key if selected value exists
          expanded.splice(index, expanded.length);

          let label = value.split("?.");
          label.splice(index + 1, label.length);
          value = label.join("?.");
        }
      } else {
        ///add new keys to expanded list
        expanded.push(currenntValue.title);
        value = `${value}?.${currenntValue.type === "array" ? `${currenntValue.title}[0]` : currenntValue.title}`;
      }
      props.onChange(
        {
          ...props?.value,
          value: value,
          expanded: crntExpanded,
          newVariable: newVariable.type === "array" || newVariable.type === "object" ?
            JSON.stringify(newVariable) :
            null
        },
        props.index
      );
    }else if (keyName === "expanded") {
      let currenntValue = v.v;
      let newVariable = v.newVariable;
      let value = crntValue;
      let expanded = crntExpanded;
      if (expanded && index < expanded.length) {
        if (expanded[index] !== currenntValue.title) {
          ///replace respective key level if another key selected on same level
          expanded[index] = currenntValue.title;
          expanded.splice(index + 1, expanded.length);

          let label = value.split("?.");
          label[index + 1] = currenntValue.type === "array" ? `${currenntValue.title}[0]` : currenntValue.title;
          label.splice(index + 2, label.length);
          value = label.join("?.");
        } else {
          ///remove key if selected value exists
          expanded.splice(index, expanded.length);

          let label = value.split("?.");
          label.splice(index + 1, label.length);
          value = label.join("?.");
        }
      } else {
        ///add new keys to expanded list
        expanded.push(currenntValue.title);
        value = `${value}?.${currenntValue.type === "array" ? `${currenntValue.title}[0]` : currenntValue.title}`;
      }
      props.onChange(
        {
          ...props?.value,
          value: value,
          expanded: crntExpanded,
          newVariable: newVariable.type === "array" || newVariable.type === "object" ?
            JSON.stringify(newVariable) :
            null
        },
        props.index
      );
    }
  };

  return (
    <Paper className={classes.root}>
      {props?.isEndDecoration && (
        <div className={classes.indexFab}>
          <Avatar>{props.index}</Avatar>
        </div>
      )}
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-helperText"
            label={props?.label}
            className={classes.input}
            size={"small"}
            value={crntKeyName}
            onChange={(event) => handleChange(event.target.value, "keyName")}
            variant="outlined"
            fullWidth
            disabled={!props?.isKeyEditable}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            className={classes.input}
            value={{ label: dataSource }}
            onChange={(event, newValue) =>
              handleChange(newValue?.label, "dataSource")
            }
            size={"small"}
            options={dataSourcesList ?? []}
            filterSelectedOptions
            getOptionLabel={(options) => options.label}
            renderInput={(params) => (
              <TextField {...params} label="Select Source" variant="outlined" />
            )}
            fullWidth
          />
        </Grid>
        {dataSource === "custom" && (
          <Grid item xs={12}>
            <TextField
              id="outlined-helperText"
              label={"enter value"}
              size={"small"}
              value={crntValue}
              onChange={(event) => handleChange(event.target.value, "custom")}
              variant="outlined"
              fullWidth
            />
          </Grid>
        )}
        {dataSource === "form" && (
          <Grid item xs={12}>
            <Autocomplete
              value={crntValue}
              onChange={(event, newValue) => handleChange(newValue, "form")}
              size={"small"}
              options={props?.listernerEvents ?? []}
              filterSelectedOptions
              getOptionLabel={(options) => options.CName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select form events"
                  variant="outlined"
                />
              )}
              fullWidth
            />
          </Grid>
        )}
        {dataSource === "external" && (
          <Grid item xs={12}>
            <Autocomplete
              value={crntValue}
              onChange={(event, newValue) => handleChange(newValue, "external")}
              size={"small"}
              options={props?.externalDataSource ?? []}
              filterSelectedOptions
              getOptionLabel={(options) => options.CName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select external events"
                  variant="outlined"
                />
              )}
              fullWidth
            />
          </Grid>
        )}
        {dataSource === "self" && (
          <Grid item xs={12}>
            <Autocomplete
              value={{ CName: crntValue }}
              onChange={(event, newValue) =>
                handleChange(newValue?.CName, "self")
              }
              size={"small"}
              options={props?.selfEvents ?? []}
              filterSelectedOptions
              getOptionLabel={(options) => options.CName}
              renderInput={(params) => (
                <TextField {...params} label="Select data" variant="outlined" />
              )}
              fullWidth
            />
          </Grid>
        )}
        {dataSource === "history" && (
          <Grid item xs={12}>
            <Autocomplete
              value={{ CName: crntValue }}
              onChange={(event, newValue) =>
                handleChange(newValue, "history")
              }
              size={"small"}
              options={props?.historyData ?? []}
              filterSelectedOptions
              getOptionLabel={(options) => options.CName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select history"
                  variant="outlined"
                />
              )}
              fullWidth
            />
          </Grid>
        )}
        {dataSource === "sessionStorage" && (
          <BalancerGenerator
            // newVariable={props?.value?.newVariable}
            newVariable={null}
            crntExpanded={crntExpanded}
            handleChange={handleChange}
            AlternateComponent={
              <Grid item xs={12}>
                <Autocomplete
                  value={{ keyName: crntValue }}
                  onChange={(event, newValue) =>
                    handleChange(newValue, "sessionStorage")
                  }
                  size={"small"}
                  options={props?.sessionList ?? []}
                  filterSelectedOptions
                  getOptionLabel={(options) => options.keyName}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Key" variant="outlined" />
                  )}
                  fullWidth
                />
              </Grid>
            }
          />
        )}
        {dataSource === "localStorage" && (
          <BalancerGenerator
            newVariable={null}
            crntExpanded={crntExpanded}
            handleChange={handleChange}
            AlternateComponent={
              <Grid item xs={12}>
                <Autocomplete
                  value={{ keyName: crntValue }}
                  onChange={(event, newValue) =>
                    handleChange(newValue, "localStorage")
                  }
                  size={"small"}
                  options={props?.localList ?? []}
                  filterSelectedOptions
                  getOptionLabel={(options) => options.keyName}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Key" variant="outlined" />
                  )}
                  fullWidth
                />
              </Grid>
            }
          />
        )}
        {dataSource === "cookies" && (
          <BalancerGenerator
            newVariable={null}
            crntExpanded={crntExpanded}
            handleChange={handleChange}
            AlternateComponent={
              <Grid item xs={12}>
                <Autocomplete
                  value={{ keyName: crntValue }}
                  onChange={(event, newValue) => handleChange(newValue, "cookies")}
                  size={"small"}
                  options={props?.cookiesList ?? []}
                  filterSelectedOptions
                  getOptionLabel={(options) => options.keyName}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Key" variant="outlined" />
                  )}
                  fullWidth
                />
              </Grid>
            }
          />
        )}
        {dataSource === "state" && (
          <Grid item xs={12}>
            {" "}
            <Autocomplete
              value={
                crntValue
                  ? { label: crntValue?.split("?.")?.[0] ?? null }
                  : null
              }
              onChange={(event, newValue) => handleChange(newValue, "state")}
              size={"small"}
              options={props?.qpasQueriesResponse?.screenLevel ?? []}
              filterSelectedOptions
              getOptionLabel={(options) => options?.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select required state"
                  variant="outlined"
                />
              )}
              fullWidth
            />
          </Grid>
        )}

        {dataSource === "state" && crntValue && (
          <Grid item xs={12}>
            <div>
              <div>{crntValue}</div>
              {props?.qpasQueriesResponse.screenLevel?.map((item) => {
                if (item?.label === crntValue?.split("?.")[0]) {
                  return TreeViewGenerator(
                    item?.payload,
                    -1,
                    crntExpanded ?? [],
                    handleChange
                  );
                }
              })}
            </div>
          </Grid>
        )}
        {dataSource === "response" && (
          <Grid item xs={12}>
            {" "}
            <Autocomplete
              value={
                crntValue
                  ? { label: crntValue?.split("?.")?.[0] ?? null }
                  : null
              }
              onChange={(event, newValue) => handleChange(newValue, "response")}
              size={"small"}
              options={props?.qpasQueriesResponse?.eventLevel ?? []}
              filterSelectedOptions
              getOptionLabel={(options) => options?.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select required response"
                  variant="outlined"
                />
              )}
              fullWidth
            />
          </Grid>
        )}
        {dataSource === "response" && crntValue && (
          <Grid item xs={12}>
            <div>
              <div>{crntValue}</div>
              {props?.qpasQueriesResponse.eventLevel?.map((item) => {
                if (item?.label === crntValue?.split("?.")[0]) {
                  return TreeViewGenerator(
                    item?.payload,
                    -1,
                    crntExpanded ?? [],
                    handleChange
                  );
                }
              })}
            </div>
          </Grid>
        )}
        {props?.value?.getParentVariable && (
          <Grid item xs={12}>
            <div>
              <div>{crntValue}</div>
              {[0]?.map((item) => {
                if (true) {
                  return TreeViewGenerator(
                    [JSON.parse(props?.value?.getParentVariable)],
                    -1,
                    crntExpanded ?? [],
                    handleChange
                  );
                }
              })}
            </div>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};


const BalancerGenerator = ({ newVariable, crntExpanded, handleChange, AlternateComponent }) => {
  return <React.Fragment>
    {
      newVariable ? (
        <Grid item xs={12}>
          <div>
            {
              TreeViewGenerator(
                [JSON.parse(newVariable)],
                -1,
                crntExpanded ?? [],
                handleChange
              )
            }
          </div>
        </Grid>
      ) : AlternateComponent
    }
  </React.Fragment>
}

const TreeViewGenerator = (payload, index, output, handleChange) => {
  return (
    <>
      {Array.isArray(payload) && payload?.map((item) => {
        if (item?.column) {
          return (
            <TreeGroup
              title={item?.name}
              active={item?.name === output[index + 1]}
              handleChange={(v, keyName, index) => handleChange({ v: v, newVariable: item }, keyName, index, payload)}
              treeIndex={index + 1}
              dataType={item?.type}
            >
              {TreeViewGenerator(item?.column, index + 1, output, handleChange)}
            </TreeGroup>
          );
        } else {
          return (
            <TreeItem
              children={item?.name}
              active={item?.name === output[index + 1]}
              hide={index + 1 < output?.length}
              handleChange={(v, keyName, index) => handleChange({ v: v, newVariable: item }, keyName, index, payload)}
              treeIndex={index + 1}
              dataType={item?.type}
            />
          );
        }
      })}
    </>
  );
};

const TreeGroup = (props) => {
  return (
    <div>
      <div>
        {props.active ? (
          <IconButton
            size="small"
            style={{ padding: 0, width: 20, height: 20 }}
          >
            <ArrowDropDownIcon
              onClick={() =>
                props.handleChange(
                  { title: props?.title, type: props?.dataType },
                  "expanded",
                  props.treeIndex
                )
              }
            />
          </IconButton>
        ) : (
          <IconButton
            size="small"
            style={{ padding: 0, width: 20, height: 20 }}
          >
            <ArrowRightIcon
              onClick={() =>
                props.handleChange(
                  { title: props?.title, type: props?.dataType },
                  "expanded",
                  props.treeIndex
                )
              }
            />
          </IconButton>
        )}
        <div style={{ display: "-webkit-inline-box" }}>
          {props.title}
          <span
            style={{
              color: props.dataType === "array" ? "orange" : "green",
              marginLeft: 10,
            }}
          >
            {props.dataType}
          </span>
        </div>
      </div>
      <div style={{ paddingLeft: 20 }}>
        {props.active ? props.children : null}
      </div>
    </div>
  );
};

const TreeItem = ({
  children,
  active,
  hide,
  treeIndex,
  handleChange,
  dataType,
}) => {
  return (
    <div
      style={{
        display: hide && !active ? "none" : "flex",
        alignItems: "center",
      }}
    >
      <div>
        <Checkbox
          checked={active}
          style={{ padding: 0, marginRight: 8 }}
          onClick={() =>
            handleChange(
              { title: children, type: dataType },
              "expanded",
              treeIndex
            )
          }
        />
      </div>
      <div>{children}</div>
    </div>
  );
};

export default DataSource;
