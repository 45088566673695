import React from "react";
import { themes, alertProps, drawerProps } from "../utils";

//Collection of all the Context used in the application

export const AuthContext = React.createContext({
  user: {
    screens: [
      {
        render: {
          child: [],
          component: "div",
          component_type: "qdm",
        },
      },
    ],
  },
  AllScreenList: [],
  setAuth: () => null,
});

export const NotificationStatus = React.createContext({
  saveStatus: null,
  notification: "",
  setNotifyState: () => null,
});

export const ThemeContext = React.createContext({
  name: themes.default,
  setTheme: () => null,
});

export const AlertContext = React.createContext({
  open: false,
  severity: alertProps.severity.success,
  msg: "",
  vertical: alertProps.vertical.top,
  horizontal: alertProps.horizontal.center,
  onclose: () => null,
  setSnack: () => null,
  tone: false,
});

export const DialogContext = React.createContext({
  open: false,
  title: "",
  body: "",
  positiveBtn: "Ok",
  negativeBtn: "Cancel",
  onOk: () => null,
  setDialog: () => null,
  tone: false,
});

export const BackdropContext = React.createContext({
  open: false,
  message: "",
  setBackDrop: () => null,
});
export const DrawerContext = React.createContext({
  open: false,
  direction: drawerProps.direction.right,
  variant: drawerProps.variant.temporary,
  onClose: () => null,
  setDrawer: () => null,
});

export const ZoomContext = React.createContext({
    zoom:0.6,
    zoomInOut: () => null, 
})
