import React from "react";
import {
  Grid,
  makeStyles,
  Typography,
  Button,
  TextField,
  Paper,
  IconButton,
} from "@material-ui/core";
import { AuthContext } from "../../../../contexts";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ControlledTreeView from "./treeview";
// import { v4 as uuidv4 } from 'uuid';
import { makeid as uuidv4 } from "../../../../components/commonFunction";
const useStyles = makeStyles((theme) => ({
  root: {},
  marginTop: {
    marginTop: 10,
  },
  cards: {
    padding: 10,
  },
}));

export default function GraphQlSupportDisplay(props) {
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const [entities, setEntities] = React.useState([]);
  const [selectedElements, setSelectedElements] = React.useState(null);
  const authUpdate = () => {
    auth.setAuth({
      ...auth.user,
      AllScreenList: auth.user.AllScreenList,
      componentId: props.component.componentId,
      isEdited: true,
    });
  };
  const setGraphQlMethod = (type) => {
    const component = props.component;

    let Screen = auth.user.AllScreenList[component.screenIndex];
    let currentFunction = Screen.events.findIndex(
      (item) => item.fromId === component.componentId
    );
    if (type === "queryMethod") {
      entities.map((item) => {
        var initiate = {
          $id: uuidv4(),
          type: "query",
          entity: "",
          isActive: false,
        };
        initiate.entity = item.entity;
        Screen.events[currentFunction].apiCalls[0].query.push(initiate);
      });
    } else {
      entities.map((item) => {
        var initiate = {
          $id: uuidv4(),
          type: "mutation",
          entity: "",
          isActive: false,
        };
        initiate.entity = item.entity;
        Screen.events[currentFunction].apiCalls[0].mutation.push(initiate);
      });
    }
    authUpdate();
    props.setData(Screen.events[currentFunction].apiCalls[0]);
  };
  const removeGrapqlMethod = (index, type) => {
    const component = props.component;

    let Screen = auth.user.AllScreenList[component.screenIndex];
    let currentFunction = Screen.events.findIndex(
      (item) => item.fromId === component.componentId
    );
    Screen.events[currentFunction].apiCalls[0] = null;
    props.setData(Screen.events[currentFunction].apiCalls[0]);
    ///old method
    // var data1 = props.data;
    // data1.splice(index, 1);
    // props.setData(data1)
  };
  const handleTitleChange = (value, index, type) => {
    const component = props.component;

    let Screen = auth.user.AllScreenList[component.screenIndex];
    let currentFunction = Screen.events.findIndex(
      (item) => item.fromId === component.componentId
    );
    // Screen.events[currentFunction].apiCalls[0][type][index].title = value
    Screen.events[currentFunction].apiCalls[0].title = value;
    props.setData(Screen.events[currentFunction].apiCalls[0]);
  };
  const updateObjectById = (data, $id, selector, v, type) => {
    Object.keys(data).filter((val) => {
      var item = data[val];
      if (val === "$id" && item === $id) {
        if (type === "id") {
          if (v)
            data[selector] = {
              $id: uuidv4(),
            };
          else data[selector] = null;
        } else if (type === "checkboxKey") {
          console.log(v, "checkboxKey");
          if (v) {
            data[selector] = null;
          } else {
            delete data[selector];
          }
        } else if (type === "checkboxActive") {
          console.log(v, "checkboxactive");
          if (v) {
            data[selector] = true;
          } else {
            delete data[selector];
          }
        } else {
          data[selector] = v;
        }
        props.setData(props.data);
      }
      if (item && typeof item === "object") {
        updateObjectById(item, $id, selector, v, type);
      }
    });
  };
  ///for all indicator changes
  const handleIndicatorChange = (v, selector, index, $id, type) => {
    const component = props.component;

    let Screen = auth.user.AllScreenList[component.screenIndex];
    let currentFunction = Screen.events.findIndex(
      (item) => item.fromId === component.componentId
    );
    if ($id) {
      if (
        selector === "and" ||
        selector === "or" ||
        selector === "not" ||
        selector.includes("query")
      ) {
        updateObjectById(
          Screen.events[currentFunction].apiCalls[0][type][index],
          $id,
          selector,
          v,
          "id"
        );
      } else {
        updateObjectById(
          Screen.events[currentFunction].apiCalls[0][type][index],
          $id,
          selector,
          v,
          "id"
        );
      }
    } else {
      const component = props.component;

      let Screen = auth.user.AllScreenList[component.screenIndex];
      let currentFunction = Screen.events.findIndex(
        (item) => item.fromId === component.componentId
      );

      if (selector === "title" || selector === "options") {
        Screen.events[currentFunction].apiCalls[0][type][index][selector] = v;
      } else if (
        selector === "filter" ||
        selector === "node" ||
        selector.includes("query") ||
        selector.includes("node")
      ) {
        if (v) {
          Screen.events[currentFunction].apiCalls[0][type][index][selector] = {
            $id: uuidv4(),
          };
        } else
          Screen.events[currentFunction].apiCalls[0][type][index][selector] =
            null;
      } else if (selector === "entity") {
        Screen.events[currentFunction].apiCalls[0][type][index]["isActive"] = v
          ? true
          : false;
      }
      props.setData(Screen.events[currentFunction].apiCalls[0]);
    }
  };
  const handleCheckboxChange = (
    v,
    selector,
    index,
    $id,
    inputDisabled,
    type
  ) => {
    const component = props.component;

    let Screen = auth.user.AllScreenList[component.screenIndex];
    let currentFunction = Screen.events.findIndex(
      (item) => item.fromId === component.componentId
    );

    if (inputDisabled) {
      updateObjectById(
        Screen.events[currentFunction].apiCalls[0][type][index],
        $id,
        selector,
        v,
        "checkboxActive"
      );
    } else {
      updateObjectById(
        Screen.events[currentFunction].apiCalls[0][type][index],
        $id,
        selector,
        v,
        "checkboxKey"
      );
    }
  };
  const handleInputChange = (v, selector, index, $id, type) => {
    const component = props.component;

    let Screen = auth.user.AllScreenList[component.screenIndex];
    let currentFunction = Screen.events.findIndex(
      (item) => item.fromId === component.componentId
    );
    updateObjectById(
      Screen.events[currentFunction].apiCalls[0][type][index],
      $id,
      selector,
      v,
      "input",
      type
    );
  };
  const setEntityMapper = async (Screenval, activeIndex) => {
    ///entity designer
    let screenMapArray = Screenval[activeIndex]?.scenes;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var list =
      screenMapArray?.length > 0
        ? screenMapArray
            ?.map((val) => val.entity)
            .join()
            .split(",")
        : [];
    var a = [];
    list.map((val) => {
      if (a.indexOf(val) === -1) {
        a.push(val);
      }
    });
    var EntitiesRaw = JSON.stringify({
      // filter: {
      //   columname: "metadataId",
      //   operator: "equals",
      //   value: metaDataId_get_url ? metaDataId_get_url : "",
      // },
      // "db_name": "Test_User_Entity",
      db_name: localStorage.projectName.replace(/\s/g, "_"),
      entity: a,
    });

    var EntitiesRequestOptions = {
      method: "POST",
      headers: myHeaders,
      body: EntitiesRaw,
      redirect: "follow",
    };
    console.log(Screenval[activeIndex]?.models?.[0].EntityDetails);
    await fetch(
      "http://164.52.210.54:8080/api/getcollectionattributes",
      EntitiesRequestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        var EntityDetails = [];
        res.Result.map((val, index) => {
          let object;
          Object.keys(val).map((tabelName, index1) => {
            var isEntityMapped = null;
            ///check whether tableName maps entity
            isEntityMapped = Screenval[
              activeIndex
            ]?.models?.[0].EntityDetails.filter((item) => {
              return item.entity === tabelName;
            });
            ////creating base items mapping
            if (!tabelName.includes("relationship") && isEntityMapped?.length) {
              object = {
                entity: tabelName,
                id: index,
              };
              let columns = val[tabelName].map((item) => {
                return { name: item.columnName };
              });
              object["column"] = columns;

              ///check relationship for matches
              let relationTable = val?.[`${tabelName}_relationship`];
              if (relationTable?.length) {
                relationTable.forEach((relation) => {
                  object.column.filter(async (item) => {
                    if (item.name === relation.sourceColumn) {
                      var entityName = relation.targetEntity;
                      ///updating base items mapping with relationed one
                      res.Result.map((val, index) => {
                        Object.keys(val).map((tabelName) => {
                          if (tabelName === entityName) {
                            item.column = val[tabelName].map((item) => {
                              return { name: item.columnName };
                            });
                            item.mappedEntity = tabelName;
                          }
                        });
                      });
                    }
                  });
                });
              }
            }
          });
          if (object) EntityDetails.push(object); ///removing undefined
        });
        // setEntities(
        //     Screenval[activeIndex]?.models?.[0].EntityDetails
        // )
        setEntities(EntityDetails);
      })
      .catch((error) => console.log("error", error));
  };
  React.useEffect(() => {
    let Screenval = auth.user.AllScreenList;
    let activeIndex = props.activeIndex;
    let selectedElements = Screenval[activeIndex]?.events.filter((item) => {
      if (item.name === "onChange") {
        return { fromId: item.fromId, CName: item.CName };
      } else return false;
    });
    let histories = Screenval[activeIndex]?.history;
    histories &&
      Object.keys(histories).map((screenName) => {
        histories[screenName].map((item) => {
          selectedElements.push({
            fromId: `history`,
            CName: `${screenName.replace(" ", "_")}_${item.name}`,
          });
        });
        // return { fromId: , CName: item.CName }
      });
    setSelectedElements(selectedElements);
    setEntityMapper(Screenval, activeIndex);
  }, [auth.user.componentId]);

  return (
    <Grid container className={`${classes.marginTop}`}>
      {/* data blocks */}
      <Grid item xs={12}>
        <Paper className={classes.cards}>
          {props?.data?.query?.length ? (
            <>
              <div style={{ textAlign: "right" }}>
                {/* <IconButton size="small" onClick={() => removeGrapqlMethod(queryIndex, "query")}> */}
                <IconButton
                  size="small"
                  onClick={() => removeGrapqlMethod("", "query")}
                >
                  <HighlightOffIcon />
                </IconButton>
              </div>
              <TextField
                id="outlined-basic"
                label={"query name"}
                variant="outlined"
                value={props?.data?.title ?? ""}
                size="small"
                fullWidth
                onChange={(event) =>
                  handleTitleChange(event.target.value, null, "query")
                }
              />
            </>
          ) : (
            ""
          )}
          {props?.data?.query &&
            props.data?.query.map((item, queryIndex) => {
              return (
                <div className={classes.marginTop}>
                  {props?.data?.title &&
                    entities &&
                    entities.map((entity) => {
                      if (item.entity === entity.entity) {
                        return (
                          <ControlledTreeView
                            title={entity.entity}
                            value={entity.entity}
                            type={item.type}
                            keyCode="entity"
                            active={item.isActive}
                            handleIndicatorChange={(val, selector, $id) =>
                              handleIndicatorChange(
                                val,
                                selector,
                                queryIndex,
                                $id,
                                "query"
                              )
                            }
                            handleCheckboxChange={(
                              val,
                              selector,
                              $id,
                              inputDisabled
                            ) =>
                              handleCheckboxChange(
                                val,
                                selector,
                                queryIndex,
                                $id,
                                inputDisabled,
                                "query"
                              )
                            }
                            handleInputChange={(val, selector, $id) =>
                              handleInputChange(
                                val,
                                selector,
                                queryIndex,
                                $id,
                                "query"
                              )
                            }
                            columnFields={entity?.column ?? []}
                            selectedElements={selectedElements ?? []}
                            data={item}
                            entityDetails={entities}
                          />
                        );
                      }
                    })}
                </div>
              );
            })}
        </Paper>
      </Grid>
      {props?.data?.mutation &&
        props.data?.mutation.map((item, mutationIndex) => {
          return (
            <Grid item xs={12}>
              <Paper className={classes.cards}>
                <div style={{ textAlign: "right" }}>
                  <IconButton
                    size="small"
                    onClick={() =>
                      removeGrapqlMethod(mutationIndex, "mutation")
                    }
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </div>
                <TextField
                  id="outlined-basic"
                  label={
                    item?.type?.charAt(0).toUpperCase() +
                    item?.type?.slice(1) +
                    " name"
                  }
                  variant="outlined"
                  value={props?.data?.title ?? ""}
                  size="small"
                  fullWidth
                  onChange={(event) =>
                    handleTitleChange(
                      event.target.value,
                      mutationIndex,
                      "mutation"
                    )
                  }
                />
                <div className={classes.marginTop}>
                  {item.title &&
                    entities &&
                    entities.map((entity) => {
                      if (!entity.entity.includes("relationship")) {
                        return (
                          <ControlledTreeView
                            title={item.entity}
                            value={item.entity}
                            type={item.type}
                            keyCode="entity"
                            active={item.entity}
                            handleIndicatorChange={(val, selector, $id) =>
                              handleIndicatorChange(
                                val,
                                selector,
                                mutationIndex,
                                $id,
                                "mutation"
                              )
                            }
                            handleCheckboxChange={(
                              val,
                              selector,
                              $id,
                              inputDisabled
                            ) =>
                              handleCheckboxChange(
                                val,
                                selector,
                                mutationIndex,
                                $id,
                                inputDisabled,
                                "mutation"
                              )
                            }
                            handleInputChange={(val, selector, $id) =>
                              handleInputChange(
                                val,
                                selector,
                                mutationIndex,
                                $id,
                                "mutation"
                              )
                            }
                            columnFields={entity?.column ?? []}
                            selectedElements={[{ componentName: "rr" }]}
                            data={item}
                          />
                        );
                      }
                    })}
                </div>
              </Paper>
            </Grid>
          );
        })}
      {/* select type blocks */}
      {/* adder block start */}
      <>
        <Grid item xs={12}>
          <div style={{ textAlign: "left" }}>
            <Button
              variant="text"
              color="primary"
              name="queryMethod"
              onClick={() => setGraphQlMethod("queryMethod")}
            >
              + Add Queries
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div style={{ textAlign: "left" }}>
            <Button
              variant="text"
              color="primary"
              name="mutationMethod"
              onClick={() => setGraphQlMethod("mutationMethod")}
            >
              + Add Mutations
            </Button>
          </div>
        </Grid>
      </>
      {/* adder block end */}
    </Grid>
  );
}
