import React, { useRef, useLayoutEffect } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Divider,
  Chip,
  TextField,
  Hidden,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useState } from "react";
import FlipCameraAndroidIcon from "@material-ui/icons/FlipCameraAndroid";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
// import { MakeTemplateModal } from "./projectTemplates";
import Xarrow from "react-xarrows/lib";
import { Delete } from "@material-ui/icons";
import { AuthContext, DialogContext, AlertContext } from "../../../contexts";
import isEqual from "react-fast-compare";
// import * as All from "qdm-component-library";
// import * as AllPackages from "functional-component-library";
// import * as AllPackages from "atp_functional_component";

import * as AllMaterialCore from "@material-ui/core";
import * as AllMaterialIcons from "@material-ui/icons";
// import * as AllMaiCus from "custom-material-ui-component";
// import * as AllMaiCus from "custom-material-ui-component";
import * as AllMaterialLab from "@material-ui/lab";
import * as AtpMasterLayout from "atp-master-layout";
import * as AtpLogin from "atp-login-components";
import * as AtpTable from "atp-table";
import * as AtpGeneralMaster from "atp-general-master";
import * as AtpAuthentication from "atp-authentication";
import * as AtpAuthorization from "atp-authorization";
import * as AtpReportViewer from "report_viewer_atp";
import * as AtpStepper from "atp-stepper";
import "../styles/styles.css";
import "../../../App.css";
import { customStyle } from "../../../utils";
import { alertProps } from "../../../utils";
import {
  layoutComponentsList,
  cloneComponent,
  deleteComponent,
  componentDeepIdRemover,
  pascalToSnake,
} from "../../../components/commonFunction";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "30px",
    display: "flex",
    flexWrap: "wrap",
    minWidth: "2200px",
    maxWidth: "2200px",
  },
  screenArea: {
    // width: "320px",
    height: "520px",
    // margin: "1px auto auto auto",
    padding: "20px",
    paddingBottom: "90px",
  },
  arrowLink: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  middleLabel: {
    backgroundColor: "#fff",
    color: "black",
    fontWeight: 500,
    padding: 10,
    marginBottom: 10,
  },
  webView: {
    // maxWidth: "1920px",
    // maxWidth: "max-content",
    // minWidth: "1620px",
    // height: "1080px"
    // height: "100vh",
    // minHeight: (props) => (props.selectedAllScreen ? "fit-content" : "100vh"),
  },
  mobileView: {
    maxWidth: "375px",
    minWidth: "375px",
    minHeight: "812px",
  },
  componentView: {
    // maxWidth: "fit-content",
    maxWidth: "100vw",
    minWidth: "760px",
    minHeight: "812px",
  },
  screenHeader: {
    textAlign: "center",
    position: "relative",
    cursor: "pointer",
    userSelect: "none",
    background: "#cbd5e1",
    display: "flex",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  titleDetails: {
    minWidth: "226px",
    background: "#fff",
    padding: "8px",
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    boxShadow: "2px -3px 6px 0px rgb(0 0 0 / 4%)",
  },
  screenSetting: {
    padding: "4px 9px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    color: "#000000b3",
    cursor: "pointer",
    borderRadius: "50px",
    "&:hover": {
      background: "rgb(0 0 0 / 8%)",
      color: "#007aff !important",
    },
  },
  screenDelete: {
    padding: "4px 9px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px",
    color: "#000000b3",
    cursor: "pointer",
    borderRadius: "50px",
    "&:hover": {
      background: "#f5005714",
      color: "red !important",
    },
  },
  projectTemplateTitle: {
    paddingLeft: "16px",
    fontSize: "15px",
    textTransform: "capitalize",
    marginTop: "10px",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: "6px",
  },
  dragArea: {
    width: "76px",
    border: "1px dashed " + theme.palette.text.hint,
    display: "grid",
    textAlign: "center",
    alignItems: "center",
    wordBreak: "break-all",
    padding: "5px 10px",
    margin: "5px auto",
    minHeight: "108px",
    borderRadius: "4px",
    "&:hover": {
      color: theme.palette.primary.dark,
    },
  },
  collapseView: {
    color: "gray",
    "& .MuiCollapse-wrapperInner": {
      display: "flex",
      flexWrap: "wrap",
      gap: "5px",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dialog: {
    minWidth: 400,
    padding: "20px",
  },
  templateName: {
    marginBottom: "30px",
  },
  templateNameInput: {
    fontSize: 14,
    padding: "12px 0px",
  },
}));
const initialState = {
  mouseX: null,
  mouseY: null,
};
function ComponentsRenderer({
  component,
  index,
  returnComponentId,
  screenName,
  draggable,
  pathId,
  screenId,
  screenIndex,
  previewScreen,
  layoutType,
  Parentindex,
  handleContextMenu,
  setSelectedComponent,
}) {
  const styleDeclaration = (compName, style, customStyle) => {
    let Compstyle = customStyle[compName];
    return { ...Compstyle, ...style };
  };
  const auth = React.useContext(AuthContext);

  const getComponentId = (event, index, component, indexs) => {
    returnComponentId(
      index,
      component,
      screenId,
      event,
      pathId,
      screenIndex,
      screenName,
      indexs
    );
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    // alert(component.component)
  };

  const onDragStart = (evt) => {
    // evt.preventDefault();
    let id = evt.target.getAttribute("data-id");
    let element = evt.target;
    element.classList.add("vivifySmall");
    element.classList.add("pulsate");
    // element.classList.add("dragged");
    evt.dataTransfer.setData("moveToComponents", id);
    localStorage.setItem("drag_item_id", id);
    evt.dataTransfer.effectAllowed = "move";
    // setTimeout(() => {
    //   document.getElementById(id).className += " hide";
    // }, 0);
    // evt.stopPropagation();
    // evt.nativeEvent.stopImmediatePropagation();
  };
  let comp,
    Layer,
    propertiesList,
    attributesList,
    nestedComments,
    htmlChildren = "";

  if (
    component.frameWork?.startsWith("material") ||
    component.frameWork === "qdm" ||
    component.frameWork === "atp-master-layout" ||
    component.frameWork === "atp-login" ||
    component.frameWork === "atp-table" ||
    component.frameWork === "atp-general-master" ||
    component.frameWork === "atp-authentication" ||
    component.frameWork === "atp-authorization" ||
    component.frameWork === "report_viewer_atp" ||
    component.frameWork === "atp-stepper" ||
    component.frameWork === "looper" ||
    component.frameWork === "functional-lib" ||
    component.frameWork === "custom-material-ui-component"
  ) {
    comp = component?.component === "Modal" ? "Row" : component?.component;
    if (component.frameWork === "functional-lib") {
      // Layer = AllPackages[comp];
      return false;
    } else if (component.frameWork?.startsWith("material")) {
      if (component.frameWork === "material/core") {
        Layer = AllMaterialCore[comp] ?? AllMaterialCore["Button"];
      } else if (component.frameWork === "material/lab") {
        Layer = AllMaterialLab[comp] ?? AllMaterialLab["Autocomplete"];
      }
    }
    // else if (component.frameWork === "custom-material-ui-component") {
    //   Layer = AllMaiCus[comp] ?? <text>not render pls check</text>;
    // }
    else if (component.frameWork === "atp-master-layout") {
      Layer = AtpMasterLayout[comp] ?? <text>not render pls check</text>;
    } else if (component.frameWork === "atp-login") {
      Layer = AtpLogin[comp] ?? <text>not render pls check</text>;
    } else if (component.frameWork === "atp-table") {
      Layer = AtpTable[comp] ?? <text>not render pls check</text>;
    } else if (component.frameWork === "atp-general-master") {
      Layer = AtpGeneralMaster[comp] ?? <text>not render pls check</text>;
    } else if (component.frameWork === "atp-authentication") {
      Layer = AtpAuthentication[comp] ?? <text>not render pls check</text>;
    } else if (component.frameWork === "atp-authorization") {
      Layer = AtpAuthorization[comp] ?? <text>not render pls check</text>;
    } else if (component.frameWork === "report_viewer_atp") {
      Layer = AtpReportViewer[comp] ?? <text>not render pls check</text>;
    } else if (component.frameWork === "atp-stepper") {
      Layer = AtpStepper[comp] ?? <text>not render pls check</text>;
    } else {
      // Layer = All[comp];
    }

    if (component?.child && component?.child.length > 0) {
      nestedComments = (component.child || []).map((component, index) => {
        return (
          <ComponentsRenderer
            Parentindex={`${Parentindex}_${index}`}
            layoutType={layoutType}
            key={component?.id}
            index={index}
            component={component}
            type="child"
            returnComponentId={returnComponentId}
            screenName={screenName}
            draggable={draggable}
            pathId={pathId}
            screenId={screenId}
            screenIndex={screenIndex}
            previewScreen={previewScreen}
            handleContextMenu={handleContextMenu}
            setSelectedComponent={setSelectedComponent}
          />
        );
      });
    } else if (typeof component?.properties?.children) {
      nestedComments = component?.properties?.children;
    }
    if (comp === "Col" && layoutType === 2) {
      if (component.properties) {
        // component.properties.lg = component.properties.xs
        propertiesList = {
          ...component.properties,
        };
        propertiesList.lg = propertiesList.xs;
      }
    } else {
      propertiesList = JSON.parse(JSON.stringify(component?.properties));
    }
  }

  function ComponentWraper({ children }) {
    return (
      <div
        draggable={draggable}
        id={component.id}
        data-id={component.id}
        data-name={component.name}
        onClick={(e) => {
          setSelectedComponent(component);
          getComponentId(e, index, component, Parentindex);
        }}
        onDragStart={(e) => onDragStart(e)}
        onMouseDown={(e) => {
          if (e.button === 2) {
            handleContextMenu(e, component);
          }
        }}
        className={`${
          component?.attributes?.class ? component.attributes.class : ""
        }${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
        style={component.inlineStyles}
      >
        {children}
      </div>
    );
  }

  if (component.component === "Autocomplete") {
    return (
      <ComponentWraper>
        <Autocomplete
          options={[]}
          key={index}
          id={component.id}
          className={`${
            component?.attributes?.class ? component.attributes.class : ""
          }`}
          disabled={component?.properties?.disabled ?? true}
          defaultValue={[]}
          data-id={component.id}
          data-name={component.name}
          style={component.inlineStyles}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.label}
                {...getTagProps({ index })}
              />
            ))
          }
          getOptionLabel={(option) => option?.title ?? option.label ?? option}
          renderInput={(params) => (
            <TextField
              // onClick={(e) => {
              //   setSelectedComponent(component);
              //   getComponentId(e, index, component, Parentindex);
              // }}
              {...params}
              label={
                component?.properties?.value
                  ? component?.properties?.value
                  : component?.properties?.label
              }
              variant="outlined"
              // {...component?.properties}
              style={component.inlineStyles}
            />
          )}
          {...propertiesList}
        />
      </ComponentWraper>
    );
  }

  if (component.component === "Hidden") {
    return (
      <ComponentWraper>
        <Hidden
          draggable={draggable}
          onDragStart={(e) => onDragStart(e)}
          onClick={(e) => {
            setSelectedComponent(component);
            getComponentId(e, index, component, Parentindex);
          }}
          onMouseDown={(e) => {
            if (e.button === 2) {
              handleContextMenu(e, component);
            }
          }}
          {...propertiesList}
          key={index}
          className={`${
            component?.attributes?.class ? component.attributes.class : ""
          } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
          data-id={component.id}
          data-name={component.name}
          inLineStyles={component.inlineStyles}
          id={component.id}
        >
          {nestedComments}
        </Hidden>
      </ComponentWraper>
    );
  }

  if (component.component === "Login") {
    return (
      <ComponentWraper>
        <Layer
          {...propertiesList}
          style={styleDeclaration(
            component.name,
            component.inlineStyles,
            customStyle
          )}
          id={component.id}
        />
      </ComponentWraper>
    );
  }

  /**
   * this is for appbarheader
   */
  // if (component.component === "AppBarHeader") {
  //   console.log("first ", component, auth.user.componentAttr.id);
  //   let gettingAppBarHeader = auth.user.UIcomponents.filter(
  //     (comp) => component.component === comp.componentName && comp
  //   );
  //   let temp = gettingAppBarHeader[0].children;
  //   let propertiess = Object.keys(component.properties);
  //   let wertt = Object.keys(temp);
  //   wertt.forEach((e) => {
  //     if (propertiess.includes(e)) {
  //       console.log(
  //         "%c bgColor",
  //         "color:black;",
  //         component.properties.bgColor.length
  //       );
  //       if (component.properties?.bgColor.length >= 0) {
  //         gettingAppBarHeader[0].children.bgColor =
  //           component.properties?.bgColor;
  //       }
  //       if (component.properties?.title?.length >= 0) {
  //         gettingAppBarHeader[0].children.title.DisplayText.Text =
  //           component.properties.title;
  //         gettingAppBarHeader[0].children.title.Typography.fontFamily =
  //           component.properties.titlefontfamily;
  //         gettingAppBarHeader[0].children.title.Typography.fontSize = `${component.properties.titlefontsize}px`;
  //         gettingAppBarHeader[0].children.title.Typography.color =
  //           component.properties.titlecolor;
  //       }
  //       if (component.properties?.userName?.length >= 0) {
  //         gettingAppBarHeader[0].children.profileDetails.userName.DisplayText.Text =
  //           component.properties.userName;
  //         gettingAppBarHeader[0].children.profileDetails.userName.Typography.fontFamily =
  //           component.properties.usernamefontfamily;
  //         gettingAppBarHeader[0].children.profileDetails.userName.Typography.fontSize = `${component.properties.usernamefontsize}px`;
  //         gettingAppBarHeader[0].children.profileDetails.userName.Typography.color =
  //           component.properties.usernamecolor;
  //       }
  //       if (component.properties?.userDetails?.length >= 0) {
  //         gettingAppBarHeader[0].children.profileDetails.userDetails.DisplayText.Text =
  //           component.properties.userDetails;
  //         gettingAppBarHeader[0].children.profileDetails.userDetails.Typography.fontFamily =
  //           component.properties.userdetailsfontfamily;
  //         gettingAppBarHeader[0].children.profileDetails.userDetails.Typography.fontSize = `${component.properties.userdetailsfontsize}px`;
  //         gettingAppBarHeader[0].children.profileDetails.userDetails.Typography.color =
  //           component.properties.userdetailscolor;
  //       }
  //       if (component.properties?.version?.length >= 0) {
  //         gettingAppBarHeader[0].children.version.DisplayText.Text =
  //           component.properties.version;
  //         gettingAppBarHeader[0].children.version.Typography.fontFamily =
  //           component.properties.versionfontfamily;
  //         gettingAppBarHeader[0].children.version.Typography.fontSize = `${component.properties.versionfontsize}px`;
  //         gettingAppBarHeader[0].children.version.Typography.color =
  //           component.properties.versioncolor;
  //       }
  //       if (component.properties?.logoIcon?.length >= 0) {
  //         gettingAppBarHeader[0].children.logoIcon.icon =
  //           component.properties.logoIcon;
  //       }
  //       if (component.properties?.profileimage?.length >= 0) {
  //         gettingAppBarHeader[0].children.profileDetails.profile.image =
  //           component.properties.profileimage;
  //       }
  //     }
  //   });

  //   return (
  //     <div
  //       draggable={draggable}
  //       id={component.id}
  //       data-id={component.id}
  //       data-name={component.name}
  //       onClick={(e) => {
  //         setSelectedComponent(component);
  //         getComponentId(e, index, component, Parentindex);
  //       }}
  //       onDragStart={(e) => onDragStart(e)}
  //       onMouseDown={(e) => {
  //         if (e.button === 2) {
  //           handleContextMenu(e, component);
  //         }
  //       }}
  //       style={component.inlineStyles}
  //       className={`${
  //         component?.attributes?.class ? component.attributes.class : ""
  //       } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
  //     >
  //       <AllPackages.AppBarHeader
  //         draggable={draggable}
  //         onDragStart={(e) => onDragStart(e)}
  //         onClick={(e) => {
  //           setSelectedComponent(component);
  //           getComponentId(e, index, component, Parentindex);
  //         }}
  //         onMouseDown={(e) => {
  //           if (e.button === 2) {
  //             handleContextMenu(e, component);
  //           }
  //         }}
  //         {...propertiesList}
  //         key={index}
  //         className={`${
  //           component?.attributes?.class ? component.attributes.class : ""
  //         } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
  //         data-id={component.id}
  //         data-name={component.name}
  //         inLineStyles={component.inlineStyles}
  //         id={component.id}
  //         barContent={gettingAppBarHeader}
  //       ></AllPackages.AppBarHeader>
  //     </div>
  //   );
  // }

  if (component.frameWork === "html" || component.frameWork === "dynamic") {
    propertiesList = component.properties ?? {};
    attributesList = component.attributes ?? {};
    Layer = component.component;

    if (component?.child && component?.child.length > 0) {
      htmlChildren = (component.child || []).map((component, index) => {
        return (
          <ComponentsRenderer
            Parentindex={`${Parentindex}_${index}`}
            layoutType={layoutType}
            key={component?.id}
            index={index}
            component={component}
            type="child"
            returnComponentId={returnComponentId}
            screenName={screenName}
            draggable={draggable}
            pathId={pathId}
            screenId={screenId}
            screenIndex={screenIndex}
            previewScreen={previewScreen}
            handleContextMenu={handleContextMenu}
            setSelectedComponent={setSelectedComponent}
          />
        );
      });
    } else if (
      component?.properties?.name ||
      typeof component?.properties?.children === "string"
    ) {
      htmlChildren =
        component.properties?.name ?? component?.properties?.children ?? "";
    } else {
      htmlChildren = "";
    }
  }

  const inlineTags = ["br", "i", "em", "b"];

  // if (component.component === "Hidden") {
  //   return (
  //     <div
  //       draggable={draggable}
  //       id={component.id}
  //       ta-id={component.id}
  //       data-name={component.name}
  //       onClick={(e) => {
  //         setSelectedComponent(component);
  //         getComponentId(e, index, component, Parentindex);
  //       }}
  //       onDragStart={(e) => onDragStart(e)}
  //       onMouseDown={(e) => {
  //         if (e.button === 2) {
  //           handleContextMenu(e, component);
  //         }
  //       }}
  //       className={`${
  //         component?.attributes?.class ? component.attributes.class : ""
  //       } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
  //       style={component.inlineStyles}
  //     >
  //       <Hidden {...component?.properties}>
  //         <Box>{nestedComments}</Box>
  //       </Hidden>
  //     </div>
  //   );
  // }
  return (
    <>
      {component.frameWork === "qdm" || component.frameWork === "looper" ? (
        //rendering qdm components
        layoutComponentsList(comp) ? (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            key={index}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
            data-id={component.id}
            data-name={component.name}
            inLineStyles={component.inlineStyles}
            id={component.id}
          >
            {nestedComments}
          </Layer>
        ) : (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...component?.properties}
            key={index}
            id={component.id}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            }`}
            data-id={component.id}
            data-name={component.name}
            inLineStyles={component.inlineStyles}
          />
        )
      ) : component.frameWork.startsWith("material") ||
        component.frameWork === "custom-material-ui-component" ||
        component.frameWork === "functional-lib" ||
        component.frameWork === "atp-master-layout" ||
        component.frameWork === "atp-login" ||
        component.frameWork === "atp-table" ||
        component.frameWork === "atp-general-master" ||
        component.frameWork === "atp-authentication" ||
        component.frameWork === "atp-authorization" ||
        component.frameWork === "report_viewer_atp" ||
        component.frameWork === "atp-stepper" ||
        component.frameWork === "custom-material-ui-component" ? (
        //rendering qdm components
        component?.isLayout ? (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            key={index}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            }${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
            data-id={component.id}
            data-name={component.name}
            style={styleDeclaration(
              component.name,
              component.inlineStyles,
              customStyle
            )}
            id={component.id}
          >
            {nestedComments}
          </Layer>
        ) : component.frameWork === "functional-lib" ? (
          <div
            draggable={draggable}
            id={component.id}
            data-id={component.id}
            data-name={component.name}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onDragStart={(e) => onDragStart(e)}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            style={component.inlineStyles}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            } ${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
          >
            <Layer {...propertiesList}>{nestedComments}</Layer>
          </div>
        ) : (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            key={index}
            id={component.id}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            }`}
            data-id={component.id}
            data-name={component.name}
            style={component.inlineStyles}
          />
        )
      ) : component.frameWork === "html" ? (
        (component?.child && component?.child.length > 0) ||
        (component?.properties?.name ?? component?.properties?.children) ? (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            {...component.attributes}
            key={index}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            }${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
            data-id={component.id}
            data-name={component.component}
            style={component.inlineStyles}
            id={component.id}
          >
            {htmlChildren}
          </Layer>
        ) : (
          <Layer
            draggable={draggable}
            onDragStart={(e) => onDragStart(e)}
            onClick={(e) => {
              setSelectedComponent(component);
              getComponentId(e, index, component, Parentindex);
            }}
            onMouseDown={(e) => {
              if (e.button === 2) {
                handleContextMenu(e, component);
              }
            }}
            {...propertiesList}
            {...component.attributes}
            key={index}
            className={`${
              component?.attributes?.class ? component.attributes.class : ""
            }${previewScreen ? "fielddrag" : "fielddrag fielddragCss"}`}
            data-id={component.id}
            data-name={component.component}
            style={component.inlineStyles}
            id={component.id}
          />
        )
      ) : (
        <Typography
          style={{ fontSize: "20px", margin: "20px", textAlign: "center" }}
        >
          Component type is not supported
        </Typography>
      )}
    </>
  );
}
export function PreviewLayout(props) {
  return (
    <div key={props.index}>
      <Grid container item>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <>
            {props?.screen?.render?.child?.length > 0 &&
              props?.screen?.render?.child?.map((component, Parentindex) => {
                return (
                  <ComponentsRenderer
                    layoutType={props.layoutType} // mobile or web
                    key={component?.id} // id that is given for each and every component
                    component={component}
                    index={Parentindex}
                    Parentindex={Parentindex}
                    returnComponentId={props.getComponentId}
                    screenId={props?.screen.id} //id of the screen
                    screenName={props?.screen?.name} // name of the screen
                    screenIndex={props?.index} // screen index value
                    pathId={props?.screen.pathId}
                    previewScreen={props?.previewScreen}
                  />
                );
              })}
          </>
        </Grid>
      </Grid>
    </div>
  );
}
