import React from "react";
import { PreviewLayout } from "./previewLayout";

import { Link, Paper, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
export function Preview({ previewScreen, onSwitchPreview }) {
  var auths = JSON.parse(localStorage.getItem("previewScreen"));
  const history = useHistory();
  return (
    <div>
      <div>
        {auths?.length > 0 &&
          auths?.map((screen, index) => {
            return (
              <PreviewLayout
                key={screen?.id}
                screen={screen}
                index={index}
                previewScreen={previewScreen}
              />
            );
          })}
      </div>
      <Paper
        elevation={10}
        style={{
          position: "fixed",
          bottom: "0",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <Button varient="text" onClick={() => history.goBack()}>
          Exit Preview
        </Button>
      </Paper>
    </div>
  );
}
