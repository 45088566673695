import React from "react";
import { NotificationStatus, AuthContext} from "./contexts"

class AppNotifyAutosave extends React.Component {

    setNotifyState = (props) => { };
    render() {
        return (
            <>{
                <NotificationStatus.Provider value={{ notify: this.state, setNotifyState: this.setNotifyState }}>
                    {
                        this.props.children
                    }
                </NotificationStatus.Provider>
            }
            </>
        );
    }
}

AppNotifyAutosave.contextType = AuthContext

export default AppNotifyAutosave;