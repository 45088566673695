import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Alerts = (props) => {
  console.log("alert props", props);
  const [open, setOpen] = React.useState(props.open);

  // const url = "http://streaming.tdiradio.com:8000/house.mp3";

  // const audio = new Audio(url);
  const audio = new Audio(
    window.location.origin + "/images/ping-bing-tone.wav"
  );

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.onclose();
    setOpen(false);
  };

  React.useEffect(() => {
    if (props.open && props.tone) {
      audio.play();
    }
  }, [props.open, props.tone, audio]);

  return (
    <Snackbar
      id="main_alert_snackbar"
      anchorOrigin={{
        vertical: props.vertical,
        horizontal: props.horizontal,
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert id="main_alert" severity={props.severity} onClose={handleClose}>
        {props.msg}
      </Alert>
    </Snackbar>
  );
};

export default Alerts;
