import React from 'react';
import { makeStyles, Typography, Grid, IconButton } from '@material-ui/core';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { fade, withStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

// const tree = [
//     {
//         name: "Header",
//         child: [
//             {
//                 name: "Logo image"
//             },
//             {
//                 name: "Title"
//             },
//             {
//                 name: "Login button"
//             }
//         ]
//     },
//     {
//         name: "from",
//         child: [
//             {
//                 name: "input"
//             },
//             {
//                 name: "select"
//             },
//             {
//                 name: "button"
//             }
//         ]
//     }
// ]

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => <TreeItem {...props} />);


function Children({ children, Parentindex }) {

  let nestedChildrens = ''
  if(children.child && children.child.length > 0){
    nestedChildrens = (children.child || []).map((children, index) => {
      return <Children key={index} Parentindex={`${Parentindex}_${index}`} children={children} type="child" />
    })
  }

  return (
    <>
    {/* // <div style={{"marginLeft": "25px", "marginTop": "10px"}}> */}
      {/* <div>{comment.name+"name"}</div> */}
      <StyledTreeItem key={Parentindex} nodeId={`${Parentindex}`} label={children.name}>
      {nestedChildrens}
      </StyledTreeItem>
    {/* // </div> */}
    </>
  )
}

export const ComponentTree = (props) => {
  const classes = useStyles();
  const goBack = () => {
    props.goBack();
  }

  return (
    <div className={classes.root}>
    <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        style={{padding: "14px 16px 14px 8px"}}
    >
        <IconButton color="primary" size={"small"} onClick={(e) => goBack(e)} >
             <ArrowBackIosRoundedIcon style={{fontSize: "16px"}} color={"action"} />
        </IconButton>
        <Typography style={{paddingLeft: "10px", fontSize: "15px" }} variant={"h6"} >Feed</Typography>
    </Grid>
    <Grid>
         <Typography style={{paddingLeft: "10px", padding: "4px 14px", fontSize: "13px" }} color={"textSecondary"} variant={"button"} >COMPONENT TREE</Typography>
    </Grid>
    <Grid container style={{padding: "14px 16px"}}>
      <TreeView
        className={classes.root}
        // defaultExpanded={['1']}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={""}
      >
        {props.screen[0]?.render?.child?.length > 0 && props.screen[0]?.render?.child?.map((val, Parentindex) => {
            return(
                <StyledTreeItem key={Parentindex} nodeId={`${Parentindex}`} label={val.name}>
                    {val?.child?.length > 0 && val?.child?.map((children, index) => {
                        return(
                            <Children key={index} Parentindex={`${Parentindex}_${index}`} children={children} />
                        )
                    })}
                </StyledTreeItem>
            )
        })}
      </TreeView>
      
    </Grid>
    </div>
  );
}
