import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  Button,
  Menu,
  MenuItem,
  Popover,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import Input from "@material-ui/core/Input";
import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded";
import ArrowDropDownRoundedIcon from "@material-ui/icons/ArrowDropDownRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CheckIcon from "@material-ui/icons/Check";
import FlipToBackIcon from "@material-ui/icons/FlipToBack";
import { array_move } from "../../../components/commonFunction";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { makeid } from "../../../components/commonFunction";
import { AuthContext, DialogContext, AlertContext } from "../../../contexts";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import isEqual from "react-fast-compare";
import { alertProps } from "../../../utils";
import cloneDeep from "lodash/cloneDeep";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { Template } from "../../../components";
import blue from "@material-ui/core/colors/blue";
import gray from "@material-ui/core/colors/blueGrey";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
    },
    projectTemplateTitle: {
      paddingLeft: "13px",
      fontSize: "13px",
      textTransform: "capitalize",
      marginTop: "10px",
      color: "#9f9f9f",
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: "4px",
    },
    dragArea: {
      width: "75px",
      cursor: "grab",
      textAlign: "center",
      wordBreak: "break-words",
      padding: "0px 0px 5px 0px",
      margin: "5px auto 16px auto",
      minHeight: "30px",
      borderRadius: "8px",
      display: "grid",
      position: "relative",
      placeItems: "center",
      backgroundColor: blue[700],
      boxShadow: `0px 0px 10px ${gray[50]}`,
      // "&:hover": {
      //   backgroundColor: blue[50],
      //   boxShadow: `0px 0px 20px ${gray[200]}`,
      //   "& .dragAreaText": {
      //     color: blue[900],
      //   },
      //   "& .iconBg": {
      //     backgroundColor: blue[700],
      //   },
      //   // "& .dragAreaIcon": {
      //   //   color: "white",
      //   // },
      // },
      "& .iconBg": {
        borderRadius: "6px 6px 0px 0px",
        backgroundColor: blue[50],
        width: "30%",
        height: "30%",
        padding: "14px 0px",
        display: "grid",
        placeItems: "center",
        justifyContent: "center",
      },
      "& .dragAreaText": {
        color: "white",
        padding: "0px 1px 0px 1px",
      },
      "& .dragAreaIcon": {
        color: blue[700],
        fontSize: "20px",
      },
    },
    collapseView: {
      color: "gray",
      "& .MuiCollapse-wrapperInner": {
        display: "flex",
        flexWrap: "wrap",
      },
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    dialog: {
      minWidth: 400,
      padding: "20px",
    },
    templateName: {
      marginBottom: "30px",
    },
    templateNameInput: {
      fontSize: 14,
      padding: "12px 0px",
    },
  };
});

const initialState = {
  position: null,
  data: null,
};

export default function ProjectTemplates(props) {
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const dialog = React.useContext(DialogContext);
  //const [anchorEl, setAnchorEl] = React.useState(initialState);
  const [open, setOpen] = React.useState({});
  const [openI, setOpenI] = React.useState(false);
  const [anchorElo, setAnchorElo] = React.useState(null);
  const [tempId, setTempId] = React.useState("");
  const [input, setInput] = React.useState({});
  const [edit, setEdit] = React.useState({});
  const [mouseOver, setMouseOver] = React.useState(false);
  const [templateMouseOver, setTemplateMouseOver] = React.useState(false);
  const { projectTemplates } = auth.user;
  //const openI = Boolean(anchorElo);
  const options = ["Delete", "Edit"];
  const [temName, setTemName] = useState("");
  //const id = openI ? "simple-popover" : undefined;

  //template group-accordion handling
  const handleClick = (id) => {
    setOpen({
      [id]: open[id] ? !open[id] : true,
    });
  };
  const handleSub = (e, name, id) => {
    // console.log("hi", e, e.currentTarget);
    setOpenI(true);
    setTemName(name);
    setAnchorElo(e.currentTarget);
    // setAnchorElo({
    //   ...anchorElo,
    //   position: e.currentTarget,
    // });
  };

  const handleClickTemp = (id) => {
    setTempId((prevId) => (prevId = id));
  };
  const handleClose = () => {
    //setAnchorEl(initialState);
    setOpenI(false);
    setAnchorElo(null);
  };

  //template group-editing TG name
  const handleEdit = (id, val) => {
    setEdit({
      [id]: true,
    });
    setInput({
      [id]: val,
    });
  };

  //template group - TG name input
  const handleInput = (e, id) => {
    let inputValue = e.target.value;
    setInput({
      [id]: inputValue,
    });
  };

  //template group - saving new TG name
  const handleSave = (id, name) => {
    if (!input[id]) {
      return;
    }
    if (input[id] === name) {
      setEdit({
        [id]: false,
      });
      setInput({
        [id]: "",
      });
    }
    const newTemplateGroupName = input[id];
    const templateGroupsCopy = cloneDeep(projectTemplates);

    templateGroupsCopy.forEach((templateGroup) => {
      if (templateGroup.id === id) {
        templateGroup.name = newTemplateGroupName;
      }
    });
    auth.setAuth({
      ...auth.user,
      projectTemplates: templateGroupsCopy,
    });
    setEdit({
      [id]: false,
    });
    setInput({
      [id]: "",
    });
  };

  //template group - deleting TG
  const handleDelete = (id) => {
    const handleOnOkModal = () => {
      const templateGroupsCopy = cloneDeep(projectTemplates);

      templateGroupsCopy.forEach((templateGroup, index, array) => {
        if (templateGroup.id === id) {
          array.splice(index, 1);
        }
      });

      auth.setAuth({
        ...auth.user,
        projectTemplates: templateGroupsCopy,
      });
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
      });
    };
    const handClose = () => {
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
        title: "Delete Alert",
        body: "Do you need to delete this screen?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: () => { },
        onCancel: () => { },
      });
    };
    dialog.setDialog({
      ...dialog,
      open: true,
      tone: true,
      title: "Delete Alert",
      body: "Do you need to delete is template group?",
      positiveBtn: "Yes",
      negativeBtn: "No",
      onOk: handleOnOkModal,
      onCancel: handClose,
    });
  };

  //template group -mouse over to show edit & delete icon
  const handleMouseOver = (id) => {
    if (!mouseOver[id]) {
      setMouseOver({
        [id]: true,
      });
    }
  };

  const handleMouseOut = (id) => {
    if (mouseOver[id]) {
      setMouseOver({
        [id]: false,
      });
    }
  };

  //project template - mouse over to show edit & delete icon
  const handleTemplateMouseOver = (id) => {
    if (!templateMouseOver[id]) {
      setTemplateMouseOver({
        [id]: true,
      });
    }
  };

  const handleTemplateMouseOut = (id) => {
    if (templateMouseOver[id]) {
      setTemplateMouseOver({
        [id]: false,
      });
    }
  };

  //project template- dragging
  const onDragStart = (evt, type) => {
    evt.dataTransfer.setData(type, evt.currentTarget.id);
    evt.dataTransfer.effectAllowed = "move";
  };

  //template group- drag & drop handling
  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const sortedTemplateGroups = array_move(
      projectTemplates,
      result.source.index,
      result.destination.index
    );

    auth.setAuth({
      ...auth.user,
      projectTemplates: sortedTemplateGroups,
    });
  };

  //template group- adding a new template group
  const addTemplategroup = () => {
    const templateGroupLength = projectTemplates?.length;
    let templateGroupsCopy = cloneDeep(projectTemplates ?? []);
    const defaultTemplateGroup = {
      name: `Group - ${makeid()}`,
      id: makeid(),
      templates: [],
    };
    templateGroupsCopy.push(defaultTemplateGroup);
    auth.setAuth({
      ...auth.user,
      projectTemplates: templateGroupsCopy,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Typography className={classes.projectTemplateTitle} variant="subtitle2">
        {localStorage.getItem("projectName") ?? "Project"} Templates
      </Typography>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div
          style={{
            marginBottom: "24px",
          }}
        >
          <Droppable droppableId="templategroup">
            {(provided) => (
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                {...provided.droppableProps}
                innerRef={provided.innerRef}
                style={{ width: "100%", marginBottom: "12px" }}
              >
                {projectTemplates?.length >= 1 &&
                  projectTemplates.map((group, index) => {
                    return (
                      <TemplateGroup
                        key={group.id}
                        group={group}
                        auth={auth}
                        index={index}
                        projectTemplates={projectTemplates}
                        classes={classes}
                        dialog={dialog}
                        templateMouseOver={templateMouseOver}
                        mouseOver={mouseOver}
                        edit={edit}
                        input={input}
                        open={open}
                        options={options}
                        tempId={tempId}
                        //openo={openo}
                        openI={openI}
                        setOpenI={setOpenI}
                        anchorElo={anchorElo}
                        handleSub={handleSub}
                        onDragStart={onDragStart}
                        handleClick={handleClick}
                        handleClickTemp={handleClickTemp}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                        handleInput={handleInput}
                        handClose={handleClose}
                        handleSave={handleSave}
                        handleTemplateMouseOut={handleTemplateMouseOut}
                        handleTemplateMouseOver={handleTemplateMouseOver}
                        handleMouseOut={handleMouseOut}
                        handleMouseOver={handleMouseOver}
                        temName={temName}
                        setTemName={setTemName}
                      />
                    );
                  })}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
          <div style={{ display: "grid", placeItems: "center" }}>
            <Button
              disabled={auth.user.loading}
              onClick={addTemplategroup}
              variant={"outlined"}
              color={"primary"}
              size="small"
              style={{ fontSize: "8px" }}
            >
              Add Template Group
            </Button>
          </div>
        </div>
      </DragDropContext>
    </div>
  );
}

export const TemplateGroup = (props) => {
  const {
    group,
    index,
    classes,
    auth,
    dialog,
    edit,
    handleEdit,
    handleDelete,
    handleSave,
    handleMouseOver,
    handleMouseOut,
    handleClick,
    handleClickTemp,
    projectTemplates,
    open,
    input,
    handleInput,
    mouseOver,
    handleTemplateMouseOut,
    handleTemplateMouseOver,
    onDragStart,
    templateMouseOver,
  } = props;

  // project template - handling edit

  const handleTemplateEdit = (name, type) => {
    //this will opens up the edit template dialog
    props.setTemName(name);
    const templateDialog = {
      open: true,
      name,
      type,
      template: {},
    };
    auth.setAuth({
      ...auth.user,
      templateDialog,
    });
    props.setOpenI(false);
    props.setAnchorElo(null);
  };

  //project template - deleting
  const handleTemplateDelete = (name) => {
    props.setTemName(name);
    const handleOnOkModal = () => {
      const templateGroupsCopy = cloneDeep(projectTemplates);
      templateGroupsCopy.forEach((templateGroup, index, array) => {
        templateGroup?.templates.length !== 0 &&
          templateGroup.templates.forEach((template, index, array) => {
            if (template.name === name) {
              array.splice(index, 1);
            }
          });
      });

      auth.setAuth({
        ...auth.user,
        projectTemplates: templateGroupsCopy,
      });
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
      });
      props.setOpenI(false);
      props.setAnchorElo(null);
    };

    const handClose = () => {
      dialog.setDialog({
        ...dialog,
        open: false,
        tone: false,
        title: "Delete Alert",
        body: "Do you need to delete this screen?",
        positiveBtn: "Yes",
        negativeBtn: "No",
        onOk: () => { },
        onCancel: () => { },
      });
    };
    dialog.setDialog({
      ...dialog,
      open: true,
      tone: true,
      title: "Delete Alert",
      body: "Do you need to delete is template?",
      positiveBtn: "Yes",
      negativeBtn: "No",
      onOk: handleOnOkModal,
      onCancel: handClose,
    });
  };

  //template group- edit & delete components
  function renderTools() {
    if (!edit[group.id]) {
      return (
        <>
          <IconButton
            onClick={() => handleEdit(group.id, group.name)}
            className={classes.iconButton}
            color="action"
            aria-label="edit"
          >
            <EditIcon style={{ fontSize: 14 }} />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(group.id)}
            className={classes.iconButton}
            color="action"
            aria-label="delete"
          >
            <DeleteIcon style={{ fontSize: 14 }} />
          </IconButton>
        </>
      );
    } else {
      return (
        <>
          <IconButton
            onClick={() => handleSave(group.id, group.name)}
            className={classes.iconButton}
            color="action"
            aria-label="save"
          >
            <CheckIcon style={{ fontSize: 15 }} />
          </IconButton>
          <IconButton
            onClick={() => handleDelete(group.id)}
            className={classes.iconButton}
            color="action"
            aria-label="delete"
          >
            <DeleteIcon style={{ fontSize: 14 }} />
          </IconButton>
        </>
      );
    }
  }

  return (
    <div>
      <Draggable key={group.id} draggableId={group.id} index={index}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.draggableProps}>
            <ListItem
              grid
              key={group.id}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "default",
                padding: "0px 10px",
                marginBottom: "4px",
              }}
              button
              onMouseEnter={() => handleMouseOver(group.id)}
              onMouseLeave={() => handleMouseOut(group.id)}
              onClick={() => handleClick(group.id)}
            >
              <span {...provided.dragHandleProps}>
                <DragIndicatorIcon
                  color={"action"}
                  style={{
                    fontSize: "12px",
                    cursor: "grab",
                  }}
                />
              </span>
              {open[group.id] ?? false ? (
                <ArrowRightRoundedIcon color={"action"} />
              ) : (
                <ArrowDropDownRoundedIcon color={"action"} />
              )}
              {!edit[group.id] ?? true ? (
                <Typography
                  id={group.id}
                  style={{
                    width: "inherit",
                    cursor: "default",
                    padding: "5px 0px",
                    fontSize: "11px",
                    color: "#888484",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  variant="body2"
                >
                  {group.name}
                </Typography>
              ) : (
                <Input
                  id={group.id}
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      handleSave(group.id, group.name);
                    }
                  }}
                  error={input[group.id] === "" ? true : false}
                  defaultValue={group.name}
                  onChange={(e) => handleInput(e, group.id)}
                  required
                  color="primary"
                  style={{ fontSize: "11px" }}
                  variant="body2"
                />
              )}

              {mouseOver[group.id] && renderTools()}
            </ListItem>
            <Collapse
              in={open[group.id] && !edit[group.id] ? true : false}
              timeout="auto"
              unmountOnExit
              className={classes.collapseView}
            >
              {group?.templates.length !== 0 &&
                group?.templates.map((template, index) => (
                  <Grid
                    item
                    xs={12}
                    onMouseEnter={() => handleTemplateMouseOver(template.name)}
                    onMouseLeave={() => handleTemplateMouseOut(template.name)}
                    className={classes.dragArea}
                    draggable={true}
                    key={index}
                    onDragStart={(e) => onDragStart(e, "projectTemplate")}
                    id={template?.name}
                    style={{
                      cursor: "grab",
                      width: "auto",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      margin: "5px",
                      border: "1.4px dashed #cbd5e1",
                      padding: "1px 4px",
                      display: "flex",
                      justifyContent: "space-between",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Template fontSize={"small"} className="dragAreaIcon" />
                    </div>
                    <div style={{ paddingTop: "4px" }}>
                      <Typography
                        variant={"subtitle2"}
                        className="dragAreaText"
                        style={{
                          fontSize: "10px",
                          color: "#0f172a",
                          width: "80px",
                          // wordBreak: "break-all",
                          // marginLeft: "10px",overflow: "hidden",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {template.name}
                      </Typography>
                    </div>

                    <IconButton
                      size={"small"}
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      onClick={(e) => props.handleSub(e, template.name)}
                    >
                      <MoreVertIcon
                        fontSize={"small"}
                        style={{ fontSize: "15px" }}
                        color={"action"}
                      />
                    </IconButton>
                  </Grid>
                ))}
              {group?.templates.length === 0 && (
                <Typography
                  style={{
                    fontSize: 12,
                    width: "90%",
                    margin: "10px 0px",
                    cursor: "default",
                  }}
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  Select any component to make as a template
                </Typography>
              )}
            </Collapse>
          </div>
        )}
      </Draggable>
      <Popover
        open={props.openI}
        anchorEl={props.anchorElo}
        onClose={props.handClose}
        style={{
          width: "100px",
          height: "160px",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {/* <Menu
          anchorEl={props.anchorElo}
          open={props.openo}
          onClose={props.handleClose}
        > */}
        <MenuItem
          style={{
            fontSize: "9px",
          }}
          onClick={() => handleTemplateDelete(props.temName)}
        >
          Delete
        </MenuItem>
        <MenuItem
          style={{
            fontSize: "9px",
          }}
          onClick={() => handleTemplateEdit(props.temName, "Edit")}
        >
          Edit
        </MenuItem>
        {/* </Menu> */}
      </Popover>
    </div>
  );
};

export const MakeTemplateModal = ({
  classes,
  auth,
  dialog,
  projectTemplates,
}) => {
  const alert = React.useContext(AlertContext);
  const templateDialog = auth.user.templateDialog;
  const [open, setOpen] = useState(templateDialog?.open ?? false);
  const [templateName, setTemplateName] = useState("");
  const [templateGroup, setTemplateGroup] = useState(null);
  const [validation, setValidation] = useState({
    helperText: "",
    error: false,
  });
  const [update, setUpdate] = useState(false);

  const [openI, setOpenI] = React.useState(false);
  const [anchorElo, setAnchorElo] = React.useState(null);

  const filter = createFilterOptions();
  const templateGroupNames = projectTemplates ?? [];
  const selectedTemplateRef = useRef(null);
  const projectTemplateRef = useRef(projectTemplates);

  //checking if the template name already exists or not
  const duplicateNameCheck = (val) => {
    if (projectTemplates) {
      const result = projectTemplates.filter((templateGroup) => {
        return templateGroup.templates.some((template) => {
          return template.name.toLowerCase() === val.toLowerCase();
        });
      });
      return result;
    }
  };

  //handling dialog close
  const handleClose = () => {
    const updatedTemplateDialog = {
      open: false,
      name: "",
      type: "",
      template: {},
    };
    setTemplateName("");
    setTemplateGroup(null);
    setValidation({
      helperText: "",
      error: false,
    });
    selectedTemplateRef.current = null;
    setOpen(false);
    console.log(projectTemplateRef.current);
    auth.setAuth({
      ...auth.user,
      templateDialog: updatedTemplateDialog,
      projectTemplates: projectTemplateRef.current,
    });
  };

  //template name handling
  const handleTemplateName = (e) => {
    let input = e.target.value;
    setTemplateName(input);
    if (templateDialog.type === "Edit") {
      //only throw error when template name found other than the current template name
      const result = duplicateNameCheck(input);
      if (
        result?.length > 0 &&
        input.toLowerCase().trim() !== templateDialog.name.toLowerCase()
      ) {
        setValidation({
          helperText: "A template already exists with this name",
          error: true,
        });
      } else {
        if (
          selectedTemplateRef.current.name.toLowerCase() !==
          input.toLowerCase().trim()
        ) {
          setUpdate(true);
        } else {
          setUpdate(false);
        }
        setValidation({
          helperText: "",
          error: false,
        });
      }
    } else {
      const result = duplicateNameCheck(input);
      if (result?.length > 0) {
        setValidation({
          helperText: "A template already exists with this name",
          error: true,
        });
      } else {
        setValidation({
          helperText: "",
          error: false,
        });
      }
    }
  };

  const handleTemplateCreate = () => {
    const templateGroupsCopy = cloneDeep(projectTemplates);

    // adding template name for the json component-useful for handling copy/paste menu
    const templatejson = {
      ...templateDialog.template,
      templateName,
    };
    const newTemplate = {
      name: templateName,
      type: "component",
      json: templatejson,
    };
    //if template group already exists
    if (templateGroup?.id) {
      templateGroupsCopy.forEach((tg) => {
        if (tg.id === templateGroup.id) {
          tg.templates.push(newTemplate);
        }
      });
    }
    //if template group newly created
    else {
      const newTemplateGroup = {
        name: templateGroup.name,
        id: makeid(),
        templates: [newTemplate],
      };
      templateGroupsCopy.push(newTemplateGroup);
    }
    projectTemplateRef.current = templateGroupsCopy;
    alert.setSnack({
      ...alert,
      open: true,
      severity: alertProps.severity.success,
      msg: "Template created successfully",
      vertical: alertProps.vertical.bottom,
      horizontal: alertProps.horizontal.center,
      tone: true,
    });
    //setting template name for currently selected component in screen layout
    const selectedScreenId = auth.user.selectedScreen;
    auth.user.AllScreenList.forEach((screen) => {
      if (screen.id === selectedScreenId) {
        screen.render.child.forEach(function iter(component, index, array) {
          if (component.id === templateDialog.componentId) {
            component.templateName = templateName;
          } else {
            if (component?.child?.length > 0) {
              component.child.forEach(iter);
            }
          }
        });
      }
    });
    handleClose();
  };

  const handleTemplateGroup = (event, newValue) => {
    if (newValue === null) return;
    if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      setTemplateGroup({
        name: newValue.inputValue,
      });
      auth.user?.templateDialog?.type === "Edit" && setUpdate(true);
    } else {
      setTemplateGroup(newValue);
      auth.user?.templateDialog?.type === "Edit" && setUpdate(true);
    }
    if (
      auth.user?.templateDialog?.type === "Edit" &&
      newValue.id === selectedTemplateRef.current.groupId
    ) {
      setUpdate(false);
    }
  };

  //updating template
  const handleTemplateUpdate = () => {
    const templateGroupsCopy = cloneDeep(projectTemplates);
    //template name changed
    if (templateName !== selectedTemplateRef?.current?.name) {
      templateGroupsCopy.forEach((tg, index, array) => {
        if (tg.id === selectedTemplateRef?.current?.groupId) {
          tg.templates.length > 0 &&
            tg.templates.forEach((template, index, array) => {
              if (template.name === selectedTemplateRef?.current?.name) {
                template.name = templateName;
                template.json.templateName = templateName;
              }
            });
        }
      });
      //update all the template name in screenlist
      const selectedScreenId = auth.user.selectedScreen;
      auth.user.AllScreenList.forEach((screen) => {
        if (screen.id === selectedScreenId) {
          screen.render.child.forEach(function iter(component, index, array) {
            if (
              component?.templateName === selectedTemplateRef?.current?.name
            ) {
              component.templateName = templateName;
            } else {
              if (component?.child?.length > 0) {
                component.child.forEach(iter);
              }
            }
          });
        }
      });
    }

    let templateToChange;
    //if template group changed
    if (
      !templateGroup?.id ||
      templateGroup.id !== selectedTemplateRef?.current?.groupId
    ) {
      //deleting the template from the current template group
      templateGroupsCopy.forEach((tg, index, array) => {
        if (tg.id === selectedTemplateRef?.current?.groupId) {
          tg.templates.length > 0 &&
            tg.templates.forEach((template, index, array) => {
              if (template.name === templateName) {
                templateToChange = array[index];
                array.splice(index, 1);
              }
            });
        }
      });
      // already created group
      //to add the template to the newly shifted template group-if a template group already created
      if (templateGroup?.id) {
        templateGroupsCopy.forEach((tg, index, array) => {
          if (tg.id === templateGroup.id) {
            tg.templates.push(templateToChange);
          }
        });
      }
      // new template group
      //to add the template to the newly shifted template group-if a template group not already created
      else {
        const newTemplateGroup = {
          name: templateGroup.name,
          id: makeid(),
          templates: [templateToChange],
        };
        templateGroupsCopy.push(newTemplateGroup);
      }
    }

    projectTemplateRef.current = templateGroupsCopy;
    alert.setSnack({
      ...alert,
      open: true,
      severity: alertProps.severity.success,
      msg: "Template updated successfully",
      vertical: alertProps.vertical.bottom,
      horizontal: alertProps.horizontal.center,
      tone: true,
    });
    auth.setAuth({
      ...auth.user,
      projectTemplates: projectTemplateRef.current,
    });
    setUpdate(false);
    handleClose();
  };

  //detecting the template dialog changes to open the make/edit template dialog
  useEffect(() => {
    //if it is Edit dialog
    if (
      auth.user?.templateDialog?.name !== "" &&
      auth.user?.templateDialog?.type === "Edit"
    ) {
      const templateName = auth.user.templateDialog?.name;
      setTemplateName(templateName);
      const result = projectTemplates?.filter((templateGroup) => {
        return templateGroup.templates.some((template) => {
          return template.name === templateName;
        });
      });
      //setting the template value for editing
      setTemplateGroup(result[0]);
      selectedTemplateRef.current = {
        name: templateName,
        groupId: result[0]?.id,
      };
    }
    //opens the make/edit dialog
    if (auth.user?.templateDialog?.open) {
      setOpen(auth.user.templateDialog.open);
    }
  }, [auth.user.templateDialog]);

  useEffect(() => {
    projectTemplateRef.current = projectTemplates;
  }, [projectTemplates]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="make-template-dialog"
      open={open}
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle
        id="make-template-dialog"
        style={{ padding: "8px 0px 32px" }}
      >
        {templateDialog?.type} Template
      </DialogTitle>
      <TextField
        value={templateName}
        label="Template Name"
        id="outlined-start-adornment"
        className={classes.templateName}
        onChange={handleTemplateName}
        helperText={validation.helperText}
        error={validation.error}
        size="small"
        variant="outlined"
        autoFocus
      />
      <Autocomplete
        value={templateGroup}
        onChange={(event, newValue) => {
          handleTemplateGroup(event, newValue);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          // Suggest the creation of a new value
          if (
            params.inputValue !== "" &&
            !options.some(
              (option) =>
                option.name.toLowerCase() === params.inputValue.toLowerCase()
            )
          ) {
            filtered.push({
              inputValue: params.inputValue,
              name: `Add "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={templateGroupNames}
        getOptionLabel={(option) => {
          return option?.name;
        }}
        renderOption={(option) => option?.name}
        freeSolo
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Template Group"
              size="small"
              className={classes.templateName}
              variant="outlined"
            />
          );
        }}
      />
      <DialogActions className={classes.dialogActions}>
        <Button size="small" onClick={handleClose}>
          Close
        </Button>
        {templateName &&
          templateGroup &&
          templateDialog?.type === "Create" &&
          !validation.error && (
            <Button
              size="small"
              type="submit"
              onClick={handleTemplateCreate}
              variant="contained"
              style={{ marginLeft: "10px" }}
              color="primary"
              disableElevation
            >
              Create
            </Button>
          )}
        {templateName &&
          templateGroup &&
          update &&
          templateDialog?.type === "Edit" && (
            <Button
              size="small"
              type="submit"
              onClick={handleTemplateUpdate}
              variant="contained"
              color="primary"
              style={{ marginLeft: "10px" }}
              disableElevation
            >
              Update
            </Button>
          )}
      </DialogActions>
    </Dialog>
  );
};
