export const themes = {
  default: "default",
  dark: "dark",
};

export const netWorkCallMethods = {
  get: "GET",
  post: "POST",
  put: "PUT",
  delete: "DELETE",
  update: "UPDATE",
};

export const localStorageKeys = {
  auth_token: "auth_token",
  version: "version",
  projectName: "projectName",
  metaDataId: "metaDataId",
  UidesignerModelerid: "UidesignerModelerid",
};
export const customStyle = {
  Table: {
    minHeight: "70px",
    maxHeight: "auto",
  },
  TableHead: {
    minHeight: "70px",
    maxHeight: "auto",
  },
};
export const alertProps = {
  vertical: {
    top: "top",
    bottom: "bottom",
  },
  horizontal: {
    left: "left",
    right: "right",
    center: "center",
  },
  severity: {
    success: "success",
    error: "error",
    warning: "warning",
    info: "info",
  },
};

export const drawerProps = {
  direction: {
    top: "top",
    bottom: "bottom",
    left: "left",
    right: "right",
  },
  variant: {
    permanent: "permanent",
    persistent: "persistent",
    temporary: "temporary",
  },
};


export const getProjectPages = (auth) => {
  let pages = [];
  console.log(auth.user.AllScreenList);
  auth.user.AllScreenList.forEach((page) => {
    if (page.type === "Screen" && !page.isLayoutScreen) {
      pages.push({
        name: page.name,
        pageId: page.id,
        page: page.path,
        masterLayoutId: auth.user?.componentAttr.id,
        size: 0,
        icon: "",
        childPageSelect: [],
      });
    }
  });
  return pages;
};


export const getChildProjectScreen = (auth) => {
  let pages = [];
  console.log(auth.user.AllScreenList);
  auth.user.AllScreenList.forEach((page) => {
    if (
      page.type === "Screen" &&
      !page.isLayoutScreen &&
      !page.masterLayoutId
    ) {
      pages.push({
        screenName: page.name,
        screenId: page.id,
        masterLayoutId: auth.user?.componentAttr.id,
      });
    }
  });
  return pages;
};

export const getDefaultProfileImg = (username) => {
  return `https://avatars.dicebear.com/api/initials/${encodeURIComponent(
    username
  )}.svg`;
};