import { useEffect, useRef } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import config from "../config";

export function useMountedRef() {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  });
  return mounted;
}

export const useFetch = (queryKey, api, options = {}, rqOptions = {}) => {
  const mounted = useMountedRef();

  async function queryFn() {
    if (!mounted.current) return;
    // Create a new CancelToken source for this request
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const promise = axios.get(api, {
      cancelToken: source.token,
      ...options,
    });
    promise.cancel = () => {
      source.cancel("Query was cancelled by React Query");
    };
    return promise;
  }

  const reactQueryOptions = {
    staleTime: 0,
    enabled: true,
    retry: 2,
    refetchOnWindowFocus: false,
    ...rqOptions,
  };

  const { data, isLoading, error } = useQuery(
    queryKey,
    queryFn,
    reactQueryOptions
  );

  return {
    loading: isLoading,
    data,
    error,
  };
};

export async function arangoQueryFn(payload) {
  console.log(payload, "payload");
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const promise = axios.post(`${config.api_url}/read_documents`, {
    db_name: `${config.db_name}`,
    entity: payload.entity,
    filter: payload.filter,
    return_fields: payload.return_fields,
    ...payload,
  });
  promise.cancel = () => {
    source.cancel("Query was cancelled by React Query");
  };
  return promise;
}

export const useArangoQuery = (queryKey, payload = {}, rqOptions = {}) => {
  const reactQueryOptions = {
    staleTime: 0,
    enabled: true,
    retry: 2,
    refetchOnWindowFocus: false,
    ...rqOptions,
  };
  const { data, isLoading, error } = useQuery(
    queryKey,
    () => arangoQueryFn(payload),
    reactQueryOptions
  );
  return {
    loading: isLoading,
    data: data?.data?.result ?? [],
    error,
  };
};

const operations = {
  create: "upsert_document",
  update: "upsert_document",
  delete: "soft_delete",
};

// export const useArangoMutate = (rqOptions = {}) => {
//   const mounted = useMountedRef();

//   async function mutationFn(payload) {
//     if (!mounted.current) return;
//     const CancelToken = axios.CancelToken;
//     const source = CancelToken.source();
//     const promise = axios.post(
//       `${config.api_url}/${operations[payload.operation]}`,
//       [
//         {
//           db_name: `${config.db_name}`,
//           entity: payload.body.entity,
//           ...payload.body,
//         },
//       ]
//     );
//     promise.cancel = () => {
//       source.cancel("Query was cancelled by React Query");
//     };
//     return promise;
//   }
//   const reactQueryOptions = {
//     retry: 2,
//     ...rqOptions,
//   };
//   const { data, mutate, isLoading, isSuccess, error } = useMutation(
//     mutationFn,
//     reactQueryOptions
//   );
//   return {
//     mutate,
//     loading: isLoading,
//     isSuccess,
//     data,
//     error,
//   };
// };
