import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';


const useStyles = makeStyles((theme,props) => ({
    root: {
        flexGrow: 1,
        position: "relative",
        padding: 10
    },
    title: {
        marginBottom: 10
    }
}))

export const SidePallette = (props) => {

    const classes = useStyles({
        marginTop: props?.title ? 0 : 10
    });

    return <div className={classes.root}>
        {
            props?.title && <div className={classes.title}>
                <Typography variant="button" >
                    {props?.title}
                </Typography>
            </div>
        }
        {props?.children}
    </div>
}