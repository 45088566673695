import React, { useEffect } from "react";
import { makeStyles, TextField, Button, Grid, FormControlLabel, FormGroup, Checkbox } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AuthContext } from "../../../contexts";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 10
  },
  panelHead: {
    backgroundColor: "#ebebeb",
    "& .text": {
      backgroundColor: "#fff",
      display: "inline-block",
      padding: 10,
      borderTopRightRadius: 6,
    },
  },
  body: {
    padding: 20,
    paddingTop: 0,
  },
  close: {
    padding: 8,
    position: "absolute",
    right: 0,
  },
  marginTop: {
    marginTop: "6%",
  },
  screenNameCss: {
    marginTop: "6%",
    fontSize: 20,
    fontWeight: 700,
  },
  autocomplete: {
    borderRadius: "6px",
    // border: "1px solid #e4e4e4",
    marginBottom: 10
  }
}));

const conditionOption = [
  { label: "less than", value: ">" },
  { label: "greater than", value: "<" },
  { label: "equal", value: "===" },
];
const optionsComponentTransition = [
  {
    label: "Default",
    value: "default",
  },
  {
    label: "Modal",
    value: "modal",
  },
  {
    label: "Popover",
    value: "popover",
  },
];
let componentName = null;
let componentType = null;
let targetScreenName = null;
const SelectedFun = (val) => {
  componentName = val ? val?.name : "";
  return val;
};
const findArr = (arr, key, listKey, nextLoop) => {
  arr.map((val, i) => {
    if (val?.[listKey] === key) {
      arr[i] = SelectedFun(val);
      return false;
    }
    if (nextLoop && val?.[nextLoop]) {
      findArr(val[nextLoop], key, listKey, nextLoop);
    }
    return false;
  });
  return arr;
};

const initialTransitionState = {
  transition: optionsComponentTransition[0],
  name: componentName?.length > 0 ? componentName : null,
  value: "true",
  condition: conditionOption[2],
};

export function Navigation(props) {
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const [options, setoptions] = React.useState([]);
  const [optionsComponentList, setOptionsComponentList] = React.useState([]);
  const [dropDownVal, setdropDownVal] = React.useState(null);
  const [eventOptions, setEventOptions] = React.useState({
    refreshEnabled: false,
    isBack: false
  })
  const [goto, setGoto] = React.useState({
    status: false,
    destination_type: ''
  })
  const [componentLinkdropDownVal, setComponentLinkdropDownVal] =
    React.useState(null);
  const [componentTransitionType, setcomponentTransitionType] = React.useState(
    initialTransitionState
  );

  useEffect(() => {
    debugger    
    let Screen = auth.user.AllScreenList.filter(
      ({ pathId }) => pathId === props.id
    );
    findArr(Screen[0].render.child, auth.user.componentId, "id", "child");

    var componentId = auth.user.componentId;
    var arrowLink = auth.user.AllScreenList[props.index]?.arrowLink ?? null;

    var istargetScreenName = arrowLink?.find((item) => {
      return item.eventId === auth.user.eventId
    });
    if (istargetScreenName?.arrowTarget) {
      let targetScreen = auth.user.AllScreenList.filter(screen => istargetScreenName.arrowTarget === screen.pathId);

      targetScreenName = targetScreen[0]?.name
      let labelText = istargetScreenName.pathId.split("/");

      let obj = {
        label: istargetScreenName.label
          ? istargetScreenName.label
          : labelText[1],
        path: istargetScreenName.pathId,
        value: istargetScreenName.arrowTarget,
        linkType: istargetScreenName?.linkType
          ? istargetScreenName?.linkType
          : "screen_link",
        id: istargetScreenName?.id,
        eventId: auth.user.eventId
      };
      if (obj?.linkType === "screen_link") {
        setdropDownVal(obj);
      } else {
        setComponentLinkdropDownVal(obj);
        if (auth.user.componentAttr?.privateComponent?.type) {
          setcomponentTransitionType({
            ...componentTransitionType,
            transition: {
              label: auth.user.componentAttr?.privateComponent?.type,
            },
          });
        }
      }
    } else {
      targetScreenName = null;
      setdropDownVal([]);
      setComponentLinkdropDownVal([]);
      setcomponentTransitionType(initialTransitionState);
    }

    const fetchData = () => {
      let append = [];
      let componentList = [];
      debugger
      auth.user.AllScreenList.map((val, index) => {
        if (
          val.name?.toLowerCase().trim().replace(" ", "") !==
          props.screenName.toLowerCase().trim().replace(" ", "") &&
          val.type === "Screen"
        ) {
          append.push({
            value: val.pathId,
            label: val.name,
            path: val.path,
          });
        }
        return true;
      });
      setoptions(append);

      // filter component list
      auth.user.AllScreenList.filter((val) => val.type === "Component").map(
        (vl) => {
          if (
            vl.name.toLowerCase().trim().replace(" ", "") !==
            props.screenName.toLowerCase().trim().replace(" ", "")
          ) {
            componentList.push({
              value: vl.pathId,
              label: vl.name,
              path: vl.path,
            });
          }
          return true;
        }
      );
      setOptionsComponentList(componentList);
    };
    fetchData();
    // eslint-disable-next-line
  }, [auth.user.eventId]);

  React.useEffect(() => {
    const { componentId, screenIndex, AllScreenList } = auth.user;
    let currentEvent = AllScreenList[screenIndex].events.filter(item => item?.eventId === auth.user.eventId);
    let eventOptions = {
      isBack: currentEvent[0]?.isBack,
      refreshEnabled: currentEvent[0]?.refreshEnabled
    }
    setEventOptions({ ...eventOptions })

    var arrowLink = auth.user.AllScreenList[props.index]?.arrowLink ?? null;
    var istargetScreenName = arrowLink?.find((item) => {
      return item.eventId === auth.user.eventId
    });
    if (istargetScreenName && Object.keys(istargetScreenName).length) {
      setGoto({
        status: true,
        destination_type: istargetScreenName?.linkType
      })
    }

  }, [auth.user.eventId])

  const updateChildJson = (screen, ComponentId, object) => {
    if (screen) {
      screen.filter((child, index) => {
        if (child.id === auth.user.componentId) {
          let obj = child["privateComponent"];
          child["privateComponent"] = { ...obj, ...object };
          return true;
        } else {
          updateChildJson(child.child, auth.user.componentId, object);
          return true;
        }
      });
      // return true
    } else {
      return false;
    }
  };

  const handleEventChanges = (actionType) => {
    const { componentId, screenIndex, AllScreenList, eventId } = auth.user;
    let currentEvent = AllScreenList[screenIndex].events.filter(item => item?.eventId === eventId);
    let eventOptionData = {
      [actionType]: !eventOptions?.[actionType]
    };
    currentEvent[0]['refreshEnabled'] = eventOptionData?.refreshEnabled;
    currentEvent[0]['isBack'] = eventOptionData?.isBack;
    setEventOptions({ ...eventOptionData })
    auth.setAuth({
      AllScreenList: AllScreenList
    })
  }

  // from components get funtion
  const dropDownChange = (event, newValue, type) => {
    if (newValue) {
      let UpdateJson = {};
      var id = auth.user.componentId;
      var Data = auth.user?.AllScreenList[props.index]?.arrowLink;
      var findindex = Data?.findIndex((x) => x.eventId === auth.user.eventId);
      if (Data[findindex]?.arrowSource === auth.user.componentId) {
        UpdateJson = {
          id: auth.user.componentId,
          arrowSource: auth.user.componentId,
          arrowTarget: newValue.value,
          pathId: newValue.path,
          label: newValue.label,
          linkType: type,
          ignore: type === "component_link" ? true : false,
          eventId: auth.user.eventId
        };
        auth.user.AllScreenList[props.index].arrowLink[findindex] = UpdateJson;
        if (type === "component_link") {
          let object = {
            id: newValue.value,
            name: newValue.label,
          };
          setComponentLinkdropDownVal(UpdateJson);
          updateChildJson(
            auth.user.AllScreenList[props.index].render.child,
            auth.user.componentId,
            object
          );
        } else {
          setdropDownVal(UpdateJson);
        }
      } else {
        UpdateJson = {
          id: auth.user.componentId,
          arrowSource: auth.user.componentId,
          arrowTarget: newValue.value,
          pathId: newValue.path,
          label: newValue.label,
          linkType: type,
          ignore: type === "component_link" ? true : false,
          eventId: auth.user.eventId
        };
        auth.user.AllScreenList[props.index].arrowLink.push(UpdateJson);
        if (type === "component_link") {
          let object = {
            id: newValue.value,
            name: newValue.label,
          };
          setComponentLinkdropDownVal(UpdateJson);

          // add privateComponent key component list based on screen
          auth.user.AllScreenList[props.index].privateComponent = auth.user
            .AllScreenList[props.index].privateComponent
            ? auth.user.AllScreenList[props.index].privateComponent
            : [];
          let already = false;
          auth.user.AllScreenList[props.index].privateComponent.map((val) => {
            if (val.id === object.id) {
              already = true;
              val.count = val.count ? val.count : 0;
              val.count = val.count + 1;
            }
          });

          if (!already) {
            auth.user.AllScreenList[props.index].privateComponent.push(object);
          }

          updateChildJson(
            auth.user.AllScreenList[props.index].render.child,
            auth.user.componentId,
            object
          );
        } else {
          setdropDownVal(UpdateJson);
        }
      }

      var screenName = document?.getElementById(newValue.value);
      targetScreenName = screenName?.dataset.screen;
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        isEdited: true,
        // undoRedo: true
      });
    } else deleteLine()
  };

  const deleteLine = () => {
    var Data = auth.user.AllScreenList[props.index].arrowLink;
    var id = auth.user.componentId;
    var eventId = auth.user.eventId;
    var findindex = Data.findIndex((x) => x.eventId === eventId);
    var component = Data.filter((x) => x.eventId === eventId)?.[0];
    var screen = auth.user.AllScreenList[props.index];

    // remove component import componet link names
    if (component.linkType === "component_link") {
      screen.privateComponent = screen.privateComponent
        ? screen.privateComponent
        : [];
      let index = null;
      screen.privateComponent.map((val, i) => {
        if (val.id === component.arrowTarget) {
          if (!val.count) {
            index = i;
          } else {
            val.count = val.count - 1;
          }
        }
      });
      if (index || index === 0) {
        screen.privateComponent.splice(index, 1);
      }
    }
    updateChildJson(
      auth.user.AllScreenList[props.index].render.child,
      component.id,
      null
    );
    setcomponentTransitionType(initialTransitionState);

    auth.user.AllScreenList[props.index].arrowLink.splice(findindex, 1);
    auth.setAuth({
      ...auth.user,
      AllScreenList: auth.user.AllScreenList,
      isEdited: false,
    });

    targetScreenName = null;
    setdropDownVal([]);
    setComponentLinkdropDownVal([]);
  };

  React.useEffect(() => {
    var Data = auth.user?.AllScreenList[props.index]?.arrowLink;
    var findindex = Data?.findIndex((x) => x.id === auth.user.componentId);

    if (
      componentTransitionType.transition?.label === "Modal" ||
      componentTransitionType.transition?.label === "Popover"
    ) {
      let event = {
        EntityDetails: {},
        name: "onClick",
        action: [],
        isApiCall: false,
        CName: componentName,
        fromId: auth.user.componentId,
        value: {
          type: 1,
        },
        isOverLay: true,
      };

      let privateComponent = {
        fromId: auth.user.componentId,
        type: componentTransitionType.transition.label,
        condtion: componentTransitionType.condition,
        value: componentTransitionType.value,
      };

      // update arrow ingore type
      let updateJson =
        auth.user.AllScreenList[props.index].arrowLink[findindex];
      auth.user.AllScreenList[props.index].arrowLink[findindex] = {
        ...updateJson,
        ignore: false,
      };

      updateChildJson(
        auth.user.AllScreenList[props.index].render.child,
        auth.user.componentId,
        privateComponent
      );

      // update event for actions
      auth.user.AllScreenList[props.index]["events"] = auth.user.AllScreenList[
        props.index
      ]["events"]
        ? auth.user.AllScreenList[props.index]["events"]
        : [];
      auth.user.AllScreenList[props.index]["events"].push(event);
    } else {
      let privateComponentNull = {
        fromId: null,
        type: null,
        condtion: null,
        value: null,
      };
      updateChildJson(
        auth.user.AllScreenList[props.index].render.child,
        auth.user.componentId,
        privateComponentNull
      );

      // update arrow ingore type
      let updateJson =
        auth.user.AllScreenList[props.index].arrowLink[findindex];
      auth.user.AllScreenList[props.index].arrowLink[findindex] = {
        ...updateJson,
        ignore: true,
      };

      // update event for actions
      auth.user.AllScreenList[props.index]["events"] = auth.user.AllScreenList[
        props.index
      ]["events"]
        ? auth.user.AllScreenList[props.index]["events"]
        : [];
      auth.user.AllScreenList[props.index]["events"] = auth.user.AllScreenList[
        props.index
      ]["events"].filter(
        (val) => !(val.isOverLay && val.fromId === auth.user.componentId)
      );
    }
  }, [componentTransitionType]);

  const changeTransitionType = (val, state) => {
    setcomponentTransitionType({
      ...componentTransitionType,
      [state]: val,
    });
  };
  return (
    <div className={classes.root}>
      <div className={classes.body}>
        {/* <p className={classes.screenNameCss}>{props.screenName} (Screen)</p> */}
        <div>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox
                checked={goto?.status}
                onClick={() => {
                  if (goto?.status) {
                    try{
                      deleteLine()
                    }catch{}
                  }
                  setGoto({
                    status: !goto?.status,
                    destination_type: ''
                  })
                }}
              />}
              label="Go to page"
            />
            {
              goto?.status && <Autocomplete
                className={classes?.autocomplete}
                size={"small"}
                value={goto?.destination_type}
                onChange={(event, newValue) => setGoto({
                  ...goto,
                  destination_type: newValue
                })}
                id="controllable-states-demo"
                options={["screen_link", "component_link"]}
                getOptionLabel={(options) => options}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="select destination type"
                    variant="outlined"
                  />
                )}
              />
            }
            {/* link navigation vs component start */}
            <div style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                {
                  goto?.destination_type === "screen_link" && <Autocomplete
                    className={classes?.autocomplete}
                    size={"small"}
                    disabled={componentLinkdropDownVal?.id ? true : false}
                    value={dropDownVal}
                    onChange={(event, newValue) =>
                      dropDownChange(event, newValue, "screen_link")
                    }
                    id="controllable-states-demo"
                    options={options}
                    getOptionLabel={(options) => options.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose Target"
                        variant="outlined"
                      />
                    )}
                  />
                }
                {
                  goto?.destination_type === "component_link" && <Autocomplete
                    className={classes?.autocomplete}
                    size={"small"}
                    disabled={dropDownVal?.id ? true : false}
                    value={componentLinkdropDownVal}
                    onChange={(event, newValue) =>
                      dropDownChange(event, newValue, "component_link")
                    }
                    id="controllable-states-demo"
                    options={optionsComponentList}
                    getOptionLabel={(options) => options.label}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Choose Component Target"
                        variant="outlined"
                      />
                    )}
                  />
                }

                {
                  goto?.destination_type === "component_link" &&
                  componentLinkdropDownVal?.label && (
                    <>
                      <Autocomplete
                        className={classes?.autocomplete}
                        size={"small"}
                        disableClearable
                        disabled={componentLinkdropDownVal?.label ? false : true}
                        value={componentTransitionType?.transition}
                        onChange={(event, newValue) =>
                          changeTransitionType(newValue, "transition")
                        }
                        id="controllable-states-demo"
                        options={optionsComponentTransition}
                        getOptionLabel={(options) => options.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Component Transition"
                            variant="outlined"
                          />
                        )}
                      />
                      {(componentTransitionType?.transition?.label === "Modal" ||
                        componentTransitionType?.transition?.label === "Popover") && (
                          <Grid container>
                            <Grid item md={12}>

                            </Grid>

                          </Grid>
                        )}
                    </>
                  )}
              </div>
            </div>
            {/* link navigation vs component start */}
            <FormControlLabel
              control={<Checkbox
                checked={eventOptions?.isBack}
                onClick={() => handleEventChanges("isBack")}
              />}
              label="Go to previous page"
            />
            <FormControlLabel
              control={<Checkbox
                checked={eventOptions?.refreshEnabled}
                onClick={() => handleEventChanges("refreshEnabled")}
              />}
              label="Refresh page"
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
}
