import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, Typography, Divider } from "@material-ui/core";
import { useEffect } from "react";
import { PropertyRenderer } from "../screens/uiBuilder/components/propertyspanel/propertyRenderer";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles((theme) => ({}));

export const ArrayOfObject = (props) => {
  const [propertiesState, setPropertiesState] = useState([]);

  const { handleChange, name, data, toRender, values } = props;

  const handlePropertiesChange = (key, val, index) => {
    let propertiesStateCopy = JSON.parse(JSON.stringify(propertiesState));
    propertiesStateCopy.forEach((property, propertyIndex, array) => {
      if (propertyIndex === index) {
        array[index] = {
          ...property,
          [key]: val,
        };
      }
    });
    setPropertiesState(propertiesStateCopy);
  };

  const createNewObject = () => {
    let result = Object.keys(toRender).reduce((prev, curr) => {
      return {
        ...prev,
        [curr]: "",
      };
    }, {});
    return result;
  };

  const removeObject = (index) => {
    let propertiesStateCopy = JSON.parse(JSON.stringify(propertiesState));
    propertiesStateCopy.forEach((property, propertyIndex, array) => {
      if (propertyIndex === index) {
        array.splice(propertyIndex, 1);
      }
    });
    setPropertiesState(propertiesStateCopy);
  };

  useEffect(() => {
    if (values.length > 0) {
      setPropertiesState(values);
    } else {
      let newObject = createNewObject();
      console.log(newObject);
      setPropertiesState([newObject]);
    }
  }, []);

  useEffect(() => {
    handleChange(name, propertiesState);
  }, [propertiesState]);

  return (
    <div style={{ display: "grid", gap: "16px 0px" }}>
      <Typography
        variant="body1"
        style={{ textTransform: "capitalize", fontSize: "15px" }}
      >
        {name}
      </Typography>

      {propertiesState.length > 0 &&
        propertiesState.map((property, propertyIndex) => {
          let propertyKeys = Object.keys(property);
          console.log(propertyKeys, property);
          return (
            <div>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton color="action" size="small">
                  <DeleteIcon
                    style={{
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    color="action"
                    onClick={() => {
                      removeObject(propertyIndex);
                    }}
                  />
                </IconButton>
              </div>
              {propertyKeys.map((propertyKey) => {
                return (
                  <PropertyRenderer
                    data={data}
                    propertyKey={propertyKey}
                    propertiesOptions={toRender[propertyKey]}
                    handleChange={handlePropertiesChange}
                    properties={property}
                    index={propertyIndex}
                  />
                );
              })}
            </div>
          );
        })}
      <Button
        onClick={() => {
          let newObj = createNewObject();
          let propertiesStateCopy = JSON.parse(JSON.stringify(propertiesState));
          propertiesStateCopy.push(newObj);
          setPropertiesState(propertiesStateCopy);
        }}
        color="primary"
        size="small"
        style={{ textTransform: "capitalize", fontSize: "13px" }}
        variant="outlined"
      >
        Add new object
      </Button>
      <Divider variant="fullWidth" />
    </div>
  );
};

ArrayOfObject.defaultProps = {
  handleChange: () => null,
  toRender: {
    header: {
      componentToRender: "text",
    },
    fullWidth: {
      componentToRender: "select",
      options: [true, false],
    },
    body: {
      componentToRender: "component",
    },
  },
  name: "stepsHeader",
  values: [],
  data: {},
};
