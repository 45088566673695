import React from "react";
import {
  makeStyles,
  Typography,
  Switch,
  FormControlLabel,
  TextField,
  withStyles,
  Divider,
  IconButton,
  Popover,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Checkbox,
} from "@material-ui/core";
import { AuthContext } from "../../../contexts";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

// import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import { nestedHandler } from "../../../components/commonFunction";
import {
  DataSource,
  commonDataSources,
  HideShow,
  SetProps
} from "../components";


const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#007aff",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const useStyles = makeStyles((theme) => ({
  body: {
    padding: "20px 14px",
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4%",
  },
  subText: {
    padding: 8,
    border: "1px solid #c7c7c7",
    marginBottom: "6%",
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  btn: {
    padding: 0,
  },
  formControl: {
    minWidth: 154,
    margin: 0,
  },

  margin: {
    margin: theme.spacing(1),
  },
}));
const filterOptions = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.entity,
});
const filterOptions1 = createFilterOptions({
  matchFrom: "start",
  stringify: (option) => option.title,
});
var EntitiesList = [];
let tableTitle1 = "";
let columnTitle1 = "";
var currentIndexArray = "";
// var EntitiesListcol = [];
export function DisplayLogic(props) {
  var getType = "";
  var getValue = "";
  const auth = React.useContext(AuthContext);
  const [showFinalAttribute, setFinalAttribute] = React.useState(false);
  const [subCollections, setSubCollection] = React.useState([]);
  const [dataSourceData, setDataSourceData] = React.useState({});
  const [dataSourceOptions, setDataSourceOptions] = React.useState({
    history: []
  })
  const getentitiesval = auth.user.AllScreenList.filter(
    ({ name }) => name === props.screenName
  );
  const indicesRef = React.useRef(null);

  React.useEffect(() => {
    EntitiesList = [];
    currentIndexArray = "";
    if (getentitiesval[0]?.models) {
      if (Array.isArray(getentitiesval[0]?.models[0].EntityDetails)) {
        EntitiesList = [...getentitiesval[0].models[0].EntityDetails];
      } else {
        EntitiesList.push(getentitiesval[0].models[0].EntityDetails);
      }
    }
    ///converting all qpas-queries into collection

    let qpasQueries = auth.user.qpasQueries;
    qpasQueries.forEach((item) => {
      let payload = [];
      try {
        payload = item.responseschema;
        EntitiesList.push({
          id: item?._id,
          entity: item?.apiname,
          column: nestedHandler(payload?.items ? payload.items : payload),
        });
      } catch (e) {
        return null;
      }
    });


  }, []);


  const handleDataSourceChange = (v) => {
    let data = dataSourceData;
    data["name"] = v.keyName;
    /// all datasource value will be set on $value independant of value passing key
    data["value"] = v;

    setDataSourceData({ ...data })
    getType = "datasource-change";
    getValue = data;
    findArr(
      getentitiesval[0].render.child,
      auth.user.componentId,
      "id",
      "child"
    );

  }

  const handleHideShow = (data) => {
    getValue = data;
    getType = "hide-show"
    if (true) {
      findArr(
        getentitiesval[0].render.child,
        auth.user.componentId,
        "id",
        "child"
      );
    }
  }

  const setCustomProps = (data) => {
    getValue = data;
    getType = "custom-props"
    if (true) {
      findArr(
        getentitiesval[0].render.child,
        auth.user.componentId,
        "id",
        "child"
      );
    }
  }

  const SelectedFun = (val, type) => {
    if (type === "table") {
      ////for setting selected entity on callback
      if (getValue) {
        val.mappedEntity = tableTitle1;
      } else {
        delete val.mappedEntity;
        delete val.mappedIndices;
        setSubCollection([]);
      }
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        isEdited: true,
        undoRedo: false,
      });
      return val;
    } else if (type === "isVarType") {
      if (getValue) {
        val.isVarType = getValue;
      } else {
        delete val.isVarType;
        delete val.mappedIndices;
        delete val.customAttr
        setSubCollection([]);
      }
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        isEdited: true,
        undoRedo: false,
      });
      return val;
    } else if (type === "column") {
      if (getValue) {
        val.customAttr = ["children"];
        var keys = Object.keys(val);
        ///will delete properties entirely
        var properties_is = keys.filter((val) => val === "properties");
        if (!properties_is) {
          val["properties"] = {};
        }
        val.mappedAttribute = columnTitle1;
        val.mappedIndices = currentIndexArray;

        if (val.isVarType) {
          val.properties[val?.valuePassingKey] = getValue
            ? `{${val.mappedEntity}Data?.data[0]?.${getValue.title}}`
            : null;
        } else {
          val.properties[val?.valuePassingKey] = getValue
            ? attributeVariables(val, val.properties?.name, getValue)
            : null;
        }
        // delete val.properties.children;
        // delete val.properties.value
      }
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        isEdited: true,
        undoRedo: false,
      });
      return val;
    } else if (type === "checkbox") {
      // val.component_type = getValue ? "looper" : "qdm";
      val.isLoop = getValue ? true : false;
      if (getValue) {
        var addon = getAttibuteVaribale(val).replace("data?.", '');
        if (addon)
          val.array = `Array.isArray(${tableTitle}Data?.${addon}) && ${tableTitle}Data?.${addon}`;
        else
          val.array = `Array.isArray(${tableTitle}Data?.data) && ${tableTitle}Data?.data`;
      } else {
        delete val.array;
      }
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        isEdited: true,
        undoRedo: false,
      });
      return val;
    } else if (type === "isloopComponent") {
      val.isloopComponent = getValue ? true : false;
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        isEdited: true,
        undoRedo: false,
      });
      return val;
    } else if (type === "isDelete") {
      val.isDelete = getValue ? true : false;
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        isEdited: true,
        undoRedo: false,
      });
      return val;
    } else if (type === "setpropskey") {
      if (getValue) {
        val.propskey = {
          value: getValue,
          renderKey: val.component === "Typography" ? "children" : "value"
        }
      } else {
        if (val.propskey) {
          delete val.propskey
          setPropskey({})
        }
      }
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        isEdited: true,
        undoRedo: false,
      });
      return val;
    } else if (type === "datasource") {
      // val.component_type = getValue ? "looper" : "qdm";
      if (val?.isDataSource) {
        val.dataSources = {}
        setDataSourceData({})
      }
      setIsDataSource(!isDataSource);
      val.isDataSource = !isDataSource;
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        isEdited: true,
        undoRedo: false,
      });
      return val;
    } else if (type === "datasource-change") {
      let setValue = `$${dataSourceData?.name}`

      val.properties[val?.valuePassingKey] = setValue;
      dataSourceData.renderKey = val?.valuePassingKey;

      val["dataSources"] = dataSourceData;
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        isEdited: true,
        undoRedo: false,
      });
      return val;
    } else if (type === "hide-show") {
      if (getValue?.length) {
        val.isShow = getValue;
      } else {
        if (val.isShow) {
          delete val.isShow
        }
      }
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        isEdited: true,
        undoRedo: false,
      });
      return val
    }
    else if (type === "custom-props") {
      if (getValue?.length) {
        val.componetprops = getValue;
      } else {
        if (val.componetprops) {
          delete val.componetprops
        }
      }
      auth.setAuth({
        ...auth.user,
        AllScreenList: auth.user.AllScreenList,
        isEdited: true,
        undoRedo: false,
      });
      return val
    }
  };
  const findArr = (arr, key, listKey, nextLoop) => {
    arr.map((val, i) => {
      if (val?.[listKey] === key) {
        arr[i] = SelectedFun(val, getType);

        return false;
      }
      if (nextLoop && val?.[nextLoop]) {
        findArr(val[nextLoop], key, listKey, nextLoop);
      }
      return false;
    });
    return arr;
  };

  const attributeVariables = (crntRef, crntValue, currentAttributeRef) => {
    if (crntValue && crntRef.oldVal === crntValue) {
      crntValue = '';
    }
    let variable = `${currentAttributeRef.title}${currentAttributeRef.type === "array" ? '?.[0]' : ''}`;
    if (crntValue) return `${crntValue}?.${variable}`
    else return `data?.${variable}`
  }

  const removeAttibuteVaribale = (val) => {
    delete val.properties[val?.valuePassingKey];
    return val;
  }

  const setAttibuteVaribale = (val, value) => {
    val.properties[val?.valuePassingKey] = value;
    return val
  }

  const getAttibuteVaribale = (val) => {
    return val.properties[val?.valuePassingKey];
  }

  const duplicateAttibuteVaribale = (val) => {
    val.oldVal = val.properties[val?.valuePassingKey];
    return val;
  }

  const [state, setState] = React.useState({
    checkedB: true,
  });
  const [isLoop, setIsLoop] = React.useState(false);
  const [propskey, setPropskey] = React.useState({})
  const [isloopComponent, setisloopComponent] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [isDataSource, setIsDataSource] = React.useState(false);
  const [variableType, setVariableType] = React.useState(null);
  const [Logics, setLogics] = React.useState([]);
  const [flitterval1, setflitterval1] = React.useState([]);
  const [flitterval2, setflitterval2] = React.useState([]);
  const [flitterval3, setflitterval3] = React.useState([]);
  const [EntitiesListcol, setEntitiesListcol] = React.useState([]);
  const [tableTitle, settableTitle] = React.useState("");
  const [columnTitle, setcolumnTitle] = React.useState("");

  React.useEffect(() => {
    const selectedComponent = auth.user.componentAttr;
    setDataSourceData(selectedComponent?.dataSources ?? {});

    ////is loop live update
    if (selectedComponent?.array) setIsLoop(selectedComponent?.array);
    setisloopComponent(selectedComponent?.isloopComponent ? true : false);
    setIsDelete(selectedComponent?.isDelete ? true : false);
    if (selectedComponent?.propskey) setPropskey({ ...selectedComponent?.propskey });
    else setPropskey({});

    /// abstracting data source
    setIsDataSource(selectedComponent?.isDataSource);

    if (selectedComponent?.isVarType)
      setVariableType(selectedComponent?.isVarType);
    else setVariableType(null);

    if (selectedComponent?.mappedEntity) {
      ////for setting selected entity on callback
      mappRequiredEntity(selectedComponent);
    } else settableTitle(null);

    ///for setting selected attribute on callback
    if (selectedComponent?.mappedAttribute) {
      setcolumnTitle(selectedComponent?.mappedAttribute);
    } else setcolumnTitle(null);

    if (selectedComponent?.mappedIndices) {
      mappRequiredIndices(auth.user.componentAttr);
    } else {
      setSubCollection([]);
      findChildParentIndices(
        auth.user.AllScreenList[props.index]?.render,
        auth.user.componentId
      );
    }

    ///duplicating old value into val.key
    duplicateAttibuteVaribale(selectedComponent)
  }, [auth.user.componentId]);

  React.useEffect(() => {
    let activeScreen = auth.user.AllScreenList[props.index];
    let listernerEvents = [], historyData = [];
    activeScreen?.events &&
      activeScreen.events.filter((item) => {
        if (item.component === "Dropdown") {
          listernerEvents.push(
            { fromId: item.fromId, CName: item.CName, eventType: item.name },
            {
              fromId: item.fromId,
              CName: `${item.CName}.label`,
              defaultKey: "label",
              eventType: item.name
            },
            {
              fromId: item.fromId,
              CName: `${item.CName}.value`,
              defaultKey: "value",
              eventType: item.name
            }
          );
        } else if (item.name === "onChange" || item.name === "onClick" || item.name === "stateCreation") {
          listernerEvents.push({ fromId: item.fromId, CName: item.CName, eventType: item.name });
        }
      });

    let histories = activeScreen?.history;
    histories && Object.keys(histories).map((screenName) => {
      histories[screenName].map((item) => {
        historyData.push({
          fromId: `history`,
          CName: `${screenName.replace(" ", "_")}_${item.name}`,
          newVariable: item?.newVariable ?? null
        });
      });
    });
    // // set all history data's


    let stateData = []
    let qpasQueries = auth.user.qpasQueries;
    qpasQueries.forEach((item) => {
      let payload = [];
      try {
        payload = item?.responseschema;
        stateData.push({
          id: item?._id,
          label: item.apiname,
          payload: nestedHandler(payload?.items ? payload.items : payload),
        });
      } catch (e) {
        return null;
      }
    });

    setDataSourceOptions({
      ...dataSourceOptions,
      listernerEvents: listernerEvents,
      historyData: historyData,
      stateData: stateData
    })
  }, [auth.user.componentId]);

  const mappRequiredIndices = (selectedComponent) => {
    var subCollections = [];
    var selectedValue = EntitiesList.filter(
      (item) => item.entity === selectedComponent.mappedEntity
    )[0];
    let indices = selectedComponent?.mappedIndices
      ?.split(".")
      .map((currentKey) => {
        if (currentKey) {
          let currentColumn = selectedValue?.column?.filter(
            (currentColumn, i) =>
              i === parseInt(currentKey) || currentColumn.name === currentKey
          );
          if (currentColumn?.length) {
            currentColumn = currentColumn[0];
            var pushArray = [];
            let a = currentColumn?.column?.map((val, i) => {
              var datajson = {
                id: i,
                title: val.name,
                name: val.name,
                type: val.type,
                column: val.column,
              };
              pushArray.push(datajson);
            });
            setEntitiesListcol(pushArray);
            subCollections.push({
              name: currentColumn.name,
              type: currentColumn.type,
              disabled: indicesRef?.current ? true : false,
            });
            setSubCollection(subCollections);
            selectedValue = currentColumn;
          }
        }
      });
    if (!selectedComponent?.mappedIndices) {
      setEntitiesListcol(selectedValue);
      setSubCollection([]);
    }
    setFinalAttribute(true);
  };
  const mappRequiredEntity = (selectedComponent) => {
    var selectedValue = EntitiesList.filter(
      (item) => item.entity === selectedComponent.mappedEntity
    )[0];
    var pusharray = [];
    let arry = selectedValue?.column?.map((val, i) => {
      var datajson = {
        id: i,
        title: val.name,
      };
      pusharray.push(datajson);
    });
    setEntitiesListcol(pusharray);
    settableTitle(selectedComponent?.mappedEntity);
  };
  const findChildParentIndices = (data, componentId) => {
    if (data.mappedIndices) {
      indicesRef.current = {
        mappedIndices: data.mappedIndices,
        mappedEntity: data.mappedEntity,
        isParent: true
      };
    }
    if (data?.id === componentId) {
      if (indicesRef.current) {
        mappRequiredEntity(indicesRef.current);
        mappRequiredIndices(indicesRef.current);
      }
      return;
    } else if (data?.child) {
      data.child.map((item) => {
        findChildParentIndices(item, componentId);
      });
    }
  };
  const handleSubCollection = (index) => {
    if (currentIndexArray) {
      var data = currentIndexArray?.split(".");
      data.splice(index);
      currentIndexArray = data.join(".");
      if (indicesRef?.current) {
        indicesRef.current.mappedIndices = currentIndexArray;
        mappRequiredIndices(indicesRef.current);
      } else {
        let selectedComponent = auth.user.componentAttr;
        selectedComponent.mappedIndices = currentIndexArray;
        mappRequiredIndices(selectedComponent);
      }
    }
    // if(subCollections[index]) subCollections.splice(index,1);
    // setSubCollection(subCollections);
  };
  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const onChangefun = (e, value, type) => {
    if (type === "table") {
      // currentIndexArray = `${value.type === 'array' ? "0" : value.name}.`
      if (value?.column) {
        ///if any non sub collections show attributes
        if (value.column.indexOf((item) => !item.column))
          setFinalAttribute(true);
      }
      settableTitle(value?.entity);
      tableTitle1 = value?.entity;
      var pusharray = [];
      if (value) {
        // eslint-disable-next-line
        let arry = value?.column?.map((val, i) => {
          var datajson = {
            id: i,
            title: val.name,
            name: val.name,
            type: val.type,
            column: val.column,
          };
          pusharray.push(datajson);
        });
        setEntitiesListcol(pusharray);
        getType = type;
        getValue = value;
        findArr(
          getentitiesval[0].render.child,
          auth.user.componentId,
          "id",
          "child"
        );
      } else {
        getType = type;
        getValue = value;
        findArr(
          getentitiesval[0].render.child,
          auth.user.componentId,
          "id",
          "child"
        );
      }
    } else if (type === "checkbox") {
      if (tableTitle && tableTitle != "") {
        getType = type;
        getValue = value;
        setIsLoop(value);
        findArr(
          getentitiesval[0].render.child,
          auth.user.componentId,
          "id",
          "child"
        );
      }
      if (isLoop) {
        getType = type;
        getValue = value;
        setIsLoop(value);
        findArr(
          getentitiesval[0].render.child,
          auth.user.componentId,
          "id",
          "child"
        );
      }
    } else if (type === "isloopComponent") {
      getType = type;
      getValue = value;
      setisloopComponent(value);
      findArr(
        getentitiesval[0].render.child,
        auth.user.componentId,
        "id",
        "child"
      );
    } else if (type === "isDelete") {
      getType = type;
      getValue = value;
      setIsDelete(value);
      findArr(
        getentitiesval[0].render.child,
        auth.user.componentId,
        "id",
        "child"
      );
    } else if (type === "setpropskey") {
      getType = type;
      getValue = value;
      setPropskey({ value: value })
      findArr(
        getentitiesval[0].render.child,
        auth.user.componentId,
        "id",
        "child"
      );
    } else if (type === "datasource") {
      getType = type;
      getValue = value;
      findArr(
        getentitiesval[0].render.child,
        auth.user.componentId,
        "id",
        "child"
      );
    }
    else if (type === "column") {
      ////if attribute is not a directy column re rendering attribute to closest
      if (
        (value && value?.column && value?.type === "array" && auth.user.componentAttr.component !== "ComponentRender") ||
        (value?.type === "object" && auth.user.componentAttr.component !== "Autocomplete")
      ) {
        currentIndexArray += `${value.type === "array" ? value.name : value.name
          }.`;
        var pusharray = [];
        let a = value?.column?.map((val, i) => {
          var datajson = {
            id: i,
            title: val.name,
            name: val.name,
            type: val.type,
            column: val.column,
          };
          pusharray.push(datajson);
        });
        setEntitiesListcol(pusharray);
        setcolumnTitle(value);
        columnTitle1 = null;
        subCollections.push({
          name: value.name,
          type: value.type,
        });
        setSubCollection(subCollections);

        getType = type;
        getValue = value;
        findArr(
          getentitiesval[0].render.child,
          auth.user.componentId,
          "id",
          "child"
        );
      } else {
        currentIndexArray = null;
        getType = type;
        getValue = value;
        setcolumnTitle(value?.title);
        columnTitle1 = value?.title;
        findArr(
          getentitiesval[0].render.child,
          auth.user.componentId,
          "id",
          "child"
        );
      }
    } else if (type === "isVarType") {
      getType = type;
      getValue = value;
      setVariableType(value);
      findArr(
        getentitiesval[0].render.child,
        auth.user.componentId,
        "id",
        "child"
      );
    }
  };

  const onChangefilter = (e, val, type) => {
    if (type === 1) {
      setflitterval1(val.props.value);
    } else if (type === 2) {
      setflitterval2(val.props.value);
    } else if (type === 3) {
      setflitterval3(e.target.value);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const addfliter = (e) => {
    var getfiltter = {
      id: btoa(Math.random()).substr(10, 5),
      flitter1: flitterval1,
      flitter2: flitterval2,
      flitter3: flitterval3,
    };
    Logics.push(getfiltter);
    setAnchorEl(null);
  };

  const deleteFlitter = (id) => {
    var filtered = Logics.filter(function (item) {
      return item.id !== id;
    });
    setLogics(filtered);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const classes = useStyles();
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);
  return (
    <div className={classes.body}>
      <div className={classes.title}>
        <Typography>is authorization request?</Typography>
        <FormControlLabel
          style={{ marginRight: 0 }}
          control={
            <IOSSwitch
              checked={state.checkedB}
              onChange={handleChange}
              name="checkedB"
            />
          }
        />
      </div>
      <Divider style={{ marginBottom: "8%", height: 2 }} />
      <HideShow
        AllScreenList={auth.user.AllScreenList}
        state={auth.user.componentAttr?.isShow}
        handleChange={handleHideShow}
        index={props.index}
        componentId={auth.user.componentId}
      />
      <SetProps
        state={auth.user.componentAttr?.componetprops}
        eventsList={dataSourceOptions?.listernerEvents}
        handleChange={setCustomProps}
      />
      <TextField
        type={"search"}
        id="outlined-helperText"
        label={"set props key"}
        style={{ marginTop: 15 }}
        size={"small"}
        value={propskey?.value}
        variant="outlined"
        fullWidth
        onChange={(e) => onChangefun(e, e.target.value, "setpropskey")}
      />
      <div className={classes.title}>
        <Typography variant={"h6"} component={"h5"}>
          Logics
        </Typography>
        <IconButton component="span" onClick={handleClick}>
          <AddBoxOutlinedIcon style={{ color: "#9c9c9c" }} />
        </IconButton>
      </div>
      {Logics?.map((val, index) => {
        return (
          <div className={classes.subText}>
            <div>
              <Typography variant={"body2"} component={"h5"}>
                show when{" "}
                <span style={{ color: "#007aff", cursor: "pointer" }}>
                  {val.flitter1}
                </span>{" "}
                {val.flitter2} to {val.flitter3}
              </Typography>
            </div>
            <div>
              <IconButton
                component="span"
                className={classes.btn}
                onClick={() => deleteFlitter(val.id)}
              >
                <DeleteOutlinedIcon
                  color={"secondary"}
                  style={{ fontSize: 19 }}
                />
              </IconButton>
              &nbsp;&nbsp;
              <IconButton component="span" className={classes.btn}>
                {/* <EditOutlinedIcon
                  color={"secondary"}
                  style={{ fontSize: 19 }}
                /> */}
              </IconButton>
            </div>
          </div>
        );
      })}
      <div className={classes.title}>
        <Typography variant={"h6"} component={"h5"}>
          Data
        </Typography>
        <div>
          {!isDataSource && <FormControlLabel
            control={
              <Checkbox
                checked={isLoop}
                onChange={(e, val) => onChangefun(e, val, "checkbox")}
                name="checkedA"
              />
            }
            label="Is Loop"
          />}
          {!tableTitle && <FormControlLabel
            control={
              <Checkbox
                checked={isDataSource}
                onChange={(e, val) => onChangefun(e, val, "datasource")}
                name="checkedA"
              />
            }
            label="Data Source"
          />}
          <div>
            {<FormControlLabel
              control={
                <Checkbox
                  checked={isloopComponent}
                  onChange={(e, val) => onChangefun(e, val, "isloopComponent")}
                  name="checkedA"
                />
              }
              label="Is External Loop"
            />}
          </div>
          {/* <div>
            {<FormControlLabel
              control={
                <Checkbox
                  checked={isDelete}
                  onChange={(e, val) => onChangefun(e, val, "isDelete")}
                  name="checkedA"
                />
              }
              label="Is Delete"
            />}
          </div> */}
        </div>
      </div>
      {!isLoop ? (
        // <Autocomplete
        //   size={"small"}
        //   value={variableType ?? null}
        //   style={{ paddingBottom: "6%", height: "6%" }}
        //   id="filter-demo"
        //   options={[
        //     { title: "detailed variable", value: "isDetailed" },
        //     { title: "child object", value: "isObject" },
        //   ]}
        //   onChange={(event, newValue) =>
        //     onChangefun(event, newValue, "isVarType")
        //   }
        //   getOptionLabel={(option) => option.title}
        //   renderInput={(params) => (
        //     <TextField {...params} label="variable type" variant="outlined" />
        //   )}
        // />
        <></>
      ) : (
        ""
      )}
      {
        !isDataSource ?
          <Autocomplete
            size={"small"}
            value={tableTitle ? { entity: tableTitle } : null}
            style={{ height: "6%" }}
            id="filter-demo"
            options={EntitiesList}
            filterSelectedOptions
            onChange={(event, newValue) => onChangefun(event, newValue, "table")}
            getOptionLabel={(option) => option.entity}
            filterOptions={filterOptions}
            renderInput={(params) => (
              <TextField {...params} label="Collection's" variant="outlined" />
            )}
          />
          : <DataSource
            label={"selected key"}
            keyName={dataSourceData?.name}
            isKeyEditable={true}
            value={dataSourceData?.value}
            index={0}
            onChange={(value, indices) =>
              handleDataSourceChange(value)
            }
            listernerEvents={dataSourceOptions?.listernerEvents ?? []}
            qpasQueriesResponse={{
              screenLevel: dataSourceOptions?.stateData,
              eventLevel: []
            }}
            dataSources={commonDataSources}
            isEndDecoration={false}
            selfEvents={[]}
            historyData={dataSourceOptions?.historyData ?? []}
            sessionList={auth.user?.storageManagement?.sessionStorage ?? []}
            localList={auth.user?.storageManagement?.localStorage ?? []}
            cookiesList={auth.user?.storageManagement?.cookies ?? []}
          />
      }
      {!isDataSource && subCollections.map((item, index) => (
        <TextField
          type={"search"}
          id="outlined-helperText"
          label={"collections-expanded"}
          style={{ marginTop: 15 }}
          size={"small"}
          value={item?.name}
          variant="outlined"
          fullWidth
          onChange={() => {
            handleSubCollection(index);
          }}
          disabled={item.disabled}
        />
      ))}
      {!isDataSource && showFinalAttribute && (
        <Autocomplete
          size={"small"}
          value={columnTitle ? { title: columnTitle } : null}
          style={{ marginTop: 15, paddingBottom: "6%", height: "6%" }}
          id="filter-demo"
          options={EntitiesListcol}
          filterSelectedOptions
          onChange={(event, newValue) => onChangefun(event, newValue, "column")}
          getOptionLabel={(option) => option.title}
          renderOption={(option) => {
            let color =
              option?.type === "array"
                ? "red"
                : option?.type === "object"
                  ? "green"
                  : "orange";
            return (
              <span>
                {option.title}
                <span
                  style={{
                    marginLeft: 10,
                    color: color,
                    backgroundColor: "rgba(255, 255, 255, 0.36)",
                  }}
                >
                  {option.type}
                </span>
              </span>
            );
          }}
          filterOptions={filterOptions1}
          renderInput={(params) => (
            <TextField {...params} label="Attribute's" variant="outlined" />
          )}
        />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ padding: 8 }}>
          <div style={{ display: "flex" }}>
            <FormControl
              variant="outlined"
              className={classes.formControl}
              size={"small"}
            >
              <InputLabel id="label1">filter</InputLabel>
              <Select
                labelId="demo-1"
                id="demo-1"
                label="filter"
                onChange={(e, val, type) => onChangefilter(e, val, 1)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"in_active"}>in_active</MenuItem>
                <MenuItem value={"active"}>active</MenuItem>
              </Select>
            </FormControl>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <FormControl
              variant="outlined"
              className={classes.formControl}
              size={"small"}
            >
              <InputLabel id="label2">filter</InputLabel>
              <Select
                labelId="demo-2"
                id="demo-2"
                label="filter"
                onChange={(e, val, type) => onChangefilter(e, val, 2)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={"equals_to"}>equals_to</MenuItem>
                <MenuItem value={"not_equals"}>not_equals</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            label="value"
            style={{ marginTop: "6%" }}
            variant="outlined"
            size="small"
            onChange={(e, val, type) => onChangefilter(e, val, 3)}
          />
        </div>
        <Button
          size="small"
          className={classes.margin}
          onClick={(e) => addfliter(e)}
        >
          Add
        </Button>
      </Popover>
    </div>
  );
}
