import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Button, Grid } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CloseIcon from "@material-ui/icons/Close";
// import { AuthContext } from "../../../../contexts";
// import { PropertiesPanel, IdmPanel, StylesPanel } from "../../components";
import { alertProps } from "../../../utils";
import { createDocument, updateDocument } from "../../../apis";
import { AuthContext } from "../../../contexts";
import { AlertContext } from "../../../contexts";
import { PropertiesPanel } from "./propertyspanel";
import { StylesPanel } from "./stylesPanel";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    height:"100vh",
    borderLeft:"2px solid #ebebeb"
  },
  tapcss: {
    fontSize: 11,
    textTransform: "none",
    // borderTopLeftRadius: 6,
    // borderTopRightRadius: 6,
    minWidth: 50,
    backgroundColor: "#fff",
    "&.MuiTab-root": {
      textTransform: "capitalize",
      padding: 0,
      margin: 0,
    },
  },
  scroller: {
    backgroundSize: "20px 20px",
    backgroundColor: "#ebebeb6e",
    "& .MuiTabs-scroller": {
      overflowX: "auto !important",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "white",
    },
    "& .MuiTabs-indicator": {
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      height: 4,
    },
  },
  close: {
    position: "absolute",
    right: 0,
    zIndex: 9,
  },
  panelHead: {
    display: "flex",
    justifyContent: "space-between",
    backgroundSize: "20px 20px",
    backgroundColor: "#ebebeb6e",
    // backgroundImage: 'linear-gradient(to right, rgb(217, 226, 233) 1px, transparent 1px), linear-gradient(rgb(217, 226, 233) 1px, transparent 1px)',
    "& .text": {
      backgroundColor: "#fff",
      display: "inline-block",
      padding: 10,
      borderTopRightRadius: 6,
    },
  },
  closeIconMargin: {
    // margin: theme.spacing(1),
  },
  tabfont: {
    fontFamily: "Roboto Helvetica Arial sans-serif",
    fontWeight: 400,
    "& .MuiTab-root": {
      minWidth: "30px",
      minHeight: "35px",

    },
   
  },
}));

let currData = null;

export default function UiBuilderPanel(props) {
  const classes = useStyles();
  const auth = React.useContext(AuthContext);
  const [value, setValue] = React.useState(0);
  const alert = React.useContext(AlertContext);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };


  const saveForm = async () => {
    // this.onClose("right", false);
    this.props.auth
      .save()
      .then((response) => {
        console.log(response);
        this.props.alert.setSnack({
          ...this.props.alert,
          open: true,
          severity: alertProps.severity.success,
          msg: "UI saved successfully",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.center,
          tone: true,
        });
      })
      .catch((err) => {
        this.props.alert.setSnack({
          ...this.props.alert,
          open: true,
          severity: alertProps.severity.error,
          msg: "Network Error",
          vertical: alertProps.vertical.top,
          horizontal: alertProps.horizontal.center,
        });
      });
  };


  const handleSaveScreen = async () => {
    // if (auth.user?.screens?.[0]?.readMode === true) {
    //   return alert.setSnack({
    //     ...alert,
    //     open: true,
    //     severity: alertProps.severity.warning,
    //     msg: "The screen is in read mode",
    //     vertical: alertProps.vertical.top,
    //     horizontal: alertProps.horizontal.right,
    //     tone: true,
    //   });
    // }

    // console.log(auth.user, "user", selectedScreen, "selectedScreen");
    console.log("called this func", auth.user);
    let currentScreen = auth.user?.screens[0];
    let screenSchema = {
      ...currentScreen,
      version: 1,
    };

    let screenQuery = [
      {
        entity: "screens",
        body: screenSchema,
      },
    ];
    console.log(screenQuery, auth.user.AllScreenList[0].render.child);
    if (auth.user.screens[0].render.child.length === 0) {
      console.log("called for upsert");
      let upsert1 = await createDocument(screenQuery);
      await Promise.all([upsert1])
        .then(function (values) {
          //update the AllScreenList & AllScreenListRef
          let screen = values[0][0]?.properties?.doc;
          console.log(values, screen);

          let allScreenList = JSON.parse(
            JSON.stringify(auth.user?.AllScreenList)
          );
          // allScreenList.forEach((eachScreen, index, array) => {
          //   if (screen.id === eachScreen?.id) {
          //     array[index] = screen;
          //   }
          // });
          auth.setAuth({
            ...auth.user,
            AllScreenList: [...allScreenList],
            AllScreenListRef: [...allScreenList],
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.success,
            msg: "Screen updated successfully",
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      //read mode
      console.log("called for update");
      let screenSchema = {
        ...auth.user?.screens[0],
        readMode: true,
        isLockedBy: "",
      };
      let screenQuery = [
        {
          entity: "screens",
          body: screenSchema,
          filter: {
            id: screenSchema?.id,
            version: screenSchema?.version,
          },
        },
      ];
      await updateDocument(screenQuery)
        .then(() => {
          let allScreenList = JSON.parse(
            JSON.stringify(auth.user?.AllScreenList)
          );
          allScreenList.forEach((eachScreen, index, array) => {
            if (currentScreen.id === eachScreen?.id) {
              array[index] = screenSchema;
            }
          });
          auth.setAuth({
            ...auth.user,
            screens: allScreenList.filter((val) => val.id === currentScreen.id),
            AllScreenList: [...allScreenList],
            AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.success,
            msg: "Screen updated successfully",
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    localStorage.setItem("previewScreen", JSON.stringify(auth.user.screens));
  };

  return (
    <div className={classes.root}>
      {/* <IconButton
        color="primary"
        component="span"
        className={classes.close}
        onClick={() => props.onClose("right")}
      >
        <CloseIcon />
      </IconButton> */}
       {/* <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={
          {
            paddingTop:"5px",
            borderBottom:"1px solid #e7e7e7",
            height:"40px",
        }
        }
      >
      <Button
              disabled={auth.user.loading}
              variant={"outlined"}
              color={"primary"}
              size="small"
              style={{ fontSize:"10px", marginLeft:"5px",}}
              disableElevation
              onClick={handleSaveScreen}
              
            >
              Save Screen
            </Button>
            <Button
              disabled={auth.user.loading}
              onClick={(e) => props.build()}
              variant={"outlined"}
              color={"primary"}
              size={"small"}
              style={{ fontSize:"10px", marginRight:"5px",  }}
            >
              Build
            </Button>
      </Grid>  */}
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="simple tabs example"
        indicatorColor="primary"
        // className={`${classes.scroller} scroll-bar`}
        textColor="primary"
        centered
        TabIndicatorProps={{style: {background:'#808080',}}}
        className={classes.tabfont}
      >
        <Tab
          label={
            <h6
              style={{
                fontSize: "10px",
                textTransform: "capitalize !important",
                fontWeight:300,
                margin: "0",
                color:"#9f9f9f",
              }}
            >
              Properties
            </h6>
          }
          {...a11yProps(0)}
          style={{
            minWidth: 50,
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: "white",
          }}
          className={classes.tapcss}
        />
        <Tab
          label={
            <h6
              style={{
                fontSize: "10px",
                textTransform: "capitalize !important",
                fontWeight:300,
                margin: "0",
                color:"#9f9f9f",
              }}
            >
              Styles
            </h6>
          }
          {...a11yProps(1)}
          style={{
            minWidth: 50,
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: "white",
          }}
          className={classes.tapcss}
        />
        <Tab
          label={
            <h6
              style={{
                fontSize: "10px",
                textTransform: "capitalize !important",
                fontWeight:300,
                margin: "0",
                color:"#9f9f9f",
              }}
            >
              Idm Settings
            </h6>
          }
          {...a11yProps(2)}
          style={{
            minWidth: 50,
            paddingLeft: 5,
            paddingRight: 5,
            backgroundColor: "white",
          }}
          className={classes.tapcss}
        />
      </Tabs>
      <div style={{ overflow: "auto", height: "84vh" }}>
        <TabPanel value={value} index={0}>
          {console.log("jo")}
           {props.showproperties&&<PropertiesPanel onClose={props.onClose} />}
        </TabPanel>
        <TabPanel value={value} index={1}>
        {props.showproperties&&<StylesPanel onClose={props.onClose} />}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {/* <IdmPanel close={props.onClose} /> */}
        </TabPanel>
      </div>
    </div>
  );
}
