import React from "react";
import { Preview } from "./previewPage";

function Renderer({ onSwitchPreview }) {
  console.log(window.location.pathname);

  return (
    <div>
      <Preview previewScreen={true} onSwitchPreview={onSwitchPreview} />
    </div>
  );
}

export { Renderer };
