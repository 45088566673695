import React from 'react';
import {
    Collapse,
    Paper,
    ListItem,
    ListItemText,
    TextField,
    Button,
    Modal,
    makeStyles,
    Grid
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import HighlightOffIcon from "@material-ui/icons/HighlightOff";


let activeScreen = null;

const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        border: '2px solid #000',
        boxShadow: 24,
        height: 450
    },
    root: {
        height: '100%',
        overflow: 'scroll',
        padding: 25
    }
}));


export default function SetProps(props) {
    const [state, setState] = React.useState([])
    const [modal, setModal] = React.useState(false)
    const classes = useStyles()


    React.useEffect(() => {
        setState(props?.state ?? [])
    }, [props?.state])

    const addNewCondition = () => {
        let setData = state;
        setData.push({});
        setState([...setData])
    }

    const handleModalClose = () => {
        props.handleChange(state)
        setModal(false)
    }

    const deleteStateKey = (index) => {
        let setData = state;
        setData.splice(index, 1)
        setState([...state])
    }
    
    const handleListChange = (v, index, keyName) => {
        let setData = state;
        setData[index][keyName] = v;
        setState([...setData])
    }

    return <div>
        <Button variant="contained" color="primary" style={{ marginTop: 20 }} onClick={() => setModal(true)}>
            Component Props
        </Button>
        <Modal
            open={modal}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Paper className={classes.paper}>
                <div className={classes.root}>
                    {
                        state?.map((customProps, index) => {
                            return <div>
                                <Paper elevation={4} style={{ padding: 15, marginBottom: 10 }}>
                                    <div>
                                        <HighlightOffIcon
                                            onClick={() => deleteStateKey(index)}
                                            style={{ float: "right", cursor: "pointer", marginBottom: 10 }}
                                        />
                                    </div>
                                    <Autocomplete
                                        size={"small"}
                                        value={customProps?.type}
                                        id="filter-demo"
                                        options={["form", "custom", "event", "loopdata"]}
                                        onChange={(event, newValue) => handleListChange(newValue, index, "type")}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField {...params} label="select type" variant="outlined" />
                                        )}
                                    />
                                    {
                                        customProps?.type && <TextField
                                            id="outlined-helperText"
                                            label={"set propsname"}
                                            style={{ marginTop: 15 }}
                                            size={"small"}
                                            variant="outlined"
                                            value={customProps?.propsName}
                                            onChange={(event) => handleListChange(event?.target?.value, index, "propsName")}
                                            fullWidth
                                        />
                                    }
                                    {
                                        customProps?.type === "custom" && <TextField
                                            id="outlined-helperText"
                                            label={"set value"}
                                            style={{ marginTop: 15 }}
                                            size={"small"}
                                            variant="outlined"
                                            value={customProps?.value}
                                            onChange={(event) => handleListChange(event?.target?.value, index, "value")}
                                            fullWidth
                                        />
                                    }
                                    {
                                        (customProps?.type === "form" || customProps?.type === "event") && <Autocomplete
                                            size={"small"}
                                            style={{ marginTop: 15 }}
                                            value={customProps?.events}
                                            id="filter-demo"
                                            options={props?.eventsList ?? []}
                                            onChange={(event, newValue) => handleListChange(newValue, index, "events")}
                                            getOptionLabel={(option) => option?.CName + ' - ' + option?.eventType}
                                            renderInput={(params) => (
                                                <TextField {...params} label="select events" variant="outlined" />
                                            )}
                                        />
                                    }
                                </Paper>
                            </div>
                        })
                    }
                    <div style={{ textAlign: 'center' }}>
                        <Button variant="contained" color="primary" onClick={addNewCondition}>
                            ADD NEW
                        </Button>
                    </div>
                </div>
            </Paper>
        </Modal>
    </div>
}