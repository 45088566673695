import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import CloseIcon from "@material-ui/icons/Close";
import { Navigation } from "./Navigation";
import { DisplayLogic } from "./DisplayLogic";
import { AuthContext } from '../../../contexts'
import { Actions } from "./actionsLogic";
import ConditonRenderer from './conditonRenderer';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    marginTop: 10
  },
  tapcss: {
    minWidth: "fit-content",
    padding: "0px 10px",
    fontSize: 13,
    textTransform: "none",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  scroller: {
    "& .MuiTabs-scroller": {
      overflowX: "auto !important",
    },
    "& .MuiTab-textColorInherit.Mui-selected": {
      backgroundColor: "white",
    },
    "& .MuiTabs-indicator": {
      borderTopLeftRadius: "12px",
      borderTopRightRadius: "12px",
      height: 4,
    },
  },
  close: {
    position: "absolute",
    right: 0,
    zIndex: 9,
  },
}));
let currData = null;

export function UiFlowMapping(props) {
  const classes = useStyles();
  const auth = React.useContext(AuthContext)
  const [value, setValue] = React.useState(0);
  const [decisionPanel, setdecisionPanel] = React.useState(false)

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    sessionStorage.removeItem('arrayData')
    sessionStorage.setItem('arrayData', JSON.stringify(auth.user.AllScreenList))
  }, [auth.user.componentId])

  //find selected component key (added compkey to diff componentDecisionId Update and others) 
  const updateNewChildComponent = (object, child_id, screen_id, compKey) => {
    auth.user.AllScreenList.filter((screen) => {
      if (screen.id === screen_id) {
        updateChildJson(screen.render.child, child_id, object, compKey);
        return true;
      } else {
        return false;
      }
    });
  };

  // Looping to find child object - updated selected component key  
  const updateChildJson = (screen, child_id, object, compKey) => {
    if (screen) {
      screen.filter((child, index) => {
        if (child.id === child_id) {
          if (compKey) {
            if (child[compKey]) delete child[compKey]
            child[compKey] = object;
          }
          else child["componentDecisionId"] = object;
        } else {
          updateChildJson(child.child, child_id, object, compKey);
          return true;
        }
      });
      // return true
    } else {
      return false;
    }
  };
  ////close decision panel
  const closeDecisionPanel = () => {
    let Data = auth.user.AllScreenList;
    let Data1 = JSON.parse(sessionStorage.arrayData).splice(0);

    if (Data1[props.index + 1]?.type === "decisionHandler") { ////replace deccion handler if not saved
      Data[props.index + 1] = Data1[props.index + 1];
      var arrayLength = Data[props.index + 1].arrowLink.filter(item => item.id === auth.user.componentDecisionId);
      if (arrayLength.length === 1 || arrayLength == 0) {
        Data[props.index + 1] = Data1[props.index + 1];
        removeDecisionArray()
      } else {
        auth.setAuth({
          ...auth.user,
          AllScreenList: auth.user.AllScreenList,
        });
      }
    } else {
      if (Data[props.index + 1]?.type === "decisionHandler") {
        Data.splice(props.index + 1, 1);
      }
      removeDecisionArray()
    }
    setdecisionPanel(false)
  }

  const applyDecisionPanel = () => {
    let Data = auth.user.AllScreenList[props.index];
    const findEventIndex = Data.events.findIndex((item) => {
      if (item.fromId === auth.user.componentId) {
        item.decisions = auth.user.AllScreenList[props.index + 1].arrowLink.map((item) => {
          if (item.conditionType) {
            return { ...item.conditionType, entity: item.entity }
          }
        }).filter(item => item !== undefined);
        return true;
      }
    });

    let arrowLinks = auth.user.AllScreenList[props.index + 1].arrowLink;
    let conditions = arrowLinks.filter((item, index) => {
      if (item.id === auth.user.componentDecisionId) {
        item.parentIndex = index;
        return true;
      }
    });

    setComponentLinking(conditions);

    /// check decision needs to be remove
    if (conditions && conditions.length === 1) {
      let Data = auth.user.AllScreenList[props.index + 1];
      Data.arrowLink.splice(conditions[0].parentIndex, 1); ////remove base connector
      ////remove component decision id
      updateNewChildComponent(null, auth.user.componentId, auth.user.AllScreenList[props.index].id)

      ////if no arrowlink found --delete screen
      if (Data.arrowLink.length == 0) {
        auth.user.AllScreenList.splice(props.index + 1, 1);
      } else { /// delete only decision box
        let body = Data.render.child[0].child;
        var base_child_index = body.findIndex(item => item.id === auth.user.componentDecisionId);
        body.splice(base_child_index, 1);
      }
      removeDecisionArray()
    }
    auth.setAuth({
      ...auth.user,
      AllScreenList: auth.user.AllScreenList,
      // componentDecisionId: null,
      isEdited: true,
      undoRedo: true
    });
    sessionStorage.setItem('arrayData', JSON.stringify(auth.user.AllScreenList))
    setdecisionPanel(false)
  }

  /// remove resultant decision array in events on removing all decision linked to component
  const removeDecisionArray = () => {
    // var data = auth.user.AllScreenList[props.index];
    // var eventIndex = data?.events.findIndex(item => item.fromId === auth.user.componentId);
    // if (eventIndex) {
    //   delete data.events[eventIndex].decisions
    // auth.setAuth({
    //   ...auth.user,
    //   AllScreenList: auth.user.AllScreenList,
    //   componentDecisionId: null,
    //   isEdited: true,
    // });
    // }
  }

  const setComponentLinking = (conditions) => {
    let privateComponent = null;
    conditions.map(arrowLink => {
      let navigationValue = arrowLink?.conditionType?.navigationValue;
      if (!privateComponent) privateComponent = [];
      if (navigationValue?.type === "Component") {
        ///adding screen level private component START
        let activeScreen = auth.user.AllScreenList[props.index];
        let isPrivatecomponent = activeScreen?.privateComponent?.findIndex(
          privateComponent => privateComponent?.name === navigationValue?.label
        );
        if (isPrivatecomponent === -1) {
          activeScreen.privateComponent.push({
            name: navigationValue?.label
          })
        } else if (!activeScreen?.privateComponent) {
          activeScreen.privateComponent = [
            { name: navigationValue?.label }
          ]
        }
        ///adding screen level private component END
        privateComponent.push({
          "fromId": auth.user.componentId,
          "type": "Modal",
          "condtion": {
            "label": "equal",
            "value": "==="
          },
          "value": "true",
          "id": navigationValue?.value,
          "name": navigationValue?.label
        })
      }
    })
    updateNewChildComponent(privateComponent, auth.user.componentId, auth.user.AllScreenList[props.index].id, "privateComponent")
  }

  return (
    <div className={classes.root}>
      <IconButton
        color="primary"
        component="span"
        className={classes.close}
        onClick={
          () => {
            decisionPanel ?
              // setdecisionPanel(false)
              closeDecisionPanel()
              :
              props.onClose("right")
          }
        }
      >
        <CloseIcon />
      </IconButton>
      {
        !decisionPanel ? <>
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="simple tabs example"
            indicatorColor="primary"
            className={`${classes.scroller} scroll-bar`}
            textColor="primary"
          >
            <Tab label="Navigation" {...a11yProps(0)} className={classes.tapcss} />
            {/* <Tab
              label="Display Logics"
              {...a11yProps(2)}
              className={classes.tapcss}
            /> */}
            {/* <Tab label="Actions" {...a11yProps(3)} className={classes.tapcss} /> */}
          </Tabs>
          <TabPanel value={value} index={0}>
            <Navigation {...props} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {/* <DisplayLogic {...props} /> */}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {/* <Actions
              {...props}
              setdecisionPanel={setdecisionPanel}
              // componentDecisionId={auth.user.componentDecisionId}
              updateNewChildComponent={updateNewChildComponent}
              applyDecisionPanel={applyDecisionPanel}
            /> */}
          </TabPanel>
        </>
          :
          // <ConditonRenderer
          //   {...props}
          //   // componentDecisionId={auth.user.componentDecisionId}
          //   updateNewChildComponent={updateNewChildComponent}
          //   applyDecisionPanel={applyDecisionPanel}
          // />
          <></>
      }
    </div>
  );
}
