import React, { useState, useEffect } from "react";
import { ScreenLayout } from "./screenLayout";
import { AuthContext, AlertContext } from "../../../contexts";
import { DynamicComponentsList, templates } from "./jsonData";
import { eleAtrribute } from "./jsonData";
import { alertProps } from "../../../utils";
import {
  getComponentMeta,
  getComponentCode,
  getPropertiesItems,
  layoutComponentsList,
  findItemNested,
  makeid,
  cloneComponent,
} from "../../../components/commonFunction";
import { createDocument, updateDocument } from "../../../apis";
import {
  makeStyles,
  Typography,
  TextField,
  AppBar,
  Toolbar,
  Switch,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import BottomCard from "../../../components/footerMenu";
// import Renderer from "./renderer";
import UndoRoundedIcon from "@material-ui/icons/UndoRounded";
import RedoRoundedIcon from "@material-ui/icons/RedoRounded";
import "flexmonster/flexmonster.css";

export function BuilderArea(props) {
  const auth = React.useContext(AuthContext);
  const [zoom, setSoom] = useState(0.6);
  const [layoutType, setLayoutType] = useState(1);
  const alert = React.useContext(AlertContext);
  const isHTMLBlock = (evt, screen_id) => {
    const htmlBlockTags = [
      "div",
      "ol",
      "a",
      "li",
      "aside",
      "footer",
      "section",
      "article",
    ];
    let type = evt.target?.closest(".fielddrag")?.getAttribute("data-name");
    //getting the target element id to check the component type is html
    let typeId = evt.target?.closest(".fielddrag")?.getAttribute("data-id");
    //getting the target element object
    let componentData;
    auth.user.AllScreenList?.forEach((screen) => {
      if (screen.id === screen_id) {
        screen.render.child.forEach(function iter(component, index, array) {
          if (component.id === typeId) {
            componentData = component;
          } else {
            if (component?.child?.length > 0) {
              component.child.forEach(iter);
            }
          }
        });
      }
    });
    localStorage.setItem("previewScreen", JSON.stringify(auth.user.screens));
    const isBlock = htmlBlockTags.includes(type);
    return {
      htmlComponent: componentData ?? {},
      isHtmlTagBlock: isBlock,
    };
  };
  const zoomInOut = (e, type) => {
    if (type === "zoomIn") {
      let n = zoom + 0.1;
      let number = Math.round(n * 10) / 10;
      if (number === 1.2) return false;
      setSoom(number);
    } else {
      let n = zoom - 0.1;
      let number = Math.round(n * 10) / 10;
      if (number === 0.1) return false;
      setSoom(number);
    }
  };
  const onDragEnter = (evt, screen_id) => {
    debugger;
    console.log("this is called inside the component itself", evt);
    if (
      auth.user?.screens[0]?.isLockedBy === "" ||
      auth.user?.user?.name === auth.user?.screens[0]?.isLockedBy
    ) {
      evt.preventDefault();
      let element = evt.currentTarget;

      console.log(element, evt.target, evt.dataTransfer.types[0]);
      if (
        evt.dataTransfer.types[0] === "movetocomponents" ||
        evt.dataTransfer.types[0] === "components" ||
        evt.dataTransfer.types[0] === "componentid" ||
        evt.dataTransfer.types[0] === "template" ||
        evt.dataTransfer.types[0] === "projecttemplate"
      ) {
        evt.dataTransfer.dropEffect = "move";
        // Add the focus backgroundColor class
        let pushTarget = evt.target.closest(".fielddrag")
          ? evt.target.closest(".fielddrag").getAttribute("data-id")
            ? evt.target.closest(".fielddrag").getAttribute("data-id")
            : "new_row"
          : "new_row";
        console.log(pushTarget);
        if (pushTarget === "new_row") {
          element.classList.add("dragged-over-builder");
        } else {
          console.log(
            pushTarget,
            evt.target.closest(".fielddrag").getAttribute("data-name")
          );
          let type = evt.target.closest(".fielddrag").getAttribute("data-name");
          const isHTMLBlockData = isHTMLBlock(evt, screen_id);
          const componentList = auth?.user?.UIcomponents;
          const closetComponentName = evt.target
            .closest(".fielddrag")
            .getAttribute("data-name");
          let materialBlockComp = componentList.filter(
            (comp) => comp.componentName === closetComponentName
          )[0]?.isLayout;

          if (
            layoutComponentsList(type) ||
            isHTMLBlockData.isHtmlTagBlock ||
            materialBlockComp
          ) {
            let result = checkDropIsNotItsChild(
              localStorage.drag_item_id,
              evt.target.closest(".fielddrag").getAttribute("data-id")
            );
            console.log(result);
            if (!result) {
              evt.target
                .closest(".fielddrag")
                .classList.add("mystyle", "fielddragCss");
            }
            // return false;
          }
        }
      }
    }
    //   else  {
    //     alert.setSnack({
    //      ...alert,
    //      open: true,
    //      severity: alertProps.severity.warning,
    //      msg: `${auth.user?.screens[0]?.isLockedBy} is currently editing the screen`,
    //      vertical: alertProps.vertical.top,
    //      horizontal: alertProps.horizontal.right,
    //      tone: true,
    //    });
    // }
  };
  const updateNewChildComponent = (object, child_id, screen_id) => {
    auth.user.AllScreenList.filter((screen) => {
      if (screen.id === screen_id) {
        updateChildJson(screen.render.child, child_id, object);
        return true;
      } else {
        return false;
      }
    });
    // this.setState({ ...this.state, isEdited: true });
    auth.setAuth({
      ...auth.user,
      screenEdited: screen_id,
    });
    localStorage.setItem("previewScreen", JSON.stringify(auth.user.screens));
  };

  const updateChildJson = (screen, child_id, object) => {
    if (screen) {
      screen.filter((child, index) => {
        if (child.id === child_id) {
          if (child.child) {
            child.child.push(object);
            if (auth.user.componentAttr.id === child_id) {
            }
          } else {
            child["child"] = [object];
          }

          return true;
        } else {
          updateChildJson(child.child, child_id, object);
          return true;
        }
      });
      // return true
    } else {
      return false;
    }
    localStorage.setItem("previewScreen", JSON.stringify(screen));
    sessionStorage.setItem("previewScreen", JSON.stringify(screen));
  };
  //   Add New Component function
  const addNewComponent = (object, screen_id) => {
    auth.user.AllScreenList.filter((screen) => {
      if (screen.id === screen_id) {
        return screen.render.child.push(object);
      } else {
        return false;
      }
    });
    auth.setAuth({
      ...auth.user,
      AllScreenList: auth.user.AllScreenList,
      screenEdited: screen_id,
    });
    // this.setState({ ...this.state, isEdited: true });
    localStorage.setItem("previewScreen", JSON.stringify(auth.user.screens));
    sessionStorage.setItem("previewScreen", JSON.stringify(auth.user.screens));
  };
  const deletearr = (arr, key) => {
    const listKey = "id",
      nextLoop = "child";
    arr.map((val, i) => {
      if (val[listKey] === key) {
        arr.splice(i, 1);
      }
      if (nextLoop && val[nextLoop]) {
        deletearr(val[nextLoop], key, listKey, nextLoop);
      }
      return false;
    });
    return arr;
  };
  const checkDropIsNotItsChild = (dragId, dropId) => {
    let dragComponent;

    auth.user.AllScreenList.forEach((screen) => {
      screen.render.child.forEach(function iter(comp, index, array) {
        if (comp.id === dragId) {
          dragComponent = comp;
        } else {
          if (comp?.child?.length > 0) {
            comp.child.forEach(iter);
          }
        }
      });
    });

    if (!dragComponent?.child) {
      return false;
    }
    if (dragId === dropId) {
      return true;
    }

    let dropComponent;

    dragComponent.child.forEach(function iter(comp, index, array) {
      if (comp.id === dropId) {
        dropComponent = comp;
      } else {
        if (comp?.child?.length > 0) {
          comp.child.forEach(iter);
        }
      }
    });
    localStorage.setItem("previewScreen", JSON.stringify(auth.user.screens));
    if (dropComponent) {
      return true;
    } else {
      return false;
    }
  };
  // Move or inter change the component JSON  function_2
  const moveChildJson = (screen, child_id, move_item_id, object, screenAll) => {
    if (screen) {
      screen.filter((child, index) => {
        if (child.id === child_id) {
          if (child.child) {
            deletearr(screenAll, move_item_id);
            object.id = move_item_id;
            child.child.push(object);
          } else {
            deletearr(screenAll, move_item_id);
            object.id = move_item_id;
            child["child"] = [object];
          }
          return true;
        } else {
          moveChildJson(child.child, child_id, move_item_id, object, screenAll);
          return true;
        }
      });
      // return true
    } else {
      return false;
    }
    localStorage.setItem("previewScreen", JSON.stringify(auth.user.screens));
  };
  const onDrop = (evt, screen_id, componentName) => {
    if (
      auth.user?.screens[0]?.isLockedBy === "" ||
      auth.user?.user?.name === auth.user?.screens[0]?.isLockedBy
    ) {
      console.log(screen_id);

      let drag_item_id = evt.dataTransfer.getData("componentId");
      let move_item_id = evt.dataTransfer.getData("moveToComponents");

      console.log(move_item_id, "move_item_id");
      let template_id = evt.dataTransfer.getData("template");
      let project_template_id = evt.dataTransfer.getData("projectTemplate");

      let pushTarget = evt.target.closest(".fielddrag")
        ? evt.target.closest(".fielddrag").getAttribute("data-id")
          ? evt.target.closest(".fielddrag").getAttribute("data-id")
          : "new_row"
        : "new_row";

      let componentObject;

      if (drag_item_id) {
        let componentConfigData = auth?.user?.UIcomponents.filter(
          (item) => item.componentId === drag_item_id
        )[0];

        let componentId = makeid();
        componentObject = {
          type: "component",
          component: componentConfigData.componentName,
          component_type: DynamicComponentsList.some(
            (component) => component.name === drag_item_id
          )
            ? "dynamic"
            : "qdm",
          isLayout: componentConfigData?.isLayout ?? false,
          name: componentConfigData.componentName,
          id: componentId,
          attr: null,
          componentId: componentConfigData.componentId,
          frameWork: componentConfigData.frameWork,
          properties:
            { id: componentId, ...componentConfigData?.defaultProps } ?? {},
        };
      }
      // add new template
      const isHTMLBlockData = isHTMLBlock(evt, screen_id);

      //handling project template dropping
      if (evt.dataTransfer.types[0] === "projecttemplate") {
        let template_name = project_template_id;
        let componentData;
        auth.user.projectTemplates.filter((templateGroup) => {
          return templateGroup.templates.some((template) => {
            if (template.name === template_name) {
              componentData = template;
              return true;
            }
          });
        });
        const clonedComponent = cloneComponent(componentData.json);
        let getJson = {
          ...componentData,
          json: clonedComponent,
        };
        if (pushTarget === "new_row") {
          addNewComponent(getJson.json, screen_id);
          evt.currentTarget.classList.remove("dragged-over-builder");
          evt.currentTarget.classList.remove("mystyle");
          evt.preventDefault();
          return false;
        } else {
          evt.target.closest(".fielddrag").classList.remove("mystyle");
          let type = evt.target.closest(".fielddrag").getAttribute("data-name");
          const componentList = auth?.user?.UIcomponents;
          let materialBlockComp = componentList.filter(
            (comp) => comp.componentName === type
          )[0]?.isLayout;
          if (
            (layoutComponentsList(type) ||
              materialBlockComp ||
              isHTMLBlockData.isHtmlTagBlock) &&
            getJson.type === "component"
          ) {
            updateNewChildComponent(getJson.json, pushTarget, screen_id);
          } else {
            alert("Template drop new screen layout only");
          }
          evt.target.closest(".fielddrag").classList.remove("mystyle");
          // return false;
        }
        evt.currentTarget.classList.remove("dragged-over");
        evt.preventDefault();
      }
      const moveChildComponent = (move_item_id, pushTarget, screen_id) => {
        // Main parent child update function
        if (pushTarget === "new_row") {
          auth.user.AllScreenList.filter((screen) => {
            if (screen.id === screen_id) {
              let object = findItemNested(
                screen.render.child,
                move_item_id,
                "child"
              );
              deletearr(screen.render.child, move_item_id);
              addNewComponent(object, screen_id);
              return true;
            } else {
              return false;
            }
          });
          return false;
        }

        //   Nested child update function
        if (move_item_id !== pushTarget) {
          auth.user.AllScreenList.filter((screen) => {
            if (screen.id === screen_id) {
              let object = findItemNested(
                screen.render.child,
                move_item_id,
                "child"
              );
              moveChildJson(
                screen.render.child,
                pushTarget,
                move_item_id,
                object,
                screen.render.child
              );
              return true;
            } else {
              return false;
            }
          });
          // this.setState({ ...this.state, isEdited: true});
          auth.setAuth({
            ...auth.user,
            screenEdited: screen_id,
          });
        }
        localStorage.setItem(
          "previewScreen",
          JSON.stringify(auth.user.screens)
        );
      };
      // move to component
      if (evt.dataTransfer.types[0] === "movetocomponents") {
        debugger;
        console.log(move_item_id);
        if (move_item_id && move_item_id?.length > 0) {
          console.log(move_item_id, pushTarget, screen_id);
          let result = checkDropIsNotItsChild(move_item_id, pushTarget);
          if (result) return false;
          moveChildComponent(move_item_id, pushTarget, screen_id);
          if (pushTarget !== "new_row") {
            evt.target.closest(".fielddrag").classList.remove("mystyle");
            evt.target.classList.remove("vivifySmall");
            evt.target.classList.remove("pulsate");
            document.getElementById(move_item_id).classList.remove("hide");
          } else {
            evt.currentTarget.classList.remove("dragged-over-builder");
            evt.currentTarget.classList.remove("mystyle");
          }
          evt.preventDefault();
          evt.currentTarget.classList.remove("dragged-over");
          return false;
        }
      }

      // if(!drag_item_id || drag_item_id?.length <= 0){
      //   return false
      // }

      if (
        evt.dataTransfer.types[0] === "components" ||
        evt.dataTransfer.types[0] === "componentid"
      ) {
        if (pushTarget === "new_row") {
          addNewComponent(componentObject, screen_id);
        } else {
          let type = evt.target.closest(".fielddrag").getAttribute("data-name");
          const componentList = auth?.user?.UIcomponents;
          let materialBlockComp = componentList.filter(
            (comp) => comp.componentName === type
          )[0]?.isLayout;

          if (
            layoutComponentsList(type) ||
            materialBlockComp ||
            isHTMLBlockData.isHtmlTagBlock
          ) {
            console.log("isLayout dude");
            updateNewChildComponent(componentObject, pushTarget, screen_id);
            evt.target.closest(".fielddrag").classList.remove("mystyle");
          }
          //checking the target element (html tag) is a block element to place the children
          // else if (
          //   isHTMLBlockData.isHtmlTagBlock &&
          //   isHTMLBlockData.htmlComponent?.component_type === "html"
          // ) {
          //   updateNewChildComponent(componentObject, pushTarget, screen_id);
          //   // Remove the focus backgroundColor class
          //   if (pushTarget === "new_row") {
          //     // evt.target.closest(".fielddrag").classList.add("mystyle")
          //   } else {
          //     evt.target.closest(".fielddrag").classList.remove("mystyle");
          //   }
          // }
          else {
            alert("Not able to drop this component to child ");
            return false;
          }
        }
      }
      console.log(auth);
      evt.currentTarget.classList.remove("dragged-over-builder");
      evt.currentTarget.classList.remove("mystyle");
      evt.preventDefault();
    } else {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
  };
  const onDragLeave = (evt) => {
    /**
     * this is triggered when we drag the component is dragged over the screen and not dropped this
     */
    evt.preventDefault();
    let element = evt.currentTarget;
    let pushTarget = evt.target.closest(".fielddrag")
      ? evt.target.closest(".fielddrag").getAttribute("data-id")
        ? evt.target.closest(".fielddrag").getAttribute("data-id")
        : "new_row"
      : "new_row";

    // Remove the focus backgroundColor class
    if (pushTarget === "new_row") {
      // evt.target.closest(".fielddrag").classList.add("mystyle")
      element.classList.remove("dragged-over-builder");
    } else {
      evt.target.closest(".fielddrag").classList.remove("mystyle");
    }
  };
  const onDragEnd = (evt) => {
    evt.currentTarget.classList.remove("dragged-over-builder");
    if (document.getElementById(evt?.target?.id)?.classList) {
      document.getElementById(evt.target.id).classList.remove("hide");
    }
  };
  const onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "move";
    evt.dataTransfer.effectAllowed = "copyMove";
  };
  const getComponentId = async (index, component, screenId) => {
    let data2 = getPropertiesItems(component, screenId, eleAtrribute);
    // let codePanel = auth.user?.codePanel;
    // if (auth?.user?.codePanel?.status) {
    //   auth.setAuth({
    //     ...auth.user,
    //     codePanel: {
    //       ...auth.user.codePanel,
    //       loading: true,
    //     },
    //   });
    //   let data = await getComponentCode(
    //     component.id,
    //     screenId,
    //     getComponentMeta(auth, screenId, component.id)
    //   );
    //   codePanel = {
    //     ...codePanel,
    //     metaData: data?.metaData,
    //     sourceCode: data?.sourceCode,
    //     loading: false,
    //   };
    // }
    auth.setAuth({
      ...auth.user,
      componentAttr: { ...data2 },
      selectedScreen: screenId,
      // codePanel: codePanel,
    });
    localStorage.setItem("previewScreen", JSON.stringify(auth.user.screens));
    props.onClose("right", true);
  };
  // const addNewComponent = (object, screen_id) => {
  //   auth.user.AllScreenList.filter((screen) => {
  //     if (screen.id === screen_id) {
  //       return screen.render.child.push(object);
  //     } else {
  //       return false;
  //     }
  //   });
  //   auth.setAuth({
  //     ...auth.user,
  //     AllScreenList: auth.user.AllScreenList,
  //     screenEdited: screen_id,
  //   });
  //   // this.setState({ ...this.state, isEdited: true });
  // };
  const handleSaveScreen = async () => {
    debugger;
    if (
      auth.user?.screens[0]?.isLockedBy === "" ||
      auth.user?.user?.name === auth.user?.screens[0]?.isLockedBy
    ) {
      // console.log(auth.user, "user", selectedScreen, "selectedScreen");
      console.log("called this func", auth.user);
      let currentScreen = auth.user?.screens[0];

      //read mode
      console.log("called for update");
      let screenSchema = {
        ...auth.user?.screens[0],
        readMode: true,
        isLockedBy: auth.user?.screens[0]?.isLockedBy,
      };
      let screenQuery = [
        {
          entity: "screens",
          body: screenSchema,
          filter: {
            id: screenSchema?.id,
            version: screenSchema?.version,
          },
        },
      ];
      await updateDocument(screenQuery)
        .then(() => {
          let allScreenList = JSON.parse(
            JSON.stringify(auth.user?.AllScreenList)
          );
          allScreenList.forEach((eachScreen, index, array) => {
            if (currentScreen.id === eachScreen?.id) {
              array[index] = screenSchema;
            }
          });
          auth.setAuth({
            ...auth.user,
            screens: allScreenList.filter((val) => val.id === currentScreen.id),
            AllScreenList: [...allScreenList],
            AllScreenListRef: JSON.parse(JSON.stringify(allScreenList)),
          });
          alert.setSnack({
            ...alert,
            open: true,
            severity: alertProps.severity.success,
            msg: "Screen updated successfully",
            vertical: alertProps.vertical.top,
            horizontal: alertProps.horizontal.right,
            tone: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });

      localStorage.setItem("previewScreen", JSON.stringify(auth.user.screens));
    } else {
      return alert.setSnack({
        ...alert,
        open: true,
        severity: alertProps.severity.warning,
        msg: `${auth.user?.screens[0]?.isLockedBy} currently editing this screen`,
        vertical: alertProps.vertical.top,
        horizontal: alertProps.horizontal.right,
        tone: true,
      });
    }
  };
  const changeLayout = (type) => {
    setLayoutType(type);
  };
  const undoRedo = (type) => {
    debugger;

    let undoData = null;
    let currentScreen = null;
    if (type === "redo") {
      undoData = JSON.parse(
        JSON.stringify(auth.user.screensHistory[auth.user.selectedScreen])
      );
      console.log("redo", undoData);
      currentScreen =
        undoData[auth.user.screensActiveIndex[auth.user.selectedScreen] + 1];
    } else {
      undoData = JSON.parse(
        JSON.stringify(auth.user.screensHistory[auth.user.selectedScreen])
      );
      currentScreen =
        undoData[auth.user.screensActiveIndex[auth.user.selectedScreen] - 1];
      console.log(currentScreen);
    }
    // let data2 = null;
    // if (Object.keys(auth.user.componentAttr).length > 0) {
    //   data2 = getPropertiesItems(
    //     auth.user.componentAttr,
    //     auth.user.selectedScreen,
    //     eleAtrribute
    //   );
    // }

    let allScreenListCopy = JSON.parse(JSON.stringify(auth.user.AllScreenList));

    allScreenListCopy.forEach((screen, index, array) => {
      if (screen.id === auth.user.selectedScreen) {
        array[index] = {
          ...currentScreen,
          readMode: false,
          isLockedBy: auth.user?.user?.name,
        };
      }
    });

    auth.setAuth({
      ...auth.user,
      AllScreenList: allScreenListCopy,
      screensActiveIndex: {
        ...auth.user.screensActiveIndex,
        [auth.user.selectedScreen]:
          type === "undo"
            ? auth.user.screensActiveIndex[auth.user.selectedScreen] - 1
            : auth.user.screensActiveIndex[auth.user.selectedScreen] + 1,
      },
      screens: [
        {
          ...currentScreen,
          readMode: false,
          isLockedBy: auth.user?.user?.name,
        },
      ],
      // componentAttr: data2 ? data2 : {},
      selectedScreen: currentScreen.id,
    });
    props.onClose("right", false);
  };

  const handleKeyboardSave = (e) => {
    let key = e.keyCode;
    if (key === 83 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      console.log("Ctrl + S Pressed !");
      handleSaveScreen();
    } else if (key === 90 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      console.log("Ctrl + Z Pressed !");
      undoRedo("undo");
    } else if (key === 89 && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      console.log("Ctrl + Z Pressed !");
      undoRedo("redo");
    }
  };

  // useEffect(() => {
  //   if (auth?.user?.screens.length > 0) {
  //     setSelectedScreen(auth?.user?.screens[0]);
  //   }
  //   let screenReadMode = auth.user?.screens[0]?.readMode;
  //   if (screenReadMode === false) {
  //     if (auth.user?.user?.name === auth.user?.screens[0]?.isLockedBy) {
  //       setEditMode(true);
  //       setIsLockedBy("You");
  //     } else {
  //       setEditMode(false);
  //       setIsLockedBy(auth.user?.screens[0]?.isLockedBy);
  //     }
  //   } else {
  //     setEditMode(false);
  //     setIsLockedBy(null);
  //   }
  // }, [auth.user]);

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyboardSave);
    return () => {
      window.removeEventListener("keydown", handleKeyboardSave);
    };
  }, [auth]);

  return (
    <div
      style={{
        display: "block",
        // border: " 1px solid darkred",
        margin: "auto ",
        minHeight: "84.5vh",
        width: "99.5%",
        paddingTop: "13px",
        paddingLeft: "5px",
      }}
    >
      {console.log(props)}
      {/* {auth?.user?.screens?.length === 1 && (
          <AppBar
            position="static"
            color={"inherit"}
            elevation={0}
            // style={{
            //   position: "fixed",
            //   top: "50px",
            //   width: props.leftMenu
            //     ? "calc(100% - 355px)"
            //     : "calc(100% - 85px)",
            // }}
          >
            <Toolbar
              style={{
                display: "flex",
                justifyContent: "space-between",
                minHeight: "48px",
                borderBottom: "1px solid #ebebeb",
              }}
            >
              {auth?.user &&
                Object.keys(auth.user.screensHistory).length > 0 &&
                Object.keys(auth.user.screensActiveIndex).length > 0 && (
                  <div
                    style={{
                      display: "flex",

                      alignItems: "center",
                    }}
                  >
                    <>
                      <IconButton
                        onClick={() => undoRedo("undo")}
                        disabled={
                          auth.user.screensHistory[auth.user.selectedScreen]
                            ?.length > 1 &&
                          auth.user.screensActiveIndex[
                            auth.user.selectedScreen
                          ] !== 0
                            ? false
                            : true
                        }
                        disableFocusRipple={
                          auth.user.screensHistory[auth.user.selectedScreen]
                            ?.length > 1 &&
                          auth.user.screensActiveIndex[
                            auth.user.selectedScreen
                          ] !== 0
                            ? false
                            : true
                        }
                        disableRipple={
                          auth.user.screensHistory[auth.user.selectedScreen]
                            ?.length > 1 &&
                          auth.user.screensActiveIndex[
                            auth.user.selectedScreen
                          ] !== 0
                            ? false
                            : true
                        }
                        style={{ marginRight: "12px" }}
                        size="small"
                      >
                        <UndoRoundedIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => undoRedo("redo")}
                        disabled={
                          auth.user.screensActiveIndex[
                            auth.user.selectedScreen
                          ] ===
                          auth.user.screensHistory[auth.user.selectedScreen]
                            ?.length -
                            1
                            ? true
                            : false
                        }
                        disableFocusRipple={
                          auth.user.screensActiveIndex[
                            auth.user.selectedScreen
                          ] ===
                          auth.user.screensHistory[auth.user.selectedScreen]
                            ?.length -
                            1
                            ? true
                            : false
                        }
                        disableRipple={
                          auth.user.screensActiveIndex[
                            auth.user.selectedScreen
                          ] ===
                          auth.user.screensHistory[auth.user.selectedScreen]
                            ?.length -
                            1
                            ? true
                            : false
                        }
                        // className={classes.margin}
                        size="small"
                      >
                        <RedoRoundedIcon />
                      </IconButton>
                    </>
                    <Button
                      disabled={auth.user.loading}
                      onClick={handleSaveScreen}
                      variant={"contained"}
                      color={"primary"}
                      size={"small"}
                      style={{ marginLeft: "24px" }}
                      disableElevation
                    >
                      Save Screen
                    </Button>
                  </div>
                )}
            </Toolbar>
          </AppBar>
        )} */}
      {
        // Circular Progress
      }
      {/* {!auth.user.loading &&
          auth?.user?.screens?.length > 0 &&
          auth?.user?.screens.map((screen, index) => {
            return <ScreenLayout />;
          })} */}
      {/* <ScreenLayout
          deleteOldScreen={props.deleteOldScreen}
          selectedAllScreen={auth?.user?.screens?.length > 1 ? true : false}
          key={screen?.id}
          previewScreen={props.previewScreen}
          screen={sampleTest}
          // index={index}
          zoom={zoom}
          layoutType={layoutType}
          draggable={true}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
          onDrop={onDrop}
          rightMenu={props.rightMenu}
          getComponentId={getComponentId}
          onClose={props.onClose}
        /> */}
      {
        // Typography - Create New Screens
      }
      {console.log("val", auth.user.screens)}
      {/* <ScreenLayout
          deleteOldScreen={props.deleteOldScreen}
          selectedAllScreen={auth?.user?.screens?.length > 1 ? true : false}
          // key={screen?.id}
          previewScreen={props.previewScreen}
          screen={auth.user.screens}
          // index={index}
          zoom={zoom}
          layoutType={layoutType}
          draggable={auth?.user?.screens?.length > 1 ? true : false}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
          onDrop={onDrop}
          rightMenu={props.rightMenu}
          getComponentId={getComponentId}
          onClose={props.onClose}
        /> */}
      {!auth.user.loading &&
        auth?.user?.screens?.length > 0 &&
        auth?.user?.screens?.map((screen, index) => {
          return (
            <ScreenLayout
              deleteOldScreen={props.deleteOldScreen}
              selectedAllScreen={auth?.user?.screens?.length > 1 ? true : false}
              key={screen?.id}
              previewScreen={props.previewScreen}
              screen={screen}
              index={index}
              zoom={props.zoom}
              layoutType={layoutType}
              draggable={auth?.user?.screens?.length > 1 ? false : true}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDragEnd={onDragEnd}
              onDragOver={onDragOver}
              onDrop={onDrop}
              rightMenu={props.rightMenu}
              getComponentId={getComponentId}
              onClose={props.onClose}
            />
          );
        })}
      {/* {!auth.user.loading &&
          auth?.user?.screens &&
          auth?.user?.screens?.length === 0 && (
            <div className={classes.screenArea}>
              <div style={{ textAlign: "center", marginBottom: "10px" }}>
                <Typography>{"Create new screes..."}</Typography>
              </div>
            </div>
          )} */}
      {/* <BottomCard
          zoomInOut={zoomInOut}
          zoom={zoom}
          layoutType={layoutType}
          changeLayout={changeLayout}
          onSwitchPreview={props.onSwitchPreview}
          previewScreen={props.previewScreen}
        /> */}
      {console.log("send to render", auth.user.screens)}

      {/* {auth?.user?.codePanel?.status && <CodePanel />} */}
    </div>
  );
}
