import cloneDeep from "lodash/cloneDeep";
import snakeCase from "lodash/snakeCase";
import { DynamicComponentsDefaultproperties } from "../screens/uiBuilder/components/jsonData";
import { networkCall } from "../networkcall";
import Axios from "axios";
import config from "../config";
import { v4 as uuidv4 } from "uuid";
import { at } from "lodash";

export const makeid = () => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const makeidWithoutCaps = () => {
  var result = "";
  var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 5; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const array_move = (arr, old_index, new_index) => {
  let cutOut = arr.splice(old_index, 1)[0]; // cut the element at index 'from'
  arr.splice(new_index, 0, cutOut);
  return arr; // insert it at index 'to'
};

export const layoutComponentsList = (componentName) => {
  const list = [
    "Col",
    "Row",
    "Container",
    "Card",
    "List",
    "ListItem",
    "Paper",
    "ButtonGroup",
    "Tab",
    "Tabpanel",
    "Hidden",
    "TopHeader",
    "Carousel",
    "BackgroundImage",
    "Collapse",
    "Div",
    "Form",
  ];
  if (list.includes(componentName)) {
    return true;
  } else {
    return false;
  }
};

export const findItemNested = (arr, itemId, nestingKey) => {
  return (
    // eslint-disable-next-line
    arr.reduce((a, item) => {
      if (a) return a;
      if (item.id === itemId) return item;
      if (item[nestingKey])
        return findItemNested(item[nestingKey], itemId, nestingKey);
    }, null)
  );
};

export const FindScene = (arr) => {
  let list = [];
  // eslint-disable-next-line
  arr.map((val) => {
    if (val.scene) {
      let listarr = getscene(val.scene, val);
      list = [...list, ...listarr];
    }
  });
  return list;
};

const getscene = (arr, scene) => {
  // eslint-disable-next-line
  let list = [];
  // eslint-disable-next-line
  arr.map((val) => {
    if (val?.title?.projectName?.[0]) {
      list.push({
        sceneId: val.id,
        label: Array.isArray(val?.title?.projectName)
          ? val?.title?.projectName?.[0]
          : val?.title?.projectName,
        actName: scene.actName,
        actId: scene.actid,
        playName: scene.playName,
        playId: scene.playid,
        storyId: scene.storyId,
        storyName: scene.storyName,
        entity:
          scene?.entity?.length > 0
            ? scene?.entity?.map((val) => val.entity)
            : [],
      });
    }
  });
  return list;
};

export const uniqeArray = (array, key) => {
  return [...new Map(array.map((item) => [item[key], item])).values()];
};

export const filterJSON = (arr, screens, title, play, act) => {
  let arrJson = arr.filter(
    (val) =>
      checkCondition(val.label, title) ||
      checkCondition(val.playName, play) ||
      checkCondition(val.actName, act)
  );
  arrJson = arrJson.map((val) => {
    let count = checkScreenMap(screens, val);
    if (count) {
      val["count"] = count;
    }
    return val;
  });
  return arrJson;
};
const checkScreenMap = (screens, val) => {
  let count = 0;
  // eslint-disable-next-line
  screens.map((sc) => {
    if (sc.scenes) {
      // eslint-disable-next-line
      sc.scenes.map((scene) => {
        if (scene.sceneId === val.sceneId) {
          count = count + 1;
        }
      });
    }
  });
  return count;
};
const checkCondition = (val1, val2) => {
  if (!val1) {
    return false;
  } else if (Array.isArray(val2) && val2.indexOf(val1) > -1) {
    return true;
  } else if (
    !Array.isArray(val2) &&
    val1.toLocaleLowerCase().includes(val2.toLocaleLowerCase())
  ) {
    return true;
  } else {
    return false;
  }
};

// Delete Component

const deletearr = (arr, key) => {
  console.log("arr,key", arr, key);
  const listKey = "id",
    nextLoop = "child";
  arr.map((val, index) => {
    if (val[listKey] === key) {
      console.log(
        "%c will delete or not",
        "background:red;color:black",
        val[listKey],
        key,
        index,
        val
      );
      arr.splice(index, 1);
    } else if (nextLoop && val[nextLoop]) {
      console.log(
        "%c will delete or not is called",
        "background:red;color:black"
      );
      deletearr(val[nextLoop], key);
    }
    return false;
  });
  // arr.map((val, i) => {
  //   if (val[listKey] === key) {
  //     arr.splice(i, 1);
  //   }
  //   if (nextLoop && val[nextLoop]) {
  //     deletearr(val[nextLoop], key, listKey, nextLoop);
  //   }
  //   return false;
  // });
  return arr;
};

export const deleteTreeListComponent = async (
  screen_id,
  move_item_id,
  AllScreenList
) => {
  // let screens = AllScreenList?.filter((screen) => {
  //   if (screen.id === screen_id) {
  //     deletearr(screen.render.child, move_item_id);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // });
  let screens = AllScreenList?.filter((screen) => {
    if (screen_id === screen.id) {
      console.log(
        " %c screen filter and delete ",
        "background:black;color:white",
        screen.render,
        move_item_id
      );
      return deletearr(screen.render.child, move_item_id);
    } else {
      return false;
    }
  });
  console.log("after deleted", screens);
  localStorage.setItem("previewScreen", JSON.stringify(screens));
  sessionStorage.setItem("previewScreen", JSON.stringify(screens));
  return screens;
};

export const getPropertiesItems = (component, screenId, eleAtrribute) => {
  debugger;
  if (component === "screensParent") {
    return false;
  }
  // add and remove selected component active css class
  if (document.querySelector(".selectedComponent")) {
    document
      .querySelector(".selectedComponent")
      .classList.remove("selectedComponent");
  }
  // event.currentTarget.className += " selectedComponent"
  if (document.getElementById(component?.id)) {
    document.getElementById(component.id).classList.add("selectedComponent");
  }
  // let PropTypes = All;
  // var example = <inputProps />
  let properties = {},
    inlineStyles = {},
    validations = {};
  if (component.component_type === "qdm") {
    let data1 = eleAtrribute[component.component];
    properties = {
      ...data1?.properties,
      ...(component?.properties ?? {}),
    };
    inlineStyles = {
      ...data1?.inlineStyles,
      ...(component?.inlineStyles ?? {}),
    };
    validations = {
      ...data1?.validations,
      ...(component?.validations ?? {}),
    };
  } else if (component.component_type !== "qdm") {
    properties = {
      ...(component?.properties ?? {}),
    };
    inlineStyles = {
      ...(component?.inlineStyles ?? {}),
    };
    if (component.component_type === "dynamic") {
      let defaultProps = cloneDeep(
        DynamicComponentsDefaultproperties[component.component] ?? {}
      );
      properties = {
        ...defaultProps,
        ...(component?.properties ?? {}),
      };
    }
  }
  let data2 = {
    id: component.id,
    componentId: component.componentId,
    component: component.component,
    component_type: component.component_type,
    frameWork: component.frameWork,
    screenId: screenId,
    name: component.name,
    properties: properties,
    inlineStyles: inlineStyles,
    validations: validations,
    idm: component?.idm,
    componentDecisionId: component?.componentDecisionId,
  };

  return data2;
};

//Duplicate component-This will change the id of every component & return cloned copy with different id

export const cloneComponent = (component) => {
  console.log(component);
  let componentCopy = cloneDeep(component);
  console.log(component, componentCopy);
  if (componentCopy?.child?.length > 0) {
    debugger;
    componentCopy.child.forEach(function iter(val, index, array) {
      let id = makeid();
      val.id = id;
      val.properties.id = makeid();
      if (val?.child?.length > 0) {
        val.child.forEach(iter);
      }
    });
  }
  console.log(component, componentCopy);
  const clonedComponent = {
    ...componentCopy,
    id: makeid(),
     properties: {
      ...componentCopy.properties,
      id: makeid(),
    },
  };
  return clonedComponent;
};

export const screenArrowLinkAndEventRemover = (screen, id) => {
  // delete ArrowLinks In Other Screens
  if (screen?.arrowLink?.length > 0) {
    screen.arrowLink.forEach((link, index, array) => {
      if (link.arrowSource === id) {
        array.splice(index, 1);
      }
    });
  }
  //delete events
  if (screen?.events?.length > 0) {
    screen.events.forEach((event, index, array) => {
      if (event.fromId === id) {
        array.splice(index, 1);
      }
    });
  }
};
export const decisionHandlerArrowLinkRemover = (screen, id) => {
  // delete ArrowLinks In Decision Handlers
  screen.arrowLink.forEach((link, index, arrowLinkArray) => {
    if (link.arrowSource === id) {
      const arrowSourceId = link.id;
      const newFilteredArray = arrowLinkArray.filter(
        (link) => link.id !== arrowSourceId
      );
      screen.arrowLink = newFilteredArray;
      screen.render.child.forEach(function iter(val, index, array) {
        if (val.component === "DecisionBox") {
          if (val.id === arrowSourceId) {
            array.splice(index, 1);
          }
        } else {
          if (val?.child?.length > 0) {
            val.child.forEach(iter);
          }
        }
      });
    }
  });
};

export const deleteComponent = (componentId, auth) => {
  //handling events & actions
  auth.user.AllScreenList.forEach((screen, index, arr) => {
    //handling deleting component & its children component arrow links & events
    // if (
    //   screen.id === auth.user.selectedScreen &&
    //   screen.type !== "decisionHandler"
    // ) {
    //   screen.render?.child?.length > 0 &&
    //     screen.render.child.forEach(function iter(cmpnt) {
    //       if (cmpnt.id === componentId) {
    //         screenArrowLinkAndEventRemover(screen, componentId);
    //         cmpnt?.child?.length > 0 &&
    //           cmpnt.child.forEach(function iterate(childCmpnt) {
    //             screenArrowLinkAndEventRemover(screen, childCmpnt.id);
    //             childCmpnt?.child?.length > 0 &&
    //               childCmpnt.child.forEach(iterate);
    //           });
    //       } else {
    //         cmpnt?.child?.length > 0 && cmpnt.child.forEach(iter);
    //       }
    //     });
    // }
    // deleting component & its children component ArrowLinks In Decision Handlers
    // if (screen.type === "decisionHandler") {
    //   screen.render?.child?.length > 0 &&
    //     screen.render.child.forEach(function iter(cmpnt) {
    //       if (cmpnt.id === componentId) {
    //         decisionHandlerArrowLinkRemover(screen, componentId);
    //         cmpnt?.child?.length > 0 &&
    //           cmpnt.child.forEach(function iterate(childCmpnt) {
    //             decisionHandlerArrowLinkRemover(screen, childCmpnt.id);
    //             childCmpnt?.child?.length > 0 &&
    //               childCmpnt.child.forEach(iterate);
    //           });
    //       } else {
    //         cmpnt?.child?.length > 0 && cmpnt.child.forEach(iter);
    //       }
    //     });
    // }
  });

  // deleting decision handler if it arrowlink is empty
  // auth.user.AllScreenList.forEach((screen, index, array) => {
  //   if (screen.type === "decisionHandler" && screen.arrowLink.length === 0) {
  //     array.splice(index, 1);
  //   }
  // });
  // deleting the component
  console.log(
    "%c show me the deleting one",
    "color:white;background:black",
    auth.user.selectedScreen,
    componentId,
    auth.user.AllScreenList
  );
  const deleted = deleteTreeListComponent(
    auth.user.selectedScreen, //this is the screen id
    componentId, //id of the component that is in the screen
    auth.user.AllScreenList
  );

  // console.log("deleting the component", deleted);
  // if (deleted) {
  //   auth.setAuth({
  //     ...auth.user,
  //     screenEdited: auth.user.selectedScreen,
  //   });
  // }
};

//removes the id of the nested component (usecae: for comparing two components without id)
export const componentDeepIdRemover = (component) => {
  //to not affect the original component copying the component
  let componentCopy = cloneDeep(component);
  delete componentCopy.id;
  componentCopy?.child?.length > 0 &&
    componentCopy.child.forEach(function iter(eachComponent) {
      delete eachComponent.id;
      eachComponent?.child?.length > 0 && eachComponent.child.forEach(iter);
    });
  return componentCopy;
};

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getScreensAndComponents = (allScreenList) => {
  let data = {
    db_name: config.db_name,
    entity: "master_pages",
    is_metadata: false,
    doc: {
      id: localStorage.getItem("metaDataId"),
      active: true,
      screens: [],
      components: [],
    },
  };
  allScreenList.forEach((screen, index, array) => {
    if (screen.type === "Screen") {
      data.doc["screens"].push(screen.name);
    } else if (screen.type === "Component") {
      data.doc["components"].push(screen.name);
    }
  });
  return data;
};

export function bytesConvertor(bytes) {
  var _size = bytes;

  var fSExt = ["Bytes", "KB", "MB", "GB"],
    i = 0;
  while (_size > 900) {
    _size /= 1024;
    i++;
  }
  var exactSize = Math.round(_size * 100) / 100 + " " + fSExt[i];
  return exactSize;
}

export const nestedHandler = (load) => {
  if (Array.isArray(load)) {
    return nestedHandler(load[0]);
  } else if (load?.type === "array") {
    if (Array.isArray(load?.items)) {
      return load?.items.map((item) => nestedHandler(item));
    } else {
      return nestedHandler(load.items);
    }
  } else if (load?.type === "object") {
    try {
      var properties = load.properties;
      var getData = [];
      Object.keys(properties).forEach((item) => {
        let nestHandle = nestedHandler(properties[item]);
        let _res =
          typeof nestHandle === "object"
            ? {
                column:
                  properties[item].type === "object"
                    ? nestHandle
                    : nestHandle[0],
                type: properties[item].type,
              }
            : { type: nestHandle };
        getData.push({
          name: item,
          ..._res,
        });
      });
      return getData;
    } catch (e) {}
  } else {
    return load.type;
  }
};

//accept camelCase name and return name with space
export function docorateName(name) {
  let newName = name;
  let positions = [];
  const splitted = newName.split("");
  splitted.forEach((char, index, array) => {
    if (Number.isInteger(parseInt(char))) return;
    if (char === char.toUpperCase()) {
      if (index !== 0) positions.push(index);
      if (index !== 1) positions.push(index);
    }
  });
  positions.map((position, index) => {
    if (position - 1 === positions[index - 1]) return;
    let finalPosition = position + index;
    newName =
      newName.substring(0, finalPosition) +
      " " +
      newName.substring(finalPosition);
  });
  return newName;
}

export const checkRecusrsiveId = (data, childKey, keyName, id, append) => {
  data.forEach((item) => {
    let data = item[childKey];
    if (data) {
      checkRecusrsiveId(data, childKey, keyName, id, append);
    } else if (item[keyName] && item[keyName] === id) {
      if (append) {
        ///append obj
        return true;
      }
      return true;
    }
  });
  return false;
};

export const getParentNode = (data, id, keyName, ref) => {
  if (!data) return;
  for (const item of data) {
    if (item?.id === id) {
      return [item];
    } else {
      let res = getParentNode(item?.child, id, keyName, ref);
      if (res && item?.[keyName]) {
        ref.push(item);
      }
      if (res) {
        res.push(item);
        return res;
      }
    }
  }
  return;
};

export const getParentNodeId = (data, id, keyName) => {
  let ref = null;
  ref = getParentNode(data, id, keyName, ref);
  return ref;
};

export const repositoryOperation = {
  updateRecursively: (data, idm, obj) => {
    data.forEach((item) => {
      if (item?.key && item.key === idm?.parent?.id) {
        if (idm?.status) {
          if (item?.children) item.children.push(obj);
          else item["children"] = [obj];
        } else {
          let isExist =
            item?.children &&
            item?.children.findIndex((i) => i.key === obj?.key);
          if (isExist > -1) item.children.splice(isExist, 1);
        }
        return true;
      }
      if (item?.children && item?.children?.length) {
        repositoryOperation.updateRecursively(item?.children, idm, obj);
      }
    });
  },
  createScreenObj: (repoObj) => {
    return {
      id: uuidv4(),
      routes: repoObj?.type === "Component" ? null : repoObj?.path,
      name: repoObj?.name,
      component_type: repoObj?.type,
      actionable: "",
      key: repoObj?.name,
      children: [],
    };
  },
  createCompObj: (repoObj) => {
    return {
      id: uuidv4(),
      name: repoObj?.idm?.name,
      component_type: repoObj?.idm?.type ?? "",
      actionable: "",
      key: repoObj?.id,
    };
  },
  read: async () => {
    let data = await networkCall(config.api_url + "/read_documents", "POST", {
      db_name: localStorage?.projectName?.replaceAll(" ", "_"),
      entity: "IDM_Repository",
      return_fields: "IDM_Repository",
    });
    return data?.data?.result?.[0] ?? {};
  },
  update: async (repoObj) => {
    if (repoObj?.rep_id) {
      let filter = {};
      if (repoObj.rep_id) {
        filter = { filter: { rep_id: repoObj.rep_id } };
      }
      try {
        var Config = {
          method: "post",
          url: config.api_url + "/upsert_document",
          headers: {
            "Content-Type": "application/json",
          },
          data: JSON.stringify([
            {
              db_name: localStorage?.projectName?.replaceAll(" ", "_"),
              entity: "IDM_Repository",
              ...filter,
              doc: repoObj,
            },
          ]),
        };
        await Axios;
        Axios(Config)
          .then(async (response) => {
            return response;
          })
          .catch((err) => {
            console.log("Auto Save Error" + err);
            return err;
          });
      } catch (e) {
        return false;
      }
    }
  },
  removeScreen: (data, repoObj) => {
    let permissionData = data;
    let isExist = permissionData?.project_component?.findIndex(
      (item) => item.key === repoObj.name
    );
    if (isExist > -1) permissionData.project_component.splice(isExist, 1);
    return permissionData;
  },
  addScreen: (data, repoObj) => {
    let permissionData = data;
    // first time set
    if (!permissionData?.rep_id) {
      permissionData = {
        rep_id: "cb98c91e-6643-4309-b15c-fd65990f57d6",
        rep_type: "",
        elementname: "",
        route: "",
        is_active: true,
        activestatus: true,
        project_component: [],
      };
    }
    let permission = repositoryOperation.createScreenObj(repoObj);
    let isExist = permissionData.project_component.findIndex(
      (item) => item.key === repoObj.name
    );
    if (isExist === -1) permissionData.project_component.push(permission);
    return permissionData;
  },
  addComponent: (data, parentId, repoObj) => {
    let permissionData = data;
    let permission = repositoryOperation.createCompObj(repoObj);
    repositoryOperation.updateRecursively(
      permissionData?.project_component,
      repoObj?.idm,
      permission
    );
  },
};

export const getComponentCode = async (componentId, screenId, component) => {
  if (localStorage.metaDataId && localStorage.projectName) {
    let response = await Axios.post(
      // "http://localhost:8080/export",
      `${config.node_backend}/export`,
      {
        metadataId: localStorage.metaDataId,
        projectname: localStorage.projectName,
        componentId: componentId,
        screenId: screenId,
        component: component,
      }
    );
    return response?.data ?? {};
  } else {
    alert("something went wrong,please contact admin");
  }
};

export const getComponentMeta = (auth, screenId, componentId) => {
  let componentData;
  auth.user.AllScreenList.forEach((screen) => {
    if (screen.id === screenId) {
      screen.render.child.forEach(function iter(parent, index, array) {
        if (parent.id === componentId) {
          componentData = parent;
        } else {
          if (parent?.child?.length > 0) {
            parent.child.forEach(iter);
          }
        }
      });
    }
  });
  return componentData;
};

export const getProjectComponents = (auth) => {
  let components = [];
  console.log(auth.user.AllScreenList);
  auth.user.AllScreenList.forEach((screen) => {
    if (screen.type === "Component") {
      components.push({
        component: true,
        componentName: screen.name,
        componentId: screen.id,
      });
    }
  });
  return components;
};
// get project screen

export const getscreenComponent = (auth) => {
  let screens = [];
  {console.log("auth.user.AllScreenList" , auth.user.AllScreenList)}
  console.log(auth.user.AllScreenList);
  auth.user.AllScreenList.forEach((screen) => {
    if (screen.type === "Screen") {
      screens.push({
       
        componentpath:screen.path
      });
    }
  });
  return screens;

}



//GetNodeId
export const GetNodeID = (treeData, selectedID, initialNode = "") => {
  for (let i = 0; i < treeData?.length; i++) {
    if (treeData[i].id === selectedID) {
      const newNode = `${initialNode}${i}`;
      return newNode;
    } else {
      if (treeData[i].child && treeData[i].child.length > 0) {
        const NewinitialNode = `${initialNode}${i}_`;
        let ChildNode = GetNodeID(
          treeData[i].child,
          selectedID,
          NewinitialNode
        );
        if (ChildNode) {
          return ChildNode;
        }
      }
    }
  }
};

export const snakeToCamel = (str) =>
  str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
export const snakeToPascal = (str) => {
  let camelCase = snakeToCamel(str);
  let pascalCase = camelCase[0].toUpperCase() + camelCase.substr(1);
  return pascalCase;
};

export const pascalToSnake = (str) => {
  return snakeCase(str);
};