import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import { TbLogout } from "react-icons/tb";
import {
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  Tooltip,
  Divider,
  Avatar,
  MenuItem,
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory } from "react-router-dom";
import config from "../config";

const useStyles = makeStyles((theme) => {
  console.log({ theme });
  return {
    grow: {
      flexGrow: 1,
      zIndex: theme?.zIndex?.drawer + 1,
    },
    appBar: {
      zIndex: theme?.zIndex?.drawer + 1,
      backgroundColor: theme?.palette?.primary,
      boxShadow: "unset",
      borderBottom: "1px solid #e0e0e0",
    },
    title: {
      display: "block",

      textTransform: "capitalize",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8rem !important",
      },
    },
    caption: {
      display: "block",
      marginRight: theme?.spacing(1),
      [theme?.breakpoints?.down("sm")]: {
        fontSize: "0.6rem !important",
      },
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
    menuIcon: {
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    userProfilecard: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme?.spacing(1),
      marginRight: theme?.spacing(1),
    },
    profilePic: {
      marginLeft: "8px",
    },
    notificationdiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    notifications: {},
  };
});

const TopNavBar = (props) => {
  const { user, role } = props;
  const classes = useStyles();
  const history = useHistory();
  const Theme = useTheme();
  const sm = useMediaQuery(Theme?.breakpoints?.down("sm"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({});
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    debugger;
    try {
      let tokenFromUrl = localStorage.getItem("token");
      let parsedToken = tokenFromUrl?.split(".")[1];
      let userInfo = JSON.parse(window.atob(parsedToken));
      setState(userInfo);
    } catch (err) {
      setState({});
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    // localStorage.removeItem("RoleId")
    window.location.replace(config.qdm_admin_url);
  };

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>
          <div className={classes.titleContainer}>
            <img
              alt="icon"
              src="https://atp.dev.ainqaplatform.in/assets/01_AINQA_Logo_Horizontal_White.svg"
              style={{ width: "100px", height: "auto" }}
            />
            <div>
              <Typography className={classes.title} variant="body1" noWrap>
                UI Builder
              </Typography>
              <Typography
                // className={classes.title}
                variant="caption"
                noWrap
              >
                {process?.env?.REACT_APP_Version}
              </Typography>
            </div>
          </div>

          <div className={classes.grow} />

          <Divider
            flexItem
            orientation="vertical"
            style={{ margin: "16px 4px", width: "2px", background: "#fff" }}
          />

          <div className={classes.userProfilecard}>
            <div>
              <Typography
                className={classes.title}
                variant="body1"
                component={"h6"}
                noWrap
              >
                {state.given_name?.trim().length > 0
                  ? state.given_name
                  : "Admin"}
              </Typography>

              <Typography
                // className={classes.title}
                variant="caption"
                noWrap
              >
                {/* {role} */}
              </Typography>
            </div>
          </div>
          <Avatar className={classes.profilePic} variant="square">
            {state?.given_name?.[0].toUpperCase() ?? "Admin"?.[0].toUpperCase()}
          </Avatar>

          {sm ? (
            <IconButton
              style={{ color: "#fff" }}
              onClick={() => handleLogout()}
            >
              <TbLogout />
            </IconButton>
          ) : (
            <Tooltip title="Logout" arrow>
              <IconButton
                style={{ color: "#fff" }}
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <TbLogout />
              </IconButton>
            </Tooltip>
          )}
        </Toolbar>
      </AppBar>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {/* <List>
          <ListItem Button onClick={() => handleLogout()}>
            <ListItemIcon style={{ width: "30px" }}>
              <ExitToAppIcon />
            </ListItemIcon>
            {!sm && <ListItemText primary="Logout" />}
          </ListItem>
        </List> */}
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Popover>
    </div>
  );
};
export default TopNavBar;
