import React from "react";
import { makeStyles, TextField, Button } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
// import { RemoveFromQueueRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "0%",
    marginBottom: "8%",
  },
  title: {
    fontWeight: "700",
    margin: 0,
    fontSize: 15,
    marginBottom: 8,
  },
  removebtn: {
    padding: 0,
    textAlign: "left",
    width: "fit-content",
    marginTop: 8,
    color: "#f10730",
  },
  subtext: {
    margin: 0,
    fontSize: 14,
  },
}));

export function QpasActions(props) {
  const classes = useStyles();
  let screenvalue = "";
  return (
    <div className={classes.root}>
      <Autocomplete
        filterSelectedOptions
        value={screenvalue}
        onChange={(event, newValue) => props.onChangeScenedropdowns(newValue)}
        size={"small"}
        id="controllable-states-demo1"
        options={props.QpassSceneData ? props.QpassSceneData.filter(val => props.screenMapArray?.map(v => v.label).indexOf(val.label) === -1) : null}
        getOptionLabel={(options) => options?.label}
        autoComplete={"off"}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <div style={{ display: "grid" }}>
              <p className={classes.title}>{option.label}</p>
              <p className={classes.subtext}>
                Scene #{option.sceneId} | {option.storyName} | {option.playName}{" "}
                | {option.actName}
              </p>
            </div>
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Actions"
            variant="outlined"
            autoComplete={"off"}
          />
        )}
      />

      {props.screenMapArray?.length > 0
        ? props.screenMapArray?.map((val, i) => {
            return (
              <div
                style={{
                  marginTop: "4%",
                  display: "grid",
                  padding: 6,
                }}
              >
                <p className={classes.title}>{val.label}</p>
                <p className={classes.subtext}>
                  Scene #{val.sceneId} | {val.storyName} | {val.playName} |{" "}
                  {val.actName}
                </p>
                <Button
                  className={classes.removebtn}
                  variant="text"
                  onClick={() => props.removeSceenFun(val.sceneId)}
                >
                  Remove
                </Button>
              </div>
            );
          })
        : null}
    </div>
  );
}
