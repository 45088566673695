const routes = {
  login: "/login",
  signup: "/signup",
  home: "/home",
  profile: "/profile",
  uibuilder: "/uibuilder",
  uiflow: "/uiflow",
  preview: "/preview",
};

export default routes;
