import React from 'react';
import {
    Popover,
    makeStyles,
    TextField,
    InputAdornment,
    fade,
    Grid,
    List,
    ListItem,
    ListItemText
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search';
import { AuthContext } from '../../../../contexts'
const useStyles = makeStyles((theme) => ({
    root: {
        width: 250,
        backgroundColor: 'white',
        padding: 10
    },
    search: {
        marginTop: 20,
        marginBottom: 16,
        borderRadius: 4,
        borderBottom: "1.3px solid rgba(0, 0, 0, 0.12)",
        border: "1.3px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: fade("#fff", 0.15),
        '&:hover': {
            backgroundColor: fade("#fff", 0.25),
        },
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        "& .MuiOutlinedInput-adornedStart": {
            paddingLeft: 0
        },
        "& .MuiInputAdornment-positionStart": {
            marginRight: 0
        }
    },
    searchIcon: {
        padding: "0px 6px",
        height: '100%',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    screensList: {
        padding: 0,
        paddingLeft: 10,
        maxHeight: 200,
        overflow: 'scroll',
        "& .MuiListItem-root": {
            paddingTop: 2,
            paddingBottom: 2
        }
    }
}))

export default function ScreenSearchPopover(props) {
    const { anchor, setAnchor } = props;
    const classes = useStyles();
    const auth = React.useContext(AuthContext)
    const [value, setValue] = React.useState('');

    const getSelectedScreen = (v, i) => {
        setValue('');
        props.getSelectedScreen(v, i)
    }

    React.useEffect(() => {
        if (anchor.open === true) {
            setValue('')
        }
    }, [anchor.open])

    return (
        <Popover
            id={`${anchor.id}popover`}
            open={anchor.open}
            anchorEl={anchor.anchorEl}
            onClose={() => {
                setAnchor(null)
            }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <TextField
                        label="search"
                        variant="outlined"
                        size="small"
                        className={classes.search}
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" className={classes.searchIcon}>
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.screensList}
                    >
                        {
                            auth.user.AllScreenList.map((item, index) => {
                                if (!item.ignore) {
                                    if (!value) {
                                        return <ListItem
                                            button
                                            onClick={() => getSelectedScreen(item, index)}
                                        >
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    } else if (item.name.toLowerCase().includes(value.toLowerCase())) {
                                        return <ListItem
                                            button
                                            onClick={() => getSelectedScreen(item, index)}
                                        >
                                            <ListItemText primary={item.name} />
                                        </ListItem>
                                    }
                                    else return <></>
                                }
                            })
                        }
                    </List>
                </Grid>
            </Grid>
        </Popover>
    )
}
