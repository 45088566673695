import React, { useEffect, useLayoutEffect, useState, useContext } from "react";
import { AuthContext } from "../../../contexts";
import {
  Grid,
  Typography,
  TextField,
  Button,
  makeStyles,
  Chip,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { getDocument } from "../../../apis/crud";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  lable: {
    margin: "auto 0",
  },
  rowSpace: {
    paddingBottom: "20px",
  },
  rowSpaceBetween: {
    justifyContent: "space-between",
    display: "flex",
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: "#fff !important",
  },
  selecteTab: {
    padding: "0 10px",
  },
  layoutStyle: {
    cursor: "pointer",
    borderRadius: "4px",
    border: "1px solid lightgray",
    padding: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "120px",
    margin: "5px",
  },
  activeLayoutStyle: {
    backgroundColor: "#017afe",
    color: "#fff",
    border: "1px solid #017afe",
  },
  selecteSceneTab: {
    padding: "10px",
    border: "1px solid lightgray",
    borderRadius: "4px",
    "&:hover": {
      // backgroundColor: "#f9f7f7",
      removeBtn: {
        display: "block",
      },
    },
  },
  removeBtn: {
    top: "-2px",
    color: "#f50057",
    padding: "2px",
    right: 0,
    borderRadius: "20px",
    minWidth: "20px",
    backgroundColor: "#ffffff",
    position: "absolute",
    fontSize: "10px",
    textTransform: "capitalize",
    display: "none",
    "&:hover": {
      display: "block",
    },
  },
  imageViewCss: {
    cursor: "pointer",
    width: "100%",
    border: "3px solid #fff",
    borderRadius: "4px",
    height: "100%",
  },
  addScreenBtn: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

function AddScreenStepper(props) {
  const [state, setState] = useState({
    name: "",
    description: "",
    isHome: false,
    isIdmEnabled: false,
    isPrivate: false,
    path: "/",
    type: "",
    error: {},
    layoutId: null,
    isLayoutScreen: false,
  });

  const auth = useContext(AuthContext);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [selectedQpasScenes, setSelectedQpasScenes] = React.useState([]);
  const [dupIndicate, setDupIndicate] = useState(false);
  const [dupIndicatecomp, setDupIndicatecomp] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [metaId, setMetaId] = useState("");

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  useLayoutEffect(() => {
    console.log("propskkk", props);
    const initialFunction = () => {
      if (props.viewType === "edit") {
        setMetaId(localStorage.getItem("metaDataId"));
        setState({
          name: auth.user.screens[0]?.name,
          description: auth.user.screens[0]?.description,
          isHome: auth.user.screens[0]?.isHome,
          isLayoutScreen: auth.user.screens[0]?.isLayoutScreen,
          isIdmEnabled: auth.user.screens[0]?.isIdmEnabled,
          isPrivate: auth.user.screens[0]?.isPrivate,
          path: auth.user.screens[0]?.path,
          type: auth.user.screens[0]?.type ?? "Screen",
        });
        setActiveStep(0);
        setSelectedQpasScenes(auth.user.screens[0]?.scenes);
      } else {
        setMetaId(localStorage.getItem("metaDataId"));
        setActiveStep(0);
        setSelectedQpasScenes([]);
        setState({
          type: props?.type?.length > 0 ? props.type : "",
          path: "/",
        });
      }
    };
    initialFunction();
    // fetchDataQpas();
    // eslint-disable-next-line
  }, [props]);

  const getSteps = () => {
    if (state.type === "Screen" && props.viewType === "edit") {
      //&& props.viewType === "edit"
      return ["Screen Details"]; //"QPAS Scenes",
      //  else if (state.type === "Component") {
      //   return ["Screen Details"];
      // }
    } else {
      return ["Screen Details"]; //"QPAS Scenes", "Screen Layout",
    }
  };
  const steps = getSteps();

  const isStepOptional = (step) => {
    if (state.type === "Screen" && props.viewType !== "edit") {
      return step === 1 || step === 0;
    } else if (state.type === "Screen" && props.viewType === "edit") {
      return step === 0;
    }
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const isValidFrom = (state) => {
    if (
      state.name?.length > 3 &&
      state.type?.length > 1 &&
      (state.type === "Screen"
        ? state.path?.length >= 1
        : state.type?.length > 1)
    ) {
      setState({
        ...state,
        error: {},
      });
      return true;
    } else {
      let errorFiled = {
        name: state.name?.length > 3 ? false : true,
        path: state.path?.length >= 1 ? false : true,
        type: state.type?.length > 1 ? false : true,
      };
      setState({
        ...state,
        error: errorFiled,
      });
      return false;
    }
  };

  const addNewScreen = (e, type) => {
    if (isValidFrom()) {
      setState({
        name: "",
        description: "",
        isHome: false,
        isPrivate: false,
        path: "/",
        type: "",
        error: {},
        layoutId: null,
        isLayoutScreen: false,
      });
      // setActiveStep(0);
      //setSelectedQpasScenes([]);

      props.submitAddScreen(e, state, type);
    }
  };

  //   function capitalize(word){
  //     return word[0].toUpperCase() + word.slice(1).toLowerCase();
  // }

  const handleNext = async (e) => {
    setDisabled(true);
    debugger;
    let getDatas = await getDocument(state.name);
    console.log("length", getDatas.result.length);

    if (getDatas.result.length > 0) {
      setDupIndicate(true);
      setDisabled(false);
    } else {
      if (props.type === "Screen" || props.type === "Component") {
        if (isValidFrom(state)) {
          props.submitAddScreen(e, state, state.type);
          setDupIndicate(false);
        }
      } else {
        if (isValidFrom(state)) {
          props.submitAddScreen(e, state, "edit");
          setDupIndicate(false);
        }
      }
    }

    // const currentStep = 0;
    // add screen api trigger
    // if (currentStep === steps.length-1) {
    //   if (!isValidFrom()) {
    //     return false;
    //   }
    //   addNewScreen(e, props.viewType);
    //   return false;
    // }
    //let newSkipped = skipped;
    //if (isStepSkipped(activeStep)) {
    // newSkipped = new Set(newSkipped.values());
    // newSkipped.delete(activeStep);
    //}

    //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    //setSkipped(newSkipped);
  };
  const handleNextcompo = async (e) => {
    setDisabled(true);
    debugger;
    let getDatas = await getDocument(state.name);
    console.log("length", getDatas.result.length);

    if (getDatas.result.length > 0) {
      setDupIndicatecomp(true);
      setDisabled(false);
    } else {
      if (props.type === "Screen" || props.type === "Component") {
        if (isValidFrom(state)) {
          props.submitAddScreen(e, state, state.type);
          setDupIndicatecomp(false);
        }
      } else {
        if (isValidFrom(state)) {
          props.submitAddScreen(e, state, "edit");
          setDupIndicatecomp(false);
        }
      }
    }
  };

  const onChangeSwitch = (e, name, value) => {
    setState({
      ...state,
      [name]: !state[name],
    });
  };

  //On Change Input function for handling and updating the state of input field
  const onChangeInput = async (e, name) => {
    if (name === "name" && state.type === "Screen") {
      setState({
        ...state,
        name: e?.target?.value.toLocaleLowerCase(),
        path: "/" + e?.target?.value?.replace(/\s/g, "_").toLocaleLowerCase(),
      });
    } else {
      setState({
        ...state,
        [name]:
          e?.target?.value?.length === 0 || e?.target?.value
            ? e?.target?.value.toLocaleLowerCase()
            : e,
      });
    }
  };

  // Function to show the stepper content(A Form)
  const getStepContent = (step) => {
    console.log("State", state.type);
    //props.viewType === "edit" ? (step === 0 ? 1 : step) : step;
    // if (props.type === "Component") {
    //     step = 1;
    //   } else if (state.type === "Component") {
    //     step = 1;
    //   }

    switch (step) {
      case 0:
        return (
          <Grid container>
            <Grid item xs={12} className={classes.rowSpace}>
              <Typography variant={"body1"}>
                {"Screen Type: " + state.type}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.rowSpace}>
              <TextField
                error={state.error?.name ?? false}
                required
                onChange={(e) => onChangeInput(e, "name")}
                fullWidth
                label="Name"
                id="screen_name"
                value={state.name}
                variant="outlined"
                size="small"
              />
              {dupIndicate && (
                <p style={{ color: "red" }}>
                  screen name is already taken. try different one
                </p>
              )}
            </Grid>
            {state.type === "Screen" && (
              <>
                <Grid item xs={12} className={classes.rowSpace}>
                  <TextField
                    error={state.error?.path ?? false}
                    required
                    onChange={(e) => onChangeInput(e, "path")}
                    fullWidth
                    label="Screen path"
                    id="screen_path"
                    value={state.path}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ paddingRight: "14px" }}
                  className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
                >
                  <Typography className={classes.lable}>
                    {"Home Screen?"}
                  </Typography>
                  <ToggleButtonGroup
                    size={"small"}
                    value={state.isHome ? "yes" : "no"}
                    exclusive
                    onChange={(e) => {
                      onChangeSwitch(e, "isHome");
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value={"yes"}
                      aria-label="left"
                      size={"small"}
                      classes={{
                        root: classes.selecteTab,
                        selected: classes.selectedTab,
                      }}
                      disabled={state.isLayoutScreen}
                    >
                      <Typography component={"p"} variant={"caption"}>
                        Yes
                      </Typography>
                    </ToggleButton>
                    <ToggleButton
                      value={"no"}
                      aria-label="centered"
                      size={"small"}
                      classes={{
                        root: classes.selecteTab,
                      }}
                      disabled={state.isLayoutScreen}
                    >
                      <Typography component={"p"} variant={"caption"}>
                        No
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
                  style={{ paddingRight: "14px" }}
                >
                  <Typography className={classes.lable}>
                    {"Private Screen?"}
                  </Typography>
                  <ToggleButtonGroup
                    size={"small"}
                    value={state.isPrivate ? "yes" : "no"}
                    exclusive
                    onChange={(e) => onChangeSwitch(e, "isPrivate")}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value={"yes"}
                      aria-label="left"
                      size={"small"}
                      classes={{
                        root: classes.selecteTab,
                        selected: classes.selectedTab,
                      }}
                      disabled={state.isLayoutScreen}
                    >
                      <Typography component={"p"} variant={"caption"}>
                        Yes
                      </Typography>
                    </ToggleButton>
                    <ToggleButton
                      value={"no"}
                      aria-label="centered"
                      size={"small"}
                      classes={{
                        root: classes.selecteTab,
                      }}
                      disabled={state.isLayoutScreen}
                    >
                      <Typography component={"p"} variant={"caption"}>
                        No
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
                  style={{ paddingRight: "14px" }}
                >
                  <Typography className={classes.lable}>
                    {"Idm Enabled?"}
                  </Typography>
                  <ToggleButtonGroup
                    size={"small"}
                    value={state.isIdmEnabled ? "yes" : "no"}
                    exclusive
                    onChange={(e) => onChangeSwitch(e, "isIdmEnabled")}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value={"yes"}
                      aria-label="left"
                      size={"small"}
                      classes={{
                        root: classes.selecteTab,
                        selected: classes.selectedTab,
                      }}
                      disabled={state.isLayoutScreen}
                    >
                      <Typography component={"p"} variant={"caption"}>
                        Yes
                      </Typography>
                    </ToggleButton>
                    <ToggleButton
                      value={"no"}
                      aria-label="centered"
                      size={"small"}
                      classes={{
                        root: classes.selecteTab,
                      }}
                      disabled={state.isLayoutScreen}
                    >
                      <Typography component={"p"} variant={"caption"}>
                        No
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ paddingRight: "14px" }}
                  className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
                >
                  <Typography className={classes.lable}>
                    {"Is Layout?"}
                  </Typography>
                  <ToggleButtonGroup
                    size={"small"}
                    value={state.isLayoutScreen ? "yes" : "no"}
                    exclusive
                    onChange={(e) => {
                      onChangeSwitch(e, "isLayoutScreen");
                    }}
                    aria-label="text alignment"
                  >
                    <ToggleButton
                      value={"yes"}
                      aria-label="left"
                      size={"small"}
                      classes={{
                        root: classes.selecteTab,
                        selected: classes.selectedTab,
                      }}
                      disabled={
                        state.isHome || state?.isPrivate || state?.isIdmEnabled
                      }
                    >
                      <Typography component={"p"} variant={"caption"}>
                        Yes
                      </Typography>
                    </ToggleButton>
                    <ToggleButton
                      value={"no"}
                      aria-label="centered"
                      size={"small"}
                      classes={{
                        root: classes.selecteTab,
                      }}
                      disabled={
                        state.isHome || state?.isPrivate || state?.isIdmEnabled
                      }
                    >
                      <Typography component={"p"} variant={"caption"}>
                        No
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </>
            )}
            <Grid item xs={12} className={classes.rowSpace}>
              {dupIndicatecomp && (
                <p style={{ color: "red" }}>
                  component name is already taken. try different one
                </p>
              )}

              <TextField
                onChange={(e) => onChangeInput(e, "description")}
                fullWidth
                id="outlined-textarea"
                label="Description"
                value={state.description}
                placeholder="Enter description"
                multiline
                variant="outlined"
                rows={4}
              />
              {/* {dupIndicatecomp && (
                <p style={{ color: "red" }}>
                  component name is already taken. try different one
                </p>
              )} */}
            </Grid>
            {state?.type === "Component" && (
              <Grid
                item
                xs={12}
                md={4}
                className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
              >
                <Typography className={classes.lable}>
                  {"Idm Enabled?"}
                </Typography>
              </Grid>
            )}
          </Grid>
        );
      default:
        return "Unkown step";
    }
  };

  return (
    <Dialog
      maxWidth={"md"}
      fullWidth={true}
      open={props.open}
      onClose={(e) => props.modal(e, "close")}
      aria-labelledby="form-dialog-title"
    >
      {/* <DialogTitle id="form-dialog-title"> */}
      <Stepper
        activeStep={activeStep}
        style={{ padding: "24px ​24px 0px 24p" }}
      >
        {steps.length > 0 &&
          steps.map((label, index) => {
            //const stepProps = {};
            //const labelProps = {};
            /* if (isStepOptional(index)) {
              labelProps.optional = (
                <Typography variant="caption">Optional</Typography>
              );
            } */
            /* if (isStepSkipped(index)) {
              stepProps.completed = false;
            } */
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
      </Stepper>
      {/* </DialogTitle> */}
      <DialogContent
        style={{
          padding: "0px 24px 8px 24px",
          minHeight: "calc(100vh - 240px)",
        }}
      >
        {/* <div> */}
        {getStepContent(activeStep)}
        {/* </div> */}
      </DialogContent>
      <DialogActions style={{ padding: "24px" }}>
        <div>
          {/* Addscreen pop up page*/}

          {props.type === "Screen" ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={disabled}
              onClick={handleNext}
            >
              Add Screen
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              disabled={disabled}
              className={classes.button}
              onClick={handleNextcompo}
            >
              {props.viewType === "edit" ? "Update" : "Add Component"}
            </Button>
          )}
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default AddScreenStepper;
