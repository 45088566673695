import { makeStyles } from '@material-ui/core';
import React from 'react';
import { DisplayCard } from './displayCard';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%'
    }
}))

export const InitiateButton = (props) => {
    const classes = useStyles();
    return <div className={classes.root} onClick={props.cardAction}>
        <DisplayCard
            isRoot={true}
            isHighlight={true}
        />
    </div>
}
