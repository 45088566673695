import React from "react";
import { themes } from "./utils";
import { ThemeProvider, responsiveFontSizes } from "@material-ui/core";
import {
  createTheme,
  StylesProvider,
  createGenerateClassName,
} from "@material-ui/core/styles";
import { ThemeContext } from "./contexts";
import config from "./config";

const AppTheme = (props) => {
  let [theme, setTheme] = React.useState({
    name: themes.default,
  });

  const giveMeTheme = () => {
    let current_theme_json;

    switch (theme.name) {
      case themes.default:
        current_theme_json = require("./themes/default.json");
        break;
      default:
        current_theme_json = require("./themes/default.json");
        break;
    }

    let current_theme = createTheme(current_theme_json);

    current_theme = responsiveFontSizes(current_theme);

    return current_theme;
  };

  const generateClassName = createGenerateClassName({
    productionPrefix: "ui-builder",
    seed: "ui-builder",
  });
  console.log("theme", giveMeTheme());
  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeContext.Provider value={{ ...theme, setTheme }}>
        <ThemeProvider theme={giveMeTheme()}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default AppTheme;
