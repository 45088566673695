import React, { useLayoutEffect, useState } from "react";
import {
  Grid,
  Typography,
  Popover,
  TextField,
  Button,
  makeStyles,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  lable: {
    margin: "auto 0",
  },
  rowSpace: {
    paddingBottom: "20px",
  },
  rowSpaceBetween: {
    justifyContent: "space-between",
    display: "flex",
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.main + " !important",
    color: "#fff !important",
  },
  selecteTab: {
    padding: "0 10px",
  },
}));

export function AddScreen(props) {
  const classes = useStyles();
  const [state, setState] = useState({
    name: "",
    description: "",
    isHome: false,
    isPrivate: false,
    path: "",
    error: {},
  });

  useLayoutEffect(() => {
    const initialFunction = () => {
      if (props.viewType === "edit") {
        setState({
          name: props?.screenData[0]?.name,
          description: props?.screenData[0]?.description,
          isHome: props?.screenData[0]?.isHome,
          isPrivate: props?.screenData[0]?.isPrivate,
          path: props?.screenData[0]?.path,
        });
      } else {
        setState({ name: "", description: "" });
      }
    };
    initialFunction();
  }, [props]);

  const onChangeInput = (e, name) => {
    setState({
      ...state,
      [name]: e.target.value,
    });
  };

  const onChangeSwitch = (e, name, value) => {
    setState({
      ...state,
      [name]: !state[name],
    });
  };

  const isValidFrom = () => {
    if (state.name?.length > 3 && state.path?.length > 1) {
      return true;
    } else {
      let errorFiled = {
        name: state.name?.length > 3 ? false : true,
        path: state.path?.length > 1 ? false : true,
      };
      setState({
        ...state,
        error: errorFiled,
      });
      return false;
    }
  };

  const addNewScreen = (e, type) => {
    if (isValidFrom()) {
      setState({
        ...state,
        name: "",
        description: "",
        isHome: false,
        isPrivate: false,
        path: "",
      });
      props.submitAddScreen(e, state, type);
    }
  };

  return (
    <Popover
      elevation={1}
      id={props.id}
      anchorEl={props.anchorEl}
      onClose={(e) => props.modal(e, "close")}
      disableRestoreFocus
      open={props.open}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Grid item xs={12}>
        <Typography style={{ padding: "10px 16px 10px" }}>
          {props.viewType === "edit" ? "Update screen" : "Add Screen"}
        </Typography>
      </Grid>
      <Grid
        container
        style={{
          width: "300px",
          padding: "16px 16px 20px",
          borderTop: "1px solid #ebebeb",
        }}
      >
        <Grid item xs={12} className={classes.rowSpace}>
          <TextField
            error={state.error?.name ?? false}
            required
            onChange={(e) => onChangeInput(e, "name")}
            fullWidth
            label="Screen name"
            id="screen_name"
            value={state.name}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid item xs={12} className={classes.rowSpace}>
          <TextField
            error={state.error?.path ?? false}
            required
            onChange={(e) => onChangeInput(e, "path")}
            fullWidth
            label="Screen path"
            id="screen_path"
            value={state.path}
            variant="outlined"
            size="small"
          />
        </Grid>
        <Grid
          item
          xs={12}
          className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
        >
          <Typography className={classes.lable}>{"Home Screen?"}</Typography>
          <ToggleButtonGroup
            size={"small"}
            value={state.isHome ? "yes" : "no"}
            exclusive
            onChange={(e) => onChangeSwitch(e, "isHome")}
            aria-label="text alignment"
          >
            <ToggleButton
              value={"yes"}
              aria-label="left"
              size={"small"}
              classes={{
                root: classes.selecteTab,
                selected: classes.selectedTab,
              }}
            >
              <Typography component={"p"} variant={"caption"}>
                Yes
              </Typography>
            </ToggleButton>
            <ToggleButton
              value={"no"}
              aria-label="centered"
              size={"small"}
              classes={{ root: classes.selecteTab }}
            >
              <Typography component={"p"} variant={"caption"}>
                No
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid
          item
          xs={12}
          className={`${classes.rowSpace} ${classes.rowSpaceBetween}`}
        >
          <Typography className={classes.lable}>{"Private Screen?"}</Typography>
          <ToggleButtonGroup
            size={"small"}
            value={state.isPrivate ? "yes" : "no"}
            exclusive
            onChange={(e) => onChangeSwitch(e, "isPrivate")}
            aria-label="text alignment"
          >
            <ToggleButton
              value={"yes"}
              aria-label="left"
              size={"small"}
              classes={{
                root: classes.selecteTab,
                selected: classes.selectedTab,
              }}
            >
              <Typography component={"p"} variant={"caption"}>
                Yes
              </Typography>
            </ToggleButton>
            <ToggleButton
              value={"no"}
              aria-label="centered"
              size={"small"}
              classes={{ root: classes.selecteTab }}
            >
              <Typography component={"p"} variant={"caption"}>
                No
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} className={classes.rowSpace}>
          <TextField
            onChange={(e) => onChangeInput(e, "description")}
            fullWidth
            id="outlined-textarea"
            label="Description"
            value={state.description}
            placeholder="Enter description"
            multiline
            variant="outlined"
            rows={4}
          />
        </Grid>
        <Grid item container justify={"flex-end"}>
          <Button
            onClick={(e) => props.modal(e, "close")}
            size="small"
            color="default"
            style={{ marginRight: "10px", color: "gary" }}
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => addNewScreen(e, props.viewType)}
            size="small"
            variant="contained"
            color="primary"
          >
            {props.viewType === "edit" ? "Update" : "Add Screen"}
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
}
